import axios from "../axios-defaults";

const updateProfile = (data) => {
  return axios.post("/api/v1/account", data);
};

const addPaymentMethod = (data) => {
  return axios.post("api/v1/bu/stripe/update-payment-method", {
    _method: "patch",
    ...data,
  });
};

const deletePaymentMethod = (data) => {
  return axios.post("/api/v1/stripe/delete", {
    _method: "delete",
    ...data,
  });
};

const getPaymentMethods = () => {
  return axios.get("api/v1/bu/stripe/payment-methods");
};

export {
  updateProfile,
  addPaymentMethod,
  getPaymentMethods,
  deletePaymentMethod,
};
