/* eslint-disable react-hooks/exhaustive-deps */
import { faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import {
  SET_REPORT_OBSERVATION,
  SET_REPORT_OBSERVATION_COMMENTS,
  SET_REPORT_OBSERVATION_PHOTO,
  SET_REPORT_OBSERVATION_PROTOCOL,
  SET_REPORT_OBSERVATION_ROOM_NAME,
  SET_REPORT_OBSERVATION_TESTS,
  SHOW_MODAL,
  SET_REPORT_OBSERVATION_PROTOCOL_SUBTITLE,
  SET_REPORT_OBSERVATION_SAMPLE_INFO,
  SET_REPORT_OBSERVATION_PROTOCOL_TITLE,
  REMOVE_REPORT_OBSERVATION_PROTOCOL,
  REMOVE_REPORT_OBSERVATION_COMMENTS
} from "../../../../../store/actions";
import { isEmptyHtml } from "../../../../shared/helpers";

export function ObservationsEditable(props) {
  const {
    children,
    textArea,
    ml,
    innerHtml,
    reduxKey,
    oi,
    ci,
    pi,
    ri,
    ti,
    si,
    editRoomName,
    editProtocol,
    protocolIndex,
    editProtocolSubTitle,
    editProtocolTitle,
    removeComment
  } = props;
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        show: true,
        value: children,
        setValue: (t) => ucp(reduxKey, t),
        textArea: textArea,
        ml: ml,
        richText: innerHtml,
        roomIndex: ri,
      },
    });
  };

  const removeProtocol = (path) => {
    dispatch({
      type: REMOVE_REPORT_OBSERVATION_PROTOCOL,
      payload: { path, roomIndex: ri, protocolIndex },
    });
  };

  const deleteComment = (path) => {
    dispatch({
      type: REMOVE_REPORT_OBSERVATION_COMMENTS,
      payload: { path, roomIndex: ri, observationIndex: oi, commentIndex: ci  },
    });
  };



  const ucp = (path, text) => {
    if (ci !== undefined) {
      dispatch({
        type: SET_REPORT_OBSERVATION_COMMENTS,
        payload: {
          path,
          data: text,
          observationIndex: oi,
          commentIndex: ci,
          roomIndex: ri,
        },
      });
    } else if (pi !== undefined) {
      dispatch({
        type: SET_REPORT_OBSERVATION_PHOTO,
        payload: {
          path,
          data: text,
          observationIndex: oi,
          photoIndex: pi,
          roomIndex: ri,
        },
      });
    } else if (si !== undefined) {
      dispatch({
        type: SET_REPORT_OBSERVATION_SAMPLE_INFO,
        payload: {
          path,
          data: text,
          observationIndex: oi,
          testIndex: ti,
          roomIndex: ri,
          sampleIndex: si,
        },
      });
    } else if (ti !== undefined) {
      dispatch({
        type: SET_REPORT_OBSERVATION_TESTS,
        payload: {
          path,
          data: text,
          observationIndex: oi,
          testIndex: ti,
          roomIndex: ri,
        },
      });
    } else if (editRoomName) {
      dispatch({
        type: SET_REPORT_OBSERVATION_ROOM_NAME,
        payload: { path, data: text, roomIndex: ri },
      });
    } else if (editProtocol) {
      dispatch({
        type: SET_REPORT_OBSERVATION_PROTOCOL,
        payload: { path, data: text, roomIndex: ri, protocolIndex },
      });
    } else if (editProtocolSubTitle) {
      dispatch({
        type: SET_REPORT_OBSERVATION_PROTOCOL_SUBTITLE,
        payload: { path, data: text, roomIndex: ri, protocolIndex },
      });
    } else if (editProtocolTitle) {
      dispatch({
        type: SET_REPORT_OBSERVATION_PROTOCOL_TITLE,
        payload: { path, data: text, roomIndex: ri },
      });
    } else {
      dispatch({
        type: SET_REPORT_OBSERVATION,
        payload: { path, data: text, observationIndex: oi, roomIndex: ri },
      });
    }
  };

  return (
    <div className="editable-text">
      <div className="tools pen">
        <FontAwesomeIcon icon={faPen} color="#2f481e" onClick={openModal} />
      </div>

      {innerHtml ? (
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmptyHtml(children) ? children : "Click to add text",
          }}
          className="no-margin"
          onClick={openModal}
        ></span>
      ) : (
        <span onClick={openModal} className="break-text">
          {children || "Click to add text"}
        </span>
      )}

      <div className="tools cross">
        <FontAwesomeIcon
          icon={faTimes}
          color="#b44a4a"
          onClick={() => {
            if (editProtocol) {
              removeProtocol(reduxKey);
            }
            else if(removeComment){
              // alert("heheheh")
              deleteComment(reduxKey)
            }
             else {
              ucp(reduxKey, "");
            }
          }}
        />
      </div>
    </div>
  );
}
