import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function ConfirmationModal(props) {
  const {
    msg,
    onDelete,
    yesBtnStyle,
    noBtnStyle,
    yesBtnText,
    noBtnText,
    hideOnPress,
  } = props;
  const [disableBtns, setDisableBtns] = useState(false);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <img
                className="iconstyle"
                src={
                  require("../../../../../assets/icons/attention_light20@3x.png")
                    .default
                }
                alt="icon"
              />
              <h5 style={{ fontWeight: "bolder" }}>Warning</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={
                require("../../../../../assets/icons/x_light50@3x.png").default
              }
              alt="icon"
              onClick={props.onHide}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <span>{msg}</span>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          disabled={disableBtns}
          variant="light"
          onClick={() => {
            onDelete(setDisableBtns);
            hideOnPress && props.onHide();
          }}
          style={{
            color: "#962424",
            background: "transparent",
            border: "none",
          }}
          id="yes"
        >
          {yesBtnText}
        </Button>
        <Button
          disabled={disableBtns}
          onClick={props.onHide}
          style={{
            marginRight: 25,
          }}
          id="no"
        >
          {noBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
