/* eslint-disable react-hooks/exhaustive-deps */
import { faCamera, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getBusinessUnit,
  getBUTransactionOnSA,
  toggleBUActivation,
  updateBUTransactionOnSA,
} from "../../../../../services/super-admin-service";
import { getErrorMsg, setHeader, showToast } from "../../../../shared/helpers";
import { regexes } from "../../../../shared/regexes";
import BillingTransactionTable from "../../../Settings/SettingTabs/BillingDetails/BillingTransactionTable";
import ConfirmationModal from "../../../Settings/Users/ConfirmationModal";
import BUStats from "./BUStats";
import BUSubscription from "./BUSubscription";

const BusinessUnitInfo = () => {
  const [loader, setloader] = useState(true);

  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState();
  const [editEnabled, setEditEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [profileData, setprofileData] = useState();
  const [disabledInput, setDisabledInput] = useState(false);
  const dispatch = useDispatch();
  const [opendel, setopendel] = useState(false);
  const { id } = useParams();
  const [disableStatusBtn, setDisableStatusBtn] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);

  const [disableFields, setDisableFields] = useState(true);
  const setDisabledFieldsChild = (value) => {
    setDisableFields(value);
  };

  useEffect(() => {
    setDisabledFieldsChild && setDisabledFieldsChild(disabledInput);
  }, [disabledInput]);

  const data = useSelector((state) => state.user.data);
  useEffect(() => {
    data && setloader(false);
  }, [data]);

  useEffect(() => {
    getBUData();
  }, []);

  const getBUData = () => {
    setloader(true);
    getBusinessUnit(id)
      .then((x) => {
        setprofileData(x.data);
        setPreview(x?.data?.logo_url);
        setHeader(dispatch, {
          title: `${x?.data?.company_name}`,
        });
        setloader(false);
      })
      .catch((err) => showToast(getErrorMsg(err) || "Something went wrong"));
  };

  const handleDeactivate = (setDisableBtns) => {
    setDisableBtns(true);
    toggleBUActivation(id, "blocked")
      .then(() => {
        showToast("Business unit has been de-activated successfully.", true);
        getBUData();
        setopendel(false);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to de-activate Business Unit.");
      })
      .finally(() => setDisableBtns(false));
  };

  const handleActivate = (setDisableBtns) => {
    setDisableBtns(true);
    toggleBUActivation(id, "active")
      .then(() => {
        showToast("Business unit has been activated successfully.", true);
        setShowActivateModal(false);
        getBUData();
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to activate Business Unit.");
      })
      .finally(() => setDisableBtns(false));
  };

  const edit = () => {
    if (
      profileData.company_name &&
      profileData.address.address1 &&
      profileData.address.city &&
      profileData.address.state &&
      profileData.address.zipcode
    ) {
      setIsSaving(true);
      updateBUTransactionOnSA(id, {
        company_name: profileData.company_name,
        street1: profileData.address.address1,
        street2: profileData.address.address2,
        city: profileData.address.city,
        state: profileData.address.state,
        zipcode: profileData.address.zipcode,
      })
        .then(() => {
          getBUData();
          showToast("BU details editted successfully", true);
        })
        .catch((err) => {
          showToast(getErrorMsg(err) || "Something went wrong");
        })
        .finally(() => {
          setEditEnabled(false);
          setIsSaving(false);
        });
    } else {
      showToast("Please fill all required fields");
    }
  };

  return (
    <>
      <div className="main">
        <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
          {loader ? (
            <div className="spinner-div">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <div className="main-content-area pb-0">
              <div className="d-flex justify-content-end">
                {!editEnabled ? (
                  <button
                    className="btn btn-primary"
                    disabled={false}
                    onClick={() => setEditEnabled(true)}
                    id="edit_fee"
                  >
                    Edit Company Details <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                ) : (
                  <div className="d-flex">
                    <button
                      className="btn btn-primary"
                      disabled={isSaving}
                      onClick={() => edit()}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-light ml-2 low-priority"
                      onClick={() => {
                        setEditEnabled(false);
                        getBUData();
                      }}
                      disabled={isSaving}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              <p className="TabsLabel d-flex">General Details</p>

              <div className="d-grid border-div">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row ">
                      <div className="col-md-12">
                        <div className="float-label mt-2">
                          <input
                            className="input-field form-control"
                            type="text"
                            maxLength="100"
                            required
                            id={`company_name`}
                            value={profileData.company_name}
                            disabled={!editEnabled}
                            onChange={(ev) => {
                              setprofileData({
                                ...profileData,
                                company_name: ev.target.value,
                              });
                            }}
                          />
                          <small className={"text-danger ml-1 visible"}>
                            {editEnabled && profileData?.company_name === ""
                              ? "Please Enter a Company Name."
                              : ""
                              ? errors?.[`name.${profileData?.company_name}`]
                              : errors?.[`name.${profileData?.company_name}`]}
                          </small>

                          <label
                            className={
                              editEnabled && profileData?.company_name === ""
                                ? `input-field`
                                : `Active input-field`
                            }
                            htmlFor={`company_name`}
                          >
                            Company Name
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6">
                        <div className="float-label mt-3">
                          <div>
                            <input
                              className="auth-input-field-extra icon form-control"
                              type="text"
                              id={`company_phone`}
                              value={new AsYouType("US").input(
                                profileData?.company_phone
                              )}
                              maxLength="16"
                              disabled={true}
                              onChange={(ev) => {
                                let value = ev.target.value;
                                value = value.replace(regexes.phoneInput, "");
                                setprofileData({
                                  ...profileData,
                                  company_phone: value,
                                });
                              }}
                            />
                          </div>

                          <small className={"text-danger ml-1 visible"}>
                            {editEnabled && profileData?.company_phone === ""
                              ? "Please Enter Company Phone Number."
                              : !regexes.phone.test(profileData?.company_phone)
                              ? "Enter Valid Phone number"
                              : errors?.[`name.${profileData?.company_phone}`]
                              ? errors?.[`name.${profileData?.company_phone}`]
                              : ""}
                          </small>

                          <label
                            className={
                              editEnabled && profileData?.company_phone === ""
                                ? `input-field`
                                : `Active input-field`
                            }
                            htmlFor={`company_phone`}
                          >
                            Company Phone
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="float-label mt-3 ">
                          <div>
                            <input
                              className="auth-input-field-extra icon form-control"
                              type="text"
                              id={`company_email`}
                              value={profileData?.company_email}
                              disabled={true}
                              maxLength="340"
                              onChange={(ev) => {
                                setprofileData({
                                  ...profileData,
                                  company_email: ev.target.value,
                                });
                              }}
                            />
                          </div>
                          <small className={"text-danger ml-1 visible"}>
                            {editEnabled && profileData?.company_email === ""
                              ? "Please Enter a ompany Name."
                              : editEnabled &&
                                !regexes.email.test(profileData?.company_email)
                              ? "Enter Valid Email Address"
                              : errors?.[`name.${profileData?.company_email}`]
                              ? errors?.[`name.${profileData?.company_email}`]
                              : ""}
                          </small>

                          <label
                            className={
                              editEnabled && profileData?.company_email === ""
                                ? `input-field`
                                : `Active input-field`
                            }
                            htmlFor={`company_email`}
                          >
                            Company Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="upload-avatar ml-4 mt-1">
                      <div className="image-area" style={{ borderRadius: 24 }}>
                        {!preview ? (
                          <span
                            className="upload-icon"
                            style={
                              preview
                                ? {
                                    backgroundColor: "transparent",
                                    borderRadius: 24,
                                  }
                                : { backgroundColor: "#fff", borderRadius: 24 }
                            }
                          >
                            <FontAwesomeIcon icon={faCamera} />
                          </span>
                        ) : null}
                        <img
                          width="120"
                          className="avatar-lg"
                          style={{ borderRadius: 24 }}
                          src={preview}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" border-div mt-3 pb-3">
                <p className="TabsLabel d-flex">Address</p>

                <div className="row">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="float-label mt-3">
                          <input
                            className="input-field form-control"
                            type="text"
                            maxLength="100"
                            required
                            id={`street1`}
                            value={profileData?.address?.address1}
                            disabled={!editEnabled}
                            onChange={(ev) => {
                              profileData.address.address1 = ev.target.value;
                              setprofileData({
                                ...profileData,
                              });
                            }}
                          />

                          <small className={"text-danger ml-1 visible"}>
                            {editEnabled &&
                            profileData?.address?.address1 === ""
                              ? "Please Enter a Street 1."
                              : ""
                              ? errors?.[
                                  `name.${profileData?.address?.address1}`
                                ]
                              : errors?.[
                                  `name.${profileData?.address?.address1}`
                                ]}
                          </small>
                          <label
                            className={
                              profileData?.address?.address1 === ""
                                ? `input-field`
                                : `Active input-field`
                            }
                            htmlFor={`street1`}
                          >
                            Street 1
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="float-label mt-3">
                          <div>
                            <input
                              className="auth-input-field-extra icon form-control"
                              type="text"
                              id={`street2`}
                              maxLength="100"
                              value={profileData?.address?.address2}
                              disabled={!editEnabled}
                              onChange={(ev) => {
                                profileData.address.address2 = ev.target.value;
                                setprofileData({
                                  ...profileData,
                                });
                              }}
                            />
                          </div>
                          <small className={"text-danger ml-1 visible"}>
                            {editEnabled &&
                            (profileData?.address?.address2 == null ||
                              profileData?.address?.address2 === "")
                              ? ""
                              : ""
                              ? errors?.[
                                  `name.${profileData?.address.address2}`
                                ]
                              : errors?.[
                                  `name.${profileData?.address.address2}`
                                ]}
                          </small>

                          <label
                            className={
                              profileData?.address?.address2 === null ||
                              profileData?.address?.address2 === ""
                                ? `input-field`
                                : `Active input-field`
                            }
                            htmlFor={`street2`}
                          >
                            Street 2 (Optional)
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="float-label">
                          <div>
                            <input
                              className="auth-input-field-extra icon form-control"
                              type="text"
                              maxLength="50"
                              id={`city`}
                              value={profileData?.address?.city}
                              disabled={!editEnabled}
                              onChange={(ev) => {
                                profileData.address.city = ev.target.value;
                                setprofileData({
                                  ...profileData,
                                });
                              }}
                            />
                          </div>
                          <small className={"text-danger ml-1 visible"}>
                            {editEnabled && profileData?.address?.city === ""
                              ? "Please Enter a City."
                              : ""
                              ? errors?.[`name.${profileData?.address.city}`]
                              : errors?.[`name.${profileData?.address.city}`]}
                          </small>

                          <label
                            className={
                              editEnabled && profileData?.address?.city === ""
                                ? `Active`
                                : `Active input-field`
                            }
                            htmlFor={`city`}
                          >
                            City
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 mt-3">
                        <div className="float-label">
                          <div>
                            <input
                              className="auth-input-field-extra icon form-control"
                              type="text"
                              id={`state`}
                              maxLength="50"
                              value={profileData?.address?.state}
                              disabled={!editEnabled}
                              onChange={(ev) => {
                                profileData.address.state = ev.target.value;
                                setprofileData({
                                  ...profileData,
                                });
                              }}
                            />
                          </div>
                          <small className={"text-danger ml-1 visible"}>
                            {editEnabled && profileData?.address?.state === ""
                              ? "Please Enter a State."
                              : ""
                              ? errors?.[`name.${profileData.address.state}`]
                              : errors?.[`name.${profileData.address.state}`]}
                          </small>

                          <label
                            className={
                              editEnabled && profileData?.address?.state === ""
                                ? `input-field`
                                : `Active input-field`
                            }
                            htmlFor={`state`}
                          >
                            State
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 mt-3">
                        <div className="float-label">
                          <div>
                            <input
                              className="auth-input-field-extra icon form-control"
                              type="text"
                              id={`zip_code`}
                              maxLength="50"
                              value={profileData?.address?.zipcode}
                              disabled={!editEnabled}
                              onChange={(ev) => {
                                profileData.address.zipcode = ev.target.value;
                                setprofileData({
                                  ...profileData,
                                });
                              }}
                            />
                          </div>
                          <small className={"text-danger ml-1 visible"}>
                            {editEnabled && profileData?.address?.zipcode === ""
                              ? "Please Enter a Zipcode."
                              : ""
                              ? errors?.[
                                  `name.${profileData?.address?.zipcode}`
                                ]
                              : errors?.[
                                  `name.${profileData?.address?.zipcode}`
                                ]}
                          </small>

                          <label
                            className={
                              editEnabled &&
                              profileData?.address?.zipcode === ""
                                ? `input-field`
                                : `Active input-field`
                            }
                            htmlFor={`zipcode`}
                          >
                            Zipcode
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" border-div  ">
                <BUSubscription profileData={profileData} />
              </div>

              <div className=" border-div mt-3 pb-4">
                <div className="white-bg-table ">
                  <h4 className="text-justify font-weight-bold">
                    Transactions
                  </h4>
                  <BillingTransactionTable
                    business_unit_id={id}
                    apiCall={getBUTransactionOnSA}
                  />
                </div>
              </div>

              <div className=" border-div mt-3 pb-3">
                <p className="TabsLabel d-flex">Stats</p>
                <BUStats profileData={profileData} />
              </div>

              <div className=" border-div mt-3 pb-2">
                <p className="TabsLabel d-flex">Status</p>
                {profileData.manager.status === "active" ? (
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => setopendel(true)}
                    style={{
                      backgroundColor: "#B44A4A",
                      borderColor: "#B44A4A",
                    }}
                    id="de_activate"
                    disabled={disableStatusBtn}
                  >
                    De-activate Business Unit
                  </button>
                ) : (
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => setShowActivateModal(true)}
                    style={{
                      backgroundColor: "#74b44a",
                      borderColor: "#74b44a",
                    }}
                    id="activate"
                    disabled={disableStatusBtn}
                  >
                    Activate Business Unit
                  </button>
                )}
                {/* <DeleteBUModal
                  show={opendel}
                  company_name={profileData?.company_name}
                  onHide={() => setopendel(false)}
                  msg={`Are you sure you want to de-activate the business unit ${profileData.company_name}?`}
                  deletestate={dims}
                  delfunc={handleDeactivate}
                /> */}
                <ConfirmationModal
                  show={opendel}
                  onHide={() => setopendel(false)}
                  msg={`Are you sure you want to de-activate the business unit ${profileData.company_name}?`}
                  onDelete={(setDisableBtns) =>
                    handleDeactivate(setDisableBtns)
                  }
                  yesBtnText="De-activate"
                  noBtnText="Don't De-activate"
                />
                <ConfirmationModal
                  show={showActivateModal}
                  onHide={() => setShowActivateModal(false)}
                  msg={`Are you sure you want to activate the business unit ${profileData.company_name}?`}
                  onDelete={(setDisableBtns) => handleActivate(setDisableBtns)}
                  yesBtnText="Activate"
                  noBtnText="Don't Activate"
                />
              </div>
            </div>
          )}
        </ScrollBar>
      </div>
    </>
  );
};

export default BusinessUnitInfo;
