import React, { useRef, useEffect, useState } from "react";
import styles from "./custom_input_field.module.css";

const CustomInputField = ({
  value,
  name,
  editorProps,
  type,
  maxLength,
  className,
  min = 0,
}) => {
  const [val, setVal] = useState(value);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const { value, name } = e.target;

    if (name === "sample_cost") {
      if (value.length > 8) {
        return value.slice(0, 7);
      }
    }
    setVal(value);
  };

  const handleOnBlur = (e) => {
    editorProps.onUpdate(val);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      editorProps.onUpdate(val);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div>
      <input
        {...editorProps}
        ref={inputRef}
        type={type}
        value={val}
        name={name}
        maxLength={maxLength}
        onChange={handleChange}
        onBlur={handleOnBlur}
        className={styles.editor_class + " " + className}
        onKeyDown={handleOnKeyDown}
        {...(type === "number" ? { min: min } : {})}
      />
    </div>
  );
};

export default CustomInputField;
