/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCaretRight,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SET_CONFIRMATION_MODAL } from "store/actions";
import {
  deleteProperties,
  getProperty,
} from "../../../services/PropertyService";
import Paginator from "../../components/Paginator/Paginator";
import {
  getErrorMsg,
  showToast,
  truncate,
  useDidUpdateEffect,
} from "../../shared/helpers";
import "./style.css";

const PropertyListComponent = (props) => {
  const { property, handleSelectProperty, getChecked } = props;
  return property?.data?.length > 0 ? (
    property?.data?.map((item, i) => (
      <tr key={`property-${i}`}>
        <td>
          <input
            checked={getChecked(item.id)}
            value={item.id}
            type="checkbox"
            onChange={handleSelectProperty}
          />
        </td>
        <td className="text-light20">
          <strong className="keep-all">
            {item?.created_at?.split(" ")[0].replaceAll("-", "/")}
          </strong>
        </td>
        <td className="text-secondary500">
          <strong className="one-line-ellipsis">
            {item?.address?.address1 +
              ", " +
              item?.address?.city +
              ", " +
              item?.address?.zipcode}
          </strong>
        </td>
        <td className="text-light20">
          <strong>
            {item?.customer?.first_name + " " + item?.customer?.last_name}
          </strong>
        </td>

        <td>
          <div>
            <Link
              style={{
                textDecoration: "none",
                width: "32px",
                cursor: "pointer",
              }}
              to={`/properties/${item.id}`}
            >
              <button className="next-btn ripple">
                <FontAwesomeIcon icon={faCaretRight} />
              </button>
            </Link>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="5">No record found</td>
    </tr>
  );
};

const Property = () => {
  const [propertyList, setpropertyList] = useState(null);

  const [loader, setloader] = useState(true);
  const [page, setpage] = useState(1);

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);

  const [selectedProperties, setSelectedProperties] = useState([]);
  const dispatch = useDispatch();

  useDidUpdateEffect(() => {
    setSkip(true);
    setloader(true);
    getProperty(searchField, page)
      .then((x) => {
        console.log(x.data);
        setpropertyList(x.data);
        setpage(1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  }, [searchField]);

  useEffect(() => {
    if (!skip) {
      loadData(searchField, page);
    } else {
      setSkip(false);
    }
  }, [page]);

  const handleSelectProperty = (e) => {
    const { value } = e.target;
    console.log(value);
    const isSelected = selectedProperties.includes(value);
    console.log(isSelected);
    if (isSelected) {
      setSelectedProperties(selectedProperties.filter((p) => p !== value));
    } else {
      setSelectedProperties([...selectedProperties, value]);
    }
  };

  const handleDeleteProperty = () => {
    setloader(true);
    const ids = selectedProperties.map((p) => Number(p));
    deleteProperties(ids)
      .then(() => {
        setloader(false);
        setSelectedProperties([]);
        showToast("Properties deleted successfully", true);
        loadData(searchField, page);
      })
      .catch((err) => {
        setloader(false);
        showToast(getErrorMsg(err) || "Unable to delete properties");
      });
  };

  const loadData = (searchField, page) => {
    setloader(true);
    getProperty(searchField, page)
      .then((x) => {
        console.log(x.data);
        setpropertyList(x.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  };

  const handleDelete = () => {
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        show: true,
        okText: "Delete",
        cancelText: "Cancel",
        title: "",
        message: "Are you sure you want to delete all selected properties?",
        onOk: handleDeleteProperty,
      },
    });
  };

  const getChecked = (id) => {
    //don't remove ==
    return selectedProperties.some((_id) => _id == id);
  };

  return (
    <>
      {loader ? (
        <Spinner
          animation="border"
          variant="success"
          className="app-spinner"
          style={{ left: "10%" }}
        />
      ) : (
        <div className="main">
          <div className="main-content-area">
            <div className="d-flex align-items-center justify-content-end">
              {selectedProperties.length > 0 && (
                <button
                  id="delete-property-button"
                  className="btn btn-primary delbtn mr-2"
                  onClick={handleDelete}
                >
                  Delete {selectedProperties.length} Selected Properties{" "}
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
              <Link
                style={{ textDecoration: "none" }}
                to="/properties/new"
                id="add_property"
              >
                <button id="add-property-button" className="btn btn-primary">
                  Add Property <FontAwesomeIcon icon={faPlus} />
                </button>
              </Link>
            </div>
            <ScrollBar className="mt-2">
              <div className="fixHeight">
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th width="10"></th>
                      <th>Date Added</th>
                      <th>Street/City/Zip</th>
                      <th>Customer Name</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <PropertyListComponent
                      property={propertyList}
                      {...{ handleSelectProperty, getChecked }}
                    />
                  </tbody>
                </table>
              </div>
            </ScrollBar>
            <nav className="mt-4 mb-3">
              <ul className="pagination justify-content-center mb-0 paginator">
                <Paginator
                  activePage={propertyList?.current_page}
                  itemsCountPerPage={propertyList?.per_page}
                  totalItemsCount={propertyList?.total}
                  onChange={(page) => {
                    setSkip(false);
                    setpage(page);
                  }}
                />
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Property;
