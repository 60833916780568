import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_REPORT_OBSERVATION_PROTOCOL } from "store/actions";
import DocumentView from "../DocumentView";
import TopRightTitle from "../TopRightTitle";
import { ObservationsEditable as OET } from "./ReportObservations";

export default function Protocol({ style, roomIndex, protocols }) {
  const roomName = useSelector(
    (state) => state.report.observations?.data[roomIndex]?.room_name
  );
  const subTitle = useSelector(
    (state) => state.report.observations?.data[roomIndex]?.protocol_header_text
  );

  const title = useSelector(
    (state) =>
      state.report?.observations?.data[roomIndex]
        ?.protocol_header_observation_consideration
  );

  const dispatch = useDispatch();
  const addProtocol = () => {
    dispatch({
      type: SET_REPORT_OBSERVATION_PROTOCOL,
      payload: {
        path: "",
        data: "",
        roomIndex,
        protocolIndex: protocols?.length,
      },
    });

    // setTimeout(() => {
    //   dispatch({
    //     type: SHOW_MODAL,
    //     payload: {
    //       show: true,
    //       value: text,
    //       setValue: (t) => ucp(t, roomIndex),
    //       ml: 10000,
    //       richText: true,
    //       roomIndex: roomIndex,
    //     },
    //   });
    // }, 100);
  };

  // const ucp = (text, i) => {
  //   dispatch({
  //     type: SET_REPORT_OBSERVATION_PROTOCOL,
  //     payload: {
  //       path: "",
  //       data: text,
  //       roomIndex,
  //       protocolIndex: i,
  //     },
  //   });
  // };

  return (
    <DocumentView style={style}>
      <div className="document-head" style={{ border: "none" }}>
        <header>
          <div className="grid-pg1">
            <div>
              <h3>
                <strong>
                  <OET
                    reduxKey="room_name"
                    ml="32"
                    ri={roomIndex}
                    editProtocolTitle
                  >
                    {title}
                  </OET>
                </strong>
              </h3>
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>

      <div className="observation-info">
        <div>
          <div className="sub-grid">
            <p>Room:</p>
            <p>
              <strong>
                <OET reduxKey="room_name" ml="50" ri={roomIndex} editRoomName>
                  {roomName}
                </OET>
              </strong>
            </p>
          </div>
        </div>
      </div>

      <div className="doc-body">
        <span style={{ fontSize: "20px" }}>
          <OET reduxKey="protocol" ml="50" ri={roomIndex} editProtocolSubTitle>
            {subTitle}
          </OET>
          <strong style={{ marginLeft: "-20px" }}>{roomName}</strong>
        </span>
        <ol className="report-protocol-list">
          {protocols.map((protocol, i) => (
            <li key={`protocol-${i}`}>
              <OET
                reduxKey="protocol"
                ml="10000"
                ri={roomIndex}
                protocolIndex={i}
                editProtocol
                innerHtml
              >
                {protocol}
              </OET>
            </li>
          ))}
        </ol>
        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <FontAwesomeIcon
            onClick={addProtocol}
            style={{
              margin: "2rem auto 3rem auto",
              fontSize: "60px",
              cursor: "pointer",
            }}
            icon={faPlusCircle}
            color="#dee2df"
          />
        </div>
      </div>
    </DocumentView>
  );
}
