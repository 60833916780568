import { getErrorMsg, showToast } from "main/shared/helpers";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { updateProtocolTitle } from "services/ProtocolService";

export default function EditProtocolTitle(props) {
  const { title, setTitle, protocolId, refresh } = props;
  const [isSaving, setIsSaving] = useState(false);

  const close = () => {
    props.onHide();
  };

  const handleUpdate = () => {
    setIsSaving(true);
    updateProtocolTitle(protocolId, { name: title })
      .then(() => {
        refresh();
        showToast("Protocol title updated successfully", true);
        setIsSaving(false);
        close();
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to update protocol title");
        setIsSaving(false);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <h5 style={{ fontWeight: "bolder" }}>Update Protocol Title</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={close}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>
        <div className="dialogbody">
          <span className="text-light20 font-weight-bold">
            Location of observations
          </span>
          <div className="mt-2">
            <div className="float-label">
              <div>
                <input
                  className="input-field form-control"
                  type="text"
                  id="location"
                  value={title}
                  onChange={(ev) => setTitle(ev.target.value)}
                  maxLength="50"
                />
              </div>
              <label
                className={`${title ? "Active " : ""}input-field`}
                htmlFor="location"
              >
                Location
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          id="cancel"
          className="text-secondary500 bg-transparent border-0"
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          id="save_observation"
          disabled={!title || isSaving}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
