import axios from "../axios-defaults";

const createInspector = (data) => {
  return axios.post("/api/v1/account", data);
};

const getInspectorDetail = (inspectorId) => {
  return axios.get(`/api/v1/bu/inspectors/${inspectorId}`);
};

const updateInspector = (inspectorId, data) => {
  return axios.post(`/api/v1/bu/inspectors/${inspectorId}`, {
    ...data,
    _method: "PATCH",
  });
};

const getSubAdminDetail = (subAdminId) => {
  return axios.get(`/api/v1/bu/sub-admin/${subAdminId}`);
};

const updateSubAdmin = (subAdminId, data) => {
  return axios.post(`/api/v1/bu/sub-admin/${subAdminId}`, {
    ...data,
    _method: "PATCH",
  });
};

const createSubAdmin = (data) => {
  axios.post("/api/v1/account", data);
};

const getUserList = (page = 1, search = "") => {
  return axios.get(`/api/v1/bu/users?page=${page}&search=${search}`);
};

const getBuContent = (type) => {
  return axios.get("/api/v1/bu/contents?type=" + type);
};

const updateBuContent = (type, data) => {
  return axios.post("/api/v1/bu/contents?type=" + type, {
    ...data,
    _method: "PATCH",
  });
};

const updateAccountBU = (data) => {
  return axios.post("/api/v1/account", { ...data, _method: "PATCH" });
};

const deleteBU = () => {
  return axios.post("/api/v1/account", { _method: "DELETE" });
};

export {
  createInspector,
  getBuContent,
  updateBuContent,
  updateAccountBU,
  deleteBU,
  getInspectorDetail,
  updateInspector,
  getUserList,
  createSubAdmin,
  getSubAdminDetail,
  updateSubAdmin,
};
