import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 5, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};
export default function DollarInput({ label, ...props }) {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });
  return (
    <div className={`float-label`}>
      <label className={props.value ? "Active input-field" : "input-field"}>
        {label}
      </label>
      <MaskedInput mask={currencyMask} {...props} />
    </div>
  );
}
