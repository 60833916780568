/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Card, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ProductService from "../../../services/ProductsService";
import { showToast } from "../../shared/helpers";
import Checkout from "./CreditCard";
import { getTestsList } from "services/TestService";
import "./style.css";

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PK);

function AddCard(props) {
  const { setupIntent, data } = props;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <img
                className="iconstyle"
                src={
                  require("../../../assets/icons/toolkit_light20@3x.png")
                    .default
                }
                alt="icon"
              />
              <h5 style={{ fontWeight: "bolder" }}>Add Card</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={props.onHide}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <Checkout
              clientSecret={setupIntent?.client_secret}
              priceDetails={data}
            />
          </Elements>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border"></Modal.Footer>
    </Modal>
  );
}

function Subscription() {
  const userInfo = useSelector((state) => state.user.data);
  const history = useHistory();
  const { failed } = useParams();
  const [isSubscribed, setisSubscribed] = useState(false);

  // const subscription = useSelector((state) => state.subscriptionReducer.data);
  // console.log(isSubscribed, "-00000000000099999999999");

  useEffect(() => {
    getTestsList()
      .then((x) => {
        // console.log(x, "================");
      })
      .catch((err) => {
        // console.log(err.response.data, "===============");
        if (err.response.data.subscription === null) {
          setisSubscribed(false);
          console.log("================");
        } else {
          setisSubscribed(true);
          console.log("11111111111111");
        }
      });
  }, []);

  useEffect(() => {
    if (failed) {
      showToast(
        "Your subscription has failed to be completed.\nPlease check your credit card information."
      );
    }
  }, [failed]);

  // if (userInfo?.isSubscribed) {
  //   history.push("/tools/tests");
  // }

  const [modalShow, setModalShow] = React.useState(false);

  const [loader, setloader] = useState(true);
  const [setupData, setSetupData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [setupIntent, setsetupIntent] = useState(null);
  const [coupon, setcoupon] = useState("");
  const [coupondis, setcoupondis] = useState(false);

  const [totalCost, settotalCost] = useState(0);
  const [discount, setdiscout] = useState(0);

  console.log(
    discount,
    setupData,
    productData,
    totalCost,
    coupondis,
    "-00000000000099999999999"
  );

  useEffect(() => {
    setloader(true);
    ProductService.getProducts().then((x) => {
      console.log(x.data, "data");
      setSetupData(
        x.data.find((d) => d?.metadata?.product_type === "setup_fees")
      );
      console.log(setupData);

      setProductData(
        x.data.find((d) => d?.metadata?.product_type === "basic_subscription")
      );

      ProductService.getSetupIntent().then((st) => {
        console.log(st.data);
        setsetupIntent(st.data);
        setloader(false);
      });
    });
  }, []);

  useEffect(() => {
    if (setupData && productData) {
      settotalCost(
        productData?.prices.find((x) => x.type === "recurring").unit_amount +
          (!isSubscribed ? setupData?.prices[0].unit_amount : "")
      );
    }
  }, [productData, setupData]);

  const validate = () => {
    setcoupondis(true);
    ProductService.validateCoupon(coupon)
      .then((x) => {
        console.log(x);
        setdiscout(x.data.amount_off);
      })
      .catch((err) => {
        let msg = "Something went wrong";
        setdiscout(0);
        console.log(err.response);
        if (err.response.status === 400) {
          msg = err.response.data.message;
        }
        showToast(msg);
      })
      .finally(() => setcoupondis(false));
  };

  return (
    <div className="main">
      <div className="main-content-area">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-primary ml-auto"
            onClick={() => setModalShow(true)}
          >
            Pay Now
          </button>
          {console.log(productData?.prices)}

          {/* {console.log(?.prices)} */}
          {loader ? null : (
            <AddCard
              // data={{
              //   one_time_price_ids: [setupData.prices[0].id],
              //   recurring_price_ids: [
              //     productData?.prices.find((x) => x.type === "recurring").id,
              //   ],
              //   coupon: discount > 0 ? coupon : "",
              // }}
              data={
                isSubscribed
                  ? {
                      recurring_price_ids: [
                        productData?.prices.find((x) => x.type === "recurring")
                          .id,
                      ],
                      coupon: discount > 0 ? coupon : "",
                    }
                  : {
                      one_time_price_ids: [setupData.prices[0].id],
                      recurring_price_ids: [
                        productData?.prices.find((x) => x.type === "recurring")
                          .id,
                      ],
                      coupon: discount > 0 ? coupon : "",
                    }
              }
              show={modalShow}
              onHide={() => setModalShow(false)}
              setupIntent={setupIntent}
            />
          )}
        </div>
        <div className="centerdiv">
          {loader ? (
            <Spinner
              animation="border"
              variant="success"
              className="app-spinner"
              style={{ left: "10%" }}
            />
          ) : (
            <>
              <Card className="cardstyle">
                <Card.Body>
                  <div className="cardinnerstyle">
                    <h5
                      className="text-light20"
                      style={{ fontSize: 17, fontWeight: 700 }}
                    >
                      {productData?.name}
                    </h5>
                    <h5
                      className="text-light20"
                      style={{ fontSize: 29, fontWeight: "bold" }}
                    >
                      $
                      {(
                        productData?.prices.find((x) => x.type === "recurring")
                          .unit_amount / 100
                      ).toFixed(2)}
                      /mo
                    </h5>
                    {!isSubscribed ? (
                      <h5 className="text-light20" style={{ fontSize: 15 }}>
                        Setup fee is $
                        {(setupData.prices[0].unit_amount / 100).toFixed(2)}
                      </h5>
                    ) : (
                      ""
                    )}
                    {!isSubscribed ? (
                      <>
                        <hr />
                        <h5 className="text-light20" style={{ fontSize: 19 }}>
                          Comes with{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {productData
                              ? productData.metadata?.inspector_credits
                              : "NAN"}{" "}
                            Inspector Licenses
                          </span>
                        </h5>
                        <h5 className="text-light20" style={{ fontSize: 19 }}>
                          Comes with{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {productData
                              ? productData.metadata?.inspection_credits
                              : "NAN"}{" "}
                            Inspection Credits
                          </span>
                        </h5>
                        <hr />
                      </>
                    ) : (
                      ""
                    )}
                    <div className="couponStyle">
                      <h4>Coupon Code (Optional)</h4>
                      <div style={{ display: "flex" }}>
                        <input
                          className="form-control auth-input-field"
                          placeholder="Enter Coupon Code"
                          type="text"
                          maxLength="50"
                          onChange={(ev) => setcoupon(ev.target.value)}
                          style={{ marginRight: 10 }}
                        />

                        <button
                          className="btn btn-primary ml-auto"
                          style={{ minWidth: 40 }}
                          disabled={coupondis || !coupon}
                          onClick={validate}
                        >
                          <FontAwesomeIcon
                            style={{ marginLeft: 0 }}
                            icon={faPlus}
                          />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div style={{ padding: "0 30px", textAlign: "center" }}>
                      {discount > 0 ? (
                        <>
                          <h5 className="text-light20">
                            Cost{" "}
                            <span style={{ marginLeft: 3, fontWeight: "bold" }}>
                              ${(totalCost / 100).toFixed(2)}
                            </span>
                          </h5>
                          <h5 className="text-light20">
                            Discount{" "}
                            <span style={{ marginLeft: 3, fontWeight: "bold" }}>
                              -${(discount / 100).toFixed(2)}
                            </span>
                          </h5>
                          <h5 className="text-light20">
                            Total{" "}
                            <span style={{ marginLeft: 3, fontWeight: "bold" }}>
                              ${((totalCost - discount) / 100).toFixed(2)}
                            </span>
                          </h5>
                        </>
                      ) : (
                        <h5 className="text-light20">
                          Total Cost{" "}
                          <span style={{ marginLeft: 3, fontWeight: "bold" }}>
                            ${(totalCost / 100).toFixed(2)}
                          </span>
                        </h5>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <div className="text-light20 descstyle">
                <p>
                  Reocurring monthly subscription{" "}
                  {productData?.metadata?.inspectors} inspector license and{" "}
                  {productData?.metadata?.properties} property credits. for
                  additional inspector licenses and property credits see
                  'Billing Details' found in 'Settings'.
                </p>
                <p>
                  {productData?.metadata?.properties} additional property
                  credits will be billed automatically once your account reaches
                  1 property credit.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Subscription;
