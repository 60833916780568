/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { updateAWS } from "services/s3";
import "./App.css";
import { getLocalUser } from "./main/shared/helpers";
import { authRoles } from "./navigation/route-config";
import AppRouter from "./navigation/router";
import { profileRequest } from "./services/auth-service";
import { LOG_IN } from "./store/actions";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

setInterval(ReactTooltip.rebuild, 500);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(async () => {
    let localUser = null;
    let localUserType = null;
    try {
      localUser = getLocalUser();

      console.log(localUser, "localUserlocalUserlocalUserlocalUser");
      localUserType = atob(localStorage.getItem("user_type"));
    } catch (e) {
      console.log("get local user failed", e);
    }
    if (
      localUser !== null &&
      localUserType !== authRoles.buSubAdmin &&
      localUserType !== authRoles.buInspector
    ) {
      dispatch({
        type: LOG_IN,
        payload: localUser,
      });

      await updateAWS();
      setIsLoading(false);
    } else if (localStorage.getItem("access_token")) {
      profileRequest()
        .then(async (res) => {
          console.log("profile", res);
          await updateAWS();
          dispatch({
            type: LOG_IN,
            payload: res.data,
          });
        })
        .catch((err) => console.log(err.response))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return isLoading ? (
    <Spinner animation="border" variant="success" className="app-spinner" />
  ) : (
    <AppRouter />
  );
}

export default App;
