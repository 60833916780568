/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Service from "../../../services/LabsService";
import Paginator from "../../components/Paginator/Paginator";
import { truncate, useDidUpdateEffect } from "../../shared/helpers";

export default function Labs(props) {
  const [labs, setLabs] = useState({});

  const [loader, setloader] = useState(true);
  const [page, setpage] = useState(1);

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);
  //to skip useEffect

  useDidUpdateEffect(() => {
    setSkip(true);
    setloader(true);
    Service.getLabs(0, searchField)
      .then((x) => {
        setpage(1);
        setLabs(x.data);  
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
  }, [searchField]);

  useEffect(() => {
    if (!skip) {
      setloader(true);
      Service.getLabs(page)
        .then((x) => setLabs(x.data))
        .catch((err) => console.log(err))
        .finally(() => setloader(false));
    } else {
      setSkip(false);
    }
  }, [page]);

  return (
    <>
      {loader ? (
        <Spinner
          animation="border"
          variant="success"
          className="app-spinner"
          style={{ left: "10%" }}
        />
      ) : (
        <div className="main">
          <div className="main-content-area">
            <div className="d-flex" style={{ justifyContent: "flex-end" }}>
              <Link style={{ textDecoration: "none" }} to="/tools/labs/new">
                <button id="add-lab-button" className="btn btn-primary ml-auto">
                  Add Lab <FontAwesomeIcon icon={faPlus} />
                </button>
              </Link>
            </div>
            <ScrollBar className="mt-2">
              <div className="fixHeight">
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th>Lab Name</th>
                      <th>Contact Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {labs?.data && labs.data.length !== 0 ? (
                      labs.data.map((lab, i) => (
                        <tr key={lab.email} id={`lab-${i}`}>
                          <td id={`lab_${i}_name`}>
                            <strong>{truncate(lab.name)}</strong>
                          </td>
                          <td id={`lab_${i}_contact`}>
                            <strong>{truncate(lab.contact_name)}</strong>
                          </td>
                          <td id={`lab_${i}_email`}>
                            <p>{truncate(lab.email)}</p>
                          </td>
                          <td id={`lab_${i}_phone`}>
                            <a href={`tel:${lab.phone}`}>
                              {truncate(new AsYouType("US").input(lab.phone))}
                            </a>
                          </td>
                          <td id={`lab_${i}_detail_button`}>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/tools/labs/update/${lab.id}`}
                            >
                              <button className="next-btn ripple">
                                <FontAwesomeIcon icon={faCaretRight} />
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No record found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </ScrollBar>
            {labs?.data && labs.data.length !== 0 ? (
              <nav className="mt-4 mb-3">
                <ul className="pagination justify-content-center mb-0 paginator">
                  <Paginator
                    activePage={labs?.current_page}
                    itemsCountPerPage={labs?.per_page}
                    totalItemsCount={labs?.total}
                    onChange={(page) => {
                      setSkip(false);
                      setpage(page);
                    }}
                  />
                </ul>
              </nav>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}
