import React from "react";

import { Button, Modal } from "react-bootstrap";

export default function Delete(props) {
  const { msg, delfunc, deletestate } = props;

  return (
    <Modal
      show={props.show}
      onHide={props.onHideDelete}
      size="sm"
      aria-labelledby="contained-modal-title"
      centered
      backdropClassName="foo-modal-backdrop"
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title">
          <div className="dialogheader">
            <div className="d-flex">
              <img
                className="iconstyle"
                src={
                  require("../../../assets/icons/attention_light20@3x.png")
                    .default
                }
                alt="icon"
              />
              <h5 style={{ fontWeight: "bolder" }}>Warning</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={props.onHideDelete}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <span>{msg}</span>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          disabled={deletestate}
          variant="light"
          onClick={() => {
            delfunc();
          }}
          style={{
            color: "#962424",
            background: "transparent",
            border: "none",
          }}
          id="delete"
        >
          Delete
        </Button>
        <Button
          disabled={deletestate}
          style={{ marginRight: 25 }}
          onClick={props.onHideDelete}
          id="dont_delete"
        >
          Don't Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
