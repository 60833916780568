/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteButton from "main/components/DeleteButton/DeleteButton";
import { getErrorMsg, showToast } from "main/shared/helpers";
import React, { Suspense, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useParams } from "react-router-dom";
import {
  deleteObservationImage,
  getInspectionRooms,
  getObservationComments,
  getObservationImages,
  getObservationTests,
} from "../../../../../services/ObservationService";
import ImageBox from "../../../../components/ImageBox/ImageBox";
import Paginator from "../../../../components/Paginator/Paginator";
import AddCaption, { AddCaptionModal } from "./AddCaption";
import CommentRow from "./CommentRow";
import EmptyListIndicator from "./EmptyListIndicator";
import ObservationSubList from "./ObservationSubList";
import TestRow from "./TestRow";

const AddObservationModal = React.lazy(() => import("./AddObservationModal"));
const AddRoomModal = React.lazy(() => import("./AddRoomModal"));
const AddTestModal = React.lazy(() => import("./AddTestModal"));
const AddPhotoModal = React.lazy(() => import("./AddPhotoModal"));
const AddCommentsModal = React.lazy(() => import("./AddCommentsModal"));

// import { useDispatch, useSelector } from "react-redux";

export default function Observation() {
  const { insp_id } = useParams();
  const [loader, setLoader] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [editRoom, setEditRoom] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [selectedObservation, setSelectedObservation] = useState("");
  const [selectedObservationId, setSelectedObservationId] = useState(null);
  const [page, setpage] = useState(1);
  const [showAddObservationModal, setShowAddObservationModal] = useState(false);
  const [showAddRoomModal, setShowAddRoomModal] = useState(false);
  const [showAddTestModal, setShowAddTestModal] = useState(false);
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const [editObservation, setEditObservation] = useState({});

  const [testsData, setTestsData] = useState([]);
  const [testPage, setTestPage] = useState(1);
  const [activePageTest, setActivePageTest] = useState(1);
  const [itemsCountPerPageTest, setItemsCountPerPageTest] = useState(0);
  const [totalItemsCountTest, setTotalItemsCountTest] = useState(0);
  const [testLoader, setTestLoader] = useState(false);

  const [commentsData, setCommentsData] = useState([]);
  const [commentPage, setCommentPage] = useState(1);
  const [activePageComment, setActivePageComment] = useState(1);
  const [itemsCountPerPageComment, setItemsCountPerPageComment] = useState(0);
  const [totalItemsCountComment, setTotalItemsCountComment] = useState(0);
  const [commentLoader, setCommentLoader] = useState(false);

  const [photos, setPhotos] = useState([]);
  const [photosPage] = useState(1);
  const [photosLoader, setPhotosLoader] = useState(false);
  const [refreshSubList, setRefreshSubList] = useState(true);
  const [refreshRoomList, setRefreshRoomList] = useState(true);
  const [refreshTestList, setRefreshTestList] = useState(true);
  const [refreshCommentList, setRefreshCommentList] = useState(true);
  const [refreshPhotoList, setRefreshPhotoList] = useState(true);

  const [editCaptionModal, setEditCaptionModal] = useState(false);
  const [captionData, setCaptionData] = useState({ id: null, caption: "" });

  const [noData, setNoData] = useState(false);

  useEffect(() => {
    getRooms(page);
  }, [page, refreshRoomList]);

  useEffect(() => {
    if (selectedObservationId) {
      getComments(selectedObservationId, commentPage);
    }
  }, [commentPage, refreshCommentList]);

  useEffect(() => {
    if (selectedObservationId) {
      getTests(selectedObservationId, testPage);
    }
  }, [testPage, refreshTestList]);

  useEffect(() => {
    if (selectedObservationId) {
      getPhotos(selectedObservationId, photosPage);
    }
  }, [photosPage, refreshPhotoList]);

  useEffect(() => {
    if (selectedObservationId) {
      getDetails(selectedObservationId);
    }
  }, [selectedObservationId]);

  const getRooms = (page) => {
    setLoader(true);
    getInspectionRooms(insp_id, page)
      .then((res) => {
        console.log(res.data);
        setRooms(res.data.data);
        if (res.data.data.length > 0) {
          setSelectedRoom(res.data.data[0].room_name);
          setSelectedRoomId(res.data.data[0].id);
          setActivePage(res.data.current_page);
          setItemsCountPerPage(res.data.per_page);
          setTotalItemsCount(res.data.total);
          setNoData(false);
        } else {
          setNoData(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false));
  };

  const getDetails = (id) => {
    if (id) {
      getTests(id, testPage);
      getComments(id, commentPage);
      getPhotos(id, photosPage);
    }
  };

  const getTests = (id, page) => {
    setTestLoader(true);
    getObservationTests(id, page)
      .then((res) => {
        setTestsData(res.data.data);
        setItemsCountPerPageTest(res.data.per_page);
        setTotalItemsCountTest(res.data.total);
        setActivePageTest(res.data.currrent);
      })
      .catch((err) => console.log(err))
      .finally(() => setTestLoader(false));
  };

  const getComments = (id, page) => {
    setCommentLoader(true);
    getObservationComments(id, page)
      .then((res) => {
        console.log(res.data);
        setCommentsData(res.data.data);
        setItemsCountPerPageComment(res.data.per_page);
        setTotalItemsCountComment(res.data.total);
        setActivePageComment(res.data.currrent);
      })
      .catch((err) => console.log(err))
      .finally(() => setCommentLoader(false));
  };

  const getPhotos = (id, page) => {
    setPhotosLoader(true);
    getObservationImages(id, page)
      .then((res) => {
        setPhotos(res.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setPhotosLoader(false));
  };

  const handleDeleteObservationPhoto = (id) => {
    return (setLoading) => {
      setLoading(true);
      deleteObservationImage(id)
        .then(() => {
          showToast("Photo deleted successfully", true);
          setLoading(false);
          setRefreshPhotoList((s) => !s);
        })
        .catch((err) => {
          console.log(err);
          showToast(getErrorMsg(err) || "Error deleting photo");
          setLoading(false);
        });
    };
  };

  const onEditRoom = (room) => {
    setEditRoom(room);
    setShowAddRoomModal(true);
  };

  return (
    <React.Fragment>
      <div className="tab-child">
        <div className="leftside" style={{ padding: "15px 5px 5px 20px" }}>
          <div className="leftside-header">
            <h5 className="mainheading">Rooms</h5>
            <button
              className="btn btn-primary ml-auto"
              onClick={() => setShowAddRoomModal(true)}
            >
              Add Room <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          <ScrollBar style={{ height: "auto" }}>
            <ul
              className={loader ? "ul-style list-spinner" : "ul-style"}
              style={{
                height: "calc(100vh - 258px)",
                padding: "0px 15px 0px 10px",
              }}
            >
              {loader ? (
                <Spinner animation="border" variant="success" />
              ) : (
                rooms.map((item, i) => (
                  <ObservationSubList
                    name={item.room_name}
                    key={item.room_name + "-" + i}
                    selectedRoomId={selectedRoomId}
                    onOpen={() => {
                      setSelectedRoom(item.room_name);
                      setSelectedRoomId(item.id);
                    }}
                    setSelectedObservation={setSelectedObservation}
                    openAddObservationModal={() =>
                      setShowAddObservationModal(true)
                    }
                    roomId={item.id}
                    setSelectedObservationId={setSelectedObservationId}
                    refreshSubList={refreshSubList}
                    refreshRoomList={() => setRefreshRoomList((s) => !s)}
                    setEditObservation={setEditObservation}
                    onEditRoom={() => onEditRoom(item)}
                  />
                ))
              )}
              {noData && (
                <li className="liststyle listActive">No rooms added!</li>
              )}
            </ul>
          </ScrollBar>
          <Paginator
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            onChange={(page) => setpage(page)}
          />
        </div>
        <ScrollBar
          className="round-top"
          style={{
            height: "calc(100vh - 140px)",
            overflowY: "auto",
            width: "100%",
            background: "#e7ece8",
          }}
        >
          <div className="rightside" style={{ width: "100%" }}>
            <div className="rightsideinner">
              {!noData && selectedObservationId && (
                <React.Fragment>
                  <div className="observation-details-section">
                    <span className="text-light20 protocol-top-text">
                      {`${selectedRoom} - ${selectedObservation}`}
                    </span>
                    <hr className="bold-hr" />
                  </div>
                  <div className="observation-details-section">
                    <div className="d-flex align-items-center">
                      <span
                        className="text-light20 font-weight-bold"
                        style={{ fontSize: "18px" }}
                      >
                        Tests
                      </span>
                      <span
                        className="ml-auto"
                        data-tip={
                          testsData.length === 5
                            ? "Maximum 5 tests can be created"
                            : ""
                        }
                      >
                        <button
                          className="btn btn-primary"
                          onClick={() => setShowAddTestModal(true)}
                          disabled={testsData.length === 5}
                        >
                          Add Test <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </span>
                    </div>
                    <ScrollBar className="mt-2">
                      <div
                        className={
                          testLoader ? "observation-detail-list-spinner" : ""
                        }
                        style={{ maxHeight: "250px" }}
                      >
                        {testLoader ? (
                          <Spinner animation="border" variant="success" />
                        ) : testsData.length !== 0 ? (
                          <table
                            id="test_table"
                            className="table td-middle pr-3 pl-3 pb-3 spacing"
                          >
                            <tbody className="">
                              {testsData.map((test, i) => (
                                <TestRow
                                  test={test}
                                  i={i}
                                  key={`test_${i}`}
                                  refresh={() => setRefreshTestList((s) => !s)}
                                />
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <EmptyListIndicator text="No Tests" />
                        )}
                      </div>
                    </ScrollBar>
                    {testsData.length !== 0 && (
                      <Paginator
                        activePage={activePageTest}
                        itemsCountPerPage={itemsCountPerPageTest}
                        totalItemsCount={totalItemsCountTest}
                        onChange={(page) => setTestPage(page)}
                      />
                    )}
                  </div>

                  {/* show comments list start */}
                  <div className="observation-details-section">
                    <div className="d-flex align-items-center">
                      {/* comments heading start */}
                      <span
                        className="text-light20 font-weight-bold"
                        style={{ fontSize: "18px" }}
                      >
                        Comments
                      </span>
                      {/* comments heading end */}
                      {/* add comments button start */}
                      <span
                        className="ml-auto"
                        data-tip={
                          commentsData.length === 12
                            ? "Maximum 12 comments can be created"
                            : ""
                        }
                      >
                        <button
                          className="btn btn-primary ml-auto"
                          onClick={() => setShowAddCommentsModal(true)}
                          disabled={commentsData.length === 12}
                        >
                          Add Comment <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </span>
                      {/* add comments button end */}
                    </div>
                    {/* comments list show start */}
                    <ScrollBar
                      className="mt-2"
                      options={{
                        suppressScrollX: true,
                        useBothWheelAxes: false,
                      }}
                    >
                      <div
                        className={
                          commentLoader ? "observation-detail-list-spinner" : ""
                        }
                        style={{ maxHeight: "250px" }}
                      >
                        {commentLoader ? (
                          <Spinner animation="border" variant="success" />
                        ) : commentsData.length !== 0 ? (
                          <table
                            id="comment_table"
                            className="table td-middle pr-3 pl-3 pb-3 spacing"
                          >
                            <tbody>
                              {commentsData.map((comment, i) => (
                                <CommentRow
                                  key={`comment_${i}`}
                                  comment={comment}
                                  i={i}
                                  setRefreshList={setRefreshCommentList}
                                />
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <EmptyListIndicator text="No Comments" />
                        )}
                      </div>
                    </ScrollBar>
                    {/* comments list show end */}
                    {/* comments pagination start */}
                    {commentsData.length !== 0 && (
                      <Paginator
                        activePage={activePageComment}
                        itemsCountPerPage={itemsCountPerPageComment}
                        totalItemsCount={totalItemsCountComment}
                        onChange={(page) => setCommentPage(page)}
                      />
                    )}
                    {/* comments pagination end */}
                  </div>
                  {/* show comments list end */}

                  <div className="observation-details-section">
                    <div className="d-flex align-items-center">
                      <span
                        className="text-light20 font-weight-bold"
                        style={{ fontSize: "18px" }}
                      >
                        Photos
                      </span>

                      <span
                        className="ml-auto"
                        data-tip={
                          photos.length === 8
                            ? "Maximum 8 photos can be uploaded"
                            : ""
                        }
                      >
                        <button
                          className="btn btn-primary ml-auto"
                          onClick={() => setShowAddPhotoModal(true)}
                          disabled={photos.length === 8}
                        >
                          Add Photo <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </span>
                    </div>
                    <ScrollBar className="mt-2">
                      <div
                        className={`${
                          photos.length !== 0 ? "d-flex flex-row p-3 " : ""
                        }${
                          photosLoader ? "observation-detail-list-spinner" : ""
                        }`}
                        style={{ maxHeight: "100%" }}
                      >
                        {photosLoader ? (
                          <Spinner animation="border" variant="success" />
                        ) : photos.length !== 0 ? (
                          photos.map((photo, i) => (
                            <div style={{ position: "relative" }}>
                              <div
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "7px",
                                }}
                              >
                                <DeleteButton
                                  deleteFunction={handleDeleteObservationPhoto(
                                    photo.id
                                  )}
                                  message={
                                    "Are you sure you want to delete this photo?"
                                  }
                                />
                              </div>
                              <ImageBox
                                key={`photos_${i}`}
                                src={photo.url}
                                style={{
                                  border: "none",
                                  height: "145px",
                                  width: "145px",
                                  boxShadow:
                                    "0 0 15px 1px rgba(132,133,122,0.5)",
                                }}
                                imgStyle={{
                                  height: "140px",
                                  width: "145px",
                                }}
                              />
                              <AddCaption
                                caption={photo.caption}
                                edit={() => {
                                  setEditCaptionModal(true);
                                  setCaptionData({
                                    id: photo.id,
                                    caption: photo.caption,
                                  });
                                }}
                              />
                            </div>
                          ))
                        ) : (
                          <EmptyListIndicator text="No Photos" />
                        )}
                      </div>
                    </ScrollBar>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </ScrollBar>
      </div>

      <Suspense fallback={null}>
        <AddObservationModal
          show={showAddObservationModal}
          onHide={() => setShowAddObservationModal(false)}
          roomId={selectedRoomId}
          setRefreshSubList={setRefreshSubList}
          editObservation={editObservation}
          setEditObservation={setEditObservation}
        />
      </Suspense>

      <Suspense fallback={null}>
        <AddRoomModal
          inspectionId={insp_id}
          show={showAddRoomModal}
          onHide={() => setShowAddRoomModal(false)}
          setRefreshRoomList={setRefreshRoomList}
          editRoom={editRoom}
          setEditRoom={setEditRoom}
        />
      </Suspense>

      <Suspense fallback={null}>
        <AddTestModal
          setRefreshTestList={setRefreshTestList}
          observationId={selectedObservationId}
          show={showAddTestModal}
          onHide={() => setShowAddTestModal(false)}
        />
      </Suspense>

      <Suspense fallback={null}>
        <AddPhotoModal
          setRefreshList={setRefreshPhotoList}
          observationId={selectedObservationId}
          show={showAddPhotoModal}
          onHide={() => setShowAddPhotoModal(false)}
          photosLength={photos.length}
        />
      </Suspense>
      {/* comments add button start */}
      <Suspense fallback={null}>
        <AddCommentsModal
          show={showAddCommentsModal}
          onHide={() => setShowAddCommentsModal(false)}
          setRefreshList={setRefreshCommentList}
          observationId={selectedObservationId}
          d_coments=""
        />
      </Suspense>

      <AddCaptionModal
        show={editCaptionModal}
        onHide={() => setEditCaptionModal(false)}
        data={captionData}
        refresh={() => setRefreshPhotoList((s) => !s)}
      />
      {/* comments add button end */}
    </React.Fragment>
  );
}
