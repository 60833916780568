/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReportTemplateEditor from "../../components/Editor/ReportTemplateEditor";
import {
  getErrorMsg,
  showToast,
  useDidUpdateEffect,
} from "../../shared/helpers";
import "./reportTemplates.css";
import sectionLengths from "./LengthValidation";
import ScrollBar from "react-perfect-scrollbar";
import {
  getReportTemplates,
  updateReportTemplates,
} from "../../../services/ReportTemplatesService";
import { useSelector } from "react-redux";

function ReportTemplates() {
  const [reports, setreports] = useState({});

  const [id, setId] = useState(null);
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");

  const [loader, setloader] = useState(true);
  const [active, setactive] = useState(0);
  const [update, setupdate] = useState(0);
  const [disableFields, setDisableFields] = useState(true);
  const [disableMD, setDisableMD] = useState(false);
  const [disableRD, setDisableRD] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const reportList = useRef(null);

  const searchField = useSelector((state) => state.header.searchField);

  useDidUpdateEffect(() => {
    setloader(true);
    getReportTemplates(searchField)
      .then((x) => {
        console.log(x.data);
        setreports(x.data);
      })
      .catch((err) => {
        console.log(err);
        showToast("Something went wrong");
      })
      .finally(() => {
        setloader(false);
        setLoader2(false);
      });
  }, [searchField]);

  const handleSave = (id, data) => {
    setDisableFields(true);
    setloader(true);
    setLoader2(true);
    console.log(data, "...................................");
    updateReportTemplates(id, data)
      .then(() => {
        setupdate((state) => state + 1);
        showToast("Report template updated successfully.", true);
      })
      .catch(() => {
        showToast("Something went wrong.");
        setloader(false);
        setLoader2(false);
      });
  };

  const MakeDefault = (id) => {
    setDisableMD(true);
    setloader(true);
    setLoader2(true);
    updateReportTemplates(id, { default: 1 })
      .then(() => {
        setupdate((state) => state + 1);
        showToast("Report template set as default.", true);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Something went wrong.");
        setloader(false);
        setLoader2(false);
      })
      .finally(() => {
        setDisableMD(false);
      });
  };

  const resetToDefault = (id) => {
    setDisableRD(true);
    setloader(true);
    setLoader2(true);
    updateReportTemplates(id, { reset_sample_default: 1 })
      .then(() => {
        setupdate((state) => state + 1);
        showToast("Report template reset to default.", true);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Something went wrong.");
        setloader(false);
        setLoader2(false);
      })
      .finally(() => {
        setDisableRD(false);
      });
  };

  useEffect(() => {
    getReportTemplates()
      .then((x) => {
        console.log(x.data);
        setreports(x.data);
      })
      .catch((err) => {
        console.log(err);
        showToast("Something went wrong");
      })
      .finally(() => {
        setloader(false);
        setLoader2(false);
      });
  }, [update]);

  useEffect(() => {
    if (reports?.data && reports?.data?.length > 0) {
      if (active === 0) {
        setactiv(0);
      } else {
        setactiv(active);
      }
    } else {
      setHeader("");
      setBody("");
    }
  }, [reports]);

  const setactiv = (index) => {
    setactive(index);
    setDisableFields(true);
    setHeader(reports.data[index].header);
    setBody(reports.data[index].body);
    setId(reports.data[index].id);
  };

  const setDisabledFieldsChild = (value) => {
    setDisableFields(value);
  };

  return (
    <div className="mainbody">
      <div className="leftside">
        <div className="leftside-header">
          <h5 className="mainheading">Report Sections</h5>
        </div>
        <div>
          <ScrollBar>
            <ul
              className={loader ? "ul-style list-spinner" : "ul-style"}
              ref={reportList}
              style={{ height: "calc(100vh - 160px)" }}
            >
              {loader ? (
                <Spinner animation="border" variant="success" />
              ) : (
                reports?.data &&
                reports.data.map((item, i) => (
                  <li
                    key={`comment-${i}`}
                    className={`liststyle${i === active ? " listActive" : ""}`}
                    onClick={() => setactiv(i)}
                  >
                    {item.header}
                  </li>
                ))
              )}
            </ul>
          </ScrollBar>
        </div>
      </div>
      <div className="rightside">
        <div
          className={
            loader2 ? "rightsideinner rightside-spinner" : "rightsideinner"
          }
        >
          {loader2 ? (
            <Spinner animation="border" variant="success" />
          ) : (
            <React.Fragment>
              <div className="float-label">
                <div>
                  <input
                    className="input-field form-control"
                    type="text"
                    maxLength={sectionLengths[active].header}
                    id="report_header"
                    value={header}
                    disabled={
                      disableFields || loader || reports?.data?.length < 1
                    }
                    onChange={(ev) => setHeader(ev.target.value)}
                  />
                </div>
                <label className="Active input-field" htmlFor="commname">
                  Report Header
                </label>
              </div>
              <div className="float-label" style={{ marginTop: "2%" }}>
                <ReportTemplateEditor
                  text={body}
                  disabled={disableFields}
                  setDisabledFieldsChild={setDisabledFieldsChild}
                  onSave={(val) => {
                    handleSave(id, { header: header, body: val });
                  }}
                  maxLength={sectionLengths[active].body}
                  toolbar={true}
                  editBtnName="Edit Report Template"
                />
              </div>
              <div className="row report-actions">
                <div className="reportDefault">
                  <p
                    className="m-0"
                    onClick={() => {
                      resetToDefault(id);
                    }}
                    disabled={disableRD}
                    style={
                      disableRD
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                  >
                    Reset Sampling Template to Default
                  </p>
                </div>
                <div className="reportDefault">
                  <p
                    className="m-0"
                    onClick={() => {
                      MakeDefault(id);
                    }}
                    disabled={disableMD}
                    style={
                      disableMD
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                  >
                    Make Default
                  </p>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportTemplates;
