const sectionLengths = [
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
  {
    header: 50,
    body: 25000,
  },
];

export default sectionLengths;
