/* eslint-disable react-hooks/exhaustive-deps */
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
];
const bindings = {
  custom: {
    key: 46,
    handler: () => {},
  },
};

export default function ReportTemplateEditor({
  text,
  disabled,
  setDisabledFieldsChild,
  onSave,
  maxLength,
  toolbar,
  editBtnName,
}) {
  const editor = useRef(null);
  const [quill, setQuill] = useState(null);
  const [disabledInput, setDisabledInput] = useState(disabled);
  const [body, setBody] = useState("");
  const currentLength = useRef(null);

  const updateLength = (quill) => {
    if (quill) {
      //currentLength.current.innerText = quill.getText().length;
      console.log("len", quill.getLength());
      const html = quill.root.innerHTML;
      const strip_tags = html.replace(/<(?!br\s*\/?)[^>]+>/g, "");
      const strip_enter = strip_tags.replace(/<br\s*\/?>/gi, " ");
      const strip_space = strip_enter.replace(/&nbsp;/gi, " ");
      //const strip_and = strip_space.replace(/&amp;/gi, "&");
      const html_to_text = strip_space;
      const total = html_to_text.length;
      currentLength.current.innerText = total;
      return total;
    }
  };

  useEffect(() => {
    setQuill(editor.current.getEditor());
  }, [editor]);

  useEffect(() => {
    if (quill) {
      updateLength(quill);
    }
  }, [quill]);

  useEffect(() => {
    if (quill) {
      updateLength(quill);
    }
  }, [body, quill]);

  useEffect(() => {
    setBody(text);
  }, [text]);

  useEffect(() => {
    setDisabledInput(disabled);
  }, [disabled]);

  useEffect(() => {
    setDisabledFieldsChild && setDisabledFieldsChild(disabledInput);
  }, [disabledInput]);

  useLayoutEffect(() => {
    document
      .getElementById(`${maxLength}`)
      .addEventListener("paste", copyListner);
    document
      .getElementById(`${maxLength}`)
      .addEventListener("keydown", deleteListner, false);
    return () => {
      maxLength &&
        document
          ?.getElementById(`${maxLength}`)
          ?.removeEventListener("paste", copyListner);
      maxLength &&
        document
          ?.getElementById(`${maxLength}`)
          ?.removeEventListener("keydown", deleteListner);
    };
  }, []);

  const deleteListner = (e) => {
    if (e.keyCode === 46) {
      e.preventDefault();
    }
  };

  useLayoutEffect(() => {
    try {
      const width = document.getElementById("report_header")?.clientWidth;
      if (width > 0) {
        document.getElementsByClassName("ql-editor")[0].style.width =
          width + "px";
      }
    } catch {}
  }, []);

  function copyListner(event) {
    var pastedText = undefined;
    if (window.clipboardData && window.clipboardData.getData) {
      pastedText = window.clipboardData.getData("Text");
    } else if (event.clipboardData && event.clipboardData.getData) {
      pastedText = event.clipboardData.getData("text/plain");
    }
    if (
      pastedText.indexOf("[") !== -1 ||
      pastedText.indexOf("[") !== -1 ||
      pastedText.length + updateLength(editor?.current?.getEditor()) >
        editor?.current?.props?.id
    ) {
      event.preventDefault();
    }
  }

  return (
    <React.Fragment>
      <ReactQuill
        className={`${disabledInput ? "disabled" : ""}`}
        id={maxLength}
        ref={editor}
        value={body}
        onChange={(val, _, source) => {
          console.log(source);
          if (updateLength(quill) > maxLength && source === "user") {
            const selection = quill.getSelection();
            const delta = quill.clipboard.convert(body);
            quill.setContents(delta, "api");
            console.log(selection);
            setTimeout(() => {
              quill.setSelection(selection);
            }, 0);
          } else if (source === "user") {
            setBody(val);
          }
        }}
        modules={{
          toolbar: toolbarOptions,
          history: {
            userOnly: true,
          },
          keyboard: {
            bindings,
          },
        }}
        //{...(!toolbar ? { formats: [] } : null)}
        readOnly={disabledInput}
        onKeyDown={(event) => {
          if (
            quill &&
            handleKeyDown(
              editor?.current?.getEditor(),
              event,
              maxLength - 1,
              updateLength
            )
          ) {
            event.preventDefault();
          }
        }}
        onKeyPress={() => {
          quill && updateLength(quill);
        }}
        onKeyUp={() => {
          quill && updateLength(quill);
        }}
        onMouseDown={() => {
          quill && updateLength(quill);
        }}
        onChangeSelection={(range) => {
          quill && handleSelectionChange(quill, range);
        }}
      />
      <div className="rightsideactions">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {disabledInput ? (
            <button
              className="btn btn-primary"
              disabled={!disabledInput}
              onClick={() => {
                setDisabledInput(false);
              }}
            >
              {editBtnName} <FontAwesomeIcon icon={faPencilAlt} />
            </button>
          ) : (
            <div className="d-flex">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setDisabledFieldsChild && setDisabledFieldsChild(true);
                  onSave && onSave(quill.root.innerHTML);
                  setDisabledInput(true);
                }}
              >
                Save
              </button>
              <button
                className="btn btn-light ml-2 low-priority"
                onClick={() => {
                  setBody(text);
                  setDisabledInput(true);
                }}
              >
                Cancel
              </button>
            </div>
          )}
          <div style={{ marginTop: "-5px" }}>
            <small ref={currentLength}></small>
            <small>/{maxLength}</small>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function handleKeyDown(quill, event, maxLength, updateLength) {
  const { keyCode } = event;
  const ctrlKey = 17,
    cmdKey = 91;
  const length = updateLength(quill);
  let selectedIndex = quill?.getSelection()?.index;
  const text = quill.getText();
  const textLength = text.length;
  if (keyCode === 8 && event.metaKey) {
    return true;
  }
  if (keyCode === 221 || keyCode === 219) {
    return true;
  } else if (keyCode === 8 && text[selectedIndex - 1] === "]") {
    return true;
  } else if (
    length > maxLength &&
    keyCode !== 8 &&
    keyCode !== 46 &&
    keyCode !== 37 &&
    keyCode !== 38 &&
    keyCode !== 39 &&
    keyCode !== 40
  ) {
    return true;
  } else if (
    (keyCode === ctrlKey || keyCode === cmdKey) &&
    (text.indexOf("[") !== -1 || text.indexOf("[") !== -1)
  ) {
    return false;
  }

  for (let i = selectedIndex; i >= 0; i--) {
    if (text[i] === "]") {
      return false;
    } else if (text[i] === "[") {
      if (keyCode === 46) {
        return true;
      } else if (keyCode !== 39) {
        /* for (let j = i; j < textLength; j++) {
          if (text[j] === "]") {
            quill.setSelection(0, 0);
            return true;
          }
        } */
        return false;
      } else {
        for (let j = i; j < textLength; j++) {
          if (text[j] === "]") {
            quill.setSelection(0, 0);
            return true;
          }
        }
      }

      return true;
    }
  }
  return false;
}

function handleSelectionChange(quill, range) {
  const text = quill.getText();
  let i = range?.index - 1;
  if (!range) {
    return;
  }
  for (let k = range?.index; k < range?.index + range?.length; k++) {
    if (text[k] === "[" || text[k] === "]") {
      quill.setSelection(0, 0);
      return;
    }
  }

  for (i; i >= 0; i--) {
    if (text[i] === "]") {
      return;
    } else if (text[i] === "[") {
      for (let j = i; j < text.length; j++) {
        if (text[j] === "]") {
          quill.setSelection(0, 0);
          return;
        }
      }
      return;
    }
  }
}

export { handleKeyDown, handleSelectionChange };
