import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import {
  UPDATE_FINANCIAL_INFO,
  UPDATE_REPORT_DATA,
} from "store/types/sample_strategy/sampleStrategyTypes";
import { Modal } from "react-bootstrap";
import styles from "./FinancialModal.module.css";
import { formatCurrencyInput } from "../../utility/helper";


let total = null;

const FinancialModal = (props) => {
  const { sampleStrategyReport } = useSelector((state) => {
    return {
      sampleStrategyReport: state.sampleStrategyReport,
    };
  });

  const { reportData, sampleCostList } = sampleStrategyReport;
  const { financial_info, discount, summary } = reportData;
  const [placeholder, setPlaceholder] = useState({
    downpayment: "$ 00.00",
    otherdiscount: "$ 00.00",
  });

  const [selectedOption, setSelectedOption] = useState(financial_info?.length);
  const [otherDiscount, setOtherDiscount] = useState(
    discount?.other_discount !== "0" && parseFloat(discount?.other_discount)
  );
  
  const [downPayment, setDownPayment] = useState(
    parseFloat(financial_info?.downPayment)
  );

  const dispatch = useDispatch();
  const { showFinancialModal, toggleFinancialModal } = props;

  useEffect(() => {
    if (financial_info) {
      handleFinancialInfo();
    }
  }, [summary, selectedOption, downPayment]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    const formattedValue = formatCurrencyInput(value);
    const numericValue = parseFloat(formattedValue) || "";
    if (!isNaN(numericValue)) {
      setOtherDiscount(formattedValue);

      const updateTotalCost = calculateTotalCost(
        parseFloat(reportData.discount.sample_discount) +
        parseFloat(numericValue || 0)
      );

      const samplingBalance = (
        Number(summary?.service_fee) +
        Number(summary?.total_sample_cost) +
        Number(summary?.shipping) +
        Number(summary?.expediate_fees) -
        (parseFloat(reportData.discount.sample_discount) +
          parseFloat(numericValue || 0))
      ).toFixed(2);

      const updatedReport = {
        ...reportData,
        discount: { ...reportData.discount, other_discount: numericValue },
        summary: {
          ...reportData.summary,
          total_discount:
            parseFloat(reportData.discount.sample_discount) +
            parseFloat(numericValue || 0),
          sampling_balance: samplingBalance,
          total_cost:
            parseFloat(updateTotalCost) + parseFloat(summary?.inspection_fee),
        },
      };

      updateReportHandler(updatedReport);
    }
  };

  const handleDownPaymentChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatCurrencyInput(value);
    const numericValue = parseFloat(formattedValue) || "";
    if (!isNaN(numericValue)) {
      setDownPayment(formattedValue);
    }
  };

  const REPORT_DELAY = 1000; //ms

  const updateReportHandler = useCallback(
    debounce((updatedReport) => {
      dispatch({
        type: UPDATE_REPORT_DATA,
        payload: updatedReport,
      });
    }, REPORT_DELAY),
    []
  );

  const calculateTotalCost = (discount) => {
    const totaldiscount = discount
      ? parseFloat(discount)
      : parseFloat(summary?.total_discount);

    total = (
      parseFloat(summary?.service_fee) +
      parseFloat(summary?.total_sample_cost) +
      parseFloat(summary?.shipping) +
      parseFloat(summary?.expediate_fees) -
      parseFloat(totaldiscount)
    ).toFixed(2);

    return total;
  };

  const handleFinancialInfo = () => {
    const total = calculateTotalCost();
    if (total > 0 && selectedOption != "N/A") {
      let amountFinanced = total;
      if (downPayment && parseFloat(downPayment) < total) {
        amountFinanced = (total - parseFloat(downPayment)).toFixed(2)
      }
      const lengthInMonths = parseInt(selectedOption);
      const monthlyPayment = (amountFinanced / lengthInMonths).toFixed(2);
      const totalFinanced = amountFinanced;

      if (totalFinanced > 0) {
        const updatedfinancialInfo = {
          length: selectedOption,
          monthly_payment: monthlyPayment,
          down_payment: parseFloat(downPayment),
          amount_financed: amountFinanced,
          total_financed: totalFinanced,
        };

        dispatch({
          type: UPDATE_FINANCIAL_INFO,
          payload: updatedfinancialInfo,
        });
      }
    } else {
      const updatedfinancialInfo = {
        length: "N/A",
        monthly_payment: "00.00",
        down_payment: parseFloat(downPayment),
        amount_financed: total,
        total_financed: total,
      };

      dispatch({
        type: UPDATE_FINANCIAL_INFO,
        payload: updatedfinancialInfo,
      });
    }
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    setPlaceholder({ ...placeholder, [name]: "" });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setPlaceholder({ ...placeholder, [name]: "$ 00.00" });
  };


  const handleDownpaymentBlur = (e) => {
    const { name, value } = e.target;
    const num = parseFloat(value.replace(/[^0-9.-]+/g, ""));
    setPlaceholder({ ...placeholder, [name]: "$ 00.00" });
    if (num < 100) {
      const formattedValue = formatCurrencyInput(value);
      const numericValue = parseFloat(formattedValue) || "";
      if (!isNaN(numericValue)) {
        setDownPayment(formattedValue);
      }
    }
  }

  const midpoint = Math.ceil(sampleCostList.length / 2);

  return (
    <Modal
      show={showFinancialModal}
      onHide={toggleFinancialModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          <h2 className={styles.heading}>Financing</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ whiteSpace: "pre-wrap" }}>
        <div className="financial-container row">
          {/* Financing Table */}
          <div className="col-md-6 sample-table">
            <table className={styles.financingTable}>
              <thead>
                <tr>
                  <th className={styles.header} colSpan="2">
                    Financing Program
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Financial Length</td>
                  <td className="text-center" style={{ width: "30%" }}>
                    <select
                      className="rounded"
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        border: "1px solid #ccc",
                      }}
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      <option value="3 months">3 Months</option>
                      <option value="6 months">6 Months</option>
                      <option value="9 months">9 Months</option>
                      <option value="12 months">12 Months</option>
                      <option value="N/A">N/A</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td style={{ fontWeight: "bold" }}>Monthly Payment</td>
                  <td className="text-center" style={{ width: "30%" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {`$${financial_info?.monthly_payment}`}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Financed Data Table */}
          <div className="col-md-6">
            <table className={styles.financingDataTable}>
              <tbody>
                <tr>
                  <td>Down Payment</td>
                  <td
                    style={{ width: "30%" }}
                    className="font-weight-bold text-center"
                  >
                    <input
                      className={`${styles.customInputStyles} font-weight-bold`}
                      maxLength="10"
                      type="text"
                      name="downpayment"
                      placeholder={placeholder?.downpayment}
                      onFocus={handleFocus}
                      onBlur={handleDownpaymentBlur}
                      value={downPayment ? `$ ${downPayment}` : ""}
                      onChange={handleDownPaymentChange}
                    />
                    {total <= parseFloat(downPayment) && total != null && 

                    <div className={styles.discountErrorCnt}>
                      <img className={styles.discountErrorImg} src={
                      require("../../../../../assets/icons/x_error@3x.png")
                        .default
                    }
                      alt="icon" />
                      <p className={styles.errordicountTxt}>Discount Value must be smaller than Amount Financed</p>
                    </div>
                    
                    }
                  </td>
                </tr>

                <tr>
                  <td>Amount Financed</td>
                  <td
                    style={{ width: "30%" }}
                    className="font-weight-bold text-center"
                  >
                    {`$${financial_info?.amount_financed}`}
                  </td>
                </tr>

                <tr className={styles.totalRow}>
                  <td>Total Financed</td>
                  <td className={`${styles.totalCount} text-center`}>
                    {`$${financial_info?.total_financed}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Discount Table 
          <h2
            className={styles.heading}
            style={{
              marginTop: "50px",
              paddingLeft: "520px",
              marginBottom: "20px",
            }}
          >
            Discount
          </h2>
          <div className="col-md-12">
            <table className={styles.tableDiscount}>
              <tbody>
                <tr>
                  <td>Other Discount $</td>
                  <td
                    style={{ width: "13%" }}
                    className="font-weight-bold text-center"
                  >
                    <input
                      className={`${styles.customInputStyles} font-weight-bold`}
                      maxLength="10"
                      type="text"
                      name="otherdiscount"
                      placeholder={placeholder?.otherdiscount}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={otherDiscount ? `$ ${otherDiscount}` : ""}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Sample Discount $</td>
                  <td
                    style={{ width: "13%" }}
                    className="font-weight-bold text-center"
                  >
                    {`$${parseFloat(discount?.sample_discount).toFixed(2)}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2
            className={styles.heading}
            style={{
              marginTop: "40px",
              paddingLeft: "500px",
              marginBottom: "20px",
            }}
          >
            Sample Costs
          </h2>
          <div className="col-md-6 ">
            <table className={styles.sampleTable}>
              <tbody>
                {sampleCostList?.slice(0, midpoint)?.map((item, index) => (
                  <tr key={`${item.name}-${index}`}>
                    <td>{item.name}</td>
                    <td className="text-center" style={{ width: "30%" }}>
                      $ {parseFloat(item?.cost).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="col-md-6">
            <table className={styles.sampleTable}>
              <tbody>
                {sampleCostList
                  .slice(midpoint, sampleCostList?.length)
                  .map((item, index) => (
                    <tr key={`${item.name}-${index}`}>
                      <td>{item.name}</td>
                      <td
                        style={{ width: "30%" }}
                        className="font-weight-semibold text-center"
                      >
                        $ {parseFloat(item.cost).toFixed(2)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>*/}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className={styles.doneButton} onClick={toggleFinancialModal}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FinancialModal;
