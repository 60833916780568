import {
  calculateSamplingBalance,
  calculateTotalCost,
} from "main/pages/SampleStrategy/utility/fieldsCalculationsFormulas";
import {
  GET_TEST_LIST_RESET,
  GET_TEST_LIST_FAIL,
  GET_TEST_LIST_REQUEST,
  GET_TEST_LIST_SUCCESS,
  GET_INSPECTOR_LIST_FAIL,
  GET_INSPECTOR_LIST_REQUEST,
  GET_INSPECTOR_LIST_RESET,
  GET_INSPECTOR_LIST_SUCCESS,
  GET_REPORT_DATA_REQUEST,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
  GET_REPORT_DATA_RESET,
  GET_SAMPLE_STRATEGY_REPORT_RESET,
  ROW_SELECTION_FAIL,
  ROW_SELECTION_REQUEST,
  ROW_SELECTION_SUCCESS,
  CLIENT_FLAG_SELECTION_FAIL,
  CLIENT_FLAG_SELECTION_REQUEST,
  CLIENT_FLAG_SELECTION_SUCCESS,
  UPDATE_HEADER_INFO_FAIL,
  UPDATE_HEADER_INFO_REQUEST,
  UPDATE_HEADER_INFO_SUCCESS,
  UPDATE_STRATEGY_REPORT_TABLE_LIST_FAIL,
  UPDATE_STRATEGY_REPORT_TABLE_LIST_REQUEST,
  UPDATE_STRATEGY_REPORT_TABLE_LIST_SUCCESS,
  SAVE_REPORT_DATA_FAIL,
  SAVE_REPORT_DATA_REQUEST,
  SAVE_REPORT_DATA_SUCCESS,
  PUBLISH_REPORT_DATA_FAIL,
  PUBLISH_REPORT_DATA_REQUEST,
  PUBLISH_REPORT_DATA_SUCCESS,
  REFRESH_REPORT_DATA_FAIL,
  REFRESH_REPORT_DATA_REQUEST,
  REFRESH_REPORT_DATA_SUCCESS,
  UPDATE_REPORT_DATA,
  UPDATE_FINANCIAL_INFO,
  UPDATE_SAMPLE_COST_LIST,
  UPDATE_SUMMARY_INFO_REQUEST,
  UPDATE_SUMMARY_INFO_SUCCESS,
  UPDATE_SUMMARY_INFO_FAIL,
} from "../../types/sample_strategy/sampleStrategyTypes";

const sampleStrategyReportInitialState = {
  getReportLoading: false,
  reportData: {},
  selectedRows: [],
  testListLoading: false,
  inspectorListLoading: false,
  rowSelectionLoading: false,
  clientFlagSelectionLoading: false,
  updateHeaderInfoLoading: false,
  updateTableLinesLoading: false,
  updateSummaryInfoLoading: false,
  saveReportDataLoading: false,
  publishReportDataLoading: false,
  refreshReportDataLoading: false,
  testList: [],
  sampleCostList: [],
  inspectorList: [],
  error: "",
};

export const sampleStrategyReportReducers = (
  state = sampleStrategyReportInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REPORT_DATA_REQUEST:
      return {
        ...state,
        getReportLoading: true,
      };
    case GET_TEST_LIST_REQUEST:
      return {
        ...state,
        testListLoading: true,
      };
    case GET_INSPECTOR_LIST_REQUEST:
      return {
        ...state,
        inspectorListLoading: true,
      };
    case ROW_SELECTION_REQUEST:
      return {
        ...state,
        rowSelectionLoading: true,
      };
    case CLIENT_FLAG_SELECTION_REQUEST:
      return {
        ...state,
        clientFlagSelectionLoading: true,
      };
    case UPDATE_HEADER_INFO_REQUEST:
      return {
        ...state,
        updateHeaderInfoLoading: true,
      };
    case UPDATE_SUMMARY_INFO_REQUEST:
      return {
        ...state,
        updateSummaryInfoLoading: true,
      };
    case SAVE_REPORT_DATA_REQUEST:
      return {
        ...state,
        saveReportDataLoading: true,
      };
    case REFRESH_REPORT_DATA_REQUEST:
      return {
        ...state,
        refreshReportDataLoading: true,
      };
    case PUBLISH_REPORT_DATA_REQUEST:
      return {
        ...state,
        publishReportDataLoading: true,
      };
    case UPDATE_STRATEGY_REPORT_TABLE_LIST_REQUEST:
      return {
        ...state,
        updateTableLinesLoading: true,
      };
    case ROW_SELECTION_SUCCESS:
      return {
        ...state,
        rowSelectionLoading: false,
      };
    case CLIENT_FLAG_SELECTION_SUCCESS:
      return {
        ...state,
        clientFlagSelectionLoading: false,
      };
    case UPDATE_HEADER_INFO_SUCCESS:
      return {
        ...state,
        updateHeaderInfoLoading: false,
      };
    case UPDATE_SUMMARY_INFO_SUCCESS:
      return {
        ...state,
        updateSummaryInfoLoading: false,
      };
    case GET_TEST_LIST_SUCCESS:
      return {
        ...state,
        testListLoading: false,
        testList: payload,
        error: "",
      };
    case GET_INSPECTOR_LIST_SUCCESS:
      return {
        ...state,
        inspectorListLoading: false,
        inspectorList: payload,
        error: "",
      };
    case UPDATE_STRATEGY_REPORT_TABLE_LIST_SUCCESS:
      return {
        ...state,
        updateTableLinesLoading: false,
        error: "",
      };
    case SAVE_REPORT_DATA_SUCCESS:
      return {
        ...state,
        saveReportDataLoading: false,
      };
    case REFRESH_REPORT_DATA_SUCCESS:
      return {
        ...state,
        refreshReportDataLoading: false,
      };
    case PUBLISH_REPORT_DATA_SUCCESS:
      return {
        ...state,
        publishReportDataLoading: false,
      };
    case GET_REPORT_DATA_SUCCESS:
      const sRows = payload?.sample_strategy_report.filter(
        (row) => row.status
      );
      calculateSamplingBalance(payload.summary);
      calculateTotalCost(payload.summary);
      return {
        ...state,
        getReportLoading: false,
        reportData: payload,
        selectedRows: sRows,
        error: "",
      };

    case UPDATE_REPORT_DATA:
      return {
        ...state,
        getReportLoading: false,
        reportData: payload,
        error: "",
      };

    case UPDATE_FINANCIAL_INFO:
      return {
        ...state,
        getReportLoading: false,
        reportData: { ...state.reportData, financial_info: payload },
        error: "",
      };

    case UPDATE_SAMPLE_COST_LIST:
      return {
        ...state,
        sampleCostList: payload,
        error: "",
      };

    case GET_TEST_LIST_RESET:
      return {
        ...state,
        testListLoading: false,
        testList: [],
        error: "",
      };
    case GET_REPORT_DATA_RESET:
      return {
        ...state,
        getReportLoading: false,
        selectedRows: [],
        reportData: {},
        error: "",
      };
    case GET_INSPECTOR_LIST_RESET:
      return {
        ...state,
        inspectorListLoading: false,
        inspectorList: [],
        error: "",
      };
    case GET_TEST_LIST_FAIL:
      return {
        ...state,
        testListLoading: false,
        error: payload,
        testList: [],
      };
    case GET_INSPECTOR_LIST_FAIL:
      return {
        ...state,
        inspectorListLoading: false,
        error: payload,
        inspectorList: [],
      };
    case ROW_SELECTION_FAIL:
      return {
        ...state,
        rowSelectionLoading: false,
        error: payload,
      };
    case CLIENT_FLAG_SELECTION_FAIL:
      return {
        ...state,
        clientFlagSelectionLoading: false,
        error: payload,
      };
    case UPDATE_HEADER_INFO_FAIL:
      return {
        ...state,
        updateHeaderInfoLoading: false,
        error: payload,
      };
    case UPDATE_SUMMARY_INFO_FAIL:
      return {
        ...state,
        updateSummaryInfoLoading: false,
        error: payload,
      };
    case UPDATE_STRATEGY_REPORT_TABLE_LIST_FAIL:
      return {
        ...state,
        updateTableLinesLoading: false,
        error: payload,
      };
    case SAVE_REPORT_DATA_FAIL:
      return {
        ...state,
        saveReportDataLoading: false,
        error: payload,
      };
    case REFRESH_REPORT_DATA_FAIL:
      return {
        ...state,
        refreshReportDataLoading: false,
        error: payload,
      };
    case PUBLISH_REPORT_DATA_FAIL:
      return {
        ...state,
        publishReportDataLoading: false,
        error: payload,
      };
    case GET_REPORT_DATA_FAIL:
      return {
        ...state,
        getReportLoading: false,
        selectedRows: [],
        reportData: {},
        error: payload,
        rowSelectionLoading: false,
        clientFlagSelectionLoading: false,
        updateHeaderInfoLoading: false,
        updateTableLinesLoading: false,
        updateSummaryInfoLoading: false,
        saveReportDataLoading: false,
        publishReportDataLoading: false,
        refreshReportDataLoading: false,
      };
    case GET_SAMPLE_STRATEGY_REPORT_RESET:
      return {
        ...state,
        inspectorListLoading: false,
        testListLoading: false,
        error: "",
        testList: [],
        inspectorList: [],
        getReportLoading: false,
        selectedRows: [],
        rowSelectionLoading: false,
        clientFlagSelectionLoading: false,
        updateHeaderInfoLoading: false,
        updateSummaryInfoLoading: false,
        updateTableLinesLoading: false,
        saveReportDataLoading: false,
        publishReportDataLoading: false,
        refreshReportDataLoading: false,
        reportData: {},
      };
    default:
      return state;
  }
};
