import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import {
  UPDATE_REPORT_DATA,
} from "store/types/sample_strategy/sampleStrategyTypes";
import { Modal } from "react-bootstrap";
import styles from "./DiscountModal.module.css";
import { formatCurrencyInput } from "../../utility/helper";


let total = null;

const DiscountModal = (props) => {
  const { sampleStrategyReport } = useSelector((state) => {
    return {
      sampleStrategyReport: state.sampleStrategyReport,
    };
  });

  const { reportData, sampleCostList } = sampleStrategyReport;
  const { discount, summary } = reportData;
  const [placeholder, setPlaceholder] = useState({
    downpayment: "$ 00.00",
    otherdiscount: "$ 00.00",
  });

  const [otherDiscount, setOtherDiscount] = useState(
    discount?.other_discount !== "0" && parseFloat(discount?.other_discount)
  );

  const dispatch = useDispatch();
  const { showDiscountModal, toggleDiscountModal } = props;

  const handleInputChange = (e) => {
    const { value } = e.target;

    const formattedValue = formatCurrencyInput(value);
    const numericValue = parseFloat(formattedValue) || "";

    if (!isNaN(numericValue)) {
      setOtherDiscount(formattedValue);

      const updateTotalCost = calculateTotalCost(
        parseFloat(reportData.discount.sample_discount) +
        parseFloat(numericValue || 0)
      );

      const samplingBalance = (
        Number(summary?.service_fee) +
        Number(summary?.total_sample_cost) +
        Number(summary?.shipping) +
        Number(summary?.expediate_fees) -
        (parseFloat(reportData.discount.sample_discount) +
          parseFloat(numericValue || 0))
      ).toFixed(2);

      const updatedReport = {
        ...reportData,
        discount: { ...reportData.discount, other_discount: numericValue },
        summary: {
          ...reportData.summary,
          total_discount:
            parseFloat(reportData.discount.sample_discount) +
            parseFloat(numericValue || 0),
          sampling_balance: samplingBalance,
          total_cost:
            parseFloat(samplingBalance) + parseFloat(summary?.inspection_fee),
        },
      };

      updateReportHandler(updatedReport);
    }
  };

  const REPORT_DELAY = 1000; //ms

  const updateReportHandler = useCallback(
    debounce((updatedReport) => {
      dispatch({
        type: UPDATE_REPORT_DATA,
        payload: updatedReport,
      });
    }, REPORT_DELAY),
    []
  );

  const calculateTotalCost = (discount) => {
    const totaldiscount = discount
      ? parseFloat(discount)
      : parseFloat(summary?.total_discount);

    total = (
      parseFloat(summary?.service_fee) +
      parseFloat(summary?.total_sample_cost) +
      parseFloat(summary?.shipping) +
      parseFloat(summary?.expediate_fees) -
      parseFloat(totaldiscount)
    ).toFixed(2);

    return total;
  };


  const handleFocus = (e) => {
    const { name } = e.target;
    setPlaceholder({ ...placeholder, [name]: "" });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setPlaceholder({ ...placeholder, [name]: "$ 00.00" });
  };

  const midpoint = Math.ceil(sampleCostList.length / 2);

  return (
    <Modal
      show={showDiscountModal}
      onHide={toggleDiscountModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          <h2 className={styles.heading}>Discount</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ whiteSpace: "pre-wrap" }}>
        <div className="financial-container row">
          <div className="col-md-12">
            <table className={styles.tableDiscount}>
              <tbody>
                <tr>
                  <td>Other Discount $</td>
                  <td
                    style={{ width: "13%" }}
                    className="font-weight-bold text-center"
                  >
                    <input
                      className={`${styles.customInputStyles} font-weight-bold`}
                      maxLength="10"
                      type="text"
                      name="otherdiscount"
                      placeholder={placeholder?.otherdiscount}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={otherDiscount ? `$ ${otherDiscount}` : ""}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Sample Discount $</td>
                  <td
                    style={{ width: "13%" }}
                    className="font-weight-bold text-center"
                  >
                    {`$${parseFloat(discount?.sample_discount).toFixed(2)}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className={styles.doneButton} onClick={toggleDiscountModal}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DiscountModal;
