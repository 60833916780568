/* eslint-disable react-hooks/exhaustive-deps */
import { faCreditCard, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCcAmex, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getPaymentMethods } from "../../../../../services/ProfileService";
import SettingService from "../../../../../services/SettingServices";
import { stripeCards } from "../../../../shared/helpers";
import { authRoles } from "../../../../../navigation/route-config";
import { useSelector } from "react-redux";
const BuyLicenseModal = React.lazy(() => import("./BuyLicenseModal"));
const BillingTransactionTable = React.lazy(() =>
  import("./BillingTransactionTable")
);
const AddCardModal = React.lazy(() => import("./AddCardModal"));
const PaymentMethodModal = React.lazy(() => import("./PaymentMethodModal"));

const BillingDetails = () => {
  const [modalInspectorShow, setModalInspectorShow] = React.useState(false);
  const [modalPropertyShow, setModalPropertyShow] = React.useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);

  const [defaultCard, setDefaultCard] = useState();
  const [update, setupdate] = useState(0);
  const [stripeData, setstripeData] = useState();
  const [loader, setloader] = useState(true);
  const [remainingCredits, setremainingCredits] = useState({});
  const [property, setproperty] = useState();
  const [inspector, setinspector] = useState();
  const [basic, setbasic] = useState();
  const [updateTransactionTable, setupdateTransactionTable] = useState(0);
  const [paymentMethods, setpaymentMethods] = useState([]);
  const [refreshPaymentMethods, setRefreshPaymentMethods] = useState(false);

  const role = useSelector((state) => state.user.data.role);

  useEffect(() => {
    setloader(true);
    SettingService.getSubscriptionPrices()
      .then((x) => {
        console.log(x.data);
        setstripeData(x.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
  }, []);

  useEffect(() => {
    if (role !== authRoles.buSubAdmin) {
      getPaymentMethods()
        .then((res) => {
          setDefaultCard(res.data.find((i) => i.default));
          setpaymentMethods(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [refreshPaymentMethods]);

  useEffect(() => {
    SettingService.getRemainingCredits()
      .then((x) => {
        setremainingCredits(x?.data?.credits);

        setTimeout(() => {
          update > 0 && setupdateTransactionTable(1 + updateTransactionTable);
        }, 1500);
      })
      .catch((err) => console.log(err));
  }, [update]);

  useEffect(() => {
    stripeData?.map((d) => {
      return (
        (d.metadata.product_type === "basic_subscription" &&
          setbasic((d.prices[0]?.unit_amount / 100).toFixed(2))) ||
        (d.metadata.product_type === "inspector_credits" &&
          setinspector((d.prices[0]?.unit_amount / 100).toFixed(2))) ||
        (d.metadata.product_type === "inspection_credits" &&
          setproperty((d.prices[0]?.unit_amount / 100).toFixed(2)))
      );
    });
  }, [stripeData]);
  return loader ? (
    <div className="spinner-div">
      <Spinner animation="border" variant="success" />
    </div>
  ) : (
    <div className="main-content-area">
      <div className="row">
        <div className="col-md-7 billing-table">
          <h4 className="text-justify font-weight-bold">Subscription</h4>
          <table className="table table-responsive-md">
            <thead>
              <tr>
                <th>Plan Name</th>
                <th>Monthly Cost </th>
                <th>Inspector License</th>
                <th>Inspection Credit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>Basic</p>
                </td>
                <td>
                  <p>${basic}</p>{" "}
                </td>
                <td>
                  <p>${inspector}</p>{" "}
                </td>
                <td>
                  <p>${property}</p>{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-sm-6 col-md-2">
          <div className="mt-4">
            <p className="text-justify font-weight-bold m-0 heading-rem">
              Remaining{" "}
            </p>
            <p className="text-justify font-weight-bold heading-rem ">
              Inspector Licenses
            </p>
            <div className="bill-amount">
              <span className="remaining-bill">
                {remainingCredits ? remainingCredits.inspector_credits : "0"}
              </span>
              <div>
                <button
                  className="btn btn-primary ml-auto"
                  onClick={() => setModalInspectorShow(true)}
                  disabled={role === authRoles.buSubAdmin}
                >
                  Buy More <FontAwesomeIcon icon={faPlus} />
                </button>
                <Suspense fallback={null}>
                  <BuyLicenseModal
                    showName="Inspector Licenses"
                    show={modalInspectorShow}
                    stripeName={stripeData?.find((d) => {
                      return (
                        d?.metadata.product_type === "inspector_credits" &&
                        `${d?.name}`
                      );
                    })}
                    onHide={() => setModalInspectorShow(false)}
                    update={() => setupdate(update + 1)}
                  />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md">
          <div className="mt-4 ml-3">
            <p className="text-justify font-weight-bold m-0 heading-rem">
              Remaining{" "}
            </p>
            <p className="text-justify font-weight-bold heading-rem ">
              Inspection Credits
            </p>
            <div className="bill-amount">
              <span className="remaining-bill">
                {remainingCredits ? remainingCredits.inspection_credits : "0"}
              </span>
              <div>
                <button
                  className="btn btn-primary ml-auto"
                  onClick={() => setModalPropertyShow(true)}
                  disabled={role === authRoles.buSubAdmin}
                >
                  Buy More <FontAwesomeIcon icon={faPlus} />
                </button>

                <Suspense fallback={null}>
                  <BuyLicenseModal
                    showName="Inspection Credits"
                    show={modalPropertyShow}
                    stripeName={stripeData?.find((element) => {
                      return (
                        element.metadata.product_type ===
                          "inspection_credits" && `${element?.name}`
                      );
                    })}
                    onHide={() => setModalPropertyShow(false)}
                    update={() => setupdate(update + 1)}
                  />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="white-bg-table">
        <h4 className="text-justify font-weight-bold">Transactions</h4>
        <Suspense fallback={null}>
          <BillingTransactionTable
            apiCall={SettingService.getBUTransactions}
            update={updateTransactionTable}
          />
        </Suspense>
      </div>

      {role !== authRoles.buSubAdmin && (
        <>
          <div className="card-on-file mt-3">
            <h4 className="text-justify font-weight-bold">Card on File</h4>
            <div className="card-file-data d-flex ">
              <div
                className="d-flex align-items-center"
                style={
                  defaultCard
                    ? { visibility: "visible" }
                    : { visibility: "hidden" }
                }
              >
                <div className="card-image">
                  <FontAwesomeIcon
                    className="text-primary500"
                    icon={
                      stripeCards[defaultCard?.card?.brand]?.icon ||
                      faCreditCard
                    }
                    size="2x"
                  />
                </div>
                <div className="card-number">
                  •••• •••••• •{defaultCard ? defaultCard.card.last4 : ""}
                </div>
              </div>
              <div>
                <button
                  onClick={() => setShowPaymentMethodsModal(true)}
                  className="btn btn-primary ml-auto"
                  id="edit_email_templates"
                >
                  Change Payment Method
                </button>
              </div>
            </div>
          </div>
          <Suspense fallback={null}>
            <AddCardModal
              show={showAddCardModal}
              onHide={() => setShowAddCardModal(false)}
              setShowPaymentMethodsModal={setShowPaymentMethodsModal}
              setRefreshPaymentMethods={setRefreshPaymentMethods}
            />
            <PaymentMethodModal
              show={showPaymentMethodsModal}
              onHide={() => setShowPaymentMethodsModal(false)}
              setShowAddCardModal={setShowAddCardModal}
              cards={paymentMethods}
              setRefreshPaymentMethods={setRefreshPaymentMethods}
            />
          </Suspense>
        </>
      )}
    </div>
  );
};

export default BillingDetails;
