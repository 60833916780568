/* eslint-disable react-hooks/exhaustive-deps */
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const editorId = "#edit-protocol-trumbowyg-editor";
const editorConfig = {
  btns: [
    ["formatting"],
    ["strong", "em", "del"],
    ["table"],
    ["link"],
    ["unorderedList", "orderedList"],
    ["removeformat"],
    ["fullscreen"],
  ],
  plugins: {
    table: {
      styler: "editor-table",
      rows: 5,
      columns: 5,
    },
  },
};

const getHtmlTextLength = (html) => {
  if (html) {
    try {
      const strip_tags = html.replace(/<(?!br\s*\/?)[^>]+>/g, "");
      const strip_enter = strip_tags.replace(/<br\s*\/?>/gi, " ");
      const strip_space = strip_enter.replace(/&nbsp;/gi, " ");
      //const strip_and = strip_space.replace(/&amp;/gi, "&");
      const html_to_text = strip_space;
      const total = html_to_text.length;
      return total;
    } catch (e) {
      console.log(e);
    }
  }
  return 0;
};

export const formats = [
  "bold",
  "italic",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
];

const lengthExceeding = (e, maxLength) => {
  const html = window.$(editorId).trumbowyg("html");
  const length = getHtmlTextLength(html);
  const { keyCode } = e;
  console.log(keyCode, length);
  if (keyCode === 219 || keyCode === 221) {
    return true;
  }
  if (
    length > maxLength - 1 &&
    keyCode !== 8 &&
    keyCode !== 46 &&
    keyCode !== 37 &&
    keyCode !== 38 &&
    keyCode !== 39 &&
    keyCode !== 40
  ) {
    return true;
  }
  return false;
};

function copyListner(event, maxLength) {
  var pastedText = undefined;
  const clipboardData = event.clipboardData || window.clipboardData;
  if (clipboardData) {
    pastedText = clipboardData.getData("text/plain");
    console.log(pastedText);
  }

  const html = window.$(editorId).trumbowyg("html");
  const length = getHtmlTextLength(html);
  if (
    pastedText?.indexOf("[") !== -1 ||
    pastedText?.indexOf("[") !== -1 ||
    pastedText?.length + length > maxLength
  ) {
    console.log("not pasting", pastedText);
    event.preventDefault();
  } else {
    console.log("pasting", pastedText);
  }
}

const arrowKeys = (e) => {
  const { keyCode } = e;
  if (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40) {
    return true;
  }
  return false;
};

const rangeContainsBracket = (t1, t2, startOffset, endOffset) => {
  console.log("rangeContainsBracket");
  if (t1 === t2) {
    for (let i = startOffset; i < endOffset; i++) {
      if (t1[i] === "[" || t1[i] === "]") {
        return true;
      }
    }
  } else {
    for (let i = startOffset; i < t1.length; i++) {
      if (t1[i] === "[" || t1[i] === "]") {
        return true;
      }
    }
    for (let i = 0; i < endOffset; i++) {
      if (t2[i] === "[" || t2[i] === "]") {
        return true;
      }
    }
  }

  return false;
};

const rangeInsideBracket = (t1, t2, startOffset, endOffset) => {
  if (t1 === t2) {
    let roomStr = "[ROOM]";
    let si = t1.indexOf(roomStr);
    let ei = si + roomStr.length - 1;
    console.log("si ei", si, ei);
    if (si !== -1 && startOffset >= si && startOffset <= ei) {
      return true;
    }
    roomStr = "[SAMPLE_TYPE]";
    si = t1.indexOf(roomStr);
    ei = si + roomStr.length - 1;
    console.log("si ei", si, ei);
    if (si !== -1 && startOffset >= si && startOffset <= ei) {
      return true;
    }
  }
  return false;
};

function handleKeyDown(e, maxLength) {
  try {
    //checking max length
    if (lengthExceeding(e, maxLength)) {
      e.preventDefault();
      return;
    }

    //preventing key press if it is deleteing "[" or "]"
    window.$(editorId).trumbowyg("saveRange");
    // const editorElement = document.getElementsByClassName("trumbowyg-editor");
    // const editorInnerText = editorElement[0] ? editorElement[0].innerText : "";
    const text = window.$(editorId).trumbowyg("getRangeText");
    console.log(text);
    const range = window.$(editorId).trumbowyg("getRange");
    const t1 = range.startContainer.wholeText;
    const t2 = range.endContainer.wholeText;
    const { startOffset, endOffset } = range;
    console.log("range", startOffset, t1, endOffset, t2);
    console.log(range);
    if (!t1 && !t2) {
      console.log("t1 t2 empty");
      return;
    }

    // both offsets are same meaning user has not selected text
    if (
      startOffset === endOffset &&
      t1 === t2 &&
      (e.keyCode === 8 || e.keyCode === 46)
    ) {
      if (t1[startOffset - 1] === "]" && e.keyCode === 8) {
        e.preventDefault();
        return;
      } else if (t1[startOffset] === "[" && e.keyCode === 46) {
        e.preventDefault();
        return;
      } else {
        let roomStr = "[ROOM]";
        let si = t1.indexOf(roomStr);
        let ei = si + roomStr.length - 1;
        console.log("si ei", si, ei);
        if (si !== -1 && startOffset >= si && startOffset <= ei) {
          e.preventDefault();
          return;
        }
        roomStr = "[SAMPLE_TYPE]";
        si = t1.indexOf(roomStr);
        ei = si + roomStr.length - 1;
        console.log("si ei", si, ei);
        if (si !== -1 && startOffset >= si && startOffset <= ei) {
          e.preventDefault();
          return;
        }
      }
    }
    // if user has selected text both offsets will be diff
    else if (
      startOffset !== endOffset &&
      !arrowKeys(e) &&
      (rangeContainsBracket(t1, t2, startOffset, endOffset) ||
        rangeInsideBracket(t1, t2, startOffset, endOffset))
    ) {
      e.preventDefault();
    }

    window.$(editorId).trumbowyg("restoreRange");
  } catch (e) {
    console.log(e);
  }
}

function handleCut(e) {
  const range = window.$(editorId).trumbowyg("getRange");
  const t1 = range.startContainer.wholeText;
  const t2 = range.endContainer.wholeText;
  const { startOffset, endOffset } = range;
  console.log("range", startOffset, t1, endOffset, t2);
  if (
    rangeContainsBracket(t1, t2, startOffset, endOffset) ||
    rangeInsideBracket(t1, t2, startOffset, endOffset)
  ) {
    e.preventDefault();
  }
}

let prevHtml = "";

export default function EditProtocolEditor({
  text,
  onSave,
  maxLength,
  disabled,
  onCancle,
}) {
  const [length, setLength] = useState(0);
  const [editEnabled, setEditEnabled] = useState(false);

  useEffect(() => {
    prevHtml = text;
    try {
      console.log("adding event");
      window
        .$(editorId)
        .trumbowyg(editorConfig)
        .on("tbwinit", () => {
          console.log("tbwinit");
          window.$(editorId).trumbowyg("html", text);
          window.$(editorId).trumbowyg("disable");

          console.log("adding keydown event");
          window
            .$(editorId)
            .parent()
            .attr("ondrop", "return false;")
            .attr("ondragstart", "return false;");
          window
            .$(editorId)
            .parent()
            .on("keydown", (e) => handleKeyDown(e, maxLength))
            .on("cut", handleCut);
        })
        .on("tbwchange", () => {
          console.log("change");
          const html = window.$(editorId).trumbowyg("html");
          if (
            html.indexOf("[ROOM]") === -1 ||
            html.indexOf("[SAMPLE_TYPE]") === -1
          ) {
            window.$(editorId).trumbowyg("html", prevHtml);
          } else {
            prevHtml = html;
          }
          setLength(getHtmlTextLength(html));
        });
    } catch (e) {
      console.log(e);
    }

    return () => {
      console.log("removing all events");
      window.$(editorId).trumbowyg().off();
      window
        .$(editorId)
        .parent()
        .off("keydown", (e) => handleKeyDown(e, maxLength))
        .off("cut", handleCut);
    };
  }, [text]);

  useEffect(() => {
    document.addEventListener("paste", (e) => copyListner(e, maxLength));
    return () => {
      document.removeEventListener("paste", (e) => copyListner(e, maxLength));
    };
  }, [maxLength, text]);

  useEffect(() => {
    window.$(editorId).trumbowyg(editEnabled ? "enable" : "disable");
  }, [editEnabled]);

  return (
    <React.Fragment>
      <textarea id="edit-protocol-trumbowyg-editor"></textarea>
      <div className="rightsideactions">
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ marginTop: "-5px" }}>
            <small></small>
            <small>
              {length}/{maxLength}
            </small>
          </div>

          <div className="d-flex">
            {!editEnabled ? (
              <button
                className="btn btn-primary"
                onClick={() => setEditEnabled(true)}
              >
                Edit Protocol <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            ) : (
              <div className="d-flex">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    onSave && onSave(window.$(editorId).trumbowyg("html"));
                    setEditEnabled(false);
                  }}
                >
                  Save
                </button>
                <button
                  className="btn btn-light ml-2 low-priority"
                  onClick={() => {
                    window.$(editorId).trumbowyg("html", text);
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
