import React from "react";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

export default function Spinner({ reactIf,style }) {
  return reactIf ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "inherit",
        maxHeight: "100%",
        width: "100%",
        ...style
      }}
    >
      <BootstrapSpinner animation="border" variant="success" />
    </div>
  ) : null;
}
