import axios from "../axios-defaults";
const getReportTemplates = (q = "") => {
  return axios.get(`/api/v1/reports-template${q ? `?q=${q}` : ""}`);
};

const getReportTemplatesSA = (q = "") => {
  return axios.get(
    `/api/v1/sa/business-units/reports-template${q ? `?q=${q}` : ""}`
  );
};

const updateReportTemplates = (id, data = {}) => {
  return axios.post(`/api/v1/reports-template/${id}`, {
    _method: "patch",
    ...data,
  });
};

const updateReportTemplatesSA = (id, data) => {
  return axios.post(`/api/v1/sa/business-units/reports-template/${id}`, {
    ...data,
    _method: "patch",
  });
};

export {
  getReportTemplates,
  updateReportTemplates,
  getReportTemplatesSA,
  updateReportTemplatesSA,
};
