/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import "./searchBox.css";
import { components } from "react-select";

export default function SearchDropdown(props) {
  const [value, onChange] = useState(
    props.defaultVal
      ? {
          value: props.defaultVal,
          label: props.defaultVal,
        }
      : null
  );

  const {
    apiCall,
    saveProp,
    showProp,
    placeHolder,
    onSelect,
    complicated,
    getAllData,
    disabled,
  } = props;
  useEffect(() => {
    if (complicated) {
      console.log("force update");
    }
  }, [props.value]);

  async function loadOptions(search, loadedOptions, { page }) {
    var val = null;
    var totalpage = { current_page: null, last_page: null };
    console.log(page, "llllll", search);

    await apiCall(search, page)
      .then((x) => {
        console.log(x.data);
        totalpage = {
          current_page: x.data.current_page,
          last_page: x.data.last_page,
        };
        console.log(x.data.last_page);
        val = x.data.data.map((d) => {
          let temp = "";
          if (!complicated) {
            showProp.forEach((element) => {
              temp = temp + d[element] + " ";
            });
          } else {
            temp = d.name + " - " + d.test.name;
          }
          return {
            value: getAllData ? d : d[saveProp],
            label: temp,
          };
        });
      })
      .catch((err) => console.log(err));
    let filteredOptions;

    if (!search) {
      filteredOptions = val;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = val.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore =
      totalpage?.current_page >= totalpage?.last_page ? false : true;
    console.log(totalpage, "pages");
    return {
      options: filteredOptions,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  }
  return (
    <div>
      <AsyncPaginate
        styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
        isClearable={true}
        components={{
          Input: (props) => <components.Input {...props} maxLength={10} />,
        }}
        debounceTimeout={1000}
        value={value}
        loadOptions={loadOptions}
        onChange={(val) => {
          onChange(val);
          onSelect(val);
        }}
        placeholder={placeHolder}
        additional={{
          page: 1,
        }}
        isDisabled={disabled}
      />
    </div>
  );
}
