import axios from "../axios-defaults";

const createBusinessUnit = (data) => {
  return axios.post("/api/v1/account", data);
};

const getPrivacyPolicy = () => {
  return axios.get("/api/v1/contents?type=privacy-policy");
};

const getBusinessUnit = (id, page) => {
  return axios.get(`api/v1/sa/business-units/${id}`);
};

const getBUTransactionOnSA = (id, page) => {
  return axios.get(`/api/v1/sa/business-unit/${id}/transactions?page=${page}`);
};

const updateBUTransactionOnSA = (id, data) => {
  console.log(data, "newdata");
  return axios.post(`api/v1/sa/business-units/${id}`, {
    _method: "patch",
    ...data,
  });
};

const updatePrivacyPolicy = (data) => {
  return axios.post(`api/v1/contents?type=privacy-policy`, {
    _method: "patch",
    ...data,
  });
};
const getTermsOfUse = () => {
  return axios.get("/api/v1/contents?type=terms-of-use");
};

const updateTermsOfUse = (data) => {
  return axios.post(`api/v1/contents?type=terms-of-use`, {
    _method: "patch",
    ...data,
  });
};

const getBusinessUnits = (page = 1, search = "") => {
  return axios.get(`/api/v1/sa/business-units?page=${page}&q=${search}`);
};

const getBUForSwitch = (page = 1) => {
  const token =
    localStorage.getItem("x-auth-token") ||
    localStorage.getItem("access_token");
  return axios.get(`/api/v1/sa/business-units?page=${page}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const toggleBUActivation = (id, status) => {
  return axios.post(`/api/v1/sa/business-units/${id}`, {
    _method: "PATCH",
    status,
  });
};

const getBUToken = (id) => {
  const token =
    localStorage.getItem("x-auth-token") ||
    localStorage.getItem("access_token");
  return axios.get(`api/v1/sa/business-units/${id}/tokens`, {
    headers: { Authorization: "Bearer " + token },
  });
};

export {
  createBusinessUnit,
  getPrivacyPolicy,
  getBusinessUnit,
  getBUTransactionOnSA,
  updateBUTransactionOnSA,
  updatePrivacyPolicy,
  getTermsOfUse,
  updateTermsOfUse,
  getBusinessUnits,
  toggleBUActivation,
  getBUToken,
  getBUForSwitch,
};
