import React, { useLayoutEffect, useRef, useState } from "react";
import ErrorBoundary from "../../../components/ErrorBoundry/ErrorBoundry";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function DocumentView({ children, style }) {
  /* const [scale, setScale] = useState(1);
  const [width] = useWindowSize();
  const wrap = useRef(null);
  const a4 = useRef(null);

  useLayoutEffect(() => {
    var wrap = document.querySelector(".a4-wrap");
    var a4 = document.querySelector(".A4");
    var scale = (wrap.offsetWidth - 10) / a4.offsetWidth;
    setScale(scale > 1 ? 1 : scale);
  }, [width]);

  useLayoutEffect(() => {
    if (wrap?.current && a4?.current) {
      console.log(a4.current.style.height, wrap?.current?.offsetHeight);
      wrap.current.style.height =
        (a4?.current?.offsetHeight + 10) * scale + "px";
    }
  }, [scale, wrap]); */

  /* return (
    <div
      ref={wrap}
      className="a4-wrap"
      style={{ margin: "10px 0px", ...style }}
    >
      <div ref={a4} className="A4" style={{ transform: `scale(${scale})` }}>
        {children}
      </div>
    </div>
  ); */
  return (
    <div style={style}>
      <div className="A4">{children}</div>
    </div>
  );
}
