/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import DateRangeFilter from "main/components/DateRangeFilter/DateRangeFilter";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getInspectionList } from "../../../services/InspectionService";
import CircleProgress from "../../components/CircleProgress";
import Paginator from "../../components/Paginator/Paginator";
import {
  captalizeFirst,
  checkStatus,
  getAddress,
  timeConversion,
  useDidUpdateEffect,
  utcDate,
} from "../../shared/helpers";

export default function Inspection() {
  const [loader, setloader] = useState(false);
  const [page, setpage] = useState(1);
  const history = useHistory();
  const [inspections, setinspections] = useState({ data: [] });
  const [type, settype] = useState("scheduled");
  const defaultAvatar = require("../../../assets/icons/person.png").default;

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  useDidUpdateEffect(() => {
    setSkip(true);
    setloader(true);
    getInspectionList(
      1,
      type,
      searchField,
      dateRange.startDate
        ? dayjs(dateRange.startDate).format("YYYY-MM-DD")
        : undefined,
      dateRange.endDate
        ? dayjs(dateRange.endDate).format("YYYY-MM-DD")
        : undefined
    )
      .then((x) => {
        setinspections(x.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
  }, [searchField]);

  useEffect(() => {
    if (!skip) {
      setloader(true);
      getInspectionList(
        page,
        type,
        searchField ?? "",
        dateRange.startDate
          ? dayjs(dateRange.startDate).format("YYYY-MM-DD")
          : undefined,
        dateRange.endDate
          ? dayjs(dateRange.endDate).format("YYYY-MM-DD")
          : undefined
      )
        .then((x) => {
          console.log(x.data);
          setinspections(x.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setloader(false));
    } else {
      setSkip(false);
    }
  }, [page, type, dateRange]);

  return (
    <>
      <div className="main">
        <div className="main-content-area">
          <div className="d-flex">
            {/* NOTE: next sprint work with different scheduled */}

            <div className="d-flex justify-content-center align-items-center">
              <input
                type="radio"
                id={`inspection_id_status_scheduled`}
                name="radiogroup"
                className="radio-btn mx-1"
                defaultChecked={type === "scheduled" ? true : false}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    settype("scheduled");
                  }
                }}
              />
              <label
                htmlFor={`inspection_id_status_scheduled`}
                className="mb-0 mx-1 radio-label"
              >
                Pending
              </label>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <input
                type="radio"
                id={`inspection_id_status_dispatched`}
                name="radiogroup"
                className="radio-btn mx-1"
                defaultChecked={type === "finished" ? true : false}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    settype("finished");
                  }
                }}
              />
              <label
                htmlFor={`inspection_id_status_dispatched`}
                className="mb-0 mx-1 radio-label"
              >
                Finished
              </label>
            </div>

            <DateRangeFilter
              dateRange={dateRange}
              setDateRange={setDateRange}
            />

            <div style={{ padding: "7px 8px" }}></div>

            <div
              style={{
                justifyContent: "flex-end",
                flexGrow: "1",
                display: "flex",
              }}
            >
              <button
                id="add-lab-button"
                onClick={() => {
                  history.push("/inspections/new");
                }}
                className="btn btn-primary ml-auto"
              >
                Add Inspection <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
          <ScrollBar
            className="mt-2"
            style={
              loader
                ? {
                    height: "calc(100vh - 240px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {
                    height: "calc(100vh - 240px)",
                    paddingRight: "15px",
                  }
            }
          >
            {loader ? (
              <>
                <div>
                  <Spinner animation="border" variant="success" />
                </div>
              </>
            ) : (
              <table className="table table-responsive-md inspections-display">
                <thead>
                  <tr>
                    <th>Inspection ID</th>
                    <th>Time/Date</th>
                    <th>Inspector</th>
                    <th>Address</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th width="10"></th>
                  </tr>
                </thead>
                <tbody>
                  {inspections?.data && inspections.data.length !== 0 ? (
                    inspections.data.map((insp, i) => (
                      <tr id={`inspection-${insp.id}`} key={insp.id}>
                        <td id={`inspection_${insp?.id}_id`}>
                          <strong className="text-light20 keep-all">
                            #{insp?.code}
                          </strong>
                        </td>
                        <td id={`inspection_${insp?.id}_scheduled_time`}>
                          <>
                            <strong className="text-light20 keep-all">
                              {utcDate(insp?.scheduled_time)}
                            </strong>
                            <br />
                            <strong className="text-light20 keep-all">
                              {timeConversion(insp?.scheduled_time)}
                            </strong>
                          </>
                        </td>
                        <td
                          id={`inspection_${insp.id}_insp_name`}
                          style={{ paddingRight: "8px" }}
                        >
                          <div className="d-flex">
                            <img
                              width="120"
                              className={`${"avatar"} mr-2 ml-0 `}
                              src={
                                insp.inspectors[0]?.inspector_details &&
                                insp.inspectors[0]?.inspector_details
                                  ?.avatar_url !== null
                                  ? insp?.inspectors[0]?.inspector_details
                                      ?.avatar_url
                                  : defaultAvatar
                              }
                              onError={(ev) => (ev.target.src = defaultAvatar)}
                              alt="Avatar"
                            />
                            <strong className="trunk-text m-auto-custom trunk-name text-light20">
                              {captalizeFirst(insp?.inspectors[0]?.first_name) +
                                " " +
                                insp?.inspectors[0]?.last_name}
                            </strong>
                          </div>
                        </td>
                        <td id={`inspection_${insp.id}_address`}>
                          <p>{getAddress(insp?.property?.address)}</p>
                        </td>
                        <td id={`inspection_${insp.id}_cus_name`}>
                          <strong className="m-auto-custom text-light20">
                            {insp?.customer?.first_name +
                              " " +
                              insp?.customer?.last_name}
                          </strong>
                        </td>
                        <td id={`inspection_${insp.id}_status`}>
                          <CircleProgress progressName={insp.status} />
                          <p className="text-progress pt-1 keep-all">
                            {checkStatus(insp?.status)}
                          </p>
                        </td>
                        <td id={`inspection_${insp?.id}_detail_button`}>
                          <div>
                            <Link
                              style={{
                                textDecoration: "none",
                                width: "32px",
                                cursor: "pointer",
                              }}
                              to={"/inspections/" + insp?.id}
                            >
                              <button className="next-btn ripple">
                                <FontAwesomeIcon icon={faCaretRight} />
                              </button>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td colSpan="9">No record found</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            )}
          </ScrollBar>
          {console.log(inspections, "lol")}
          {!loader && inspections?.data && inspections.data.length !== 0 ? (
            <nav className="mt-3 mb-3">
              <ul className="pagination justify-content-center paginator">
                <Paginator
                  activePage={inspections?.current_page}
                  itemsCountPerPage={inspections?.per_page}
                  totalItemsCount={inspections?.total}
                  onChange={(page) => {
                    setSkip(false);
                    setpage(page);
                  }}
                />
              </ul>
            </nav>
          ) : null}
        </div>
      </div>
    </>
  );
}
