import React from "react";
import { useSelector } from "react-redux";
import DocumentView from "../DocumentView";
import ET from "../EditableText";
import ImageEditor from "../ImageEditor";
import ReportTerms from "./ReportTerms";
import "./style.css";

export default function Intro({ style }) {
  const cover_page = useSelector((state) => state.report.cover_page);

  return (
    <div style={style}>
      <DocumentView>
        <div className="mx-auto comp-logo">
          <ImageEditor
            src={cover_page?.company?.logo}
            rotation={cover_page?.company?.rotation ?? 0}
            imgRotationKey={"cover_page.company.rotation"}
            alt="company logo"
            className="logo-footer"
            reduxKey="cover_page.company.logo"
            disableRotate
          />
        </div>
        <h1 className="cover-page-heading">
          <ET reduxKey="cover_page.title" ml="32">
            {cover_page?.title}
          </ET>
        </h1>
        <ImageEditor
          src={cover_page?.property?.image}
          rotation={cover_page?.property?.rotation ?? 0}
          imgRotationKey={"cover_page.property.rotation"}
          alt=""
          className="cover-img"
          reduxKey="cover_page.property.image"
        />
        <div className="cover-client-info">
          <strong>
            <ET reduxKey="cover_page.client.title" ml="50">
              {cover_page?.client?.title}
            </ET>
          </strong>
          <p>
            <ET reduxKey="cover_page.client.name" ml="60">
              {cover_page?.client?.name}
            </ET>
          </p>

          <strong>
            <ET reduxKey="cover_page.property.title" ml="50">
              {cover_page?.property?.title}
            </ET>
          </strong>
          <p style={{ fontSize: "13px", padding: "0px 0.5cm" }}>
            <ET reduxKey="cover_page.property.address" ml="350">
              {cover_page?.property?.address}
            </ET>
          </p>

          <strong>
            <ET reduxKey="cover_page.survey.title" ml="50">
              {cover_page?.survey?.title}
            </ET>
          </strong>
          <p>
            <ET reduxKey="cover_page.survey.date" ml="50">
              {cover_page?.survey?.date}
            </ET>
          </p>
        </div>
        <footer className="grid-container">
          <div className="my-4 w-100">
            <p style={{ fontSize: "13px" }} className="fw-500 fw-bold">
              <ET reduxKey="cover_page.company.name" ml="100">
                {cover_page?.company?.name}
              </ET>
              |
              <ET reduxKey="cover_page.company.address" ml="350">
                {cover_page?.company?.address}
              </ET>
              |
              <ET reduxKey="cover_page.company.email" ml="50">
                {cover_page?.company?.email}
              </ET>
              |
            </p>
            <p style={{ fontSize: "13px" }} className="fw-500 fw-bold">
              <ET reduxKey="cover_page.company.contact" ml="20">
                {cover_page?.company?.contact}
              </ET>
              |
              <ET reduxKey="cover_page.company.website" ml="50">
                {cover_page?.company?.website}
              </ET>
            </p>
          </div>
        </footer>
      </DocumentView>
      <ReportTerms />
    </div>
  );
}
