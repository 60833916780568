import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateProfile } from "../../../services/ProfileService";
import { uploadToS3 } from "../../../services/s3";
import { LOG_IN } from "../../../store/actions";
import { showToast } from "../../shared/helpers";
import { regexes } from "../../shared/regexes";
import { fileSizeLimits, folders } from "../../shared/validations";
import "./profile.css";

function Profile() {
  const history = useHistory();
  const data = useSelector((state) => state.user.data);
  console.log(data, "usr data");
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [file_e, setFile_e] = useState("");

  const [disableSave, setDisableSave] = useState(false);

  const dispatch = useDispatch();

  const [payload, setpayload] = useState({
    _method: "PATCH",
    first_name: "",
    last_name: "",
    phone_number: "",
    company_name: "",
    company_phone: "",
    company_email: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
    bu_bio: "",
    logo_url: "",
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
  });

  const [errors, seterrors] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > fileSizeLimits.avatarBu) {
        setFile_e("Photo cannot be larger than 1MB");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG or PNG");
      } else {
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  const handleSave = () => {
    console.log("file", file);
    return new Promise((resolve, reject) => {
      if (file) {
        uploadToS3(file, folders.profile)
          .then((res) => {
            console.log(res);
            resolve(res.url);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve("");
      }
    });
  };

  const submit = () => {
    setIsSubmit(true);
    if (
      payload.company_name &&
      payload.company_phone &&
      payload.company_email &&
      payload.street1 &&
      payload.city &&
      payload.state &&
      payload.zipcode &&
      payload.first_name &&
      payload.last_name &&
      payload.phone_number &&
      payload.bu_bio &&
      regexes.email.test(payload.company_email) &&
      regexes.phone.test(payload.company_phone) &&
      regexes.phone.test(payload.phone_number)
    ) {
      setDisableSave(true);
      handleSave()
        .then((url) => {
          console.log(url);
          updateProfile({ ...payload, logo_url: url })
            .then((res) => {
              dispatch({
                type: LOG_IN,
                payload: res.data,
              });
              showToast("Profile Updated Successfully.", true);
              history.push("/subscription");
              setDisableSave(false);
            })
            .catch((err) => {
              console.log(err?.response);
              if (err?.response?.status === 422) {
                seterrors(err?.response?.data);
              } else {
                showToast("Something went wrong");
              }
            });
        })
        .catch((err) => {
          console.log(err);
          showToast("Error while uploading image");
        })
        .finally(() => setDisableSave(false));
    }
  };

  useEffect(() => {
    setpayload({
      _method: "PATCH",
      first_name: data?.first_name ? data?.first_name : "",
      last_name: data?.last_name ? data?.last_name : "",
      phone_number: data?.phone_number ? data?.phone_number : "",
      company_name: data?.business_unit?.company_name
        ? data?.business_unit?.company_name
        : "",
      company_phone: data?.business_unit?.company_phone
        ? data?.business_unit?.company_phone
        : "",
      company_email: data?.business_unit?.company_email
        ? data?.business_unit?.company_email
        : "",
      street1: data?.business_unit?.address?.address1
        ? data?.business_unit?.address?.address1
        : "",
      street2: data?.business_unit?.address?.address2
        ? data?.business_unit?.address?.address2
        : "",
      city: data?.business_unit?.address?.city
        ? data?.business_unit?.address?.city
        : "",
      state: data?.business_unit?.address?.state
        ? data?.business_unit?.address?.state
        : "",
      zipcode: data?.business_unit?.address?.zipcode
        ? data?.business_unit?.address?.zipcode
        : "",
      bu_bio: data?.business_unit?.bio ? data?.business_unit?.bio : "",
      logo_url: data?.business_unit?.logo_url
        ? data?.business_unit?.logo_url
        : "",
      website: data?.business_unit?.company_website
        ? data?.business_unit?.company_website
        : "",
      facebook: data?.business_unit?.social_links?.facebook
        ? data?.business_unit?.social_links?.facebook
        : "",
      instagram: data?.business_unit?.social_links?.instagram
        ? data?.business_unit?.social_links?.instagram
        : "",
      twitter: data?.business_unit?.social_links?.twitter
        ? data?.business_unit?.social_links?.twitter
        : "",
      youtube: data?.business_unit?.social_links?.youtube
        ? data?.business_unit?.social_links?.youtube
        : "",
    });
  }, []);

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        <div className="main-content-area">
          <div className="d-flex align-items-center">
            <button
              className={`btn btn-primary ml-auto`}
              onClick={submit}
              disabled={disableSave}
            >
              Next Step - Subscription
            </button>
          </div>
          <div>
            <h4>Company Details</h4>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="float-label">
                        <input
                          className="input-field form-control"
                          type="text"
                          maxLength="100"
                          value={payload.company_name}
                          style={{ maxWidth: 650 }}
                          id="cpname1"
                          onChange={(e) => {
                            setpayload({
                              ...payload,
                              company_name: e.target.value,
                            });
                          }}
                        />

                        <label
                          className={`${
                            payload.company_name !== "" ? "Active " : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Company Name
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit && payload.company_name === ""
                          ? "Company name cannot be empty"
                          : errors?.company_name
                          ? errors?.company_name[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="input-field form-control"
                            type="text"
                            maxLength="16"
                            id="cpname2"
                            value={new AsYouType("US").input(
                              payload.company_phone
                            )}
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(regexes.phoneInput, "");
                              setpayload({ ...payload, company_phone: value });
                            }}
                          />
                        </div>
                        <label
                          className={`${
                            payload.company_phone !== "" ? "Active " : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Company Phone Number
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit &&
                          (payload.company_phone === ""
                            ? "Company phone cannot be empty"
                            : !regexes.phone.test(payload.company_phone)
                            ? "Enter Valid Phone number"
                            : errors?.company_phone
                            ? errors?.company_phone[0]
                            : "")}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="input-field form-control"
                            type="text"
                            maxLength="50"
                            value={payload.company_email}
                            id="cpname3"
                            disabled={true}
                            onChange={(e) => {
                              setpayload({
                                ...payload,
                                company_email: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <label
                          className={`${
                            payload.company_email !== "" ? "Active " : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Company Email Address
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit &&
                          (payload.company_email === ""
                            ? "Company email cannot be empty"
                            : !regexes.email.test(payload.company_email)
                            ? "Enter Valid Email Address"
                            : errors?.company_email
                            ? errors?.company_email[0]
                            : "")}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="input-field form-control"
                            type="text"
                            maxLength="30"
                            id="cpname4"
                            onChange={(e) => {
                              setpayload({
                                ...payload,
                                website: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <label
                          className={`${
                            payload.website !== "" ? "Active " : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Company Website (Optional)
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {errors?.website ? errors?.website[0] : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="upload-avatar">
                  <div className="image-area">
                    {!preview ? (
                      <span
                        className="upload-icon"
                        style={
                          preview
                            ? { backgroundColor: "transparent" }
                            : { backgroundColor: "#fff" }
                        }
                      >
                        <FontAwesomeIcon icon={faCamera} />
                      </span>
                    ) : null}
                    <img
                      width="120"
                      className="avatar-lg"
                      src={preview}
                      alt=""
                    />

                    <input
                      type="file"
                      className="upload-image"
                      accept="image/jpeg,image/png"
                      onChange={(e) => {
                        fileChangedHandler(e);
                      }}
                    />
                  </div>
                  <p>Upload Company Logo (Optional)</p>
                  <small className="text-danger ml-1">{file_e}</small>
                </div>
              </div>
            </div>

            <hr className="mt-0 mb-4" />
            <div>
              <h4>Company Address</h4>
              <div className="row">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="100"
                              value={payload.street1}
                              id="cpname5"
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  street1: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.street1 !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Street 1
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {isSubmit && payload.street1 === ""
                            ? "Street 1 cannot be empty"
                            : errors?.street1
                            ? errors?.street1[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="100"
                              value={payload.street2}
                              id="cpname6"
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  street2: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.street2 !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Street 2 (Optional)
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {errors?.street2 ? errors?.street2[0] : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="50"
                              id="cpname7"
                              value={payload.city}
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  city: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.city !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            City
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {isSubmit && payload.city === ""
                            ? "City cannot be empty"
                            : errors?.city
                            ? errors?.city[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="float-label">
                              <div>
                                <input
                                  className="input-field form-control"
                                  type="text"
                                  maxLength="50"
                                  id="cpname8"
                                  value={payload.state}
                                  onChange={(e) => {
                                    setpayload({
                                      ...payload,
                                      state: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <label
                                className={`${
                                  payload.state !== "" ? "Active " : ""
                                }input-field`}
                                htmlFor="cpname"
                              >
                                State
                              </label>
                            </div>
                            <small className="text-danger ml-1">
                              {isSubmit && payload.state === ""
                                ? "State cannot be empty"
                                : errors?.state
                                ? errors?.state[0]
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="float-label">
                              <div>
                                <input
                                  className="input-field form-control"
                                  type="text"
                                  maxLength="50"
                                  id="cpname11"
                                  value={payload.zipcode}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setpayload({ ...payload, zipcode: value });
                                  }}
                                />
                              </div>
                              <label
                                className={`${
                                  payload.zipcode !== "" ? "Active " : ""
                                }input-field`}
                                htmlFor="cpname"
                              >
                                Zip code
                              </label>
                            </div>
                            <small className="text-danger ml-1">
                              {isSubmit && payload.zipcode === ""
                                ? "Zip Code cannot be empty"
                                : errors?.zipcode
                                ? errors?.zipcode[0]
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="mb-4" />
            <div>
              <h4>Personal Details</h4>
              <div className="row">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="25"
                              id="cpname22"
                              value={payload.first_name}
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  first_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.first_name !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Your First Name
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {isSubmit && payload.first_name === ""
                            ? "First Name cannot be empty"
                            : errors?.first_name
                            ? errors?.first_name[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="25"
                              id="cpname33"
                              value={payload.last_name}
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  last_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.last_name !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Your Last Name
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {isSubmit && payload.last_name === ""
                            ? "Last Name cannot be empty"
                            : errors?.last_name
                            ? errors?.last_name[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="16"
                              id="cpname44"
                              value={new AsYouType("US").input(
                                payload.phone_number
                              )}
                              onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(regexes.phoneInput, "");
                                setpayload({ ...payload, phone_number: value });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.phone_number !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Your Phone Number
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {isSubmit &&
                            (payload.phone_number === ""
                              ? "phone cannot be empty"
                              : !regexes.phone.test(payload.phone_number)
                              ? "Enter Valid Phone number"
                              : errors?.phone_number
                              ? errors?.phone_number[0]
                              : "")}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <h4>Personal Bio</h4>
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <textarea
                              className="input-field form-control"
                              style={{ height: "30vh", resize: "none" }}
                              type="text"
                              maxLength="100"
                              value={payload.bu_bio}
                              id="remarks"
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  bu_bio: e.target.value,
                                });
                              }}
                            />
                            <div style={{ textAlign: "right" }}>
                              <small>{payload.bu_bio.length}/100</small>
                            </div>
                          </div>
                        </div>
                        <small className="text-danger ml-1">
                          {isSubmit && payload.bu_bio === ""
                            ? "Bio cannot be empty"
                            : errors?.bu_bio
                            ? errors?.bu_bio[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="mb-4" />
            <div>
              <h4>Connect your social media</h4>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="100"
                              id="cpname55"
                              value={payload.instagram}
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  instagram: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.instagram !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Instagram URL (Optional)
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {errors?.instagram ? errors?.instagram[0] : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="100"
                              id="cpname66"
                              value={payload.facebook}
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  facebook: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.facebook !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Facebook URL (Optional)
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {errors?.facebook ? errors?.facebook[0] : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="100"
                              id="cpname77"
                              value={payload.twitter}
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  twitter: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.twitter !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Twitter URL (Optional)
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {errors?.twitter ? errors?.twitter[0] : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <div className="float-label">
                          <div>
                            <input
                              className="input-field form-control"
                              type="text"
                              maxLength="100"
                              id="cpname88"
                              value={payload.youtube}
                              onChange={(e) => {
                                setpayload({
                                  ...payload,
                                  youtube: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <label
                            className={`${
                              payload.youtube !== "" ? "Active " : ""
                            }input-field`}
                            htmlFor="cpname"
                          >
                            Youtube URL (Optional)
                          </label>
                        </div>
                        <small className="text-danger ml-1">
                          {errors?.youtube ? errors?.youtube[0] : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  );
}

export default Profile;
