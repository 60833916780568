/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCaretDown,
  faCaretUp,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import DeleteButton from "main/components/DeleteButton/DeleteButton";
import { getErrorMsg, showToast } from "main/shared/helpers";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  deleteObservation,
  deleteObservationRoom,
  getRoomObservation,
} from "../../../../../services/ObservationService";

export default function ObservationSubList({
  name,
  selectedRoomId,
  onOpen,
  setSelectedObservation,
  setSelectedObservationId,
  openAddObservationModal,
  roomId,
  refreshSubList,
  refreshRoomList,
  setEditObservation,
  onEditRoom,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(0);
  const [observations, setObservations] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [noData, setNoData] = useState(false);
  const source = Axios.CancelToken.source();
  const [refresh, setRefresh] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getObservations(roomId, page);
    } else {
      source.cancel();
      setLastPage(1);
      setObservations([]);
      setNoData(false);
      setActive(0);
    }
  }, [isOpen, page, refreshSubList, refresh]);

  useEffect(() => {
    console.log("refreshSublist", refreshSubList);
  }, [refreshSubList]);

  useEffect(() => {
    if (observations.length > 0) {
      setSelectedObservation(
        `#${observations[active].code} - ${observations[active].location}`
      );
      setSelectedObservationId(observations[active].id);
    }
  }, [active]);

  useEffect(() => {
    console.log("room id changes");
    if (selectedRoomId === roomId) {
      console.log("open");
      setIsOpen(true);
    } else {
      console.log("not open");
      setIsOpen(false);
    }
  }, [selectedRoomId]);

  const getObservations = (roomId, page) => {
    setIsLoading(true);
    getRoomObservation(roomId, page)
      .then((x) => {
        console.log(x.data.data);
        if (page === 1) {
          setObservations(x.data.data);
        } else {
          setObservations((state) => [...state, ...x.data.data]);
        }
        if (x.data.data.length > 0 && page === 1) {
          setSelectedObservation(
            "#" + x.data.data[0].code + " - " + x.data.data[0].location
          );
          setSelectedObservationId(x.data.data[0].id);
          setNoData(false);
        } else {
          setNoData(true);
          setSelectedObservationId(null);
          setSelectedObservation("");
        }

        setLastPage(x.data.last_page);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleScrollEnd = () => {
    console.log("page", page, "lastPage", lastPage);
    if (page < lastPage) {
      console.log("onscrollyend");
      setPage((page) => page + 1);
    }
  };

  const handleDeleteObservation = (id) => {
    return (setLoading) => {
      setLoading(true);
      deleteObservation(id)
        .then(() => {
          showToast("Observation deleted successfully", true);
          setLoading(false);
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log(err);
          showToast(getErrorMsg(err) || "Error deleting observation");
          setLoading(false);
        });
    };
  };

  const handleDeleteObservationRoom = (id) => {
    return (setLoading) => {
      setLoading(true);
      deleteObservationRoom(id)
        .then(() => {
          showToast("Room deleted successfully", true);
          setLoading(false);
          refreshRoomList();
        })
        .catch((err) => {
          console.log(err);
          showToast(getErrorMsg(err) || "Error deleting room");
          setLoading(false);
        });
    };
  };

  const onEditObservation = (item) => {
    setEditObservation({
      location: item.location,
      id: item.id,
    });
    openAddObservationModal();
  };
  return (
    <React.Fragment>
      <li
        className="room-accordian-style"
        id={`room-${roomId}`}
        onClick={() => {
          onOpen && onOpen();
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="d-flex align-items-center">
          <span className="align-self-center">{name}</span>

          <div
            style={{
              minWidth: "50px",
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              color: "#84857A",
            }}
          >
            {hovered && (
              <FontAwesomeIcon icon={faPencilAlt} onClick={onEditRoom} />
            )}
            <DeleteButton
              show={hovered}
              deleteFunction={handleDeleteObservationRoom(roomId)}
              message={"Are you sure you want to delete this room?"}
            />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span
            id={`open_add_observation_${roomId}`}
            className="text-secondary500 mr-1"
            onClick={(e) => {
              onOpen && onOpen();
              openAddObservationModal();
              e.stopPropagation();
            }}
          >
            + Add Observation
          </span>
          <FontAwesomeIcon
            onClick={() => setIsOpen((state) => !state)}
            className="align-self-center text-light50"
            icon={isOpen ? faCaretUp : faCaretDown}
          />
        </div>
      </li>
      {isOpen && (
        <PerfectScrollbar
          style={{ height: "auto" }}
          onYReachEnd={() => handleScrollEnd()}
        >
          <ul className="sub-list">
            {observations.map((item, i) => (
              <li
                key={`observation-${i}`}
                className={`liststyle d-flex${
                  i === active ? " listActive" : ""
                }`}
                onClick={() => {
                  setActive(i);
                }}
              >
                #{item.code} - {item.location}
                <div
                  style={{
                    marginLeft: "auto",
                    padding: "0 6px",
                    color: "#84857A",
                    display: "flex",
                    alignItems: "center",
                    minWidth: "65px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    onClick={() => onEditObservation(item)}
                  />

                  <DeleteButton
                    deleteFunction={handleDeleteObservation(item.id)}
                    message={
                      "Are you sure you want to delete this observation?"
                    }
                  />
                </div>
              </li>
            ))}
            {noData && (
              <li className="liststyle listActive">No observations created!</li>
            )}
            {isLoading && (
              <div className="center-div">
                <Spinner animation="border" variant="success" />
              </div>
            )}
          </ul>
        </PerfectScrollbar>
      )}
    </React.Fragment>
  );
}
