import { getReduxPermissions } from "../../main/shared/helpers";
import * as Actions from "../actions";

const initialState = {
  data: {},
};

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.IS_SUBSCRIBED:
      return { ...state, data: { ...state.data, ...action.payload } };
    default:
      return state;
  }
}
