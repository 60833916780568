import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteButton from "main/components/DeleteButton/DeleteButton";
import { getErrorMsg, showToast } from "main/shared/helpers";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Suspense } from "react";
import { deleteObservationComment } from "services/ObservationService";
const ShowCommentModal = React.lazy(() => import("./ShowCommentModal"));
const UpdateExistingComentModal = React.lazy(() =>
  import("./updateExistingCommentModal")
);

export default function CommentRow({ comment, i, setRefreshList }) {
  const defaultcomname = comment.default_comment != null && comment;
  const td = useRef(null);
  const [width, setWidth] = useState("100px");
  const [show, setShow] = useState(false);
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);

  useLayoutEffect(() => {
    console.log(td.current.clientWidth);
    setWidth(td.current.clientWidth - 40 + "px");
  }, []);

  const handleDeleteComment = (id) => {
    return (setLoading) => {
      setLoading(true);
      deleteObservationComment(id)
        .then(() => {
          showToast("Comment deleted successfully", true);
          setLoading(false);
          setRefreshList();
        })
        .catch((err) => {
          console.log(err);
          showToast(getErrorMsg(err) || "Error deleting comment");
          setLoading(false);
        });
    };
  };

  return (
    <>
      <tr
        id={`comment_${i}`}
        className="shadow"
        style={{ borderRadius: "10px" }}
      >
        <td
          ref={td}
          id={`comment_${i}_name`}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <div className="d-flex flex-column">
            <span
              className="text-light20"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              {comment.name || comment.default_comment.name}
            </span>
            <span
              className="text-light20 row-sub-text"
              style={{
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: width,
              }}
            >
              {comment.remarks}
            </span>
          </div>
        </td>
        <td
          width="25"
          style={{ padding: "5px 0px" }}
          id={`test_${i}_test_delete`}
        >
          <DeleteButton
            deleteFunction={handleDeleteComment(comment.id)}
            message={"Are you sure you want to delete this comment?"}
          />
        </td>
        <td
          id={`comment_${i}_detail_button`}
          style={{ padding: "5px 0px", width: "40px" }}
        >
          <button
            className="next-btn ripple"
            onClick={() => {
              if (comment.default_comment != null) {
                setShowAddCommentsModal(true);
              } else if (comment.default_comment == null) {
                setShow(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
        </td>
      </tr>
      <Suspense fallback={null}>
        <ShowCommentModal
          show={show}
          onHide={() => setShow(false)}
          comment={comment}
          refresslist={setRefreshList}
        />
      </Suspense>
      <Suspense fallback={null}>
        <UpdateExistingComentModal
          show={showAddCommentsModal}
          onHide={() => setShowAddCommentsModal(false)}
          observationId={comment.observation_id}
          d_coments={defaultcomname}
          refresslist={setRefreshList}
        />
      </Suspense>
    </>
  );
}
