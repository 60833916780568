import axios from "../axios-defaults";

const getInspectionRooms = (inspectionId, page = 1) => {
  return axios.get(`/api/v1/room?inspection_id=${inspectionId}&page=${page}`);
};

const getRoomObservation = (roomId, page = 1, data) => {
  return axios.get(`/api/v1/observation?room_id=${roomId}&page=${page}`, data);
};

const createObservation = (data) => {
  return axios.post(`/api/v1/observation`, data);
};

const getObservationTests = (observationId, page = 1) => {
  return axios.get(
    `/api/v1/observation/test?observation_id=${observationId}&page=${page}`
  );
};

const getObservationComments = (observationId, page = 1) => {
  return axios.get(
    `/api/v1/observation/comments?observation_id=${observationId}&page=${page}`
  );
};

const getObservationImages = (observationId, page = 1) => {
  return axios.get(
    `/api/v1/observation/images?observation_id=${observationId}&page=${page}`
  );
};

const addObservationTest = (data) => {
  return axios.post("/api/v1/observation/test", data);
};

const getObservationTestTypes = (keyword, page = 1) => {
  return axios.get(
    `/api/v1/observation/test-sample-info?${
      keyword ? `search=${keyword}&` : ""
    }page=${page}`
  );
};

const updateObservationExistingComment = (cid, data) => {
  return axios.post(`/api/v1/observation/comments/${+cid}`, {
    ...data,
    _method: "PATCH",
  });
};
const updateObservationCustomComment = (cid, data) => {
  return axios.post(`/api/v1/observation/comments/${+cid}`, {
    ...data,
    _method: "PATCH",
  });
};

const getInvoices = (inspectionId, page = 1) => {
  return axios.get(
    `/api/v1/inspection/transaction?inspection_id=${inspectionId}&page=${page}`
  );
};

const getInvoiceTest = (inspectionId, page = 1) => {
  return axios.get(
    `/api/v1/inspection/transaction/test?inspection_id=${inspectionId}&page=${page}`
  );
};

const getInvoiceExtraFees = () => {
  return axios.get(`/api/v1/inspection/transaction/extra-fee`);
};

const addInvoice = (data) => {
  return axios.post("/api/v1/inspection/transaction", data);
};

const addPhotos = (data) => {
  return axios.post("/api/v1/observation/images", data);
};

const addCaption = (id, data) => {
  return axios.post(`/api/v1/observation/images/${id}`, {
    ...data,
    _method: "patch",
  });
};

const addObservationComment = (data) => {
  return axios.post("/api/v1/observation/comments", data);
};

const markTestStatus = (id, status, test_type_id, sample_type_id) => {
  return axios.post(`/api/v1/observation/test/${id}`, {
    status,
    _method: "patch",
    test_type_id,
    sample_type_id,
  });
};

const updateObservation = (id, data) => {
  return axios.post(`/api/v1/observation/${id}`, {
    location: data,
    _method: "patch",
  });
};

const deleteObservation = (id) => {
  return axios.delete(`/api/v1/observation/${id}`, { _method: "delete" });
};

const deleteObservationTest = (id) => {
  return axios.delete(`/api/v1/observation/test/${id}`, { _method: "delete" });
};

const deleteObservationComment = (id) => {
  return axios.delete(`/api/v1/observation/comments/${id}`, {
    _method: "delete",
  });
};

const deleteObservationImage = (id) => {
  return axios.post(`/api/v1/observation/images/${id}`, { _method: "delete" });
};

const deleteObservationRoom = (id) => {
  return axios.post(`/api/v1/room/${id}`, { _method: "delete" });
};

export {
  getInspectionRooms,
  getRoomObservation,
  createObservation,
  getObservationTests,
  getObservationImages,
  getObservationComments,
  addObservationTest,
  getObservationTestTypes,
  updateObservationExistingComment,
  updateObservationCustomComment,
  updateObservation,
  getInvoices,
  getInvoiceTest,
  getInvoiceExtraFees,
  addInvoice,
  addPhotos,
  addObservationComment,
  markTestStatus,
  addCaption,
  deleteObservation,
  deleteObservationTest,
  deleteObservationComment,
  deleteObservationImage,
  deleteObservationRoom,
};
