/* eslint-disable react-hooks/exhaustive-deps */
import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { Suspense, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_PROFILE } from "store/actions";
import { updateSubAdminProfile } from "../../../../services/auth-service";
import { uploadToS3 } from "../../../../services/s3";
import CustomInput from "../../../components/Custom/CustomInput";
import { getErrorMsg, roleNames, showToast } from "../../../shared/helpers";
import { regexes } from "../../../shared/regexes";
import { fileSizeLimits, folders } from "../../../shared/validations";

const ResetPasswordModal = React.lazy(() => import("./ResetPasswordModal"));

export default function EditSubAdminProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [file_e, setFile_e] = useState("");
  const [file, setFile] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const userId = user.id;

  useEffect(() => {
    loadUser(user);
  }, [user]);

  const loadUser = (user) => {
    setIsLoading(true);
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setRole(user.role);
    setEmail(user.email);
    setPhone(user.phone_number);
    setPreview(user.image?.url || null);
    setImage(user.image?.url || null);
    setIsLoading(false);
  };

  const handleSave = () => {
    setDisableSave(true);
    if (image !== preview) {
      console.log("upload photo");
      if (image) {
        console.log("delete photo");
        //deleteFromS3(data?.business_unit?.logo_url);
      }
      uploadPhoto()
        .then((url) => {
          editUser(url);
        })
        .catch(() => showToast("Unable to upload photo."));
    } else {
      editUser(null);
    }
  };

  const editUser = (image) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      ...(image ? { avatar_url: image } : null),
    };
    updateSubAdminProfile(data)
      .then((res) => {
        showToast("User has been updated successfully.", true);
        loadUser(user);
        setEditEnabled(false);
        dispatch({ type: UPDATE_PROFILE, payload: res.data });
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Unable to update user.");
        setIsLoading(false);
      })
      .finally(() => setDisableSave(false));
  };

  const uploadPhoto = () => {
    console.log("file", file);
    return new Promise((resolve, reject) => {
      if (file) {
        uploadToS3(file, folders.profile)
          .then((res) => {
            console.log(res);
            resolve(res.url);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve("");
      }
    });
  };

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("File size cannot be larger than 2MB");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG or PNG");
      } else {
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="main-content-area">
            <div className="d-flex align-items-center">
              {editEnabled ? (
                <div className="d-flex ml-auto">
                  <button
                    className="btn btn-primary ml-auto"
                    id="save_user"
                    onClick={handleSave}
                    disabled={disableSave}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-light ml-2 low-priority"
                    onClick={() => {
                      setEditEnabled(false);
                      loadUser(user);
                    }}
                    disabled={disableSave}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary ml-auto"
                  id="edit_customer"
                  onClick={() => setEditEnabled(true)}
                >
                  Edit Sub-Admin <FontAwesomeIcon icon={faPen} color="#fff" />
                </button>
              )}
            </div>
            <div>
              <h4 className="font-weight-bold">Personal Details</h4>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          inputProps={{
                            maxLength: "30",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          inputProps={{
                            maxLength: "30",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Role"
                          value={roleNames[role]}
                          onChange={() => {}}
                          inputProps={{ maxLength: "50", disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Email"
                          value={email}
                          onChange={() => {}}
                          inputProps={{ maxLength: "50", disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Phone Number"
                          value={new AsYouType("US").input(phone)}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(regexes.phoneInput, "");
                            setPhone(value);
                          }}
                          inputProps={{
                            maxLength: "16",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="upload-avatar">
                    <div
                      className={
                        editEnabled
                          ? "image-area"
                          : "cursor-disabled image-area"
                      }
                    >
                      {!preview ? (
                        <span
                          className="upload-icon"
                          style={
                            preview
                              ? { backgroundColor: "transparent" }
                              : { backgroundColor: "#fff" }
                          }
                        >
                          <FontAwesomeIcon icon={faCamera} />
                        </span>
                      ) : null}
                      <img
                        width="120"
                        className="avatar-lg"
                        src={preview}
                        alt=""
                      />
                      <input
                        type="file"
                        className="upload-image"
                        accept="image/jpeg,image/png"
                        onChange={(e) => {
                          fileChangedHandler(e);
                        }}
                        disabled={!editEnabled}
                      />
                    </div>
                    <p>Upload Photo (Optional)</p>
                    <small className="text-danger ml-1">{file_e}</small>
                  </div>
                </div>
              </div>

              <hr className="mt-0 mb-4" />

              <h4 className="font-weight-bold">Password</h4>
              <button
                className="btn btn-primary mt-3 mb-4"
                id="reset_password"
                onClick={() => setShowResetModal(true)}
                disabled={disableSave}
              >
                Reset Password
              </button>
            </div>
          </div>
        )}
      </ScrollBar>
      <Suspense fallback={null}>
        <ResetPasswordModal
          userId={userId}
          onHide={() => setShowResetModal(false)}
          show={showResetModal}
          userType={role}
          isSubAdmin
        />
      </Suspense>
    </div>
  );
}
