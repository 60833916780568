import { faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import {
  SET_REPORT,
  SET_REPORT_LEFT_TITLE,
  SHOW_MODAL,
} from "../../../../store/actions";
import { isEmptyHtml } from "../../../shared/helpers";

export default function EditableText(props) {
  const { children, textArea, ml, innerHtml, reduxKey, isPageTitle, titleKey } =
    props;
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        show: true,
        value: children,
        setValue: (t) => ucp(reduxKey, t),
        textArea: textArea,
        ml: ml,
        richText: innerHtml,
      },
    });
  };

  const ucp = (path, text) => {
    if (isPageTitle) {
      dispatch({
        type: SET_REPORT_LEFT_TITLE,
        payload: { path, data: text, titleKey },
      });
    }
    dispatch({
      type: SET_REPORT,
      payload: { path, data: text },
    });
  };

  return (
    <div className="editable-text">
      <div className="tools pen">
        <FontAwesomeIcon icon={faPen} color="#2f481e" onClick={openModal} />
      </div>

      {innerHtml ? (
        <span
          dangerouslySetInnerHTML={{
            __html: !isEmptyHtml(children) ? children : "Click to add text",
          }}
          onClick={openModal}
        ></span>
      ) : (
        <span onClick={openModal} className="break-text">
          {children || "Click to add text"}
        </span>
      )}

      <div className="tools cross">
        <FontAwesomeIcon
          icon={faTimes}
          color="#b44a4a"
          onClick={() => ucp(reduxKey, "")}
        />
      </div>
    </div>
  );
}
