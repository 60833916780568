import axios from "../axios-defaults";

class Room {
  createRoom(data) {
    return axios.post("/api/v1/room-types", data);
  }

  getRooms(page, q = "") {
    return axios.get(`/api/v1/room-types?page=${page}&q=${q}`);
  }

  updateRooms(id, data) {
    Object.assign(data, { _method: "patch" });
    return axios.post(`api/v1/room-types/${id}`, data);
  }

  DeleteRoom(id) {
    return axios.delete(`/api/v1/room-types/${id}`);
  }

  searchRooms(keyword, page = 1) {
    return axios.get(
      `/api/v1/room-types?${keyword ? `search=${keyword}&` : ""}page=${page}`
    );
  }

  searchRoomsForObservation(keyword, page = 1) {
    return axios.get(
      `/api/v1/inspection-rooms?${
        keyword ? `search=${keyword}&` : ""
      }page=${page}`
    );
  }
}

const RoomService = new Room();

export default RoomService;
