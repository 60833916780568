import axios from "../axios-defaults";

class Products {
    getProducts(){
        return axios.get('/api/v1/stripe/products');
    }

    validateCoupon(id){
        return axios.get(`/api/v1/stripe/coupon?id=${id}`)
    }

    getSetupIntent(){
        return axios.get('/api/v1/stripe/setup-intent');
    }

    makeSubscription(data){
        return axios.post('/api/v1/stripe/user/subscribe',data);
    }
}

const obj = new Products();

export default obj;