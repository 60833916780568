/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "../../Auth/ResetPassword/ResetPassword";
import Documents from "./Documents/Documents";
import GeneralInfo from "./GeneralInfo";
import Observation from "./Observation/Observation";
import Transactions from "./Transactions/Transactions";
import { isSampleStrategyReportShown } from "main/pages/SampleStrategy/utility/companySpecific";
import { useSelector } from "react-redux";

export default function InspectionDetails() {
  const [key, setKey] = useState("generalInfo");
  const user = useSelector((state) => state.user);

  let history = useHistory();
  let query = useQuery();
  let location = useLocation();

  useEffect(() => {
    setKey(query.get("tab") || "generalInfo");
  }, [query]);

  return (
    <div
      className="main"
      style={key === "observation" ? { background: "white" } : {}}
    >
      <Tabs
        className="setting-tab-btns"
        defaultActiveKey="generalInfo"
        activeKey={key}
        onSelect={(k) => {
          history.push(`${location.pathname}?tab=${k}`);
        }}
      >
        <Tab eventKey="generalInfo" title="General Info">
          <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
            {key === "generalInfo" && <GeneralInfo />}
          </ScrollBar>
        </Tab>
        <Tab eventKey="observation" title="Observation">
          {key === "observation" && <Observation />}
        </Tab>
        <Tab eventKey="documents" title="Documents">
          {key === "documents" && <Documents />}
        </Tab>
        {!isSampleStrategyReportShown(user) ? (
          <Tab eventKey="transactions" title="Transactions">
            {key === "transactions" && <Transactions />}
          </Tab>
        ) : (
          <></>
        )}
      </Tabs>
    </div>
  );
}
