import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import {
  getPrivacyPolicy,
  getTermsOfUse,
  updatePrivacyPolicy,
  updateTermsOfUse,
} from "../../../../../services/super-admin-service";
import { showToast } from "../../../../shared/helpers";
import AdminContentEditor from "./AdminContentEditor";
const AdminContent = () => {
  const [loader, setloader] = useState(true);
  const [editEnabled, setEditEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [disabledInput, setDisabledInput] = useState(false);

  const [bodyPrivacy, setBodyPrivacy] = useState({ content: {} });

  const [disableFields, setDisableFields] = useState(true);
  const [bodyTermsofUse, setbodyTermsofUse] = useState({ content: {} });
  const setDisabledFieldsChild = (value) => {
    setDisableFields(value);
  };

  useEffect(() => {
    setDisabledFieldsChild && setDisabledFieldsChild(disabledInput);
  }, [disabledInput]);

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    setloader(true);
    getPrivacyPolicy()
      .then((x) => {
        setBodyPrivacy({ content: x.data.content });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        getTermsOfUse()
          .then((x) => {
            setbodyTermsofUse({ content: x.data.content });
          })
          .catch((err) => console.log(err))
          .finally(() => setloader(false));
      });
  };

  const edit = () => {
    let updateReq = "";
    setIsSaving(true);
    updatePrivacyPolicy(bodyPrivacy)
      .then((res) => {
        if (res.status === 200) {
          updateReq = "privacy";
          console.log(res.data);
          setEditEnabled(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          showToast(err?.response?.data);
        } else {
          showToast("Something went wrong in privacy policy");
        }
        console.log(err);
      })
      .finally(() => {
        updateTermsOfUse(bodyTermsofUse)
          .then((res) => {
            if (res.status === 200) {
              updateReq = updateReq + "terms";
              console.log(res.data);
              setEditEnabled(false);
            }
          })
          .catch((err) => {
            if (err?.response?.status === 422) {
              showToast(err?.response?.data);
            } else {
              showToast("Something went wrong in terms of use");
            }
            console.log(err);
          })
          .finally(() => {
            setEditEnabled(false);
            if (updateReq === "privacyterms") {
              showToast("Content updated successfully", true);
            } else if (updateReq === "privacy") {
              showToast("Only Privacy Policy has been updated", true);
            } else if (updateReq === "terms") {
              showToast("Only Terms of Use has been updated", true);
            }

            setIsSaving(false);
          });
      });

    console.log(updateReq);
  };

  return (
    <>
      {loader ? (
        <div className="spinner-div">
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <div
          className="main-content-area"
          style={{ height: "calc(100vh - 140px)" }}
        >
          <div className="d-flex justify-content-end">
            {!editEnabled ? (
              <button
                className="btn btn-primary"
                disabled={false}
                onClick={() => setEditEnabled(true)}
                id="edit_fee"
              >
                Edit Content <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            ) : (
              <div className="d-flex">
                <button
                  className="btn btn-primary"
                  disabled={isSaving}
                  onClick={() => edit()}
                >
                  Save
                </button>
                <button
                  className="btn btn-light ml-2 low-priority"
                  onClick={() => {
                    setEditEnabled(false);
                    getContent();
                  }}
                  disabled={isSaving}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <p className="TabsLabel d-flex">Terms of Use</p>
          <AdminContentEditor
            text={bodyTermsofUse.content}
            disabled={!editEnabled}
            setDisabledFieldsChild={setDisabledFieldsChild}
            onSave={(val) => {
              setbodyTermsofUse({ content: val });
            }}
            maxLength={60000}
            toolbar={true}
          />

          <p className="TabsLabel d-flex mt-4">Privacy Policy</p>
          <AdminContentEditor
            text={bodyPrivacy.content}
            disabled={!editEnabled}
            setDisabledFieldsChild={setDisabledFieldsChild}
            onSave={(val) => {
              setBodyPrivacy({ content: val });
            }}
            maxLength={60000}
            toolbar={true}
          />
        </div>
      )}
    </>
  );
};

export default AdminContent;
