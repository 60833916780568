/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useParams } from "react-router";
import { getInvoices } from "../../../../../services/ObservationService";
import { voidTransaction } from "../../../../../services/InspectionService";
import Paginator from "../../../../components/Paginator/Paginator";
import {
  USD,
  utcToLocalDateTime,
  getErrorMsg,
  showToast,
} from "../../../../shared/helpers";
import TestRow from "./TestRow";

const FeesModal = React.lazy(() => import("./FeesModal"));
const AddInvoiceModal = React.lazy(() => import("./AddInvoiceModal"));

export default function Transactions() {
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [active, setActive] = useState(0);
  const [showFeesModal, setShowFeesModal] = useState(false);
  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);
  const { insp_id } = useParams();
  const [refreshInvoices, setRefreshInvoices] = useState(false);
  const [addInvoiceDisabled, setAddInvoiceDisabled] = useState(true);
  const [isVoiding, setIsVoiding] = useState(false);

  useEffect(() => {
    loadInvoices(page);
  }, [page, refreshInvoices]);

  const loadInvoices = (page) => {
    setInvoicesLoading(true);
    getInvoices(insp_id, page)
      .then((res) => {
        // console.log(res);
        setInvoices(res.data.data);
        setActivePage(res.data.current_page);
        setItemsCountPerPage(res.data.per_page);
        setTotalItemsCount(res.data.total);
        if (res.data.data.length > 0) {
          setActive(0);
          setAddInvoiceDisabled(false);
        } else {
          setAddInvoiceDisabled(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setInvoicesLoading(false);
      });
  };

  const handleVoid = () => {
    setIsVoiding(true);
    voidTransaction(invoices[active].id)
      .then(() => {
        showToast("Invoice Voided successfully", true);
        setRefreshInvoices((refreshInvoices) => !refreshInvoices);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to void transaction");
      })
      .finally(() => setIsVoiding(false));
  };

  return (
    <div className="tab-child">
      <div className="leftside" style={{ padding: "15px 5px 5px 20px" }}>
        <div className="leftside-header">
          <h5 className="mainheading">Transactions</h5>
          <button
            className="btn btn-primary ml-auto"
            onClick={() => setShowAddInvoiceModal(true)}
            disabled={addInvoiceDisabled}
          >
            Add Invoice <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <ScrollBar style={{ height: "auto" }}>
          <ul
            className={invoicesLoading ? "ul-style list-spinner" : "ul-style"}
            style={{
              height: "calc(100vh - 258px)",
              padding: "0px 15px 0px 10px",
            }}
          >
            {invoicesLoading ? (
              <Spinner animation="border" variant="success" />
            ) : invoices.length ? (
              <table width="100%">
                <tbody>
                  {invoices.map((invoice, i) => (
                    <tr
                      className={
                        active === i ? "liststyle listActive" : "liststyle"
                      }
                      style={{ height: "34px" }}
                      key={`invoice-${i}`}
                      id={`invoice-${i}`}
                      onClick={() => setActive(i)}
                    >
                      <td className="padding-invoice-td">
                        {invoice.code.toUpperCase()}
                      </td>
                      <td className="padding-invoice-td force-small">
                        {utcToLocalDateTime(invoice.created_at)}
                      </td>
                      <td
                        className="padding-invoice-td"
                        style={{ textTransform: "capitalize" }}
                      >
                        {invoice.status === "pending"
                          ? "Pending Pay"
                          : invoice.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <li className="liststyle listActive">No invoice added!</li>
            )}
          </ul>
        </ScrollBar>
        <Paginator
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onChange={(page) => setPage(page)}
        />
      </div>
      <ScrollBar
        className="round-top"
        style={{
          height: "calc(100vh - 140px)",
          overflowY: "auto",
          width: "100%",
          background: "#e7ece8",
        }}
      >
        <div className="rightside" style={{ width: "100%", height: "100%" }}>
          {!invoicesLoading && invoices.length > 0 && (
            <div className="rightsideinner" style={{ height: "100%" }}>
              <div className="observation-details-section">
                <span className="text-light20 protocol-top-text">Tests</span>
                <hr className="bold-hr" />
              </div>
              <div className="observation-details-section">
                <ScrollBar style={{ maxHeight: "400px" }}>
                  <table
                    id="test_table"
                    className="table pr-3 pl-3 pb-3 spacing"
                  >
                    <tbody className="">
                      {invoices.length
                        ? invoices[active].receipt.map((rec, i) => (
                            <TestRow receipt={rec} key={`invoice-test-${i}`} />
                          ))
                        : null}
                    </tbody>
                  </table>
                </ScrollBar>
              </div>
              <div className="observation-details-section">
                <span className="text-light20 protocol-top-text">
                  Total and Fees
                </span>
                <hr className="bold-hr" />
              </div>
              <div className="observation-details-section">
                <div className="d-flex justify-content-end">
                  <span
                    className="text-secondary500 font-weight-bold p-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowFeesModal(true)}
                  >
                    View Fees
                  </span>
                  <span
                    className="text-light20 font-weight-bold p-3"
                    style={{ fontSize: "18px" }}
                  >
                    {USD(
                      invoices.length ? invoices[active].extra_fee_total : ""
                    ).format()}
                  </span>
                </div>
                <div className="d-flex justify-content-end">
                  <span
                    className="text-light20 font-weight-bold p-3"
                    style={{ fontSize: "24px" }}
                  >
                    Total Cost
                  </span>
                  <span
                    className="text-light20 font-weight-bold p-3"
                    style={{ fontSize: "24px" }}
                  >
                    {USD(
                      invoices.length ? invoices[active].total_cost : ""
                    ).format()}
                  </span>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    id="void_btn"
                    className="bg-error br-error"
                    onClick={handleVoid}
                    disabled={isVoiding || invoices[active].status === "void"}
                  >
                    Void
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </ScrollBar>
      <Suspense fallback={null}>
        <FeesModal
          show={showFeesModal}
          onHide={() => setShowFeesModal(false)}
          fees={invoices.length ? invoices[active].extra_fee : []}
          invoice={invoices.length ? invoices[active].code.toUpperCase() : ""}
          readOnly={true}
        />
        <AddInvoiceModal
          show={showAddInvoiceModal}
          onHide={() => setShowAddInvoiceModal(false)}
          setRefreshInvoices={setRefreshInvoices}
        />
      </Suspense>
    </div>
  );
}
