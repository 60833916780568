/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { templatePagesArray } from "../pages/InvestigationReport/Components/ReportPages/TemplatePage";
import { mergeArray } from "./helpers";
import pdfFonts from "./pdf-fonts/vfs_fonts.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Exo2: {
    normal: "Exo2-Regular.ttf",
    bold: "Exo2-Bold.ttf",
    italics: "Exo2-Italic.ttf",
    bolditalics: "Exo2-BoldItalic.ttf",
  },
};

const options = {
  defaultStyles: {
    p: { margin: [0, 0, 0, 0], color: "#2f481e" },
    strong: { color: "#2f481e" },
    ul: { color: "#2f481e" },
    ol: { color: "#2f481e" },
    li: { color: "#2f481e" },
    em: { color: "#2f481e" },
    h1: { color: "#2f481e" },
    h2: { color: "#2f481e" },
    h3: { color: "#2f481e" },
    h4: { color: "#2f481e" },
    h5: { color: "#2f481e" },
    h6: { color: "#2f481e" },
    span: { color: "#2f481e" },
  },
};

let html = [];
let length = 0;

const placeholderImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";

function DownloadPDF({ report }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    html = [];
    length = 0;

    setIsLoading(true);
    makeCoverPage();
    makeTocPage();
    makeBackgroundaAndInspectionData();
    makeObservationPages();
    makeTemplatePageData();
    documentDefinitions.content = html;
    setIsLoading(false);
    pdfMake.createPdf(documentDefinitions).open();
  };

  const makeTemplatePageData = () => {
    templatePagesArray.forEach((key) => {
      const tempHtml = htmlToPdfmake(report[key].text, options);
      mergeArray(html, tempHtml);
      html[length].pageTitle = report[key].title;
      length = html.length;
      html[length - 1].pageBreak = "after";
    });
    html[length - 1].pageBreak = null;
  };

  const makeBackgroundaAndInspectionData = () => {
    ["background", "inspection"].forEach((key) => {
      const tempHtml = htmlToPdfmake(report[key].text, options);
      mergeArray(html, tempHtml);
      html[length].pageTitle = report[key].title;
      length = html.length;
      html[length - 1].pageBreak = "after";
    });
  };

  const makeCoverPage = () => {
    html.push([
      {
        text: report.cover_page.title,
        style: "coverTitle",
        alignment: "center",
      },
      {
        image: report.cover_page?.property?.image
          ? "coverImage"
          : placeholderImage,
        fit: [400, 350],
        alignment: "center",
      },
      {
        text: report.cover_page.client.title,
        style: "coverHead",
        alignment: "center",
      },
      {
        text: report.cover_page.client.name,
        style: "coverText",
        alignment: "center",
      },
      {
        text: report.cover_page.property.title,
        style: "coverHead",
        alignment: "center",
      },
      {
        text: report.cover_page.property.address,
        style: "coverText",
        alignment: "center",
      },
      {
        text: report.cover_page.survey.title,
        style: "coverHead",
        alignment: "center",
      },
      {
        text: report.cover_page.survey.date,
        style: "coverText",
        alignment: "center",
      },
    ]);
    const tempHtml = htmlToPdfmake(report.cover_page.report.terms.content, {
      defaultStyles: {
        ...options.defaultStyles,
        p: { margin: [40, 15, 40, 15], color: "#2f481e" },
      },
    });
    console.log(tempHtml);
    mergeArray(html, tempHtml);
    length = html.length;
    html[length - 1].pageBreak = "after";

    if (report.cover_page?.property?.image) {
      documentDefinitions.images.coverImage = report.cover_page.property.image;
    }
    if (report.cover_page?.company?.logo) {
      documentDefinitions.images.companyLogo = report.cover_page.company.logo;
    }
  };

  const makeObservationPages = () => {
    const totalObs = report.observations?.data.reduce((total, room) => {
      return (
        total +
        room.observations?.reduce((total2, obsInd) => {
          return total2 + 1;
        }, 0)
      );
    }, 0);
    let current = 0;
    report.observations?.data?.forEach((room, roomInd) => {
      room.observations?.forEach((_, obsInd) => {
        current++;
        makeCommentsPage(roomInd, obsInd, totalObs, current);
        makePhotosPages(roomInd, obsInd, totalObs, current);
        makeTestsPage(roomInd, obsInd, totalObs, current);
      });
      makeProtocolPages(roomInd);
    });
  };

  const makeCommentsPage = (roomInd, obsInd, totalObs, current) => {
    const comments =
      report?.observations?.data[roomInd]?.observations[obsInd]?.comments ?? [];
    const roomName = report?.observations?.data[roomInd]?.room_name ?? "";
    const obsId =
      "#" +
      (
        report?.observations?.data[roomInd]?.observations[obsInd]?.code ?? ""
      ).toUpperCase();
    const location =
      report?.observations?.data[roomInd]?.observations[obsInd]?.location ?? "";

    const tempContent = [
      {
        margin: [-35, -17, -35, 0],
        table: {
          headerRows: 0,
          widths: ["auto", "auto", "*", 70],
          body: [
            [
              {
                text: [
                  {
                    text: "Room: ",
                  },
                  {
                    text: roomName,
                    style: "observationInfoText",
                  },
                ],
                margin: [35, 0, 0, 0],
              },
              {
                text: [
                  {
                    text: "Observation ID: ",
                  },
                  {
                    text: obsId,
                    style: "observationInfoText",
                  },
                ],
              },
              {
                text: [
                  {
                    text: "Observation Location: ",
                  },
                  {
                    text: location,
                    style: "observationInfoText",
                  },
                ],
              },
              {
                text: `${current} of ${totalObs}`,
                style: "observationInfoText",
                alignment: "right",
                margin: [0, 0, 35, 0],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          fillColor: () => "#E7ECE8",
          paddingTop: () => 12,
          paddingBottom: () => 12,
        },
      },
      {
        table: {
          headerRows: 0,
          widths: ["auto", "*"],
          body: [
            [
              { text: "Comments", style: "commentTitleStyle" },
              {
                /* margin: [0, 0, 0, 10], */
                table: {
                  widths: ["*"],
                  body: [[" "], [" "]],
                },
                layout: {
                  hLineWidth: (i, node) =>
                    i === 0 || i === node.table.body.length ? 0 : 1,
                  vLineWidth: () => 0,
                  hLineColor: (i) => "#2f481e",
                },
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
        },
      },
    ];
    comments.forEach((c) => {
      tempContent.push(
        {
          text: c?.name ?? c.default_comment.name,
          style: "commentHead",
        },
        {
          text: c.remarks,
          style: "commentBody",
        }
      );
    });
    mergeArray(html, tempContent);
    html[length].pageTitle = report?.observations?.title;
    length = html.length;
    html[length - 1].pageBreak = "after";
  };

  const makePhotosPages = (roomInd, obsInd, totalObs, current) => {
    const photos =
      report?.observations?.data[roomInd]?.observations[obsInd]?.images ?? [];
    const roomName = report?.observations?.data[roomInd]?.room_name ?? "";
    const obsId =
      "#" +
      (
        report?.observations?.data[roomInd]?.observations[obsInd]?.code ?? ""
      ).toUpperCase();
    const location =
      report?.observations?.data[roomInd]?.observations[obsInd]?.location ?? "";
    const col1 = [];
    const col2 = [];
    photos.forEach((p, i) => {
      documentDefinitions.images[`obs-${roomInd}-${obsInd}-${i}`] = p.url;
      const photoData = {
        width: 100,
        table: {
          headerRows: 0,
          widths: ["*"],
          body: [
            [
              {
                image: `obs-${roomInd}-${obsInd}-${i}`,
                fit: [100, 100],
                alignment: "center",
              },
            ],
            [
              {
                text: p.caption,
                style: "photoRemarks",
                alignment: "center",
              },
            ],
          ],
        },
        layout: "noBorders",
      };
      if (i <= 3) {
        col1.push(photoData);
      } else {
        col2.push(photoData);
      }
    });

    const tempContent = [
      {
        margin: [-35, -17, -35, 0],
        table: {
          headerRows: 0,
          widths: ["auto", "auto", "*", 70],
          body: [
            [
              {
                text: [
                  {
                    text: "Room: ",
                  },
                  {
                    text: roomName,
                    style: "observationInfoText",
                  },
                ],
                margin: [35, 0, 0, 0],
              },
              {
                text: [
                  {
                    text: "Observation ID: ",
                  },
                  {
                    text: obsId,
                    style: "observationInfoText",
                  },
                ],
              },
              {
                text: [
                  {
                    text: "Observation Location: ",
                  },
                  {
                    text: location,
                    style: "observationInfoText",
                  },
                ],
              },
              {
                text: `${current} of ${totalObs}`,
                style: "observationInfoText",
                alignment: "right",
                margin: [0, 0, 35, 0],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          fillColor: () => "#E7ECE8",
          paddingTop: () => 12,
          paddingBottom: () => 12,
        },
      },
      {
        table: {
          headerRows: 0,
          widths: ["auto", "*"],
          body: [
            [
              { text: "Photos", style: "commentTitleStyle" },
              {
                table: {
                  widths: ["*"],
                  body: [[" "], [" "]],
                },
                layout: {
                  hLineWidth: (i, node) =>
                    i === 0 || i === node.table.body.length ? 0 : 1,
                  vLineWidth: () => 0,
                  hLineColor: (i) => "#2f481e",
                },
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
        },
      },
      {
        alignment: "center",
        columns: col1,
        columnGap: 25,
        margin: [15, 0, 0, 25],
      },
      {
        alignment: "center",
        columns: col2,
        columnGap: 25,
        margin: [15, 0, 0, 25],
      },
    ];

    mergeArray(html, tempContent);
    html[length].pageTitle = report.observations.title;
    length = html.length;
    html[length - 1].pageBreak = "after";
  };

  const makeTestsPage = (roomInd, obsInd, totalObs, current) => {
    const tests =
      report?.observations?.data[roomInd]?.observations[obsInd]?.tests ?? [];
    const roomName = report?.observations?.data[roomInd]?.room_name ?? "";
    const obsId =
      "#" +
      (
        report?.observations?.data[roomInd]?.observations[obsInd]?.code ?? ""
      ).toUpperCase();
    const location =
      report?.observations?.data[roomInd]?.observations[obsInd]?.location ?? "";

    const tempContent = [
      {
        margin: [-35, -17, -35, 0],
        table: {
          headerRows: 0,
          widths: ["auto", "auto", "*", 70],
          body: [
            [
              {
                text: [
                  {
                    text: "Room: ",
                  },
                  {
                    text: roomName,
                    style: "observationInfoText",
                  },
                ],
                margin: [35, 0, 0, 0],
              },
              {
                text: [
                  {
                    text: "Observation ID: ",
                  },
                  {
                    text: obsId,
                    style: "observationInfoText",
                  },
                ],
              },
              {
                text: [
                  {
                    text: "Observation Location: ",
                  },
                  {
                    text: location,
                    style: "observationInfoText",
                  },
                ],
              },
              {
                text: `${current} of ${totalObs}`,
                style: "observationInfoText",
                alignment: "right",
                margin: [0, 0, 35, 0],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          fillColor: () => "#E7ECE8",
          paddingTop: () => 12,
          paddingBottom: () => 12,
        },
      },
      {
        table: {
          headerRows: 0,
          widths: ["auto", "*"],
          body: [
            [
              { text: "Tests", style: "commentTitleStyle" },
              {
                table: {
                  widths: ["*"],
                  body: [[" "], [" "]],
                },
                layout: {
                  hLineWidth: (i, node) =>
                    i === 0 || i === node.table.body.length ? 0 : 1,
                  vLineWidth: () => 0,
                  hLineColor: (i) => "#2f481e",
                },
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
        },
      },
      ...tests.map((t, testInd) => ({
        margin: [0, 0, 0, 15],
        layout: {
          hLineWidth: (i) => (i === 0 || i === 1 || i === 3 ? 0 : 1),
          vLineWidth: (i, node) => {
            console.log(node.table);
            return i === 0 || i === node.table.widths.length ? 0 : 1;
          },
          hLineColor: () => "#b9b9b9",
          vLineColor: () => "#b9b9b9",
          fillColor: (i) => (i === 0 ? "#84857A" : "#E7ECE8"),
          paddingTop: (i) => (i === 0 ? 5 : 12),
          paddingBottom: (i) => (i === 0 ? 5 : 12),
        },
        table: {
          headerRows: 0,
          widths: ["*", "*", "*", "*"],
          body: [
            [
              { text: "Test Type", style: "testTableHead" },
              { text: "Sample Type", style: "testTableHead" },
              { text: "Client Approval", style: "testTableHead" },
              { text: "Lab code", style: "testTableHead" },
            ],
            [
              { text: t.test.name, style: "testTableRow" },
              { text: t.test_sample.name, style: "testTableRow" },
              { text: t.client_approval, style: "testTableRow" },
              { text: t.code, style: "testTableRow" },
            ],
            ...(tests[testInd].images.length > 0
              ? [
                  [
                    {
                      colSpan: 4,
                      layout: {
                        hLineWidth: () => 0,
                        vLineWidth: () => 0,
                        fillColor: () => "#E7ECE8",
                      },
                      table: {
                        headerRows: 0,
                        widths: ["*", "*", "*", "*"],
                        body: [
                          [
                            ...tests[testInd].images.map((t, i) => {
                              documentDefinitions.images[
                                `test-${roomInd}-${obsInd}-${0}-${i}`
                              ] = t.url;
                              return {
                                image: `test-${roomInd}-${obsInd}-${0}-${i}`,
                                fit: [100, 100],
                                alignment: "center",
                              };
                            }),
                            ...[
                              ...new Array(4 - tests[testInd].images.length),
                            ].map(() => {
                              console.log("adding text");
                              return { text: "" };
                            }),
                          ],
                        ],
                      },
                    },
                  ],
                ]
              : []),
          ],
        },
      })),
    ];

    mergeArray(html, tempContent);
    html[length].pageTitle = report.observations.title;
    length = html.length;
    html[length - 1].pageBreak = "after";
  };

  const makeProtocolPages = (roomInd) => {
    const protocols = report?.observations?.data[roomInd]?.type.protocol ?? [];
    const roomName = report?.observations?.data[roomInd]?.room_name ?? "";

    const tempContent = [
      {
        margin: [-35, -17, -35, 0],
        table: {
          headerRows: 0,
          widths: ["*"],
          body: [
            [
              {
                text: [
                  {
                    text: "Room: ",
                  },
                  {
                    text: roomName,
                    style: "observationInfoText",
                  },
                ],
                margin: [35, 0, 0, 0],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          fillColor: () => "#E7ECE8",
          paddingTop: () => 12,
          paddingBottom: () => 12,
        },
      },
      {
        margin: [0, 15, 0, 0],
        style: { bold: true },
        ol: protocols.map((p) => ({
          text: htmlToPdfmake(p.protocol, options),
          style: { bold: false },
          margin: [0, 0, 0, 10],
        })),
      },
    ];

    mergeArray(html, tempContent);
    html[length].pageTitle = "Observation Considerations";
    length = html.length;
    html[length - 1].pageBreak = "after";
  };

  const makeTocPage = () => {
    const content = report?.toc?.content ?? [];
    const observationRooms = report?.observations?.data ?? [];
    const tempContent = [
      ...content.reduce((total, section, i) => {
        if (i === 0) {
          return total;
        }
        total.push({
          text: section?.title,
          style: "tocTitle",
        });
        section?.title === "Observations"
          ? observationRooms.forEach((r) => {
              total.push({
                table: {
                  headerRows: 0,
                  dontBreakRows: true,
                  widths: ["auto", "*", "auto"],
                  heights: [5],
                  body: [
                    [
                      { text: r.room_name },
                      {
                        margin: [0, 8, 0, 0],
                        table: {
                          widths: ["*"],
                          heights: [5],
                          body: [[" "]],
                        },
                        layout: {
                          hLineWidth: (i) => (i === 0 ? 1 : 0),
                          vLineWidth: () => 0,
                          hLineColor: () => "#84857a",
                        },
                      },
                      { text: "1" },
                    ],
                  ],
                },
                layout: "noBorders",
              });
            })
          : section.sub_content.forEach((content) => {
              total.push({
                table: {
                  headerRows: 0,
                  widths: ["auto", "*", "auto"],
                  heights: [5],
                  body: [
                    [
                      { text: content.label },
                      {
                        margin: [0, 8, 0, 0],
                        table: {
                          widths: ["*"],
                          heights: [5],
                          body: [[" "]],
                        },
                        layout: {
                          hLineWidth: (i) => (i === 0 ? 1 : 0),
                          vLineWidth: () => 0,
                          hLineColor: () => "#84857a",
                        },
                      },
                      { text: "1" },
                    ],
                  ],
                },
                layout: "noBorders",
              });
            });
        return total;
      }, []),
    ];
    console.log(tempContent);

    mergeArray(html, tempContent);
    html[length].pageTitle = report.toc.title;
    length = html.length;
    html[length - 1].pageBreak = "after";
  };

  // document definition used to create pdf
  let documentDefinitions = {
    pageSize: "A4",
    pageMargins: [35, 60, 35, 40],
    header: (currentPage) => {
      let headerText = "";
      for (let i = 0; i < documentDefinitions.content.length; i++) {
        if (
          documentDefinitions.content[i].pageTitle &&
          currentPage >= documentDefinitions.content[i].positions[0].pageNumber
        ) {
          headerText = documentDefinitions.content[i].pageTitle;
        }
      }
      return currentPage === 1
        ? null
        : {
            table: {
              headerRows: 0,
              widths: ["*", 230],

              body: [
                [
                  {
                    text: headerText,
                    style: "customHeaderTitle",
                  },
                  {
                    text: "Environmental Assessment Report",
                    alignment: "right",
                    style: "reportTitle",
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: (i, node) =>
                i === 0 || headerText === report.observations.title ? 0 : 1,
              vLineWidth: () => 0,
              hLineColor: (i) => "#808080",
            },
          };
    },

    footer: (currentPage, pageCount) =>
      currentPage === 1
        ? {
            /* absolutePosition: { x: 0, y: 750 }, */
            margin: [0, -50, 0, 0],
            layout: {
              hLineWidth: (i, node) => (i === 0 ? 1 : 0),
              vLineWidth: () => 0,
              hLineColor: (i) => "#808080",
              paddingTop: () => 15,
              paddingBottom: () => 15,
            },
            table: {
              headerRows: 0,
              widths: [110, "*", "*"],

              body: [
                [
                  {
                    image: report.cover_page?.company?.logo
                      ? "companyLogo"
                      : placeholderImage,
                    fit: [80, 80],
                    alignment: "center",
                  },
                  {
                    layout: "noBorders",
                    table: {
                      headerRows: 0,
                      widths: ["*"],
                      body: [
                        [{ text: "The Mold Guys", style: "compnayName" }],
                        [
                          {
                            text: "9190 W. Olympic Blvd. #206 Beverly Hills CA, 90212",
                            style: "normalBold",
                          },
                        ],
                      ],
                    },
                  },
                  {
                    layout: "noBorders",
                    table: {
                      headerRows: 0,
                      widths: ["*"],
                      body: [
                        [{ text: "(888) 351- 9565", style: "normalBold" }],
                        [
                          {
                            text: "help@themoldguyinc.com",
                            style: "normalBold",
                          },
                        ],
                        [
                          {
                            text: "www.themoldguysinc.com",
                            style: "normalBold",
                          },
                        ],
                      ],
                    },
                  },
                ],
              ],
            },
          }
        : {
            text: `${currentPage} of ${pageCount}`,
            alignment: "right",
            style: "pageFooter",
          },

    images: {},
    defaultStyle: {
      color: "#2f481e",
      font: "Exo2",
    },
    styles: {
      coverTitle: {
        font: "Exo2",
        fontSize: 22,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      coverHead: {
        bold: true,
        margin: [0, 15, 0, 0],
      },
      coverText: { margin: [0, 5, 0, 0] },
      reportTerms: { margin: [40, 15, 40, 15] },
      customHeaderTitle: {
        fontSize: 15,
        bold: true,
        margin: [35, 10, 0, 10],
      },
      reportTitle: {
        margin: [0, 13, 35, 10],
      },
      pageFooter: {
        fontSize: 10,
        margin: [0, 10, 35, 0],
      },
      compnayName: {
        fontSize: 16,
        bold: true,
      },
      normalBold: {
        bold: true,
      },
      observationInfoText: {
        bold: true,
      },
      commentTitleStyle: {
        bold: true,
        fontSize: 12,
        margin: [0, 10, 0, 0],
      },
      commentHead: {
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 5],
      },
      commentBody: {
        fontSize: 11,
        margin: [0, 0, 0, 15],
      },
      photoRemarks: {
        fontSize: 9,
      },
      testTableHead: {
        color: "#fff",
        bold: true,
        alignment: "center",
      },
      testTableRow: {
        bold: true,
        alignment: "center",
      },
      tocTitle: {
        bold: true,
        fontSize: 14,
        margin: [0, 15, 0, 8],
      },
      tocContent: {
        margin: [0, 0, 0, 5],
      },
    },
  };

  return (
    <Button onClick={handleClick} disabled={isLoading}>
      Publish
    </Button>
  );
}

export default DownloadPDF;
