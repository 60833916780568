import axios from "../axios-defaults";

const createTest = (data) => {
  return axios.post("/api/v1/tests", data);
};

const getTestsList = (page = 0, search = "") => {
  return axios.get(`/api/v1/tests?page=${page}&q=${search}`);
};

const getTestById = (id) => {
  return axios.get("/api/v1/bu/tests/" + id);
};

const updateTest = (id, data) => {
  return axios.post("/api/v1/bu/tests/" + id, { _method: "PATCH", ...data });
};

const deleteTest = (id) => {
  return axios.post(`/api/v1/bu/tests/${id}`, { _method: "delete" });
};

const deleteTestSample = (id) => {
  return axios.post(`/api/v1/test-sample/${id}`, { _method: "delete" });
};

const deleteTestSampleInfo = (id) => {
  return axios.post(`/api/v1/test-samples-info/${id}`, { _method: "delete" });
};

export {
  createTest,
  getTestsList,
  getTestById,
  updateTest,
  deleteTest,
  deleteTestSample,
  deleteTestSampleInfo
};
