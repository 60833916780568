import { faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmptyHtml } from "main/shared/helpers";
import React from "react";
import { useDispatch } from "react-redux";
import { SET_REPORT_EXTRA_PAGE, SHOW_MODAL } from "store/actions";
import DocumentView from "../DocumentView";
import TopRightTitle from "../TopRightTitle";

export default function ExtraPage({ style, data, index }) {
  return (
    <DocumentView style={style}>
      <div className="document-head">
        <header>
          <div className="grid-pg1">
            <div>
              <h3>
                <strong>
                  <ET reduxKey="title" ml="50" index={index}>
                    {data.title}
                  </ET>
                </strong>
              </h3>
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>
      <div className="doc-body template-body">
        <ET reduxKey="text" ml="500" innerHtml={true} index={index}>
          {data.text}
        </ET>
      </div>
    </DocumentView>
  );
}

function ET(props) {
  const { children, textArea, ml, innerHtml, index, reduxKey } = props;
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        show: true,
        value: children,
        setValue: (t) => setPage(reduxKey, t),
        textArea: textArea,
        ml: ml,
        richText: innerHtml,
      },
    });
  };

  const setPage = (reduxKey, text) => {
    dispatch({
      type: SET_REPORT_EXTRA_PAGE,
      payload: {
        path: reduxKey,
        data: text,
        extraPageIndex: index,
      },
    });
  };

  return (
    <div className="editable-text">
      <div className="tools pen">
        <FontAwesomeIcon icon={faPen} color="#2f481e" onClick={openModal} />
      </div>

      {innerHtml ? (
        <span
          dangerouslySetInnerHTML={{
            __html: children && !isEmptyHtml(children) ? children : "Click to add text",
          }}
          onClick={openModal}
        ></span>
      ) : (
        <span onClick={openModal} className="break-text">
          {children || "Click to add text"}
        </span>
      )}

      <div className="tools cross">
        <FontAwesomeIcon
          icon={faTimes}
          color="#b44a4a"
          onClick={() => setPage(reduxKey, "")}
        />
      </div>
    </div>
  );
}
