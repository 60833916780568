import React from "react";
import DocumentView from "../../DocumentView";
import { ObservationPhotoEditor } from "../../ImageEditor";
import ObservationInfo from "../../ObservationInfo";
import ObservationPageTitle from "../../ObservationPageTitle";
import TopRightTitle from "../../TopRightTitle";
import { ObservationsEditable as OET } from "../ReportObservations";

export default function ObservationTests({ style, index, roomIndex, tests }) {
  return (
    <DocumentView style={style}>
      <div className="document-head" style={{ border: "none" }}>
        <header>
          <div className="grid-pg1">
            <div>
              <ObservationPageTitle />
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>

      <ObservationInfo index={index} roomIndex={roomIndex} />

      <div className="doc-body">
        <div>
          <div className="comments-heading">
            <p>
              <strong>Tests</strong>
            </p>
            <span></span>
          </div>

          {tests.map((test, i) => (
            <div className="test-list" key={`tests-${i}`}>

              <table className="test-table">
                <tbody>
                  <tr>
                    <td>Test Type</td>
                    <td>Sample Type</td>
                    <td>Client Approval</td>
                  </tr>
                  <tr>
                    <td>
                      <OET
                        reduxKey="test.name"
                        ml="50"
                        oi={index}
                        ti={i}
                        ri={roomIndex}
                      >
                        {test.test.name}
                      </OET>
                    </td>
                    <td>
                      <OET
                        reduxKey="test_sample.name"
                        ml="50"
                        oi={index}
                        ti={i}
                        ri={roomIndex}
                      >
                        {test.test_sample.name}
                      </OET>
                    </td>
                    <td>
                      {test.transaction_status === "finished" ? "Yes" : "No"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="test-table">
                <tbody>
                  <tr>
                    {test.sample_info?.map((info) => (
                      <td>{info.name}</td>
                    ))}
                  </tr>
                  <tr>
                    {
                      test.transaction_status === "finished"
                        ?
                        test.sample_info?.map((info, si) => (
                          <td>
                            <OET
                              reduxKey="test.sample_info"
                              ml="50"
                              oi={index}
                              ti={i}
                              ri={roomIndex}
                              si={si}
                            >
                              {info.pivot.value == null ? "----" : info.pivot.value}
                            </OET>
                          </td>
                        )
                        )
                        :
                        // null
                       test.sample_info?.map((info, si) =>
                        <td>
                          <OET
                            reduxKey="test.sample_info"
                            ml="50"
                            oi={index}
                            ti={i}
                            ri={roomIndex}
                            si={si}
                          >
                            {info.pivot.value == null ? "----" : info.pivot.value}
                          </OET>
                        </td>
                      )
                    }
                  </tr>
                </tbody>
              </table>
              {test.transaction_status === "finished" && (
                <div className="photos-grid">
                  {test.images.map((img, j) => (
                    <ObservationPhotoEditor
                      key={`test-img-${j}`}
                      src={img.url}
                      rotation={img.rotation ?? 0}
                      alt=""
                      className="test-photo-img"
                      ri={roomIndex}
                      oi={index}
                      ti={i}
                      ii={j}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </DocumentView>
  );
}
