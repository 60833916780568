import React from "react";
import { useSelector } from "react-redux";
import DocumentView from "../DocumentView";
import TopRightTitle from "../TopRightTitle";
import ET from "../EditableText";

export default function ReportTerms({ style }) {
  const cover_page = useSelector((state) => state.report.cover_page);

  return (
    <DocumentView style={style}>
      <div className="document-head">
        <header>
          <div className="grid-pg1">
            <div>
              <h3>{/* <strong>Terms and Conditions</strong> */}</h3>
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>
      <div className="doc-body template-body">
        <div style={{ padding: "0px 0cm" }} className="template-body">
          <ET
            ml="10000"
            innerHtml={true}
            reduxKey="cover_page.report.terms.content"
          >
            {cover_page?.report?.terms?.content}
          </ET>
        </div>
      </div>
    </DocumentView>
  );
}
