export const fileSizeLimits = {
  avatarBuInspector: 1024 * 1024 * 2, //2mb
  avatarBu: 1024 * 1024 * 1, //1mb,
  "2mb": 1024 * 1024 * 2, //2mb
  "5mb": 1024 * 1024 * 5, //5mb
};

export const folders = {
  profile: "profile/",
  property: "property/",
  signature: "signature/",
  test_samples: "test_samples/",
  documents: "documents/",
  IRpdf: "documents/investigation-report",
  documentImages: "document_images/",
};
