import { faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ADD_REPORT_EXTRA_PAGE } from "store/actions";
import DocumentView from "./DocumentView";

export default function AddPageButton({ style }) {
  const dispatch = useDispatch();

  const addPage = () => {
    dispatch({
      type: ADD_REPORT_EXTRA_PAGE,
      payload: { extraPageData: { title: "", text: "" } },
    });
  };

  return (
    <DocumentView style={style}>
      <div
        style={{
          height: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <FontAwesomeIcon
          onClick={addPage}
          style={{
            margin: "30% auto 70% auto",
            fontSize: "80px",
            cursor: "pointer",
          }}
          icon={faPlusCircle}
          color="#dee2df"
        />
      </div>
    </DocumentView>
  );
}
