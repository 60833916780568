/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../../axios-defaults";
import { authRoles } from "../../../navigation/route-config";
import { profileRequest } from "../../../services/auth-service";
import {
  getBUForSwitch,
  getBUToken,
} from "../../../services/super-admin-service";
import {
  LOG_IN,
  SET_SELECTED_BU,
  SET_SLECTING_BU,
} from "../../../store/actions";
import { getErrorMsg, Logout, showToast } from "../../shared/helpers";
import "./style.css";

const CheckBoxList = React.lazy(() => import("../CheckBox"));

export default function ProfileIcon(props) {
  const [open, setOpen] = useState(false);
  const [isLoggingout, setIsLoggingout] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user.data);
  const defaultImage = require("../../../assets/default_profile.png").default;
  const handleLogout = () => {
    setIsLoggingout(true);
    Logout(dispatch, setIsLoggingout);
  };
  const [image, setImage] = useState(defaultImage);

  const [showModal, setShowModal] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  // const [selectedBU, setSelectedBU] = useState("");
  const selectedBU = useSelector((state) => state.app.selectedBU);
  const [selectedBUName, setSelectedBUName] = useState("");
  const [page, setPage] = useState(1);
  const [units, setUnits] = useState([]);
  const defaultLogo = require(`../../../assets/default-logo.png`).default;

  const setSelectedBU = (bu) => {
    dispatch({ type: SET_SELECTED_BU, payload: bu });
  };

  useEffect(() => {
    if (user.role === authRoles.buAdmin || user.role === authRoles.ultraAdmin) {
      setImage(user?.business_unit?.logo_url || defaultImage);
    } else if (user.role === authRoles.buSubAdmin) {
      setImage(user?.image?.url || defaultImage);
    } else if (user.role === authRoles.buInspector) {
      setImage(user?.inspector_details?.avatar_url || defaultImage);
    } else {
      setImage(defaultImage);
    }
  }, [user]);

  useEffect(() => {
    if (user.role === authRoles.ultraAdmin) {
      history.push("/tools/tests");
      setSelectedBUName(`${user.first_name} ${user.last_name}`);
    } else {
      setSelectedBU("");
      setSelectedBUName("");
    }
  }, [user.role]);

  useEffect(() => {
    if (selectedBU) {
      dispatch({ type: SET_SLECTING_BU, payload: true });
      setModalLoader(true);
      getBUToken(selectedBU)
        .then((res) => {
          console.log(res);
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem(
            "x-auth-token",
            localStorage.getItem("x-auth-token") ||
              axios.defaults.headers.common.Authorization.split(" ")[1]
          );
          /* axios.defaults.headers.common["X-Custom-Information"] =
            "Bearer " +
            (localStorage.getItem("x-auth-token") ||
              axios.defaults.headers.common.Authorization.split(" ")[1]); */
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.access_token;

          profileRequest()
            .then((res) => {
              user.role === authRoles.superAdmin &&
                localStorage.setItem("oldUser", localStorage.getItem("user"));
              dispatch({
                type: LOG_IN,
                payload: { ...res.data, role: authRoles.ultraAdmin },
              });
              setSelectedBUName(res.data.business_unit?.company_name || "");
              if (location.pathname === "/tools/tests") {
                history.go(0);
              } else {
                history.push("/tools/tests");
              }
              dispatch({ type: SET_SLECTING_BU, payload: false });
            })
            .catch((err) => {
              console.log(err.response);
              showToast(getErrorMsg(err) || "Unable to switch role");
              dispatch({ type: SET_SLECTING_BU, payload: false });
            })
            .finally(() => {
              setModalLoader(false);
              setShowModal(false);
            });
        })
        .catch((err) => {
          console.log(err);
          showToast(getErrorMsg(err) || "Unable to switch role");
          setModalLoader(false);
          setShowModal(false);
          dispatch({ type: SET_SLECTING_BU, payload: false });
        });
    }
  }, [selectedBU]);

  useEffect(() => {
    if (showModal) {
      setModalLoader(true);
      getBUForSwitch(page)
        .then(({ data: res }) => {
          setUnits((state) => ({
            ...res,
            data: state?.data ? [...state.data, ...res.data] : res.data,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setModalLoader(false);
        });
    }
  }, [page, showModal]);

  return (
    <div
      className="d-flex flex-column align-items-center mt-3"
      style={{
        width: "100%",
        padding: "50px 0px 105px  0px",
        ...(user.role === authRoles.superAdmin
          ? { position: "absolute", bottom: "0px" }
          : {}),
        ...props.style,
      }}
    >
      {user.role === authRoles.superAdmin ||
      user.role === authRoles.ultraAdmin ? (
        <div
          className="d-flex flex-column mx-3 my-4"
          onClick={() => setShowModal(true)}
        >
          <span
            style={{ fontSize: "14px", fontWeight: "600" }}
            className="text-light50 pb-1"
          >
            View As:
          </span>
          <input
            className="form-control br-light80 different-placeholder dark-green"
            placeholder="View Unit"
            value={selectedBUName}
            disabled={true}
            style={{
              borderWidth: "2px",
              cursor: "pointer",
              fontWeight: "600",
            }}
          />
          <FontAwesomeIcon
            icon={faCaretDown}
            className="text-light50 cursor-pointer"
            style={{ position: "relative", bottom: "34px", left: "180px" }}
          />
        </div>
      ) : null}
      <div>
        <img
          src={image}
          className="avatar bg-white"
          alt="profile_image"
          style={{
            width: "64px",
            height: "64px",
            border: "1px solid lightgray",
          }}
          onError={(ev) =>
            (ev.target.src =
              require("../../../assets/default_profile.png").default)
          }
        />
      </div>
      <div className="d-flex flex-row align-items-center mt-1">
        <Dropdown
          drop="down"
          onToggle={(isOpen) => {
            setOpen(isOpen);
          }}
        >
          <Dropdown.Toggle
            variant="light"
            id="profile_ations_dropdown"
            disabled={isLoggingout}
          >
            {user.first_name && user.last_name
              ? `${user.first_name} ${user.last_name}`
              : user.business_unit?.company_name}
            <FontAwesomeIcon
              className="ml-2"
              id="dropdown_icon"
              icon={open ? faCaretUp : faCaretDown}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu id="dropdown_menu" align="right">
            <Dropdown.Item
              className="dropdown_btn"
              as="button"
              onClick={handleLogout}
            >
              Logout
            </Dropdown.Item>
            {(user.role === authRoles.buAdmin ||
              user.role === authRoles.ultraAdmin ||
              user.role === authRoles.buSubAdmin) &&
            props.subscribed !== false ? (
              <Dropdown.Item
                className="dropdown_btn"
                as="button"
                onClick={() =>
                  history.push(
                    user.role === authRoles.buSubAdmin
                      ? `/subadmin-profile`
                      : "/settings?tab=companyDetails"
                  )
                }
              >
                Edit Profile
              </Dropdown.Item>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {user.role === authRoles.superAdmin ||
      user.role === authRoles.ultraAdmin ? (
        <Suspense fallback={null}>
          <CheckBoxList
            items={units?.data?.map((x) => ({
              id: x.id,
              name: x.company_name,
              image: x.logo_url || defaultLogo,
            }))}
            onChecked={(items) => console.log(items)}
            show={showModal}
            onHide={() => {
              setShowModal(false);
              setUnits([]);
              setPage(1);
            }}
            isRadio={true}
            isAvatar={true}
            defaultAvatar={defaultLogo}
            loader={modalLoader}
            title={"Select Business Unit"}
            showTittleIcon={false}
            buttonLabel={"View Business Unit"}
            emptyText={"No business units available"}
            value={[selectedBU]}
            onSave={(ev) => {
              if (ev.length > 0) {
                console.log(ev[0]);
                setSelectedBU(ev[0]);
              }
            }}
            applyPagination={true}
            pagination={units}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </Suspense>
      ) : null}
    </div>
  );
}
