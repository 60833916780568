import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";

const editorId = "#trumbowyg-editor";
const editorConfig = {
  btns: [
    ["fontsize"],
    ["undo", "redo"], // Only supported in Blink browsers
    ["formatting"],
    ["strong", "em", "del"],
    ["table"],
    ["link"],
    ["unorderedList", "orderedList"],
    ["removeformat"],
    ["fullscreen"],
  ],

  plugins: {
    fontsize: {
      sizeList: [
        '14px',
        '18px',
        '22px'
      ],
    },
    table: {
      styler: "editor-table",
      rows: 5,
      columns: 5,
    },
    allowTagsFromPaste: {
      allowedTags: ['h4', 'p', 'br']
    },

  },
};

export const getHtmlTextLength = (html) => {
  if (html) {
    try {
      // const strip_tags = html.replace(/<(?!br\s*\/?)[^>]+>/g, "").replace(/(style=".+?")/g, "").replace(/(class=".+?")/g, "");
      const strip_tags = html.replace(/<(?!br\s*\/?)[^>]+>/g, "");
      const strip_enter = strip_tags.replace(/<br\s*\/?>/g, " ");
      const strip_space = strip_enter.replace(/&nbsp;/gi, " ");
      //const strip_and = strip_space.replace(/&amp;/gi, "&");
      const html_to_text = strip_space;
      const total = html_to_text.length;
      return total;
    } catch (e) {
      console.log(e);
    }
  }
  return 0;
};

export const formats = [
  "bold",
  "italic",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
];

// export default function ReportEditor({ text, onSave, maxLength, onCancle }) {
//   const toolbarOptions = [
//     ["bold", "italic", "underline"],
//     [{ list: "ordered" }, { list: "bullet" }],
//   ];
//   const editor = useRef(null);
//   const [quill, setQuill] = useState(null);
//   const [body, setBody] = useState("");
//   const currentLength = useRef(null);

//   const updateLength = (quill) => {
//     if (quill) {
//       const length = quill.getText().length;
//       currentLength.current.innerText = length;
//       return length;
//     }
//   };

//   useLayoutEffect(() => {
//     document.addEventListener("paste", copyListner);
//     return () => {
//       document.removeEventListener("paste", copyListner);
//     };
//   }, []);

//   function copyListner(event) {
//     var pastedText = undefined;
//     if (window.clipboardData && window.clipboardData.getData) {
//       pastedText = window.clipboardData.getData("Text");
//     } else if (event.clipboardData && event.clipboardData.getData) {
//       pastedText = event.clipboardData.getData("text/plain");
//     }
//     if (
//       pastedText.length + editor?.current?.getEditor()?.getText()?.length >
//       maxLength
//     ) {
//       console.log(pastedText);
//       event.preventDefault();
//     } else {
//       console.log("not pasting", pastedText);
//     }
//   }

//   useEffect(() => {
//     setQuill(editor.current.getEditor());
//     console.log("editor");
//   }, [editor]);

//   useEffect(() => {
//     if (quill) {
//       updateLength(quill);
//     }
//   }, [quill]);

//   useEffect(() => {
//     if (quill) {
//       updateLength(quill);
//     }
//   }, [body, quill]);

//   useEffect(() => {
//     const id = setTimeout(() => {
//       setBody(text);
//     }, 0);

//     return () => clearTimeout(id);
//   }, [text]);

//   const handleKeyDown = (quill, event, maxLength, updateLength) => {
//     const length = updateLength(quill);
//     const { keyCode } = event;
//     if (
//       length > maxLength &&
//       keyCode !== 8 &&
//       keyCode !== 46 &&
//       keyCode !== 37 &&
//       keyCode !== 38 &&
//       keyCode !== 39 &&
//       keyCode !== 40
//     ) {
//       event.preventDefault();
//     }
//   };

//   return (
//     <React.Fragment>
//       <ReactQuill
//         ref={editor}
//         value={body}
//         modules={{
//           toolbar: toolbarOptions,
//           history: {
//             userOnly: true,
//           },
//         }}
//         formats={formats}
//         onKeyDown={(event) => {
//           quill &&
//             handleKeyDown(
//               editor?.current?.getEditor(),
//               event,
//               maxLength - 1,
//               updateLength
//             );
//         }}
//         onKeyPress={() => {
//           quill && updateLength(quill);
//         }}
//         onKeyUp={() => {
//           quill && updateLength(quill);
//         }}
//         onMouseDown={() => {
//           quill && updateLength(quill);
//         }}
//       />
//       <div className="rightsideactions">
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//             alignItems: "flex-end",
//             width: "100%",
//           }}
//         >
//           <div style={{ marginTop: "-5px" }}>
//             <small ref={currentLength}></small>
//             <small>/{maxLength}</small>
//           </div>

//           <div className="d-flex mt-3">
//             <Button
//               id="cancel"
//               className="text-secondary500 bg-transparent border-0"
//               onClick={onCancle}
//             >
//               Cancel
//             </Button>
//             <button
//               className="btn btn-primary"
//               onClick={() => {
//                 onSave && onSave(quill.root.innerHTML);
//               }}
//             >
//               Save
//             </button>
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

const handleKeyDown = (e, maxLength) => {
  const html = window.$(editorId).trumbowyg("html");
  const length = getHtmlTextLength(html);
  const { keyCode } = e;
  if (
    length > maxLength - 1 &&
    keyCode !== 8 &&
    keyCode !== 46 &&
    keyCode !== 37 &&
    keyCode !== 38 &&
    keyCode !== 39 &&
    keyCode !== 40
  ) {
    e.preventDefault();
  }
};

function copyListner(event, maxLength) {
  var pastedText = undefined;
  const clipboardData = event.clipboardData || window.clipboardData;
  if (clipboardData) {
    pastedText = clipboardData.getData("text/plain");
    // console.log(pastedText);
  }

  const html = window.$(editorId).trumbowyg("html");
  const length = getHtmlTextLength(html);
  if (pastedText?.length + length > maxLength) {
    console.log("not pasting", pastedText);
    event.preventDefault();
  } else {
    console.log("pasting", pastedText);
  }
}

export default function ReportEditor({ text, onSave, maxLength, onCancle }) {
  const [length, setLength] = useState(0);
  // const filered_Text = text?.replace(/(\r\n|\n|\r)/gm, " ");
  const filered_Text = text
  // const filered_Text = text.replace(/(style=".+?")/g, "").replace(/(class=".+?")/g, "");
  useEffect(() => {
    try {
      console.log("adding event");
      window
        .$(editorId)
        .trumbowyg(editorConfig)
        .on("tbwinit", () => {
          console.log("tbwinit");
          window.$(editorId).trumbowyg("html", filered_Text);

          console.log("adding keydown event");
          window
            .$(editorId)
            .parent()
            .on("keydown", (e) => handleKeyDown(e, maxLength));
        })
        .on("tbwchange", () => {
          const html = window.$(editorId).trumbowyg("html");
          setLength(getHtmlTextLength(html));
        });
    } catch (e) {
      console.log(e);
    }

    return () => {
      console.log("removing all events");
      window.$(editorId).trumbowyg().off();
      window
        .$(editorId)
        .parent()
        .off("keydown", (e) => handleKeyDown(e, maxLength));
    };
  }, [text]);

  useEffect(() => {
    document.addEventListener("paste", (e) => copyListner(e, maxLength));
    return () => {
      document.removeEventListener("paste", (e) => copyListner(e, maxLength));
    };
  }, [maxLength, text]);

  return (
    <React.Fragment>
      <textarea id="trumbowyg-editor"></textarea>
      <div className="rightsideactions">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <div style={{ marginTop: "-5px" }}>
            <small></small>
            <small>
              {length}/{maxLength}
            </small>
          </div>

          <div className="d-flex mt-3">
            <Button
              id="cancel"
              className="text-secondary500 bg-transparent border-0"
              onClick={onCancle}
            >
              Cancel
            </Button>
            <button
              className="btn btn-primary"
              onClick={() => {
                onSave && onSave(window.$(editorId).trumbowyg("html"));
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
