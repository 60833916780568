import React from "react";

export default function ImageBox({ src, style, imgStyle }) {
  return (
    <a href={src} target="_blank" rel="noreferrer">
      <div
        style={{
          height: "85px",
          width: "85px",
          border: "2px solid #DEE2DF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "8px",
          margin: "5px 10px 10px 0px",
          background: "white",
          transition: "0.5s",
          ...style,
        }}
      >
        <img
          src={src}
          alt="test"
          style={{
            height: "80px",
            width: "80px",
            objectFit: "contain",
            padding: "0px 5px",
            ...imgStyle,
          }}
        />
      </div>
    </a>
  );
}
