import React from "react";
import "./style.css";

const Chip = (props) => {
  const inputs = { ...props };
  const classes = inputs?.className ? inputs.className : "";
  const onCross = inputs?.onCross;

  delete inputs?.className;
  delete inputs?.onCross;

  return (
    <div className={"chip " + classes} {...inputs}>
      {inputs?.children}
      <span
        id={`${props.id}-delete`}
        className="chipclosebtn"
        onClick={onCross}
      >
        &times;
      </span>
    </div>
  );
};

export default Chip;
