/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { setHeader } from "../../../main/shared/helpers";

function Submenu(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { data } = props;
  const accordion = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (
      location.pathname.startsWith("/tools/") &&
      accordion.current &&
      !isOpen
    ) {
      accordion.current.click();
    } else if (!location.pathname.startsWith("/tools/") && isOpen) {
      accordion.current.click();
    }
  }, [location.pathname]);

  return (
    <Accordion>
      <Accordion.Toggle
        as={Button}
        variant="link"
        style={{ textDecoration: "none", padding: "0px", width: "100%" }}
        eventKey="0"
        ref={accordion}
        onClick={() => {
          setIsOpen((state) => !state);
        }}
      >
        <a className="nav-link text-light50" id="tools_route">
          {data.icon ? (
            <img
              src={require(`../../../assets/icons/${data.icon}`).default}
              alt="icon"
            />
          ) : null}
          {data.name}
        </a>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <div>
          {data.childrens &&
            data.childrens.map((child, i) => (
              <NavLink
                key={`sub-menu-${i}`}
                id={child.name}
                to={data.path + child.to}
                className="nav-link"
                style={{ paddingLeft: 50, color: "#84857a" }}
                activeClassName="active"
                activeStyle={{ color: "#2F481E" }}
                isActive={(match) => {
                  if (match) {
                    setHeader(dispatch, {
                      title: child.name,
                      previousPath: "",
                      icon: data.icon,
                    });
                    return true;
                  }
                  return false;
                }}
              >
                {child.name}
              </NavLink>
            ))}
          <hr style={{ width: "80%" }} />
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default Submenu;
