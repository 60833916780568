/* eslint-disable react-hooks/exhaustive-deps */
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import {
  emailTemplatesList,
  emailTemplateUpdate,
} from "services/emailTemplates";
import { Spinner } from "react-bootstrap";
import cn from "classnames";
import { getErrorMsg, htmlToText, showToast } from "main/shared/helpers";
import EmailTemplateEditor from "main/components/Editor/EmailTemplateEditor";

const EmailTemplates = () => {
  const [loading, setloading] = useState(true);
  const [inpdis, setinpdis] = useState(true);
  const [indexx, setindexx] = useState(0);
  const [emailList, setemailList] = useState([]);
  const [oneemaildata, setoneemaildata] = useState([]);
  const [editEnabled, setEditEnabled] = useState(false);
  const [refresh, setrefresh] = useState(null);
  const [savedis, setsavedis] = useState(false);

  useEffect(() => {
    getData();
  }, [refresh]);

  const getData = async () => {
    const { data, error } = await emailTemplatesList();
    if (!error) {
      setemailList(data);
      setloading(false);
      if (refresh == null) {
        setoneemaildata(data[0]);
      }
    }
  };

  const templateUpdate = async () => {
    const { id, body, subject } = oneemaildata;
    const { data, error } = await emailTemplateUpdate({
      id,
      subject: htmlToText(subject),
      body: body,
    });
    console.log(data);
    if (!error) {
      setinpdis(true);
      setrefresh(!refresh);
      setEditEnabled(false);

      showToast("Email Template Successfully Updated", true);
    } else {
      // setEditEnabled(false);
      // setsavedis(false);
      setsavedis(false);
      showToast(getErrorMsg(data) || "Unable to update Email Template.", false);
    }
  };
  return (
    <div className="d-flex mt-0 bg-white">
      <div className="leftside pt-3">
        <div className="leftside-header">
          <h5 className="mainheading">Templates</h5>
        </div>
        <div>
          <ul className={"ul-style "} style={{ height: "calc(100vh - 211px)" }}>
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              emailList.map((item, i) => (
                <li
                  key={i + 1}
                  className={cn(
                    "liststyle text-left",
                    indexx === i && "listActive"
                  )}
                  onClick={() => {
                    setindexx(i);
                    setoneemaildata(item);
                  }}
                >
                  {item.type}
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
      <div className="rightside round-top">
        <div className="rightsideinner">
          {!editEnabled ? (
            <button
              className="btn btn-primary ml-auto mb-3"
              disabled={false}
              onClick={() => {
                setEditEnabled(true);
                setinpdis(false);
                setsavedis(false);
              }}
              id="edit_fee"
            >
              Edit Company Details <FontAwesomeIcon icon={faPencilAlt} />
            </button>
          ) : (
            <div className="d-flex mb-3">
              <button
                className="btn btn-primary ml-auto"
                disabled={savedis}
                onClick={() => {
                  templateUpdate();
                  setsavedis(true);
                }}
              >
                Save
              </button>
              <button
                className="btn btn-light ml-2 low-priority"
                onClick={() => {
                  setEditEnabled(false);
                  setinpdis(true);
                }}
              >
                Cancel
              </button>
            </div>
          )}
          <div className="float-label">
            <div>
              <EmailTemplateEditor
                text={oneemaildata.subject}
                disabled={inpdis}
                maxLength={50}
                smallHieght={true}
                setTemplateBody={(text) => {
                  setoneemaildata((s) => ({ ...s, subject: text }));
                }}
                id="email_subject"
                disableEnter
              />
            </div>
            <label className="input-field Active" htmlFor="subject_line">
              Subject Line
            </label>
          </div>

          <div className="float-label mt-3">
            <div>
              <EmailTemplateEditor
                text={oneemaildata.body}
                disabled={inpdis}
                maxLength={10000}
                setTemplateBody={(text) => {
                  setoneemaildata((s) => ({ ...s, body: text }));
                }}
                id="email_body"
              />
            </div>
            {/* <label className="Active input-field back-label" htmlFor="remarks">
              Body
            </label> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplates;
