/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

export default function Toggle(props) {
  const [on, setOn] = useState(false);
  const [color, setColor] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    change(props.on);
  }, [props.on]);

  const change = (value) => {
    props.onChange(value);
    if (value) {
      setOn(true);
      setText("ON");
      setColor("#4AA2B4");
    } else {
      setOn(false);
      setText("OFF");
      setColor("#84857A");
    }
  };

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        props.disabled !== true && change(!on);
      }}
    >
      <div className="d-flex flex-row align-items-center">
        <div
          className="d-flex flex-row align-items-center"
          style={{
            height: "32px",
            width: "68px",
            borderRadius: "8px",
            background: color,
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              color: "white",
              paddingRight: on ? "10px" : "28px",
              paddingLeft: on ? "8px" : "28px",
            }}
          >
            {text}
          </span>
        </div>
        <div
          style={{
            borderRadius: "50%",
            border: "2px solid " + color,
            width: "42px",
            height: "42px",
            background: "white",
            position: "relative",
            right: on ? "22px" : "84px",
            transition: "0.5s",
          }}
        ></div>
      </div>
    </div>
  );
}
