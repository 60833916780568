import axios from "../axios-defaults";

const createProperty = (data) => {
  return axios.post("/api/v1/properties", data);
};

const getProperty = (keyword = "", page = 1) => {
  return axios.get(`/api/v1/properties?search=${keyword}&page=${page}`);
};

const getPropertyByCustomer = (customerId, page = 1) => {
  return axios.get(
    `/api/v1/bu/properties?customer_id=${customerId}&page=${page}`
  );
};

const getInspectionsByProperty = (propertyId, page = 1) => {
  return axios.get(`api/v1/bu/jobs?property_id=${propertyId}&page=${page}`);
};

const getPropertyDetails = (propertyId) => {
  return axios.get(`api/v1/bu/properties/${propertyId}`);
};

const updateProperty = (propertyId, data) => {
  return axios.post(`api/v1/bu/properties/${propertyId}`, {
    ...data,
    _method: "patch",
  });
};

const deleteProperties = (ids) => {
  return axios.post("api/v1/properties/bulk/delete", {
    _method: "delete",
    property_id: ids,
  });
};

export {
  createProperty,
  getProperty,
  getPropertyByCustomer,
  getInspectionsByProperty,
  getPropertyDetails,
  updateProperty,
  deleteProperties,
};
