import axios from "axios-defaults.js";

export const emailTemplatesList = async () =>
  axios({
    method: "get",
    url: `api/v1/bu/email-template`,
  })
    .then((res) => ({
      error: false,
      data: res.data,
    }))
    .catch((error) => ({
      error: true,
      data: error,
    }));
export const emailTemplateUpdate = async ({ id, subject, body }) =>
  axios
    .post(`api/v1/bu/email-template/${id}`, {
      _method: "PATCH",
      subject,
      body,
    })
    .then((res) => ({
      error: false,
      data: res.data,
    }))
    .catch((error) => ({
      error: true,
      data: error,
    }));

export const getTemplateForDoc = async (id, multiple_documents = "") =>
  axios
    .get(
      `/api/v1/documents/${id}/email-template?multiple_documents=${multiple_documents}`
    )
    .then((res) => ({
      error: false,
      data: res.data,
    }))
    .catch((error) => ({
      error: true,
      data: error,
    }));

export const getTemplateForMultipleDoc = async (inspection_id, type) =>
  axios
    .get(
      // `/api/v1/documents/${id}/email-template?multiple_documents=${multiple_documents}`
      `/api/v1/inspection/email-template?inspection_id=${inspection_id}&type=${type}`
    )
    .then((res) => ({
      error: false,
      data: res.data,
    }))
    .catch((error) => ({
      error: true,
      data: error,
    }));
