import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function InfoIcon({ style, dataTip }) {
  return (
    <FontAwesomeIcon
      data-tip={dataTip}
      data-place="right"
      icon={faInfoCircle}
      style={{ color: "#fff", outline: "none", ...style }}
    />
  );
}
