import React from "react";
import { useSelector } from "react-redux";
import DocumentView from "../DocumentView";
import ET from "../EditableText";
import TopRightTitle from "../TopRightTitle";
import "./style.css";

export default function Background({ style }) {
  const background = useSelector((state) => state.report.background);

  return (
    <DocumentView style={style}>
      <div className="document-head">
        <header>
          <div className="grid-pg1">
            <div>
              <h3>
                <strong>
                  <ET
                    reduxKey="background.title"
                    isPageTitle
                    titleKey="background"
                    ml="50"
                  >
                    {background?.title}
                  </ET>
                </strong>
              </h3>
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>
      <div className="doc-body template-body">
        <ET reduxKey="background.text" ml="26000" innerHtml={true}>
          {background?.text}
        </ET>
      </div>
    </DocumentView>
  );
}
