import * as Actions from "../actions";

const initialState = {
  refreshDocuments: false,
};

export default function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.NEW_DOCUMENTS:
      return { ...state, refreshDocuments: !state.refreshDocuments };
    default:
      return state;
  }
}
