import ConfirmationModal from "main/pages/Settings/Users/ConfirmationModal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HIDE_CONFIRMATION_MODAL } from "store/actions";

export default function ConfirmDialog() {
  const dispatch = useDispatch();
  const confirmationModal = useSelector((state) => state.app.confirmationModal);

  return (
    <ConfirmationModal
      show={confirmationModal.show}
      onHide={() =>
        dispatch({
          type: HIDE_CONFIRMATION_MODAL,
        })
      }
      msg={confirmationModal.message}
      onDelete={confirmationModal.onOk}
      yesBtnText={confirmationModal.okText}
      noBtnText={confirmationModal.cancelText}
      hideOnPress
    />
  );
}
