/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import Paginator from "../../../../components/Paginator/Paginator";
import {
  getErrorMsg,
  showToast,
  timeConversionUTC2,
  utcDate,
} from "../../../../shared/helpers";

const BillingTransactionTable = ({ business_unit_id, apiCall, update }) => {
  const [page, setpage] = useState(1);
  const [loader, setloader] = useState(true);
  const [transcationData, setTranscationData] = useState();
  useEffect(() => {
    console.log(update);
    setloader(true);
    if (business_unit_id) {
      apiCall(business_unit_id, page)
        .then((x) => {
          setTranscationData(x.data);
          x.data && setloader(false);
        })
        .catch((err) => showToast(getErrorMsg(err) || "Something went wrong"));
    } else {
      apiCall(page)
        .then((x) => {
          setTranscationData(x.data);
          x.data && setloader(false);
        })
        .catch((err) => showToast(getErrorMsg(err) || "Something went wrong"));
    }
  }, [apiCall, page, update]);
  return (
    <div>
      <ScrollBar className="mt-2 bg-white bg-table-billing-shadow">
        <div style={{ height: "260px" }}>
          {loader ? (
            <div className="spinner-div">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Transaction ID</th>
                </tr>
              </thead>
              <tbody>
                {/* style={{ backgroundColor: "#edf6f8" }} */}
                {transcationData?.data && transcationData.data.length !== 0 ? (
                  transcationData.data.map((trans, i) => (
                    <tr id={`trans-${i}`} key={`trans-${i}`}>
                      <td
                        className={`${i % 2 === 0 && "bg-secondary900"}`}
                        id={`trans__name`}
                      >
                        <div>
                          <strong> {utcDate(trans?.created_at)}</strong>
                          <br />
                          <strong>
                            {" "}
                            {timeConversionUTC2(trans?.updated_at)}
                          </strong>
                        </div>
                      </td>
                      <td
                        className={`${i % 2 === 0 && "bg-secondary900"}`}
                        id={`trans__contact`}
                      >
                        <div>
                          <strong>
                            {(trans?.reason === "" && "NAN") ||
                              (trans?.reason && trans.reason)}
                          </strong>
                          <br />
                          <strong> ${(trans?.amount / 100).toFixed(2)}</strong>
                        </div>
                      </td>
                      <td
                        className={`${
                          i % 2 === 0 && "bg-secondary900"
                        } white-bg-table-price`}
                        id={`trans__email`}
                      >
                        <strong>Successful</strong>
                      </td>
                      <td
                        className={`${
                          i % 2 === 0 && "bg-secondary900"
                        } white-bg-table-price`}
                        id={`trans__phone`}
                      >
                        <strong>{trans?.transaction_id}</strong>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="9">No record found</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          )}
        </div>
      </ScrollBar>

      {!loader && transcationData?.data && transcationData.data.length !== 0 ? (
        <nav className="mt-3 mb-3">
          <ul
            className="pagination justify-content-center paginator"
            style={{ position: "inherit" }}
          >
            <Paginator
              activePage={transcationData?.current_page}
              itemsCountPerPage={transcationData?.per_page}
              totalItemsCount={transcationData?.total}
              onChange={(page) => setpage(page)}
            />
          </ul>
        </nav>
      ) : null}
    </div>
  );
};

export default BillingTransactionTable;
