import React, { useRef, useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useQuery } from "../Auth/ResetPassword/ResetPassword";

import "./style.css";

const ImageView = () => {
  let query = useQuery();
  const [scale, setScale] = useState(1);
  const [rotatedScale, setRotatedScale] = useState(0);
  const [url, setUrl] = useState("");
  const [rotation, setRotation] = useState(null);

  const imgRef = useRef(null);

  useEffect(() => {
    setUrl(query.get("url").split("$rotate$")[0]);
    setRotation(Number(query.get("url").split("$rotate$")[1]));
  }, [query]);

  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    document.onreadystatechange = () => {
      // to get the original width/height of url image:
      getMeta(url, (err, img) => {
        console.log(imgRef.current.offsetHeight, "myheight", imgRef.current);

        if (img.naturalWidth > 0 && img.naturalHeight > 0) {
          const height = img.naturalHeight;
          const width = img.naturalWidth;

          setRotatedScale(height / width);
        }
      });
    };
  }, [imgRef, url]);

  useEffect(() => {
    if (
      rotation === 90 ||
      rotation === -90 ||
      rotation === 270 ||
      rotation === -270
    ) {
      setScale(rotatedScale);
    } else {
      setScale(1);
    }
  }, [rotatedScale, rotation]);

  return (
    <div className="body-container">
      <TransformWrapper minScale={1} maxScale={2}>
        <TransformComponent>
          <img
            ref={imgRef}
            className="zoomable-image"
            src={url}
            style={{
              transformOrigin: "center",
              transform: `rotate(${rotation}deg) scale(${scale})`,
              transition: "all 0.4s ease 0s",
            }}
            alt="preview"
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

export default ImageView;
