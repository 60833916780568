import React, { useState, useEffect } from "react";
import { Alert, Button, ListGroup, Modal, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./checkBox.css";

const CheckBoxList = (props) => {
  const {
    items,
    onChecked,
    title,
    buttonLabel,
    onSave,
    onHide,
    show,
    showTittleIcon,
    loader,
    emptyText,
    isAvatar,
    defaultAvatar,
    isRadio,
    value,
    applyPagination,
    pagination,
    onPageChange,
    isAvatarCover,
    modalSize = "md",
  } = props;

  const [checked, setchecked] = useState([]);

  useEffect(() => {
    value && setchecked([...value]);
  }, [value]);

  const addChecked = (bool, id) => {
    console.log(checked, value);
    let selected = checked;
    if (bool) {
      selected.push(id);
      setchecked(selected);
    } else {
      selected.splice(selected.indexOf(id), 1);
      setchecked(selected);
    }
    onChecked && onChecked(selected);
  };

  const Hide = () => {
    setchecked([]);
    onHide();
  };

  const onBtnClick = () => {
    onSave && onSave(checked);
    Hide();
  };

  const ChangePage = () => {
    if (applyPagination && pagination?.current_page < pagination?.last_page) {
      onPageChange(pagination?.current_page + 1);
    }
  };

  return (
    <Modal
      show={show}
      onHide={Hide}
      size={modalSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              {showTittleIcon ? (
                <img
                  className="iconstyle"
                  src={
                    require("../../../assets/icons/toolkit_light20@3x.png")
                      .default
                  }
                  alt="icon"
                />
              ) : null}

              <h5 style={{ fontWeight: "bolder" }}>{title}</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={Hide}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`modalBodyStyles`}>
        {items?.length < 1 && !loader ? (
          <Alert variant="danger">{emptyText}</Alert>
        ) : (
          <PerfectScrollbar
            onYReachEnd={(elem) => {
              setTimeout(() => {
                ChangePage();
              }, 10);
            }}
          >
            <ListGroup>
              {items?.map((data, i) => (
                <ListGroup.Item
                  key={i}
                  className={`listItems listStyle ${
                    i % 2 === 0 && "check-active"
                  }`}
                >
                  <div
                    style={{
                      paddingLeft: isRadio && "0px",
                    }}
                    className="myTest d-flex custom-control custom-checkbox align-items-center"
                  >
                    {isRadio ? (
                      <>
                        <input
                          type="radio"
                          name="radiogroup"
                          style={{ verticalAlign: "middle" }}
                          id={`customCheck${data.id}`}
                          defaultChecked={
                            value ? value.indexOf(data.id) > -1 : false
                          }
                          onChange={(ev) => {
                            if (ev.target.checked) {
                              setchecked([data.id]);
                            }
                          }}
                        />

                        <label
                          style={{
                            width: "100%",
                            margin: "0",
                            marginLeft: "1rem",
                          }}
                          htmlFor={`customCheck${data.id}`}
                        >
                          <span className="text-trunk-prop">
                            {isAvatar ? null : <span>{data?.name}</span>}
                            {isAvatar ? (
                              <>
                                <img
                                  src={data.image ? data.image : defaultAvatar}
                                  alt="Avatar"
                                  className={
                                    isAvatarCover ? "avatar" : "avatar-contain"
                                  }
                                  onError={(ev) =>
                                    (ev.target.src = defaultAvatar)
                                  }
                                />
                                <span>{data?.name}</span>
                              </>
                            ) : null}
                          </span>
                        </label>
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`customCheck${data.id}`}
                          defaultChecked={
                            value ? value.indexOf(data.id) > -1 : false
                          }
                          onChange={(ev) =>
                            addChecked(ev.target.checked, data.id)
                          }
                        />

                        <label
                          className="custom-control-label"
                          htmlFor={`customCheck${data.id}`}
                          style={{ width: "100%" }}
                        >
                          <span className="text-trunk-prop">
                            {isAvatar ? null : <span>{data?.name}</span>}
                            {isAvatar ? (
                              <>
                                <img
                                  src={data.image ? data.image : defaultAvatar}
                                  alt="Avatar"
                                  className="avatar"
                                  onError={(ev) =>
                                    (ev.target.src = defaultAvatar)
                                  }
                                />
                                <span className="span-style-none">
                                  {" "}
                                  {data?.name}
                                </span>
                              </>
                            ) : null}
                          </span>
                        </label>
                      </>
                    )}
                  </div>
                </ListGroup.Item>
              ))}
              {loader && (
                <div className="center-div">
                  <Spinner animation="border" variant="success" />
                </div>
              )}
            </ListGroup>
          </PerfectScrollbar>
        )}
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          onClick={() => onBtnClick()}
          style={{ marginRight: 25 }}
          disabled={loader || items?.length < 1}
        >
          {buttonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckBoxList;
