import { getReduxPermissions } from "../../main/shared/helpers";
import * as Actions from "../actions";

const initialState = {
  data: {},
  permissions: {
    tools: true,
    customers: true,
    properties: true,
    inspections: true,
    settings: true,
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.LOG_IN:
      const temp = {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        permissions: action.payload.roles
          ? getReduxPermissions(action.payload.roles)
          : state.permissions,
      };
      localStorage.setItem("user", btoa(JSON.stringify(action.payload)));
      localStorage.setItem("user_type", btoa(action.payload.role));
      return temp;
    case Actions.UPDATE_PROFILE:
      return { ...state, data: { ...state.data, ...action.payload } };
    case Actions.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
