import * as Actions from "../actions";

const initialState = {
  title: "",
  previousPath: "",
  icon: "lock_light20@3x.png",
  searchField: "",
  showSearch: false,
  searchLabel: "",
  searchFilter: "",
};

export default function headerReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SEARCH_FIELD:
      return { ...state, searchField: action.payload };
    case Actions.SET_HEADER:
      return { ...state, ...action.payload };
    case Actions.SHOW_SEARCH:
      return { ...state, showSearch: action.payload };
    case Actions.SET_SEARCH_LABEL:
      return { ...state, searchLabel: action.payload };
    case Actions.SET_SEARCH_FILTER:
      return { ...state, searchFilter: action.payload };
    default:
      return state;
  }
}
