import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import cellEditFactory from "react-bootstrap-table2-editor";

const CustomTable = ({
  keyField = "id",
  data = [],
  columns = [],
  hideEditCells = true,
  hideRowSelection = true,
  hideSelectAll = true,
  blurToSave = true,
  cellMode = "click",
  rowMode = "checkbox",
  rowClickToSelect = false,
  handleOnSelect = () => {},
  handleOnSelectAll = () => {},
  clickToEdit = false,
  rowClasses = {},
  handleAfterSaveCell = () => {},
  handleOnStartEditCell = () => {},
  handleBeforeSaveCell = () => {},
  timeToCloseMessage = 3000,

}) => {
  const selectRow = {
    mode: rowMode,
    clickToSelect: rowClickToSelect,
    clickToEdit: clickToEdit,
    hideSelectColumn: hideRowSelection,
    hideSelectAll: hideSelectAll,
    onSelect: (row, isSelect, rowIndex) => {
      handleOnSelect(row, isSelect, rowIndex);
    },
    onSelectAll: (isSelect, rows, e) => {
      handleOnSelectAll(isSelect, rows, e);
    },
  };
  const cellEdit = cellEditFactory({
    mode: cellMode,
    blurToSave: blurToSave,
    timeToCloseMessage: timeToCloseMessage,
    onStartEdit: (row, column, rowIndex, columnIndex) =>
      handleOnStartEditCell(row, column, rowIndex, columnIndex),
    beforeSaveCell: (oldValue, newValue, row, column) =>
      handleBeforeSaveCell(oldValue, newValue, row, column),
    afterSaveCell: (oldValue, newValue, row, column) =>
      handleAfterSaveCell(oldValue, newValue, row, column),
  });

  return (
    <BootstrapTable
      keyField={keyField}
      data={data}
      columns={columns}
      selectRow={selectRow}
      cellEdit={!hideEditCells ? cellEdit : {}}
      rowClasses={rowClasses}
    />
  );
};

export default CustomTable;
