import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SET_CONFIRMATION_MODAL } from "store/actions";

export default function DeleteButton({ deleteFunction, message, show = true }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const open = (e) => {
    e.stopPropagation();
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        show: true,
        okText: "Delete",
        cancelText: "Cancel",
        title: "",
        message,
        onOk: () => deleteFunction(setLoading),
      },
    });
  };

  return (
    <div
      style={{
        marginLeft: "auto",
        padding: "0 6px",
        color: "#84857A",
      }}
    >
      {loading ? (
        <Spinner animation="border" variant="success" size="sm" />
      ) : (
        show && (
          <FontAwesomeIcon
            icon={faTrash}
            onClick={open}
            style={{ cursor: "pointer" }}
          />
        )
      )}
    </div>
  );
}
