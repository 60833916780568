import { AsYouType } from "libphonenumber-js";
import React, { useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useHistory } from "react-router";
import { createCustomer } from "../../../services/CustomerService";
import { showToast } from "../../shared/helpers";
import { regexes } from "../../shared/regexes";
import "./style.css";

const AddCustomer = () => {
  const [isSubmit, setisSubmit] = useState(false);
  const history = useHistory();

  const [disbutton, setdisbutton] = useState(false);

  const [payload, setpayload] = useState({
    type: "bu_customer",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const reset = () => {
    seterrors({});
    setpayload({
      type: "bu_customer",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipcode: "",
    });
  };

  const [errors, seterrors] = useState({});

  const submit = () => {
    setisSubmit(true);
    if (
      payload.first_name &&
      payload.last_name &&
      payload.email &&
      payload.phone_number &&
      payload.street1 &&
      payload.city &&
      payload.state &&
      payload.zipcode &&
      regexes.phone.test(payload.phone_number) &&
      regexes.email.test(payload.email)
    ) {
      setdisbutton(true);
      createCustomer(payload)
        .then((res) => {
          showToast("An email to the customer has been sent.", true);
          if (res?.data?.id) {
            history.push("/customers/" + res.data.id);
          } else {
            history.push("/customers");
          }
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data);
          } else {
            showToast("Something went wrong");
          }
        })
        .finally(() => setdisbutton(false));
    }
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        <div className="main-content-area">
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary ml-auto"
              disabled={disbutton}
              onClick={submit}
              id="save_customer"
            >
              Save
            </button>
          </div>
          <div>
            <h4 className="font-weight-bold">Customer Details</h4>
            <div className="row">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            id="first_name"
                            value={payload.first_name}
                            onChange={(ev) =>
                              setpayload({
                                ...payload,
                                first_name: ev.target.value,
                              })
                            }
                            type="text"
                            maxLength="30"
                          />
                        </div>
                        <label
                          className={`${
                            payload.first_name !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="fn"
                        >
                          First Name
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit && payload.first_name === ""
                          ? "First name cannot be empty"
                          : errors?.first_name
                          ? errors?.first_name[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            value={payload.last_name}
                            onChange={(ev) =>
                              setpayload({
                                ...payload,
                                last_name: ev.target.value,
                              })
                            }
                            id="last_name"
                            type="text"
                            maxLength="30"
                          />
                        </div>
                        <label
                          className={`${
                            payload.last_name !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="ln"
                        >
                          Last Name
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {isSubmit && payload.last_name === ""
                          ? "Last name cannot be empty"
                          : errors?.last_name
                          ? errors?.last_name[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            type="text"
                            id="phone_number"
                            value={new AsYouType("US").input(
                              payload.phone_number
                            )}
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(regexes.phoneInput, "");
                              setpayload({ ...payload, phone_number: value });
                            }}
                            maxLength="16"
                          />
                        </div>
                        <label
                          className={`${
                            payload.phone_number !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="pn"
                        >
                          Phone Number
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit && payload.phone_number === ""
                          ? "Phone number cannot be empty"
                          : isSubmit &&
                            !regexes.phone.test(payload.phone_number)
                          ? "Enter Valid Phone number"
                          : errors?.phone_number
                          ? errors?.phone_number[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            id="email"
                            value={payload.email}
                            onChange={(ev) =>
                              setpayload({ ...payload, email: ev.target.value })
                            }
                            type="text"
                            maxLength="50"
                          />
                        </div>
                        <label
                          className={`${
                            payload.email !== "" ? "Active " : ""
                          } auth-input-field`}
                          htmlFor="em"
                        >
                          Email
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit && payload.email === ""
                          ? "Email cannot be empty"
                          : isSubmit && !regexes.email.test(payload.email)
                          ? "Enter Valid Email Address"
                          : errors?.email
                          ? errors?.email[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-3">
            <h4 className="font-weight-bold">Billing Address</h4>
            <div className="row">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            value={payload.street1}
                            id="first_street"
                            onChange={(ev) =>
                              setpayload({
                                ...payload,
                                street1: ev.target.value,
                              })
                            }
                            type="text"
                            maxLength="100"
                          />
                        </div>
                        <label
                          className={`${
                            payload.street1 !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="st1"
                        >
                          Street 1
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {isSubmit && payload.street1 === ""
                          ? "Street cannot be empty"
                          : errors?.street1
                          ? errors?.street1[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            value={payload.street2}
                            id="second_street"
                            onChange={(ev) =>
                              setpayload({
                                ...payload,
                                street2: ev.target.value,
                              })
                            }
                            type="text"
                            maxLength="100"
                          />
                        </div>
                        <label
                          className={`${
                            payload.street2 !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="st2"
                        >
                          Street 2 (Optional)
                        </label>
                      </div>
                      <small className="text-danger ml-1"></small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            id="city"
                            value={payload.city}
                            onChange={(ev) =>
                              setpayload({ ...payload, city: ev.target.value })
                            }
                            type="text"
                            maxLength="50"
                          />
                        </div>
                        <label
                          className={`${
                            payload.city !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="cty"
                        >
                          City
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit && payload.city === ""
                          ? "Street cannot be empty"
                          : errors?.city
                          ? errors?.city[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            type="text"
                            id="state"
                            value={payload.state}
                            onChange={(ev) =>
                              setpayload({ ...payload, state: ev.target.value })
                            }
                            maxLength="50"
                          />
                        </div>
                        <label
                          className={`${
                            payload.state !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="stat"
                        >
                          State
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit && payload.state === ""
                          ? "State cannot be empty"
                          : errors?.state
                          ? errors?.state[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            id="zip_code"
                            value={payload.zipcode}
                            onChange={(ev) =>
                              setpayload({
                                ...payload,
                                zipcode: ev.target.value,
                              })
                            }
                            type="text"
                            maxLength="50"
                          />
                        </div>
                        <label
                          className={`${
                            payload.zipcode !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="zip"
                        >
                          Zip Code
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {isSubmit && payload.zipcode === ""
                          ? "Zip code cannot be empty"
                          : errors?.zipcode
                          ? errors?.zipcode[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  );
};

export default AddCustomer;
