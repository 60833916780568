import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Async from "react-async";

const AdminContentEditor = ({
  text,
  disabled,
  setDisabledFieldsChild,
  onSave,
  maxLength,
  toolbar,
  BUEdit,
}) => {
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    BUEdit ? undefined : [{ list: "ordered" }, { list: "bullet" }],
  ];
  const editor = useRef(null);
  const [quill, setQuill] = useState(null);
  const [disabledInput, setDisabledInput] = useState(disabled);
  const [body, setBody] = useState("");
  const currentLength = useRef(null);

  useEffect(() => {
    editor !== null && setQuill(editor?.current?.getEditor());
    //
  }, [editor]);

  useEffect(() => {
    if (quill) {
      updateLength(quill);
      console.log(quill.length);
    }
  }, [quill]);

  useEffect(() => {
    if (quill) {
      updateLength(quill);
    }
  }, [body, quill]);

  useEffect(() => {
    setDisabledInput(disabled);
  }, [disabled]);

  useEffect(() => {
    text && setBody(text);
  }, [text]);

  const updateLength = (quill) => {
    if (quill) {
      //currentLength.current.innerText = quill.getText().length;
      console.log("len", quill.getLength());
      const html = quill.root.innerHTML;
      const strip_tags = html.replace(/<(?!br\s*\/?)[^>]+>/g, "");
      const strip_enter = strip_tags.replace(/<br\s*\/?>/gi, " ");
      const strip_space = strip_enter.replace(/&nbsp;/gi, " ");
      //const strip_and = strip_space.replace(/&amp;/gi, "&");
      const html_to_text = strip_space;
      const total = html_to_text.length;
      currentLength.current.innerText = total;
      return total;
    }
  };

  return (
    <div>
      <div style={{ marginTop: "1%" }}>
        <Async>
          <ReactQuill
            className={`${BUEdit && `admin-edit`} ${
              disabledInput ? "disabled" : ""
            } ${toolbar ? "" : "no-toolbar"}`}
            id="header"
            ref={editor}
            defaultValue=""
            value={body}
            onChange={(val, _, source) => {
              console.log(updateLength(quill));
              console.log("object", source);

              if (updateLength(quill) > maxLength && source === "user") {
                const selection = quill.getSelection();
                const delta = quill.clipboard.convert(body);
                console.log("object..............................");

                quill.setContents(delta, "api");
                console.log(selection, "asdasdasd");

                setTimeout(() => {
                  quill.setSelection(selection);
                }, 0);
              } else if (source === "user") {
                setBody(val);
                setDisabledFieldsChild && setDisabledFieldsChild(true);
                onSave && onSave(quill?.root?.innerHTML);
              }
            }}
            modules={{
              toolbar: toolbarOptions,
              history: {
                userOnly: true,
              },
            }}
            readOnly={disabledInput}
            onKeyPress={() => {
              quill && updateLength(quill);
            }}
            onKeyUp={() => {
              quill && updateLength(quill);
            }}
            onMouseDown={() => {
              quill && updateLength(quill);
            }}
          />
        </Async>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <small ref={currentLength}></small>
        <small>/{maxLength}</small>
      </div>
    </div>
  );
};

export default AdminContentEditor;
