import React from "react";
import DocumentView from "../../DocumentView";
import { ObservationPhotoEditor } from "../../ImageEditor";
import ObservationInfo from "../../ObservationInfo";
import ObservationPageTitle from "../../ObservationPageTitle";
import TopRightTitle from "../../TopRightTitle";
import { ObservationsEditable as OET } from "../ReportObservations";

export default function ObservationPhotos({
  index,
  photos,
  style,
  roomIndex,
}) {
  return (
    <DocumentView style={style}>
      <div className="document-head" style={{ border: "none" }}>
        <header>
          <div className="grid-pg1">
            <div>
              <ObservationPageTitle />
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>

      <ObservationInfo index={index} roomIndex={roomIndex} />

      <div className="doc-body">
        <div>
          <div className="comments-heading">
            <p>
              <strong>Photos</strong>
            </p>
            <span></span>
          </div>

          <div className="d-flex flex-wrap">
            {photos.map((photo, i) => (
              <div style={{ marginTop: "0.5cm" }} key={`photo-${i}`}>
                <ObservationPhotoEditor
                  src={photo.url}
                  rotation={photo.rotation ?? 0}
                  alt=""
                  className="obs-photo-img"
                  oi={index}
                  pi={i}
                  ri={roomIndex}
                />

                <div className="obs-photo-caption">
                  <OET
                    reduxKey="caption"
                    ml="200"
                    oi={index}
                    pi={i}
                    ri={roomIndex}
                  >
                    {photo.caption}
                  </OET>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
    </DocumentView>
  );
}
