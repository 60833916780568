import React, { useEffect, useState } from "react";
import { regexes } from "../../../shared/regexes";
import {
  checkResetTokenRequest,
  loginRequest,
  profileRequest,
  resetPassword,
} from "../../../../services/auth-service";
import ScoutLogo from "../../../../assets/logo/scout_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faExclamationTriangle,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import "../style.css";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { LOG_IN, SET_PASS_REST } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import axios from "../../../../axios-defaults";
import { getErrorMsg, showToast } from "../../../shared/helpers";
import InfoIcon from "main/components/InfoIcon/InfoIcon";
import ReactTooltip from "react-tooltip";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [viewPass, setViewPass] = useState(false);
  const { id } = useParams();
  let history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  let email = query.get("email");
  try {
    if (email) {
      email = atob(query.get("email"));
    } else {
      email = false;
    }
  } catch {
    email = false;
  }

  function handleSubmit(event) {
    event.preventDefault();

    setErrorText("");
    setShowSuccess(false);

    if (!password) {
      setErrorText("Please enter a password.");
    } else if (password !== confirmPassword) {
      setErrorText("Passwords don't match.");
    } else if (password.length < 8) {
      setErrorText("Password should be between 8 to 15 characters.");
    } else if (!regexes.password.test(password)) {
      setErrorText(
        "At-least one lowercase letter, one uppercase letter, one numeric digit and one special character."
      );
    } else {
      setDisableReset(true);

      resetPassword({
        password,
        token: id,
        _method: "PATCH",
      })
        .then(() => {
          if (email) {
            loginRequest({
              username: email,
              password,
              client_secret: process.env.REACT_APP_CLIENT_SECRET,
              client_id: process.env.REACT_APP_CLIENT_ID,
              grant_type: process.env.REACT_APP_GRANT_TYPE,
            })
              .then((resp) => {
                localStorage.setItem("access_token", resp.data.access_token);
                localStorage.setItem("refresh_token", resp.data.refresh_token);

                if (resp.data.access_token) {
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${resp.data.access_token}`;
                }

                return profileRequest();
              })
              .then((profile) => {
                dispatch({
                  type: LOG_IN,
                  payload: profile.data,
                });
                ReactTooltip.hide();
                history.replace("/profile");
              })
              .catch((err) => {
                console.log(err);
                setDisableReset(false);
              });
          } else {
            setPassword("");
            setConfirmPassword("");
            setErrorText("");
            setShowSuccess(true);
            setDisableReset(false);
            dispatch({
              type: SET_PASS_REST,
              payload: {
                showResetSuccess: true,
              },
            });
            history.replace("/login");
          }
        })
        .catch((err) => {
          setErrorText(getErrorMsg(err));
          setDisableReset(false);
        });
    }
  }

  useEffect(() => {
    checkResetTokenRequest(id)
      .then(() => {
        setErrorText("");
        setDisableReset(false);
      })
      .catch((err) => {
        if (err) {
          setErrorText(err.response.data.message);
          showToast(err.response.data.message);
          setDisableReset(true);
        }
      });
  }, [id]);

  return (
    <div className="login-page">
      <div>
        <img alt="Scout-Logo" className="scout-logo" src={ScoutLogo} />
      </div>

      {email ? null : (
        <div className="reset-password-text">Enter your password to reset</div>
      )}

      {errorText !== "" ? (
        <div>
          <div className="email-pass-error">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span className="ml-4 font-bold">{errorText}</span>
          </div>
        </div>
      ) : null}
      {showSuccess ? (
        <div>
          <div className="email-pass-success">
            <span className="ml-4 font-bold">
              Your password has been Successfully changed. Try logging in the
              application again.
            </span>
          </div>
        </div>
      ) : null}

      <form onSubmit={handleSubmit}>
        <div id="float-label-auth" className="mt-3">
          <div>
            <span className="placeholder-icon mr-n-50">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="auth-input-field"
              type={viewPass ? "text" : "password"}
              maxLength="15"
              autoComplete="new-password"
            />
            <FontAwesomeIcon
              className="pass-eye"
              icon={viewPass ? faEyeSlash : faEye}
              onClick={() => setViewPass((s) => !s)}
            />
          </div>
          <label
            className={
              password.length < 1
                ? "auth-input-field"
                : "Active auth-input-field"
            }
          >
            Enter New Password
          </label>
        </div>

        <div id="float-label-auth" className="mt-3">
          <div>
            <span className="placeholder-icon mr-n-50">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="auth-input-field"
              type={viewPass ? "text" : "password"}
              maxLength="15"
              autoComplete="new-password"
            />
            <FontAwesomeIcon
              className="pass-eye"
              icon={viewPass ? faEyeSlash : faEye}
              onClick={() => setViewPass((s) => !s)}
            />
          </div>
          <label
            className={
              confirmPassword.length < 1
                ? "auth-input-field"
                : "Active auth-input-field"
            }
          >
            Re-Enter New Password
          </label>
        </div>

        <div className="mt-3 text-align-end">
          <button
            disabled={disableReset}
            variant="primary"
            className={
              disableReset
                ? "login-btn disabled-bg-color"
                : "login-btn bg-light20"
            }
            type="submit"
          >
            Set New Password{" "}
            <InfoIcon dataTip="Password must contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character." />
          </button>
        </div>

        <div className="extra-text text-center">
          <div>Please contact your administrator for access to Scout Pro</div>
          <div className="mt-3">
            By Logging in you agree to the{" "}
            <Link to="/terms-of-use" target="_blank" className="new-tab-link">
              terms of use
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy" target="_blank" className="new-tab-link">
              privacy policy
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
