import axios from "axios-defaults.js";

export const documentList = async ({ id, page, type }) =>
  axios({
    method: "get",
    url: `/api/v1/documents/${id}?page=${page}&type=${type}`,
  })
    .then((res) => ({
      error: false,
      data: res.data,
    }))
    .catch((error) => ({
      error: true,
      data: error,
    }));
export const createDocument = async (datas) =>
  axios
    .post(`/api/v1/documents`, datas)
    .then((res) => ({
      error: false,
      data: res.data,
    }))
    .catch((error) => ({
      error,
      data: error,
    }));
export const deleteDocument = async ({ id, inspid }) =>
  axios
    .post(`/api/v1/documents/${inspid}?_method=delete&document_id=${id}`)
    .then((res) => ({
      error: false,
      data: res.data,
    }))
    .catch((error) => ({
      error,
      data: error,
    }));
export const emailSend = async ({ docids, inspid }) =>
  axios
    .post(`/api/v1/documents/${inspid}/send-to-customer`, {
      document_id: docids,
    })
    .then((res) => ({
      error: false,
      data: res.data,
    }))
    .catch((error) => ({
      error,
      data: error,
    }));

export const customEmailSend = (data) => {
  return axios.post("/api/v1/bu/custom-email-send", data);
};
