import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import Service from "../../../services/LabsService";
import Chip from "../../components/Chip";
import CheckBoxModal from "../../components/CheckBox";
import { showToast, setHeader } from "../../shared/helpers";
import { useDispatch } from "react-redux";
import { regexes } from "../../shared/regexes";
import { Delete } from "../Comments";
import "./style.css";
import ScrollBar from "react-perfect-scrollbar";

const AddLab = () => {
  const { labid } = useParams();

  const location = useLocation();

  const dispatch = useDispatch();

  const [loader, setloader] = useState(false);

  const [dis, setdis] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  // const [isSaving, setIsSaving] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);

  const [pay, setpay] = useState({
    name: "",
    email: "",
    contact_name: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    link_test_sample_ids: [],
  });

  const reset = () => {
    setpay({
      name: "",
      email: "",
      contact_name: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
    });
    setIsSubmit(false);
    seterrors({});
  };

  const [errors, seterrors] = useState({});

  const submit = () => {
    setIsSubmit(true);
    if (
      pay.email &&
      pay.contact_name &&
      pay.phone &&
      regexes.email.test(pay.email) &&
      pay.name &&
      pay.address1 &&
      pay.city &&
      pay.state &&
      pay.zipcode
    ) {
      setdis(true);
      Service.postLab(pay)
        .then(() => {
          reset();
          showToast("Lab created successfully.", true);
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data);
          } else {
            showToast("Something went wrong.");
          }
        })
        .finally(() => setdis(false));
    }
  };

  //update flow
  const [items, setitems] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalLoader, setModalLoader] = useState(true);
  const [index, setindex] = useState(0);
  const [innerindex, setinnerindex] = useState(0);
  const [opendel, setopendel] = useState(false);
  const [delid, setdelid] = useState(0);
  const [updateOn, setupdateOn] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("update")) {
      console.log(editEnabled, "Asdasda");
      setEditEnabled(false);
      setloader(true);
      setupdateOn(true);
      console.log(editEnabled, "Asdasda");
      getUpdatedItems();
    }
  }, []);

  const getUpdatedItems = () => {
    Service.getLabById(labid)
      .then((resp) => {
        let temp = resp.data;
        Object.assign(temp, temp.address);
        delete temp.address;
        console.log(temp);
        setpay({ ...pay, ...temp });
        setHeader(dispatch, {
          title: temp.name,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  };
  const openDialog = (id, index) => {
    setModalLoader(true);
    setModalShow(true);
    setindex(index);
    Service.getTestTypeById(id, labid)
      .then((resp) => {
        console.log(resp.data);
        console.log("yeh chck", pay.link_test_sample_ids);
        if (pay.link_test_sample_ids) {
          setitems(
            resp.data.data.filter(
              (x) => pay.link_test_sample_ids.indexOf(x.id) < 0
            )
          );
        } else {
          setitems(resp.data.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setModalLoader(false));
  };

  const saveSamples = (samples) => {
    console.log(samples);
    let temp = pay;
    if (temp.link_test_sample_ids) {
      temp.link_test_sample_ids.push(...samples);
    } else {
      temp.link_test_sample_ids = samples;
    }

    //for making unique
    temp.link_test_sample_ids = temp.link_test_sample_ids.filter(
      (item, pos, self) => {
        return self.indexOf(item) == pos;
      }
    );

    temp.test_types_list[index].lab_test_samples = [
      ...temp.test_types_list[index].lab_test_samples.concat(
        items.filter((x) => samples.indexOf(x.id) > -1)
      ),
    ];
    setpay(temp);
    update();
  };

  const update = () => {
    setIsSubmit(true);
    if (
      pay.email &&
      pay.contact_name &&
      pay.phone &&
      regexes.email.test(pay.email) &&
      pay.name &&
      pay.address1 &&
      pay.city &&
      pay.state &&
      pay.zipcode
    ) {
      setdis(true);
      Service.updateLab(labid, pay)
        .then(() => {
          setIsSubmit(false);
          seterrors({});
          showToast("Lab updated successfully.", true);
        })
        .catch((err) => {
          console.log(err?.response);

          if (err?.response?.status === 422) {
            seterrors(err?.response?.data);
          } else {
            showToast("Something went wrong.");
          }
        })
        .finally(() => {
          setEditEnabled(false);

          setdis(false);
        });
    }
  };

  const OpenDelete = (id, parentindex, childindex) => {
    setdelid(id);
    setindex(parentindex);
    setinnerindex(childindex);
    setopendel(true);
  };

  const RemoveSample = (disable) => {
    disable(true);
    if (
      pay.link_test_sample_ids &&
      pay.link_test_sample_ids.indexOf(delid) > -1
    ) {
      let temp = pay;
      temp.link_test_sample_ids.splice(
        temp.link_test_sample_ids.indexOf(delid),
        1
      );
      temp.test_types_list[index].lab_test_samples.splice(innerindex, 1);
      setpay(temp);
      disable(false);
      setopendel(false);
    } else {
      Service.unLinkSample(delid, labid)
        .then(() => {
          let temp = pay;
          temp.test_types_list[index].lab_test_samples.splice(innerindex, 1);
          setpay(temp);
        })
        .catch((err) => {})
        .finally(() => {
          disable(false);
          setopendel(false);
        });
    }
  };

  return (
    <>
      {loader ? (
        <Spinner
          animation="border"
          variant="success"
          className="app-spinner"
          style={{ left: "10%" }}
        />
      ) : (
        <div className="main">
          <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
            <div className="main-content-area">
              <div className="d-flex align-items-center">
                {location.pathname.includes("update") ? (
                  !editEnabled ? (
                    <button
                      id="edit_lab_button"
                      className="btn btn-primary ml-auto"
                      disabled={dis}
                      onClick={() => {
                        setEditEnabled(true);
                      }}
                    >
                      Edit Lab <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                  ) : (
                    <>
                      <button
                        id="edit_lab_button"
                        className="btn btn-primary ml-auto"
                        disabled={dis}
                        onClick={update}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-light ml-2 low-priority"
                        onClick={() => {
                          getUpdatedItems();
                          setEditEnabled(false);
                        }}
                        // disabled={isSaving}
                      >
                        Cancel
                      </button>
                    </>
                  )
                ) : (
                  <>
                    <button
                      className="btn btn-primary ml-auto"
                      disabled={dis}
                      id="add_lab_button"
                      onClick={submit}
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
              <div>
                <h4>Lab Details</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                type="text"
                                value={pay.name}
                                maxLength="50"
                                disabled={!editEnabled && updateOn}
                                onChange={(e) =>
                                  setpay({ ...pay, name: e.target.value })
                                }
                                id="lab_name"
                              />
                            </div>
                            <label
                              className={`${
                                pay.name !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_name"
                            >
                              Lab Name
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {isSubmit && pay.name === ""
                              ? "Lab name cannot be empty"
                              : errors?.name
                              ? errors?.name[0]
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                id="lab_email"
                                type="text"
                                maxLength="50"
                                value={pay.email}
                                disabled={!editEnabled && updateOn}
                                onChange={(e) =>
                                  setpay({ ...pay, email: e.target.value })
                                }
                              />
                            </div>
                            <label
                              className={`${
                                pay.email !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_email"
                            >
                              Email
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {isSubmit && pay.email === ""
                              ? "Email cannot be empty"
                              : isSubmit && !regexes.email.test(pay.email)
                              ? "Enter Valid Email Address"
                              : errors?.email
                              ? errors?.email[0]
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                value={pay.contact_name}
                                type="text"
                                maxLength="50"
                                disabled={!editEnabled && updateOn}
                                onChange={(e) =>
                                  setpay({
                                    ...pay,
                                    contact_name: e.target.value,
                                  })
                                }
                                id="lab_contact_name"
                              />
                            </div>
                            <label
                              className={`${
                                pay.contact_name !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_contact_name"
                            >
                              Contact Name
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {isSubmit && pay.contact_name === ""
                              ? "Contact name cannot be empty"
                              : errors?.contact_name
                              ? errors?.contact_name[0]
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                id="lab_phone"
                                type="text"
                                maxLength="16"
                                disabled={!editEnabled && updateOn}
                                value={new AsYouType("US").input(pay.phone)}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  value = value.replace(regexes.phoneInput, "");
                                  setpay({ ...pay, phone: value });
                                }}
                              />
                            </div>
                            <label
                              className={`${
                                pay.phone !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_phone"
                            >
                              Phone Number
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {isSubmit && pay.phone === ""
                              ? "Phone cannot be empty"
                              : errors?.phone
                              ? errors?.phone[0]
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h4>Address</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                value={pay.address1}
                                type="text"
                                maxLength="100"
                                disabled={!editEnabled && updateOn}
                                onChange={(e) =>
                                  setpay({ ...pay, address1: e.target.value })
                                }
                                id="lab_street_1"
                              />
                            </div>
                            <label
                              className={`${
                                pay.address1 !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_street_1"
                            >
                              Street 1
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {isSubmit && pay.address1 === ""
                              ? "Street 1 cannot be empty"
                              : errors?.address1
                              ? errors?.address1[0]
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                id="lab_street_2"
                                type="text"
                                value={pay.address2}
                                disabled={!editEnabled && updateOn}
                                maxLength="100"
                                onChange={(e) =>
                                  setpay({ ...pay, address2: e.target.value })
                                }
                              />
                            </div>
                            <label
                              className={`${
                                pay.address2 !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_street_2"
                            >
                              Street 2 (Optional)
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {errors?.address2 ? errors?.address2[0] : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                value={pay.city}
                                disabled={!editEnabled && updateOn}
                                type="text"
                                maxLength="50"
                                onChange={(e) =>
                                  setpay({ ...pay, city: e.target.value })
                                }
                                id="lab_city"
                              />
                            </div>
                            <label
                              className={`${
                                pay.city !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_city"
                            >
                              City
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {isSubmit && pay.city === ""
                              ? "City cannot be empty"
                              : errors?.city
                              ? errors?.city[0]
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                id="lab_state"
                                value={pay.state}
                                type="text"
                                maxLength="50"
                                disabled={!editEnabled && updateOn}
                                onChange={(e) =>
                                  setpay({ ...pay, state: e.target.value })
                                }
                              />
                            </div>
                            <label
                              className={`${
                                pay.state !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_state"
                            >
                              State
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {isSubmit && pay.state === ""
                              ? "State cannot be empty"
                              : errors?.state
                              ? errors?.state[0]
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <div className="float-label">
                            <div>
                              <input
                                className="form-control input-field"
                                id="lab_zip"
                                type="text"
                                value={pay.zipcode}
                                disabled={!editEnabled && updateOn}
                                maxLength="50"
                                onChange={(e) =>
                                  setpay({ ...pay, zipcode: e.target.value })
                                }
                              />
                            </div>
                            <label
                              className={`${
                                pay.zipcode !== "" ? "Active " : ""
                              }auth-input-field`}
                              htmlFor="lab_zip"
                            >
                              Zip Code
                            </label>
                          </div>

                          <small className="text-danger ml-1">
                            {isSubmit && pay.zipcode === ""
                              ? "Zip code cannot be empty"
                              : errors?.zipcode
                              ? errors?.zipcode[0]
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {location.pathname.includes("update") ? (
                <div>
                  <table className="table table-responsive-md">
                    <thead>
                      <tr>
                        <th width="40%">Test Type</th>
                        <th width="40%">Test Sample</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pay.test_types_list &&
                        pay.test_types_list.map((item, i) => (
                          <tr style={{ lineHeight: "7vh" }} key={i}>
                            <td>
                              <strong>{item.name}</strong>
                            </td>
                            <td style={{ borderRadius: "0px 10px 10px 0px" }}>
                              {item.lab_test_samples.length > 0 ? (
                                item.lab_test_samples.map((x, childindex) => (
                                  <Chip
                                    id={`${x.name}_chip`}
                                    onCross={() =>
                                      OpenDelete(x.id, i, childindex)
                                    }
                                    style={{ margin: "0px 5px 0px 0px" }}
                                    key={childindex}
                                  >
                                    {x.name}
                                  </Chip>
                                ))
                              ) : (
                                <span className="notest">{`No ${item.name} Tests`}</span>
                              )}
                            </td>
                            <div>
                              <button
                                className="btn btn-primary ml-auto"
                                disabled={dis}
                                onClick={() => openDialog(item.id, i)}
                                id={`${item.name}_btn`}
                              >
                                Add Sample Type{" "}
                                <FontAwesomeIcon icon={faPlus} />
                              </button>
                            </div>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              <Delete
                show={opendel}
                onHide={() => setopendel(false)}
                msg={"Are you sure you want to unlink this sample type?"}
                delfunc={RemoveSample}
              />

              <CheckBoxModal
                items={items}
                onChecked={(items) => console.log(items)}
                showTittleIcon={true}
                show={modalShow}
                onHide={() => setModalShow(false)}
                loader={modalLoader}
                title={"Add Sample Type"}
                buttonLabel={"Save Sample Types"}
                emptyText={"No Sample Types Available"}
                onSave={(ev) => saveSamples(ev)}
              />
            </div>
          </ScrollBar>
        </div>
      )}
    </>
  );
};

export default AddLab;
