import Axios from "axios";
import { logoutFromInterceptor, navigate } from "navigation/router";
import { dismissAllToasts, showToast } from "./main/shared/helpers";
import { authRoles } from "./navigation/route-config";
import { refreshTokenRequest } from "./services/auth-service";
import { checkConnectivity } from "./services/connectivity";
import * as Sentry from "@sentry/react";
const axios = Axios.create();
axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;
axios.defaults.withCredentials = false;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    Sentry.captureException(error);
    console.log("response interceptor");
    console.log("Code", error?.code);
    console.log("Res code", error?.response?.code);
    console.log("Res Status", error?.response?.status);
    let originalRequest = error.config;
    console.log(error.config._retry);
    if (error?.response?.status === 500) {
      // console.log(error?.response, "0000000000000000000");
      if (!error?.response.url.includes("q=extra_pages")) {
        dismissAllToasts();
        showToast("Temporarily Down for Maintenance");
      }
    } else if (error?.response?.status === 402) {
      const userType = atob(localStorage.getItem("user_type"));
      if (
        userType === authRoles.buSubAdmin ||
        userType === authRoles.buInspector
      ) {
        navigate("/subscription-expired");
      } else if (userType === authRoles.ultraAdmin) {
        navigate("/subscription/failed");
      } else if (
        userType === authRoles.buAdmin &&
        !window.location.pathname.includes("subscription/failed")
      ) {
        console.log(
          window.location.pathname.includes("subscription/failed"),
          "8888888888888888"
        );
        localStorage.removeItem("user");
        navigate("/subscription/failed");
      }
      return Promise.reject(error);
    } else if (error?.response?.status === 403) {
      const userType = atob(localStorage.getItem("user_type"));
      if (userType === authRoles.buSubAdmin) {
        dismissAllToasts();
        showToast(
          error?.response?.message || "Your permissions have been changed."
        );
        localStorage.removeItem("user");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
    if (error?.response?.status === 401 && !originalRequest._retry) {
      const refresh_token = localStorage.getItem("refresh_token");
      originalRequest = { ...originalRequest, _retry: true };
      console.log(originalRequest._retry);

      if (refresh_token) {
        return refreshTokenRequest({
          refresh_token,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          client_id: process.env.REACT_APP_CLIENT_ID,
          grant_type: "refresh_token",
        })
          .then((resp) => {
            localStorage.setItem("access_token", resp.data.access_token);
            localStorage.setItem("refresh_token", resp.data.refresh_token);
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${resp.data.access_token}`;
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp.data.access_token}`;

            return axios(originalRequest);
          })
          .catch((err) => {
            console.log(err);
            logoutFromInterceptor("Authorization expired");
            return Promise.reject(err);
          });
      }
    } else if (!error?.response) {
      console.log("no internet");
      if (originalRequest._retryIteration === 2) {
        console.log("waiting");
        const newError = await checkConnectivity()
          .then(() => {
            dismissAllToasts();
            showToast(
              "Temporarily down for maintenance.\nPlease try again later."
            );
            return {
              noInternet:
                "Temporarily down for maintenance.\nPlease try again later.",
            };
          })
          .catch(() => {
            dismissAllToasts();
            showToast(
              "Please check your internet connection or try again later."
            );
            return {
              noInternet:
                "Please check your internet connection or try again later.",
            };
          });
        return Promise.reject(newError);
      } else {
        if (!originalRequest?._retryIteration) {
          originalRequest._retryIteration = 0;
        }
        originalRequest._retryIteration = originalRequest._retryIteration + 1;
        return axios(originalRequest);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
