/* eslint-disable react-hooks/exhaustive-deps */
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import RoomService from "../../../services/RoomService";
import Paginator from "../../components/Paginator/Paginator";
import { showToast, truncate, useDidUpdateEffect } from "../../shared/helpers";
import { Delete } from "../Comments";
import "./rooms.css";

function AddRoom(props) {
  const [payload, setpayload] = useState({ room_type: "" });

  const [dis, setdis] = useState(false);

  const [errors, seterrors] = useState({});

  const add = () => {
    setdis(true);
    RoomService.createRoom(payload)
      .then(() => {
        seterrors({});
        props.update();
        props.onHide();
        showToast("Room created successfully", true);
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 422) {
          //seterrors(err?.response?.data)
          showToast(err?.response?.data?.room_type[0]);
        } else {
          showToast("Something went wrong");
        }
      })
      .finally(() => {
        setdis(false);
        setpayload({ room_type: "" });
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <img
                className="iconstyle"
                src={
                  require("../../../assets/icons/toolkit_light20@3x.png")
                    .default
                }
                alt="icon"
              />
              <h5 style={{ fontWeight: "bolder" }}>Add Room</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={() => props.onHide()}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <div>
            <h4>Room Name</h4>
            <input
              className="form-control input-field"
              placeholder="Room Name"
              value={payload.room_type}
              onChange={(e) =>
                setpayload({ ...payload, room_type: e.target.value })
              }
              type="text"
              maxLength="50"
              autoComplete="new-password"
            />
            <small className="text-danger ml-1">
              {errors?.room_type ? errors?.room_type[0] : ""}
            </small>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          disabled={dis || !payload.room_type}
          style={{ marginRight: 25 }}
          onClick={add}
        >
          Save Room
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Rooms() {
  const [modalShow, setModalShow] = React.useState(false);
  const [delmodalShow, setdelModalShow] = React.useState(false);
  const roomList = useRef(null);

  const [rooms, setrooms] = useState({});

  const [page, setpage] = useState(1);

  const [singleRoom, setsingleRoom] = useState({
    room_type: "",
    id: 0,
  });

  const [loader, setloader] = useState(true);
  const [active, setactive] = useState(0);
  const [update, setupdate] = useState(0);
  const [disedit, setdisedit] = useState(false);

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);

  useDidUpdateEffect(() => {
    setSkip(true);
    setloader(true);
    RoomService.getRooms(1, searchField)
      .then((x) => {
        console.log(x.data);
        setrooms(x.data);
        setpage(1);
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
  }, [searchField]);

  const setactiv = (index) => {
    setsingleRoom({
      ...singleRoom,
      room_type: rooms.data[index].room_type,
      id: rooms.data[index].id,
    });
    setactive(index);
  };

  useEffect(() => {
    if (!skip) {
      setloader(true);
      RoomService.getRooms(page, searchField)
        .then((x) => {
          console.log(x.data);
          setrooms(x.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setloader(false));
    } else {
      setSkip(false);
    }
  }, [update, page]);

  useEffect(() => {
    if (rooms?.data && rooms?.data?.length > 0) {
      if (active > rooms?.data?.length - 1 || update === 0) {
        setactiv(0);
      } else {
        setactiv(active);
      }
    } else {
      setsingleRoom({
        room_type: "",
        id: 0,
      });
    }
    if (roomList?.current) {
      roomList.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [rooms]);

  const edit = () => {
    setdisedit(true);
    RoomService.updateRooms(singleRoom.id, { room_type: singleRoom.room_type })
      .then(() => {
        setupdate(update + 1);
        showToast("Room Type Editted Successfully", true);
      })
      .catch(() => {
        showToast("Something went wrong");
      })
      .finally(() => setdisedit(false));
  };

  const delcomm = (setdisdel) => {
    setdisdel(true);
    RoomService.DeleteRoom(singleRoom.id)
      .then(() => {
        setupdate(update + 1);
        showToast("Room Deleted Successfully", true);
      })
      .catch(() => {
        showToast("Something went wrong");
      })
      .finally(() => {
        setdisdel(false);
        setdelModalShow(false);
      });
  };

  return (
    <div className="mainbody">
      <div className="leftside">
        <div className="leftside-header">
          <h5 className="mainheading">Rooms</h5>
          <button
            onClick={() => setModalShow(true)}
            className="btn btn-primary ml-auto"
            id="add_room"
          >
            Add Room <FontAwesomeIcon icon={faPlus} />
          </button>
          <AddRoom
            show={modalShow}
            onHide={() => setModalShow(false)}
            update={() => setupdate(update + 1)}
          />
          <Delete
            show={delmodalShow}
            onHide={() => setdelModalShow(false)}
            msg={
              "Are you sure you want to delete this room? Deleting will also delete the room from associated inspections and reports."
            }
            delfunc={delcomm}
          />
        </div>
        <div>
          <ScrollBar>
            <ul
              className={loader ? "ul-style list-spinner" : "ul-style"}
              ref={roomList}
            >
              {loader ? (
                <Spinner animation="border" variant="success" />
              ) : (
                rooms?.data?.map((item, i) => (
                  <li
                    key={`room-${i}`}
                    className={`liststyle${i === active ? " listActive" : ""}`}
                    onClick={() => setactiv(i)}
                  >
                    {item.room_type}
                  </li>
                ))
              )}
            </ul>
          </ScrollBar>
          <Paginator
            activePage={rooms?.current_page}
            itemsCountPerPage={rooms?.per_page}
            totalItemsCount={rooms?.total ?? 0}
            onChange={(page) => {
              setSkip(false);
              setpage(page);
            }}
          />
        </div>
      </div>
      <ScrollBar
        style={{
          height: "calc(100vh - 90px)",
          overflowY: "auto",
          width: "100%",
          background: "#e7ece8",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <div className="rightside">
          <div className="rightsideinner">
            <div className="float-label">
              <div>
                <input
                  className="input-field form-control"
                  type="text"
                  maxLength="50"
                  disabled={loader || rooms?.data?.length < 1}
                  value={singleRoom.room_type}
                  onChange={(ev) => {
                    setsingleRoom({
                      ...singleRoom,
                      room_type: ev.target.value,
                    });
                  }}
                  id="roomname"
                />
              </div>
              <label className="Active input-field" htmlFor="roomname">
                Room Name
              </label>
            </div>
            <div className="rightsideactions">
              <button
                className="btn btn-primary delbtn"
                disabled={loader || rooms?.data?.length < 1}
                onClick={() => setdelModalShow(true)}
                id="delete_room"
              >
                Delete{" "}
                {rooms?.data?.length > 0 &&
                  rooms?.data[active] &&
                  truncate(rooms?.data[active]?.room_type)}
              </button>
              <button
                className="btn btn-primary"
                disabled={loader || disedit || !singleRoom?.room_type}
                onClick={edit}
                id="edit_room"
              >
                Edit{" "}
                {rooms?.data?.length > 0 &&
                  rooms?.data[active] &&
                  truncate(rooms?.data[active]?.room_type)}
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  );
}

export default Rooms;
