import React from "react";
import ScoutLogo from "../../../assets/logo/scout_logo.png";

export default function ServerDownPage() {
  return (
    <div className="login-page">
      <div>
        <img alt="Scout-Logo" className="scout-logo" src={ScoutLogo} />
      </div>
      <div style={{ marginTop: "10px" }}>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Unfortunately the site is down for a bit of maintainance right now.
          <br />
          Please try again later.
        </p>
      </div>
    </div>
  );
}
