/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCaretDown,
  faCaretRight,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { deleteProperties } from "services/PropertyService";
import { SET_CONFIRMATION_MODAL } from "store/actions";
import {
  getCustomerById,
  getCustomerProperties,
  updateCustomer,
} from "../../../services/CustomerService";
import CustomInput from "../../components/Custom/CustomInput";
import Paginator from "../../components/Paginator/Paginator";
import {
  getAddress,
  getCreatedAtDate,
  getErrorMsg,
  setHeader,
  showToast,
  utcDate,
} from "../../shared/helpers";
import { regexes } from "../../shared/regexes";

export default function EditCustomer() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");

  const { customerId } = useParams();
  const [editEnabled, setEditEnabled] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const dispatch = useDispatch();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [page, setPage] = useState(1);

  const [selectedProperties, setSelectedProperties] = useState([]);

  const propertiesPermission = useSelector(
    (state) => state.user.permissions.properties
  );

  useEffect(() => {
    console.log(customerId);
    loadCustomer(page);
  }, [customerId, page]);

  const loadCustomer = (page = 1) => {
    setIsLoading(true);

    const promise1 = new Promise((resolve, reject) => {
      getCustomerById(customerId)
        .then(({ data }) => {
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setPhone(data.phone_number);
          setEmail(data.email);
          setStreet1(data.address.address1);
          setStreet2(data.address.address2);
          setCity(data.address.city);
          setState(data.address.state);
          setZipcode(data.address.zipcode);

          setHeader(dispatch, {
            title: data.first_name + " " + data.last_name,
          });
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });

    const promise2 = new Promise((resolve, reject) => {
      getCustomerProperties(customerId, page)
        .then((res) => {
          console.log(res);
          setProperties(res.data.data);
          setActivePage(res.data.current_page);
          setItemsCountPerPage(res.data.per_page);
          setTotalItemsCount(res.data.total);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });

    Promise.allSettled([promise1, promise2]).finally(() => {
      setIsLoading(false);
    });
  };

  const handleUpdate = () => {
    setDisableSave(true);
    const payload = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      street1,
      street2,
      city: city,
      state: state,
      zipcode: zipcode,
    };

    updateCustomer(customerId, payload)
      .then(({ data }) => {
        console.log(data);
        setEditEnabled(false);
        showToast("Customer updated successfully", true);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setPhone(data.phone_number);
        setEmail(data.email);
        setStreet1(data.address.address1);
        setStreet2(data.address.address2);
        setCity(data.address.city);
        setState(data.address.state);
        setZipcode(data.address.zipcode);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to update customer");
      })
      .finally(() => setDisableSave(false));
  };

  const handleSelectProperty = (e) => {
    const { value } = e.target;
    console.log(value);
    const isSelected = selectedProperties.includes(value);
    console.log(isSelected);
    if (isSelected) {
      setSelectedProperties(selectedProperties.filter((p) => p !== value));
    } else {
      setSelectedProperties([...selectedProperties, value]);
    }
  };

  const handleDeleteProperty = () => {
    setIsLoading(true);
    const ids = selectedProperties.map((p) => Number(p));
    deleteProperties(ids)
      .then(() => {
        setIsLoading(false);
        setSelectedProperties([]);
        showToast("Properties deleted successfully", true);
        loadCustomer(page);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(getErrorMsg(err) || "Unable to delete properties");
      });
  };

  const handleDelete = () => {
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        show: true,
        okText: "Delete",
        cancelText: "Cancel",
        title: "",
        message: "Are you sure you want to delete all selected properties?",
        onOk: handleDeleteProperty,
      },
    });
  };

  const getChecked = (id) => {
    //don't remove ==
    return selectedProperties.some((_id) => _id == id);
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="main-content-area">
            <div className="d-flex align-items-center">
              {editEnabled ? (
                <div className="d-flex ml-auto">
                  <button
                    className="btn btn-primary ml-auto"
                    id="save_customer"
                    onClick={handleUpdate}
                    disabled={disableSave}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-light ml-2 low-priority"
                    onClick={() => {
                      setEditEnabled(false);
                      loadCustomer();
                    }}
                    disabled={disableSave}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary ml-auto"
                  id="edit_customer"
                  onClick={() => setEditEnabled(true)}
                >
                  Edit Customer <FontAwesomeIcon icon={faPen} color="#fff" />
                </button>
              )}
            </div>
            <div>
              <h4 className="font-weight-bold">Customer Details</h4>
              <div className="row">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          inputProps={{
                            maxLength: "30",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          inputProps={{
                            maxLength: "30",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Phone Number"
                          value={new AsYouType("US").input(phone)}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(regexes.phoneInput, "");
                            setPhone(value);
                          }}
                          inputProps={{
                            maxLength: "16",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Email"
                          value={email}
                          onChange={() => {}}
                          inputProps={{ maxLength: "50", disabled: true }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-3">
              <h4 className="font-weight-bold">Billing Address</h4>
              <div className="row">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Street 1"
                          value={street1}
                          onChange={(e) => setStreet1(e.target.value)}
                          inputProps={{
                            maxLength: "100",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Street 2"
                          value={street2 || ""}
                          onChange={(e) => setStreet2(e.target.value)}
                          inputProps={{
                            maxLength: "100",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          inputProps={{
                            maxLength: "50",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <CustomInput
                          label="State"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          inputProps={{
                            maxLength: "50",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <CustomInput
                          label="Zip Code"
                          value={zipcode}
                          onChange={(e) => setZipcode(e.target.value)}
                          inputProps={{
                            maxLength: "50",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center pt-5">
                <img
                  src={
                    require(`../../../assets/icons/home_light20@3x.png`).default
                  }
                  alt="home"
                  width="20px"
                  className="mr-2"
                />
                <span
                  className="text-light20 font-weight-bold mt-1"
                  style={{ fontSize: "20px" }}
                >
                  {`${firstName} ${lastName}`}'s Properties
                </span>
              </div>
              <div
                className={
                  !propertiesPermission ? "cursor-disabled d-flex" : "d-flex"
                }
              >
                {selectedProperties.length > 0 && (
                  <button
                    id="delete-property-button"
                    className="btn btn-primary delbtn mr-2"
                    onClick={handleDelete}
                  >
                    Delete {selectedProperties.length} Selected Properties{" "}
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
                <Link
                  style={{
                    textDecoration: "none",
                    ...(!propertiesPermission && {
                      pointerEvents: "none",
                    }),
                  }}
                  to={`/properties/new/${customerId}/${firstName} ${lastName}`}
                  id="add_property"
                >
                  <button id="add-property-button" className="btn btn-primary">
                    Add Property <FontAwesomeIcon icon={faPlus} />
                  </button>
                </Link>
              </div>
            </div>
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th width="10"></th>
                  <th width="200">
                    Date Created <FontAwesomeIcon icon={faCaretDown} />
                  </th>
                  <th width="200">
                    Last Modified <FontAwesomeIcon icon={faCaretDown} />
                  </th>
                  <th>
                    Street/City/Zip <FontAwesomeIcon icon={faCaretDown} />
                  </th>
                  <th width="10"></th>
                </tr>
              </thead>
              <tbody>
                {properties.map((p, i) => (
                  <tr key={`property-${i}`}>
                    <td>
                      <input
                        checked={getChecked(p.id)}
                        value={p.id}
                        type="checkbox"
                        onChange={handleSelectProperty}
                      />
                    </td>
                    <td width="200" className="text-light20">
                      <strong>{utcDate(p.created_at)}</strong>
                    </td>
                    <td width="200" className="text-light20">
                      <strong>{utcDate(p.updated_at)}</strong>
                    </td>
                    <td>
                      <strong className="one-line-ellipsis text-secondary500">
                        {getAddress(p.address)}
                      </strong>
                    </td>
                    <td>
                      <div
                        className={!propertiesPermission && "cursor-disabled"}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            width: "32px",
                            cursor: "pointer",
                            ...(!propertiesPermission && {
                              pointerEvents: "none",
                            }),
                          }}
                          to={`/properties/${p.id}`}
                        >
                          <button className="next-btn ripple">
                            <FontAwesomeIcon icon={faCaretRight} />
                          </button>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
                {properties.length === 0 && (
                  <tr>
                    <td width="250" className="text-light20">
                      <strong>No properties added.</strong>
                    </td>
                    <td className="text-light20"></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
            <Paginator
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              onChange={(page) => setPage(page)}
            />
          </div>
        )}
      </ScrollBar>
    </div>
  );
}
