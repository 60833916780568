/* eslint-disable react-hooks/exhaustive-deps */
import {
  faDownload,
  faEnvelope,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "main/components/Paginator/Paginator";
import { getErrorMsg, showToast } from "main/shared/helpers";
import { timeConversionUTC, utcDate } from "main/shared/helpers.js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteDocument, documentList } from "services/documents";
import { NEW_DOCUMENTS, SET_CONFIRMATION_MODAL } from "store/actions";
import AddDocumentModal from "./addDocumentModal";
import EmailSender from "./EmailSender";
import { isSampleStrategyReportShown } from "main/pages/SampleStrategy/utility/companySpecific";

export default function Documents() {
  const [checkboxValue, setcheckboxValue] = useState([]);
  const [docids] = useState([]);
  const [docUrls, setDocUrls] = useState([]);
  const [docdata, setdocdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [docdisable, setdocdisable] = useState([]);
  const [page, setpage] = useState(1);
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState("");

  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showEmailSender, setShowEmailSender] = useState(false);

  const { insp_id } = useParams();
  const refreshDocs = useSelector((state) => state.docreducer.refreshDocuments);
  const dispatch = useDispatch();

  useEffect(async () => {
    const { data, error } = await documentList({
      id: +insp_id,
      page,
      type: filter,
    });
    if (!error) {
      setdocdata(data);
      data.data.forEach(() => {
        checkboxValue.push(false);
        docdisable.push(false);
      });
      setloading(false);
    } else {
      console.log(data);
    }
  }, [refreshDocs]);

  // pagination documents list show
  useEffect(async () => {
    if (!skip) {
      setloading(true);
      const { data, error } = await documentList({
        id: +insp_id,
        page,
        type: filter,
      });
      if (!error) {
        setdocdata(data);
        setloading(false);
      }
    } else {
      setSkip(false);
    }
  }, [page, filter]);

  // delete document
  const delDocument = async ({ index, id }) => {
    const updatedCheckedState = docdisable.map((item, i) =>
      i === index ? !item : item
    );
    setdocdisable(updatedCheckedState);
    const inspid = +insp_id;
    const { error } = await deleteDocument({ id, inspid });
    if (!error) {
      showToast("Document Successfully Deleted", true);
      dispatch({ type: NEW_DOCUMENTS });
      const updatedCheckedState2 = docdisable.map(
        (_, i) => i === index && false
      );
      setdocdisable(updatedCheckedState2);
    } else if (error) {
      const updatedCheckedState1 = docdisable.map(
        (_, i) => i === index && false
      );
      setdocdisable(updatedCheckedState1);
      showToast(getErrorMsg(error) || "Unable to delete document.");
      dispatch({ type: NEW_DOCUMENTS });
    }
  };

  // checkbox function
  const handleOnChange = ({ index, id, url, type }) => {
    const updatedCheckedState = checkboxValue.map((item, i) =>
      i === index ? !item : item
    );
    setcheckboxValue(updatedCheckedState);
    if (!checkboxValue[index]) {
      docids.push(id);
    } else if (checkboxValue[index]) {
      const index = docids.indexOf(id);
      docids.splice(index, 1);
    }

    setDocUrls((s) => {
      const i = s.findIndex((item) => item.id === id);
      if (i === -1) {
        return [...s, { url, type, id }];
      } else {
        const res = s.filter((item) => item.id !== id);
        return res;
      }
    });
  };

  const handleDeleteDoc = (data) => {
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        show: true,
        okText: "Delete",
        cancelText: "Cancel",
        title: "",
        message: "Are you sure you want to delete this document?",
        onOk: () => delDocument(data),
      },
    });
  };

  return (
    <div className="inspection-table main-content-area">
      <div className="d-flex justify-content-between mb-3">
        <Filters setFilter={setFilter} />
        <button
          onClick={() => setShowActivateModal(true)}
          className="btn btn-primary"
          id="edit_email_templates"
        >
          Add Document <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      {loading ? (
        // loader start
        <div className="spinner-div">
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        // loader end
        <>
          {docids && docids?.length > 0 && (
            <div className="documents-rectangle">
              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    padding: "14px 0px 3px 50px",
                  }}
                >
                  {/* document select count show start */}
                  <strong className="num-documents-selected">
                    {docids?.length} Document Selected
                  </strong>
                  {/* document select count show end */}
                  <div className="arrow-down"></div>
                </div>
                {/* email send button start */}
                <div className="col-md-6 d-flex justify-content-end pr-4 pt-2">
                  <button
                    className="next-btn"
                    style={{
                      height: "40px",
                      borderRadius: "11px",
                      minWidth: "40px",
                      background: "white",
                      border: "white",
                      outline: "none",
                      color: "#4AA2B4",
                      padding: "10px 20px",
                    }}
                    onClick={() => {
                      setShowEmailSender(true);
                    }}
                  >
                    Email
                    <FontAwesomeIcon className="ml-3" icon={faEnvelope} />
                  </button>
                </div>
                {/* email send button end */}
              </div>
            </div>
          )}
          <ScrollBar
            className="bg-white bg-table-billing-shadow table-docs-radius"
            style={{ height: "calc(100vh - 345px)" }}
          >
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th width="10px"></th>
                  <th>Type</th>
                  <th>Date/Time</th>
                  <th>Name</th>
                  <th width="40px"></th>
                </tr>
              </thead>
              <tbody>
                {docdata?.data &&
                  docdata.data.map((data, index) => {
                    const id = data.id;
                    const dname = data.name;
                    return (
                      <tr key={index + 1} id={`trans-`}>
                        <td
                          style={{ backgroundColor: "#edf6f8" }}
                          id={`trans__name`}
                        >
                          <div
                            className="custom-control custom-checkbox"
                            style={{ marginBottom: "8px" }}
                          >
                            <input
                              className="custom-control-input inspection-checkbox"
                              id={id}
                              type="checkbox"
                              checked={checkboxValue[index]}
                              onChange={() => {
                                handleOnChange({
                                  index,
                                  id,
                                  url: data.path,
                                  type: data.type,
                                });
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={id}
                            ></label>
                          </div>
                        </td>
                        {/* 
                              <div className="inspector-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`inspection-check`}
                                  // defaultChecked={value ? value.indexOf(data.id) > -1 : false}
                                  // onChange={(ev) => addChecked(ev.target.checked, data.id)}
                                />
                                <label for="inspection-check"></label>
                              </div> */}
                        <td
                          style={{ backgroundColor: "#edf6f8" }}
                          id={`trans__contact`}
                        >
                          <div>
                            <p>{data.type}</p>
                          </div>
                        </td>
                        <td
                          style={{ backgroundColor: "#edf6f8" }}
                          id={`trans__email`}
                        >
                          <div>
                            <strong>{utcDate(data.created_at)}</strong>
                            <br />
                            <strong>
                              {timeConversionUTC(data.created_at)}
                            </strong>
                            {/* <br />
                        <strong>2:30 PM</strong> */}
                          </div>
                        </td>
                        <td
                          style={{ backgroundColor: "#edf6f8" }}
                          id={`trans__phone`}
                          // className="white-bg-table-price"
                        >
                          <p>{data.name}</p>
                        </td>
                        <td
                          style={{ backgroundColor: "#edf6f8" }}
                          id={`trans__phone`}
                          // className="white-bg-table-price"
                        >
                          <div className="inspection-td-btn">
                            <a
                              href={data.path}
                              download={dname}
                              target="_blank"
                              rel="noreferrer"
                              className="document-download next-btn "
                            >
                              <FontAwesomeIcon
                                className="align-top"
                                icon={faDownload}
                              />
                            </a>
                            <button
                              onClick={() => handleDeleteDoc({ index, id })}
                              disabled={docdisable[index]}
                              className="document-download next-btn "
                            >
                              <FontAwesomeIcon
                                className="align-top"
                                icon={faTimes}
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {docdata?.data?.length === 0 ? (
                  <tr>
                    <td style={{ backgroundColor: "#edf6f8" }} colSpan="5">
                      No record found
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </ScrollBar>
          {/* document list shoe with scrollbar end */}
        </>
      )}
      {!loading && docdata.data.length !== 0 ? (
        <nav className="mt-2 mb-3">
          <ul className="pagination justify-content-center ">
            <Paginator
              activePage={docdata?.current_page}
              itemsCountPerPage={docdata?.per_page}
              totalItemsCount={docdata?.total}
              onChange={(page) => {
                setSkip(false);
                setpage(page);
              }}
            />
          </ul>
        </nav>
      ) : null}
      {/* pagination show end */}

      <AddDocumentModal
        inspect_id={insp_id}
        show={showActivateModal}
        onHide={() => setShowActivateModal(false)}
      />

      <EmailSender
        show={showEmailSender}
        onHide={() => setShowEmailSender(false)}
        documents={docUrls}
        inspection_id={insp_id}
      />
    </div>
  );
}

function Filters({ setFilter }) {
  const user = useSelector((state) => state.user);

  const filterNames = [
    {
      value: "",
      id: "all",
      label: "All",
      defaultChecked: true,
    },
    {
      value: "MAIN REPORT",
      id: "MAIN_REPORT",
      label: "Main Report",
    },
    {
      value: "LABORATORY_RESULT",
      id: "LABORATORY_RESULT",
      label: "Laboratory Result",
    },
    {
      value: "RECEIPT",
      id: "RECEIPT",
      label: "Receipt",
    },
    {
      value: "INVOICE",
      id: "INVOICE",
      label: "Invoice",
    },
    {
      value: "COC",
      id: "COC",
      label: "COC",
    },
  ];
  if (isSampleStrategyReportShown(user)) {
    filterNames.push({
      value: "SAMPLE_STRATEGY",
      id: "SAMPLE_STRATEGY",
      label: "Sample Strategy",
    });
  }
  return (
    <div className="d-flex doc-filter">
      <div className="d-flex align-items-center justify-content-center ml-2">
        {filterNames.map((f, i) => (
          <div
            key={`filter-${i}`}
            className="d-flex justify-content-center align-items-center"
          >
            <input
              type="radio"
              id={f.id}
              name="filter_type"
              value={f.value}
              className="radio-btn mx-1"
              defaultChecked={f.defaultChecked ?? false}
              onChange={(e) => setFilter(e.target.value)}
            />
            <label htmlFor={f.id} className="mb-0 mx-1 radio-label">
              {f.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
