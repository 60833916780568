/* eslint-disable react-hooks/exhaustive-deps */
import {
  faBars,
  faCaretLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  SEARCH_FIELD,
  SET_SEARCH_FILTER,
  TOGGLE_SIDENAV,
} from "../../../store/actions";
import "./style.css";

export default function Header() {
  const history = useHistory();
  const location = useLocation();
  const header = useSelector((state) => state.header);
  const showGoBack = useSelector((state) => state.app.showGoback);
  const dispatch = useDispatch();
  const toggleNavBar = () => {
    console.log("toggle");
    dispatch({ type: TOGGLE_SIDENAV });
  };
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [protocolFilter, setProtocolFilter] = useState("");

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch({ type: SEARCH_FIELD, payload: encodeURIComponent(searchText) });
    }, 500);

    return () => clearTimeout(id);
  }, [searchText]);

  useEffect(() => {
    setSearchOpen(false);
    setSearchText("");
    setProtocolFilter(
      location.pathname === "/tools/protocols" ? "protocol_name" : ""
    );
  }, [location.pathname]);

  useEffect(() => {
    dispatch({ type: SET_SEARCH_FILTER, payload: protocolFilter });
  }, [protocolFilter]);

  return (
    <div className="header">
      <div>
        {showGoBack && (
          <div
            className="go-back"
            id="go-back-button"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon className="text-secondary500" icon={faCaretLeft} />
            <span className="go-back-text">Go Back</span>
          </div>
        )}
        <div className="d-flex align-items-center">
          <img
            className="heading-icon"
            src={require(`../../../assets/icons/${header?.icon}`).default}
            alt=""
          />
          <h3 className="text-light20 header-title one-line-ellipsis">
            {header?.title}
          </h3>
        </div>
      </div>

      <div
        className="d-flex align-items-center justify-content-end"
        style={{ minWidth: "425px" }}
      >
        {location.pathname === "/tools/protocols" &&
        header.showSearch &&
        searchOpen ? (
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex justify-content-center align-items-center">
              <input
                type="radio"
                id="protocol_name"
                name="filter_type"
                value="protocol_name"
                className="radio-btn mx-1"
                defaultChecked={true}
                onChange={(e) => setProtocolFilter(e.target.value)}
              />
              <label htmlFor="protocol_name" className="mb-0 mx-1 radio-label">
                Protocols
              </label>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <input
                type="radio"
                id="room_name"
                name="filter_type"
                value="room_name"
                className="radio-btn  mx-1"
                onChange={(e) => setProtocolFilter(e.target.value)}
              />
              <label htmlFor="room_name" className="mb-0  mx-1 radio-label">
                Rooms
              </label>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <input
                type="radio"
                id="sample_type"
                name="filter_type"
                value="sample_type"
                className="radio-btn mx-1"
                onChange={(e) => setProtocolFilter(e.target.value)}
              />
              <label
                htmlFor="sample_type"
                className="mb-0 ml-1 mr-3 radio-label"
              >
                Sample Types
              </label>
            </div>
          </div>
        ) : null}
        {header.showSearch && (
          <div
            style={
              searchOpen ? { marginRight: "10px" } : { marginRight: "35px" }
            }
          >
            {location.pathname === "/tools/protocols" && searchOpen ? null : (
              <p
                className={` search-header-activate ${
                  searchOpen
                    ? "active-color"
                    : "non-active-color cursor-pointer"
                }`}
                onClick={() => !searchOpen && setSearchOpen(true)}
              >
                Search {header.searchLabel}
              </p>
            )}
            <img
              className={` search-box-icon  ${
                searchOpen ? "" : "margin-without"
              }`}
              src={
                require("../../../assets/icons/magnifying_glass_primary500@3x.png")
                  .default
              }
              alt="search icon"
              width="24px"
              height="24px"
              onClick={() => !searchOpen && setSearchOpen(true)}
            />
            {searchOpen && (
              <>
                <FontAwesomeIcon
                  className="cancel-box-icon text-light20"
                  icon={faTimes}
                  onClick={() => {
                    setSearchText("");
                    setSearchOpen(false);
                  }}
                />

                <input
                  type="text"
                  className={`new-rect ${searchOpen && "wid"}`}
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  autoFocus
                />
              </>
            )}
          </div>
        )}

        <FontAwesomeIcon
          className="open-menu-icon text-light20"
          icon={faBars}
          onClick={() => toggleNavBar()}
          id="toggle-navbar-button"
        />
      </div>
    </div>
  );
}
