/* eslint-disable react-hooks/exhaustive-deps */
import {
  faEnvelope,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ScoutLogo from "../../../../assets/logo/scout_logo.png";
import { forgetPassword } from "../../../../services/auth-service";
import { getErrorMsg } from "../../../shared/helpers";
import { regexes } from "../../../shared/regexes";
import "../style.css";

const ForgetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [disableReset, setDisableReset] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [timer, setTimer] = useState(60);

  let history = useHistory();

  const goToLogin = () => {
    history.replace("/login");
  };

  function handleSubmit(event) {
    setErrorText("");
    setShowSuccess(false);
    if (!email) {
      setErrorText("Please enter an email address.");
    } else if (!regexes.email.test(email)) {
      setErrorText("Please enter a valid email address.");
    } else {
      setErrorText("");
      setDisableReset(true);
      forgetPassword({ email })
        .then(() => {
          setShowSuccess(true);
        })
        .catch((err) => {
          setShowSuccess(false);
          setErrorText(getErrorMsg(err));
        })
        .finally(() => {
          const id = setInterval(() => setTimer((t) => t - 1), 1000);
          setTimeout(() => {
            setDisableReset(false);
            setTimer(60)
            clearInterval(id);
          }, 60001);
        });
    }
    event.preventDefault();
  }

  return (
    <div className="login-page">
      <div>
        <img alt="Scout-Logo" className="scout-logo" src={ScoutLogo} />
      </div>
      <div style={{ visibility: errorText !== "" ? "visible" : "hidden" }}>
        <div className="email-pass-error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span className="ml-4 font-bold">{errorText}</span>
        </div>
      </div>

      {showSuccess ? (
        <div>
          <div className="email-pass-success">
            <span className="ml-4 font-bold">
              A password reset link has been sent to your email address. Make
              sure to check your Spam folder as well.
            </span>
          </div>
        </div>
      ) : null}

      <div className="reset-password-text">
        Enter your email address to reset your password
      </div>

      <form onSubmit={handleSubmit}>
        <div id="float-label-auth">
          <div>
            <span className="placeholder-icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>

            <input
              autoFocus
              //placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="auth-input-field"
              maxLength="340"
              type="email"
            />
          </div>

          <label
            className={
              email.length < 1 ? "auth-input-field" : "Active auth-input-field"
            }
            htmlFor="email"
          >
            Email Address
          </label>
        </div>

        <div className="forgot-password">
          <span style={{ cursor: "pointer" }} onClick={goToLogin}>
            Back
          </span>
        </div>

        <div className="mt-3 text-align-end">
          <button
            disabled={disableReset}
            variant="primary"
            className={
              disableReset
                ? "login-btn disabled-bg-color cursor-disabled"
                : "login-btn bg-light20"
            }
            type="submit"
          >
            {showSuccess
              ? timer === 60
                ? "Resend Email"
                : "0:" + ('0' + timer).slice(-2)
              : "Reset Password"}
          </button>
        </div>

        <div className="extra-text text-center">
          <div>Please contact your administrator for access to Scout Pro</div>
          <div className="mt-3">
            By Logging in you agree to the&nbsp;
            <Link to="/terms-of-use" target="_blank" className="new-tab-link">
              terms of use
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy" target="_blank" className="new-tab-link">
              privacy policy
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
