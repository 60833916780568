import React, { useState } from "react";
import { Button } from "react-bootstrap";
import DiscountModal from "./DiscountModal/DiscountModal";
import ExpediteModal from "./ExpediteModal/ExpediteModal";
import FinancialModal from "./FinancialModal/FinancialModal";
import styles from "./addOn.module.css";

const AddOns = (props) => {
  const { expedites = [], sampleCount = [] } = props;

  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showExpediteModal, setShowExpediteModal] = useState(false);
  const [showFinancialModal, setShowFinancialModal] = useState(false);

  const toggleDiscountModal = () => {
    setShowDiscountModal(!showDiscountModal);
  };
  const toggleExpediteModal = () => {
    setShowExpediteModal(!showExpediteModal);
  };
  const toggleFinancialModal = () => {
    setShowFinancialModal(!showFinancialModal);
  };

  return (
    <>
      <div className={styles.summary_footer}>
        <div className={styles.container}>
          <Button
            onClick={toggleDiscountModal}
            className={styles.anchor_discount_btn}
            size="sm"
          >
            <img
              src={require("../../../../assets/icons/Discount.png").default}
              alt="Discount"
            />
          </Button>

          <Button
            className={styles.anchor_expedite_btn}
            size="sm"
            onClick={toggleExpediteModal}
          >
            <img
              src={require("../../../../assets/icons/Expedite.png").default}
              alt="Expedite"
            />
          </Button>
          <Button
            className={styles.anchor_financial_btn}
            size="sm"
            onClick={toggleFinancialModal}
          >
            <img
              src={
                require("../../../../assets/icons/Financial Info.png").default
              }
              alt="Financial Info"
            />
          </Button>
        </div>
      </div>
      <DiscountModal
        showDiscountModal={showDiscountModal}
        toggleDiscountModal={toggleDiscountModal}
      />
      <ExpediteModal
        expedites={expedites}
        sampleCount={sampleCount}
        showExpediteModal={showExpediteModal}
        toggleExpediteModal={toggleExpediteModal}
      />
      <FinancialModal
        showFinancialModal={showFinancialModal}
        toggleFinancialModal={toggleFinancialModal}
      />
    </>
  );
};

export default AddOns;
