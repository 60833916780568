/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretRight, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { SET_SELECTED_BU, SET_SLECTING_BU } from "store/actions";
import { getBusinessUnits } from "../../../../../services/super-admin-service";
import Paginator from "../../../../components/Paginator/Paginator";
import {
  getAddress,
  updateHeaderSearch,
  useDidUpdateEffect,
} from "../../../../shared/helpers";

const BusinessUnit = (props) => {
  let history = useHistory();
  const [units, setUnits] = useState([]);
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(false);

  const dispatch = useDispatch();
  const searchField = useSelector((state) => state.header.searchField);
  const selectingBU = useSelector((state) => state.app.selectingBU);
  const setSelectedBU = (bu) => {
    dispatch({ type: SET_SELECTED_BU, payload: bu });
  };
  useEffect(() => {
    updateHeaderSearch(dispatch, true, "Business Units");
    return () => updateHeaderSearch(dispatch, false, "");
  }, []);

  useDidUpdateEffect(() => {
    getData(1, searchField, () => {
      setSkip(true);
      setPage(1);
    });
  }, [searchField]);

  useEffect(() => {
    if (!props.parentLoading) {
      if (!skip) {
        getData(page);
      } else {
        setSkip(false);
      }
    }
  }, [page, props.parentLoading]);

  const getData = (page, search, func) => {
    setIsLoading(true);
    getBusinessUnits(page, search)
      .then((res) => {
        setUnits(res.data.data);
        setActivePage(res.data.current_page);
        setItemsCountPerPage(res.data.per_page);
        setTotalItemsCount(res.data.total);
        func && func();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="main-content-area pb-0">
      <div className="d-flex align-items-center">
        <button
          className="btn btn-primary ml-auto"
          onClick={() => {
            history.push("/business-unit/new");
          }}
        >
          Add Business Unit <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
      <ScrollBar className="mt-2" style={{ height: "calc(100vh - 265px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="">
            <table className="table table-responsive-md extra-padding pr-3">
              <thead>
                <tr>
                  <th width="120"></th>
                  <th width="30%">Name/Email/Phone</th>
                  <th width="150">Date Added</th>
                  <th>Street/City/Zip</th>
                  <th width="10"></th>
                </tr>
              </thead>
              <tbody>
                {units.map((unit, i) => (
                  <tr key={`units-${i}`}>
                    <td>
                      <img
                        width="120"
                        className="heading-icon"
                        src={
                          unit.logo_url ||
                          require(`../../../../../assets/default-logo.png`)
                            .default
                        }
                        alt="company logo"
                        style={{
                          height: "50px",
                          width: "100px",
                          objectFit: "contain",
                          padding: "0px 5px",
                        }}
                      />
                    </td>
                    <td>
                      <h3>{unit.company_name}</h3>
                      <p>{unit.company_email}</p>
                      <p>{new AsYouType("US").input(unit.company_phone)}</p>
                    </td>
                    <td>
                      <p>
                        {unit?.created_at.split(" ")[0].replaceAll("-", "/")}
                      </p>
                    </td>
                    <td>
                      <p className="two-line-ellipsis">
                        {getAddress(unit.address)}
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          width: "32px",
                          cursor: "pointer",
                        }}
                      >
                        <button
                          className="next-btn"
                          onClick={() => setSelectedBU(unit.id)}
                          disabled={selectingBU}
                          data-tip="View as Business Unit"
                        >
                          <FontAwesomeIcon
                            style={{
                              fontSize: "20px",
                            }}
                            icon={faEye}
                          />
                        </button>
                      </div>
                    </td>
                    <td>
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "32px",
                          cursor: "pointer",
                        }}
                        to={"/business-unit/" + unit.id}
                      >
                        <button className="next-btn">
                          <FontAwesomeIcon icon={faCaretRight} />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </ScrollBar>
      <Paginator
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        onChange={(page) => setPage(page)}
      />
    </div>
  );
};

export default BusinessUnit;
