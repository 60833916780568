import axios from "../axios-defaults";

class Comment {
  createComment(data) {
    return axios.post("/api/v1/comments", data);
  }

  getComments(q = "", page) {
    return axios.get(`/api/v1/comments?page=${page}&q=${q}`);
  }

  updateComment(id, data) {
    Object.assign(data, { _method: "patch" });
    return axios.post(`/api/v1/comments/${id}`, data);
  }

  DeleteComment(id) {
    return axios.delete(`/api/v1/comments/${id}`);
  }
}

const obj = new Comment();

export default obj;
