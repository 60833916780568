import axios from "../axios-defaults";

class Settings {
  buyLicenses(data) {
    return axios.post("/api/v1/stripe/invoice/charge", data);
  }

  getSubscriptionPrices() {
    return axios.get(`/api/v1/stripe/products`);
  }

  getRemainingCredits() {
    return axios.get(`/api/v1/account?additional-info=credits`);
  }

  getBUTransactions(page) {
    return axios.get(`/api/v1/bu/transactions?page=${page}`);
  }

  updateBUCompanyDetails(data = {}) {
    return axios.post(`/api/v1/account`, {
      _method: "patch",
      ...data,
    });
  }

  //   DeleteComment(id) {
  //     return axios.delete(`/api/v1/comments/${id}`);
  //   }
}

const obj = new Settings();

export default obj;
