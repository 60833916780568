/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import { Tabs } from "react-bootstrap";
import BillingDetails from "./SettingTabs/BillingDetails/BillingDetails";
import "./style.css";
import CompanyDetails from "./SettingTabs/CompanyDetails";
import EmailTemplates from "./SettingTabs/EmailTemplates";
import ManageUsers from "./Users/ManageUsers";
import ScrollBar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { authRoles } from "../../../navigation/route-config";
import { useQuery } from "../Auth/ResetPassword/ResetPassword";

const Settings = () => {
  let history = useHistory();
  let query = useQuery();
  const [key, setKey] = useState("manageUser");
  const role = useSelector((state) => state.user.data.role);

  useEffect(() => {
    setKey(query.get("tab") || "manageUser");
  }, [query]);

  return (
    <div className="main">
      <Tabs
        className="setting-tab-btns"
        defaultActiveKey="manageUser"
        activeKey={key}
        onSelect={(k) => {
          history.push("/settings?tab=" + k);
        }}
      >
        <Tab eventKey="manageUser" title="Manage User">
          <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
            {key === "manageUser" && <ManageUsers />}
          </ScrollBar>
        </Tab>
        <Tab eventKey="companyDetails" title="Company Details">
          <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
            {key === "companyDetails" && <CompanyDetails />}
          </ScrollBar>
        </Tab>
        <Tab eventKey="emailTemplates" title="Email Templates">
          <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
            {key === "emailTemplates" && <EmailTemplates />}
          </ScrollBar>
        </Tab>
        {role !== authRoles.ultraAdmin && (
          <Tab eventKey="billingDetails" title="Billing Details">
            <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
              {key === "billingDetails" && <BillingDetails />}
            </ScrollBar>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default Settings;
