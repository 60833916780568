import React from "react";

export default function TestRow({ receipt }) {
  return (
    <tr style={{ borderRadius: "10px", height: "72px" }} className="shadow">
      {/* <td id={`test__completed`} style={{ width: "50px" }}>
        <input
          style={{ width: "25px", height: "25px", verticalAlign: "middle" }}
          type="checkbox"
          defaultChecked={true}
          disabled
        />
      </td> */}
      <td>
        <div className="d-flex flex-column">
          <span
            className="text-light20"
            style={{ fontSize: "18px", fontWeight: "600" }}
          >
            {receipt.room_name} / {receipt.location}
          </span>
          <span className="text-light20 row-sub-text">
            {receipt.test_name} / {receipt.test_sample_name}
          </span>
        </div>
      </td>
      {/* <td style={{ width: "125px" }}>
        <span
          className="text-secondary500 font-weight-bold"
          style={{ cursor: "pointer" }}
        >
          View Test
        </span>
      </td> */}
      <td style={{ width: "150px" }}>
        <div className="float-label-extra" style={{ width: "150px" }}>
          <div>
            <input
              className="auth-input-field-extra icon br-primary500"
              style={{
                border: "1px solid #74b44a !important",
                color: "#2f481e",
                fontWeight: "600",
                fontSize: "16px",
              }}
              type="text"
              value={receipt.cost}
              disabled
            />
            <i
              style={{
                color: "#2f481e",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              $
            </i>
          </div>
          <label className="Active auth-input-field-extra">Test Cost</label>
        </div>
      </td>
    </tr>
  );
}
