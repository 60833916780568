import {
  faCalendarWeek,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import ReactTooltip from "react-tooltip";
import "./style.css";

export default function DateRangeFilter({ dateRange, setDateRange }) {
  const [dateFilterClass, setDateFilterClass] = useState(
    "filter-container-hidden"
  );

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setDateFilterClass("filter-container-hidden");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => document.removeEventListener("keydown", escFunction, false);
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dateFilterClass]);

  return (
    <div className="position-relative ml-3">
      <div className="d-flex align-items-center">
        <button
          id="add-lab-button"
          onClick={() =>
            setDateFilterClass((s) =>
              s === "filter-container-visible"
                ? "filter-container-hidden"
                : "filter-container-visible"
            )
          }
          className="btn btn-primary ml-auto"
        >
          Date Range <FontAwesomeIcon icon={faCalendarWeek} />
        </button>
        {dateRange.startDate !== null && (
          <FontAwesomeIcon
            className="clear-filter"
            icon={faTimes}
            data-tip="clear"
            onClick={() =>
              setDateRange({
                startDate: null,
                endDate: null,
                key: "selection",
              })
            }
          />
        )}
      </div>
      <div className={dateFilterClass}>
        <FontAwesomeIcon
          className="close-filter"
          icon={faTimesCircle}
          onClick={() => setDateFilterClass("filter-container-hidden")}
        />
        <DateRangePicker
          rangeColors={["#74b44a"]}
          className="shadow"
          ranges={[dateRange]}
          onChange={(e) => {
            setDateRange((s) => ({ ...s, ...e.selection }));
          }}
        />
      </div>
    </div>
  );
}
