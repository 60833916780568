/* eslint-disable react-hooks/exhaustive-deps */
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "main/components/Custom/CustomInput";
import DollarInput from "main/components/DollarInput/DollarInput";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import ExtraFeesService from "../../../services/ExtraFeesService";
import { showToast } from "../../shared/helpers";
import { regexes } from "../../shared/regexes";
import "./extraFeeStructure.css";

function ExtraFeeStructure() {
  const [extraFee, setextraFee] = useState([]);
  const [loader, setloader] = useState(true);
  const [errors, setErrors] = useState();
  const [editEnabled, setEditEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    getFees();
  }, []);

  const getFees = () => {
    setloader(true);
    ExtraFeesService.getExtraFees()
      .then((x) => {
        setextraFee(x.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
  };

  const edit = () => {
    let updatedData = { name: {}, amount: {} };
    extraFee.forEach((d) => {
      if (d.name === "") {
      } else {
        updatedData.name[d.id] = d.name;
        updatedData.amount[d.id] = d.amount
          .replace("$", "")
          .replaceAll(",", "");
      }
    });
    // _.isEmpty(objectToCheck);
    if (Object.entries(updatedData.name).length === extraFee.length) {
      setIsSaving(true);
      ExtraFeesService.updateExtraFees(updatedData)
        .then((res) => {
          if (res.status === 200) {
            showToast("Fee has been updated", true);
            setEditEnabled(false);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            setErrors(err?.response?.data);
          } else {
            showToast("Something went wrong");
          }
          console.log(err);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };
  return (
    <>
      {loader ? (
        <Spinner
          animation="border"
          variant="success"
          className="app-spinner"
          style={{ left: "10%" }}
        />
      ) : (
        <div className="main">
          <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
            <div className="main-content-area">
              <div className="d-flex justify-content-end">
                {!editEnabled ? (
                  <button
                    className="btn btn-primary"
                    disabled={false}
                    onClick={() => setEditEnabled(true)}
                    id="edit_fee"
                  >
                    Edit Extra Fee Structure{" "}
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                ) : (
                  <div className="d-flex">
                    <button
                      className="btn btn-primary"
                      disabled={isSaving}
                      onClick={() => edit()}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-light ml-2 low-priority"
                      onClick={() => {
                        setEditEnabled(false);
                        getFees();
                      }}
                      disabled={isSaving}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>

              <p className="TabsLabel">Your Extra Fees</p>

              {extraFee.map((d, i) => {
                return (
                  <div key={d.id} className="row mb-3">
                    <div className="col-md-7">
                      <CustomInput
                        label="Fee Name"
                        id={`fee_name_${i}`}
                        value={d.name}
                        onChange={(e) => {
                          extraFee[i].name = e.target.value;
                          setextraFee([...extraFee]);
                        }}
                        inputProps={{ maxLength: "50" }}
                      />
                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled && d.name === ""
                          ? "Please Enter a Fee Name."
                          : ""
                          ? errors?.[`name.${d.id}`]
                          : errors?.[`name.${d.id}`]}
                      </small>
                    </div>
                    <div className="col-md-4">
                      <DollarInput
                        id={`fee_cost_+${i}`}
                        label="Customer Cost"
                        type="text"
                        className="form-control"
                        value={d.amount}
                        onChange={(e) => {
                          extraFee[i].amount = e.target.value;
                          setextraFee([...extraFee]);
                        }}
                        disabled={!editEnabled}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollBar>
        </div>
      )}
    </>
  );
}

export default ExtraFeeStructure;
