/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCustomers } from "../../../services/CustomerService";
import Paginator from "../../components/Paginator/Paginator";
import { truncate, useDidUpdateEffect } from "../../shared/helpers";
import "./style.css";

const CustomerListComponent = (props) => {
  const { customer } = props;
  return customer?.data && customer.data.length !== 0 ? (
    customer.data.map((item, i) => (
      <tr key={`customer-${i}`}>
        <td className="text-light20">
          <strong className="keep-all">{item.created_at.split(" ")[0].replaceAll("-", "/")}</strong>
        </td>
        <td className="text-light20">
          <strong>{item.first_name + " " + item.last_name}</strong>
        </td>
        <td className="text-secondary500">
          <strong className="keep-all">{item.email}</strong>
        </td>
        <td className="text-secondary500">
          <strong className="keep-all">
            {truncate(new AsYouType("US").input(item.phone_number))}
          </strong>
        </td>
        <td>
          <div>
            <Link
              style={{
                textDecoration: "none",
                width: "32px",
                cursor: "pointer",
              }}
              to={"/customers/" + item.id}
            >
              <button className="next-btn ripple">
                <FontAwesomeIcon icon={faCaretRight} />
              </button>
            </Link>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="4">No record found</td>
    </tr>
  );
};

const Customer = () => {
  const [loader, setloader] = useState(true);
  const [customerList, setcustomerList] = useState({});
  const [page, setpage] = useState(1);

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);

  useDidUpdateEffect(() => {
    setSkip(true);
    setloader(true);
    getCustomers(searchField, page)
      .then((x) => {
        setcustomerList(x.data);
        setpage(1);
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
  }, [searchField]);

  useEffect(() => {
    if (!skip) {
      setloader(true);
      getCustomers(searchField, page)
        .then((x) => setcustomerList(x.data))
        .catch((err) => console.log(err))
        .finally(() => setloader(false));
    } else {
      setSkip(false);
    }
  }, [page]);

  return (
    <>
      {loader ? (
        <Spinner
          animation="border"
          variant="success"
          className="app-spinner"
          style={{ left: "10%" }}
        />
      ) : (
        <div className="main">
          <div className="main-content-area">
            <div className="d-flex align-items-center">
              <Link
                className="ml-auto"
                style={{ textDecoration: "none" }}
                to="/customers/new"
                id="add_customer"
              >
                <button id="add-user-button" className="btn btn-primary">
                  Add Customer <FontAwesomeIcon icon={faPlus} />
                </button>
              </Link>
            </div>
            <ScrollBar className="mt-2">
              <div className="fixHeight">
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th>Date Added</th>
                      <th>Customer</th>
                      <th>Email</th>
                      <th>Phone number</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <CustomerListComponent customer={customerList} />
                  </tbody>
                </table>
              </div>
            </ScrollBar>

            <nav className="mt-4 mb-3">
              <ul className="pagination justify-content-center mb-0 paginator">
                <Paginator
                  activePage={customerList?.current_page}
                  itemsCountPerPage={customerList?.per_page}
                  totalItemsCount={customerList?.total}
                  onChange={(page) => {
                    setSkip(true);
                    setpage(page);
                  }}
                />
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Customer;
