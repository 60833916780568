import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { isAuthenticated } from "../main/shared/helpers";

const RouteCustom = ({ isProtected, exact, path, children }) => {
  const logout = useSelector((state) => state.app.logout);
  useEffect(() => {
    if (logout) {
      return <Redirect to="/login" />;
    }
  }, [logout]);

  if (isProtected === "both") {
    return (
      <Route exact={exact} path={path}>
        {children}
      </Route>
    );
  }
  if (!isAuthenticated() && isProtected === true) {
    console.log("redirect to login");
    return <Redirect to="/login" />;
  }

  if (isAuthenticated() && isProtected === false) {
    console.log("redirect to root");
    return <Redirect to="" />;
  }

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  );
};

export default RouteCustom;
