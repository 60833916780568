import { faArrowCircleUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, ListGroup, Modal, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { deleteInspectionbyId } from "../../../../services/InspectionService";
import Delete from "../../../components/SubItemDeleteModal";
import { captalizeFirst, showToast } from "../../../shared/helpers";

const ViewInspectorsModal = (props) => {
  const {
    items,
    title,
    buttonLabel,
    onSave,
    onHideview,
    show,
    loader,
    emptyText,
    isAvatar,
    defaultAvatar,
    inspection_id,
    openMakeLead
  } = props;

  const [opendel, setopendel] = useState(false);
  const [loader2, setloader2] = useState();
  const [delid, setdelid] = useState(0);
  const [dims, setdims] = useState(false);
  useEffect(() => {
    setloader2(loader);
    return () => {
      setloader2(false);
    };
  }, [loader]);
  const Hide = () => {
    onHideview();
  };

  const onBtnClick = () => {
    Hide();
  };
  const [index, setindex] = useState(0);

  const RemoveSample = () => {
    setdims(true);
    console.log(loader2);
    console.log(index, delid, "data");
    let data_del = {
      inspectors_delete: [delid],
    };
    console.log(data_del, "del");
    deleteInspectionbyId(inspection_id, data_del)
      .then(() => {
        setopendel(false);

        setloader2(true);
        onSave();
        showToast("Inspector deleted successfully.", true);
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 422) {
          showToast(
            captalizeFirst(err?.response?.data["inspectors_delete.0"][0])
          );
        } else {
          showToast("Something went wrong.");
        }
      })
      .finally(() => {
        setloader2(false);
        setdims(false);
      });
    console.log(loader2);
  };
  const OpenDelete = (id, parentindex) => {
    setdelid(parentindex);
    setindex(id);
    setopendel(true);
  };
  return (
    <Modal
      show={show}
      onHide={Hide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <h5 style={{ fontWeight: "bolder" }}>{title}</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={Hide}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`modalBodyStyles ${loader2 ? "list-spinner" : ""}`}
      >
        {loader2 ? (
          <Spinner animation="border" variant="success" />
        ) : (
          <>
            {items?.length < 1 ? (
              <Alert variant="danger">{emptyText}</Alert>
            ) : (
              <PerfectScrollbar>
                <ListGroup>
                  {items?.map((data, i) => (
                    <ListGroup.Item
                      key={i}
                      className={`listItems listStyle ${
                        i % 2 === 0 && "check-active"
                      }`}
                    >
                      <div
                        className="myTest d-flex custom-control custom-checkbox"
                        style={{ paddingLeft: "0px" }}
                      >
                        <>
                          <span className="text-trunk-prop">
                            {isAvatar ? null : <span>{data?.name}</span>}
                            {isAvatar ? (
                              <>
                                <img
                                  src={data.image ? data.image : defaultAvatar}
                                  alt="Avatar"
                                  className="avatar"
                                  onError={(ev) =>
                                    (ev.target.src = defaultAvatar)
                                  }
                                />
                                <span className="span-style-none">
                                  {" "}
                                  {data?.name}
                                </span>
                              </>
                            ) : null}
                          </span>
                          {i !== 0 && (
                            <>
                              <button
                                data-tip="Make lead inspector"
                                className="btn btn-primary inspection-plus-btn"
                                style={{
                                  height: "40px",
                                  minWidth: "40px",
                                  background: "transparent",
                                  border: "transparent",
                                  marginLeft: "auto",
                                }}
                                onClick={() =>
                                  openMakeLead(
                                    data?.name,
                                    data.id
                                  )
                                }
                                disabled={false}
                              >
                                <FontAwesomeIcon
                                  icon={faArrowCircleUp}
                                  style={{ color: "#84857A" }}
                                />
                              </button>
                              <button
                                className="btn btn-primary inspection-plus-btn d-flex justify-content-end"
                                style={{
                                  height: "40px",
                                  minWidth: "40px",
                                  background: "transparent",
                                  border: "transparent",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  OpenDelete(inspection_id, data.id);
                                  console.log("object");
                                }}
                                disabled={false}
                                id="plus_inspection_onsite_del"
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  style={{ color: "#84857A" }}
                                />
                              </button>
                              <Delete
                                show={opendel}
                                onHideDelete={() => setopendel(false)}
                                msg={
                                  "Are you sure you want to delete this inspector?"
                                }
                                deletestate={dims}
                                delfunc={RemoveSample}
                              />
                            </>
                          )}
                        </>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </PerfectScrollbar>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          onClick={() => onBtnClick()}
          style={{ marginRight: 25 }}
          disabled={loader2 || items?.length < 1}
        >
          {items?.length === 0 ? "Close" : buttonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewInspectorsModal;
