/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { authRoles } from "../../../../../navigation/route-config";
import { createBusinessUnit } from "../../../../../services/super-admin-service";
import { getErrorMsg, showToast } from "../../../../shared/helpers";
import { regexes } from "../../../../shared/regexes";
import { AsYouType } from "libphonenumber-js";
import ScrollBar from "react-perfect-scrollbar";

const AddBusinessUnit = () => {
  const [company_name, setCompany_name] = useState("");
  const [company_phone, setCompany_phone] = useState("");
  const [company_email, setCompany_email] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [company_name_e, setCompany_name_e] = useState("");
  const [company_phone_e, setCompany_phone_e] = useState("");
  const [company_email_e, setCompany_email_e] = useState("");
  const [city_e, setCity_e] = useState("");
  const [state_e, setState_e] = useState("");
  const [street1_e, setStreet1_e] = useState("");
  const [zipcode_e, setZipcode_e] = useState("");

  const [disableSave, setDisableSave] = useState(false);

  const handleSave = () => {
    setDisableSave(true);
    if (validateForm()) {
      createBusinessUnit({
        type: authRoles.buAdmin,
        company_name,
        company_phone,
        company_email,
        city,
        state,
        street1,
        street2,
        zipcode,
      })
        .then((res) => {
          console.log(res);
          clearForm();
          showToast("An email to the customer has been sent.", true);
        })
        .catch((err) => {
          console.log(err);
          showToast(getErrorMsg(err));
        })
        .finally(() => {
          setDisableSave(false);
        });
    } else {
      setDisableSave(false);
    }
  };

  const clearForm = () => {
    setCompany_name("");
    setCompany_phone("");
    setCompany_email("");
    setCity("");
    setState("");
    setStreet1("");
    setStreet2("");
    setZipcode("");
  };

  const validateForm = () => {
    let isValid = true;
    if (company_name === "") {
      setCompany_name_e("Company name cannot be empty");
      isValid = false;
    } else {
      setCompany_name_e("");
    }
    if (company_email === "") {
      setCompany_email_e("Company email cannot cannot be empty");
      isValid = false;
    } else if (company_email !== "" && !regexes.email.test(company_email)) {
      setCompany_email_e("Please enter a valid email address");
      isValid = false;
    } else {
      setCompany_email_e("");
    }
    if (company_phone === "") {
      setCompany_phone_e("Company phone cannot be empty");
      isValid = false;
    } else if (company_phone !== "" && !regexes.phone.test(company_phone)) {
      setCompany_phone_e("Please enter a valid phone number");
      isValid = false;
    } else {
      setCompany_phone_e("");
    }
    if (city === "") {
      setCity_e("City cannot be empty");
      isValid = false;
    } else {
      setCity_e("");
    }
    if (state === "") {
      setState_e("State cannot be empty");
      isValid = false;
    } else {
      setState_e("");
    }
    if (street1 === "") {
      setStreet1_e("Street 1 cannot be empty");
      isValid = false;
    } else {
      setStreet1_e("");
    }
    if (zipcode === "") {
      setZipcode_e("Zipcode cannot be empty");
      isValid = false;
    } else {
      setZipcode_e("");
    }
    return isValid;
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        <div className="d-flex align-items-center pb-0">
          <div className="main-content-area pb-0">
            <div className="d-flex align-items-center">
              <button
                className={`btn btn-primary ml-auto ${
                  disableSave ? "disabled-bg-color" : ""
                }`}
                onClick={() => handleSave()}
                disabled={disableSave}
              >
                Save
              </button>
            </div>
            <form>
              <div className="row">
                <div className="col-md-8">
                  <h4>Company Details</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          className="form-control capital-case"
                          placeholder="Company Name"
                          maxLength="100"
                          value={company_name}
                          onChange={(e) => setCompany_name(e.target.value)}
                        />
                        <small className="text-danger ml-1">
                          {company_name_e}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Company Phone Number"
                          type="tel"
                          maxLength="20"
                          value={new AsYouType("US").input(company_phone)}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(regexes.phoneInput, "");
                            setCompany_phone(value);
                          }}
                        />
                        <small className="text-danger ml-1">
                          {company_phone_e}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Company Email Address"
                          type="email"
                          maxLength="50"
                          value={company_email}
                          onChange={(e) => setCompany_email(e.target.value)}
                        />
                        <small className="text-danger ml-1">
                          {company_email_e}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-0" />
              <div className="row">
                <div className="col-md-10">
                  <h4>Company Address</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Street 1"
                          maxLength="100"
                          value={street1}
                          onChange={(e) => setStreet1(e.target.value)}
                        />
                        <small className="text-danger ml-1">{street1_e}</small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Street 2 (optional)"
                          maxLength="100"
                          value={street2}
                          onChange={(e) => setStreet2(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="City"
                          maxLength="50"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <small className="text-danger ml-1">{city_e}</small>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="State"
                          maxLength="50"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                        <small className="text-danger ml-1">{state_e}</small>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Zip Code"
                          maxLength="50"
                          value={zipcode}
                          onChange={(e) => setZipcode(e.target.value)}
                        />
                        <small className="text-danger ml-1">{zipcode_e}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ScrollBar>
    </div>
  );
};

export default AddBusinessUnit;
