/* eslint-disable react-hooks/exhaustive-deps */
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import CommentService from "../../../services/CommentService";
import Paginator from "../../components/Paginator/Paginator";
import {
  getErrorMsg,
  showToast,
  useDidUpdateEffect,
} from "../../shared/helpers";
import "./comments.css";

export function Delete(props) {
  const { msg, delfunc } = props;

  const [dis, setdis] = useState(false);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <img
                className="iconstyle"
                src={
                  require("../../../assets/icons/attention_light20@3x.png")
                    .default
                }
                alt="icon"
              />
              <h5 style={{ fontWeight: "bolder" }}>Warning</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={!dis && props.onHide}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <span>{msg}</span>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          disabled={dis}
          variant="light"
          onClick={() => delfunc(setdis)}
          style={{
            color: "#962424",
            background: "transparent",
            border: "none",
          }}
          id="delete"
        >
          Delete
        </Button>
        <Button
          disabled={dis}
          style={{ marginRight: 25 }}
          onClick={props.onHide}
          id="dont_delete"
        >
          Don't Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function AddComments(props) {
  const [payload, setpayload] = useState({ name: "", remarks: "" });

  const [dis, setdis] = useState(false);

  const add = () => {
    setdis(true);
    CommentService.createComment(payload)
      .then(() => {
        props.update();
        props.onHide();
        showToast("Comment created Successfully", true);
        setdis(false);
        setpayload({ name: "", remarks: "" });
      })
      .catch((err) => {
        console.log(err);
        setdis(false);
        showToast(getErrorMsg(err) || "Something Went Wrong");
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <img
                className="iconstyle"
                src={
                  require("../../../assets/icons/toolkit_light20@3x.png")
                    .default
                }
                alt="icon"
              />
              <h5 style={{ fontWeight: "bolder" }}>Add Comment</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={props.onHide}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <div>
            <h4>Comment Name</h4>
            <input
              className="form-control input-field"
              placeholder="Comment Name"
              type="text"
              id="comment_name"
              maxLength="100"
              onChange={(ev) =>
                setpayload({ ...payload, name: ev.target.value })
              }
            />
          </div>
          <div className="dialogtextarea">
            <h4>Additional Remarks</h4>
            <textarea
              id="additional_remarks"
              className="form-control input-field"
              placeholder="Additional Remarks"
              type="text"
              maxLength="5000"
              onChange={(ev) =>
                setpayload({ ...payload, remarks: ev.target.value })
              }
            />
          </div>
          <div className="text-right">
            <small>
              {payload.remarks?.length ?? 0}/5000
            </small>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          disabled={dis || !payload.name}
          style={{ marginRight: 25 }}
          onClick={add}
          id="save_comment"
        >
          Save Comment
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Comments() {
  const commentList = useRef(null);

  const [modalShow, setModalShow] = React.useState(false);
  const [delmodalShow, setdelModalShow] = React.useState(false);

  const [comments, setcomments] = useState({});

  const [page, setpage] = useState(1);

  const [singleComment, setsingleComment] = useState({
    name: "",
    remarks: "",
    id: 0,
  });

  const [loader, setloader] = useState(true);
  const [active, setactive] = useState(0);
  const [update, setupdate] = useState(0);
  const [disedit, setdisedit] = useState(false);

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);

  useDidUpdateEffect(() => {
    setSkip(true);
    setloader(true);
    CommentService.getComments(searchField, 1)
      .then((x) => {
        setcomments(x.data);
        setpage(1);
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
  }, [searchField]);

  useEffect(() => {
    if (!skip) {
      setloader(true);
      CommentService.getComments("", page)
        .then((x) => {
          setcomments(x.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setloader(false));
    } else {
      setSkip(false);
    }
  }, [update, page]);

  const setactiv = (index) => {
    setsingleComment({
      ...singleComment,
      name: comments.data[index].name,
      id: comments.data[index].id,
      remarks: comments.data[index].remarks,
    });
    setactive(index);
  };

  useEffect(() => {
    if (comments?.data && comments?.data?.length > 0) {
      if (active > comments?.data?.length - 1 || update === 0) {
        setactiv(0);
      } else {
        setactiv(active);
      }
    } else {
      setsingleComment({
        name: "",
        remarks: "",
        id: 0,
      });
    }
    if (commentList?.current) {
      commentList.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [comments]);

  const edit = () => {
    setdisedit(true);
    CommentService.updateComment(singleComment.id, {
      name: singleComment.name,
      remarks: singleComment.remarks,
    })
      .then(() => {
        setupdate(update + 1);
        showToast("Comment Editted Successfully", true);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Something went wrong");
      })
      .finally(() => setdisedit(false));
  };

  const delcomm = (setdisdel) => {
    setdisdel(true);
    CommentService.DeleteComment(singleComment.id)
      .then(() => {
        setupdate(update + 1);
        showToast("Comment Deleted Successfully", true);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Something went wrong");
      })
      .finally(() => {
        setdisdel(false);
        setdelModalShow(false);
      });
  };

  return (
    <div className="mainbody">
      <div className="leftside">
        <div className="leftside-header">
          <h5 className="mainheading">Comments</h5>
          <button
            onClick={() => setModalShow(true)}
            className="btn btn-primary ml-auto"
            id="add_comment"
          >
            Add Comment <FontAwesomeIcon icon={faPlus} />
          </button>
          <AddComments
            show={modalShow}
            onHide={() => setModalShow(false)}
            update={() => setupdate(update + 1)}
          />
          <Delete
            show={delmodalShow}
            onHide={() => setdelModalShow(false)}
            msg={
              "“Are you sure you want to delete this comment?” Deleting will also delete the comment from associated inspections and reports"
            }
            delfunc={delcomm}
          />
        </div>
        <div>
          <ScrollBar>
            <ul
              className={loader ? "ul-style list-spinner" : "ul-style"}
              ref={commentList}
            >
              {loader ? (
                <Spinner animation="border" variant="success" />
              ) : (
                comments?.data?.map((item, i) => (
                  <li
                    key={`comment-${i}`}
                    className={`liststyle${i === active ? " listActive" : ""}`}
                    onClick={() => setactiv(i)}
                  >
                    {item.name}
                  </li>
                ))
              )}
            </ul>
          </ScrollBar>
        </div>
        <nav>
          <ul className="pagination justify-content-center mb-0">
            <Paginator
              activePage={comments?.current_page}
              itemsCountPerPage={comments?.per_page}
              totalItemsCount={comments?.total}
              onChange={(page) => {
                setSkip(false);
                setpage(page);
              }}
            />
          </ul>
        </nav>
      </div>
      <ScrollBar
        style={{
          height: "calc(100vh - 90px)",
          overflowY: "auto",
          width: "100%",
          background: "#e7ece8",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <div className="rightside" style={{ width: "100%" }}>
          <div className="rightsideinner">
            <div className="float-label">
              <div>
                <input
                  className="input-field form-control"
                  type="text"
                  maxLength="100"
                  id="comment_name"
                  value={singleComment.name}
                  disabled={loader || comments?.data?.length < 1}
                  onChange={(ev) => {
                    setsingleComment({
                      ...singleComment,
                      name: ev.target.value,
                    });
                  }}
                />
              </div>
              <label className="input-field Active" htmlFor="commname">
                Comment Name
              </label>
            </div>
            <div className="float-label" style={{ marginTop: "2%" }}>
              <div>
                <textarea
                  className="input-field form-control"
                  type="text"
                  maxLength="5000"
                  style={{ minHeight: "250px" }}
                  id="remarks"
                  disabled={loader || comments?.data?.length < 1}
                  value={singleComment.remarks ?? ""}
                  onChange={(ev) => {
                    setsingleComment({
                      ...singleComment,
                      remarks: ev.target.value,
                    });
                  }}
                />
              </div>
              <label className="Active input-field" htmlFor="remarks">
                Additional Remarks
              </label>
              <small className="text-right">
                {singleComment.remarks?.length ?? 0}/5000
              </small>
            </div>
            <div className="rightsideactions">
              <button
                className="btn btn-primary delbtn"
                onClick={() => setdelModalShow(true)}
                disabled={loader || comments?.data?.length < 1}
                id="delete_comment"
              >
                Delete Comment
              </button>
              <button
                className="btn btn-primary"
                disabled={
                  disedit ||
                  !singleComment.name ||
                  loader ||
                  comments?.data?.length < 1
                }
                onClick={edit}
                id="edit_comment"
              >
                Edit Comment <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  );
}

export default Comments;
