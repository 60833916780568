/* eslint-disable react-hooks/exhaustive-deps */
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditProtocolEditor from "main/components/Editor/EditProtocolEditor";
import Paginator from "main/components/Paginator/Paginator";
import React, { Suspense, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import {
  deleteProtocol,
  getProtocolDetails,
  getProtocols,
  updateProtocol,
} from "../../../services/ProtocolService";
import Chip from "../../components/Chip";
import ReportTemplateEditor from "../../components/Editor/ReportTemplateEditor";
import { showToast, useDidUpdateEffect } from "../../shared/helpers";
import EditProtocolTitle from "./EditProtocolTitle";
import "./protocols.css";

const EditSampleModal = React.lazy(() => import("./EditSampleModal"));
const AddProtocolModal = React.lazy(() => import("./AddProtocolModal"));
const EditRoomModal = React.lazy(() => import("./EditRoomModal"));

function Protocols() {
  const [modalShow, setModalShow] = React.useState(false);
  const [editRoomsModalShow, setEditRoomsModalShow] = useState(false);
  const [editSampleModalShow, setEditSampleModalShow] = useState(false);
  const [page, setpage] = useState(1);
  const [loader, setloader] = useState(true);
  const [disableEditProtocolText] = useState(true);
  const [protocolText, setProtocolText] = useState("");
  const [selectedProtocol, setSelectedProtocol] = useState("");
  const [protocolList, setProtocolList] = useState([]);

  const [roomChips, setRoomChips] = useState([]);
  const [sampleChips, setSampleChips] = useState([]);

  const [defaultRooms, setDefaultRooms] = useState(new Set());
  const [defaultSamples, setDefaultSamples] = useState(new Set());
  const [protocolId, setProtocolId] = useState(null);

  const [loader2, setLoader2] = useState(true);

  const [updateDetails, setUpdateDetails] = useState(false);
  const [active, setActive] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [noData, setNoData] = useState(false);

  const [showTitleEditModal, setShowTitleEditModal] = useState(false);
  const [editTitleData, setEditTitleData] = useState("");
  const [editProtocolId, setEditProtocolId] = useState(null);
  const [refreshList, setRefreshList] = useState(0);

  const searchField = useSelector((state) => state.header.searchField);
  const searchFilter = useSelector((state) => state.header.searchFilter);
  const [skip, setSkip] = useState(false);

  useDidUpdateEffect(() => {
    getProtocolList(1, searchField ? searchFilter : "", searchField, () => {
      setSkip(true);
      setpage(1);
    });
  }, [searchField, searchFilter]);

  useEffect(() => {
    if (!skip) {
      getProtocolList(page, searchFilter, searchField);
    } else {
      setSkip(false);
    }
  }, [page]);

  useEffect(() => {
    console.log("refreshList", refreshList);
    if (refreshList > 0) {
      let lastActive = active;
      getProtocolList(page, searchFilter, searchField, () => {
        setActive(lastActive);
      });
    }
  }, [refreshList]);

  const getProtocolList = (page, searchFilter, searchField, func) => {
    setloader(true);
    getProtocols(page, searchField ? searchFilter : "", searchField)
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          setProtocolList(res.data.data);
          setActivePage(res.data.current_page);
          setItemsCountPerPage(res.data.per_page);
          setTotalItemsCount(res.data.total);
          getDetails(res.data.data[0].id);
          setNoData(false);
          setActive(0);
        } else {
          setProtocolList([]);
          setActivePage(res.data.current_page);
          setItemsCountPerPage(res.data.per_page);
          setTotalItemsCount(res.data.total);
          setNoData(true);
          setLoader2(false);
        }
        func && func();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  };

  const getDetails = (id) => {
    setLoader2(true);
    getProtocolDetails(id)
      .then((res) => {
        const protocol = res.data;
        console.log(protocol);
        setProtocolText(protocol.protocol);
        setRoomChips(protocol.room);
        const samples = protocol.test_sample.map((item) => ({
          id: item.id,
          name: item.name + " - " + item.test.name,
        }));
        setSampleChips(samples);
        setDefaultRooms(new Set(protocol.room.map((i) => i.id)));
        setDefaultSamples(new Set(protocol.test_sample.map((i) => i.id)));
        setProtocolId(protocol.id);
        setSelectedProtocol(protocol.name);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader2(false));
  };

  useEffect(() => {
    if (protocolList[active]) {
      getDetails(protocolList[active].id);
    }
  }, [active, updateDetails]);

  const handleTextUpdate = (text) => {
    setLoader2(true);
    updateProtocol(protocolId, { text: text })
      .then(() => {
        showToast("Protocol updated successfully.", true);
        setUpdateDetails((state) => !state);
      })
      .catch(() => {
        showToast("Unable to update protocol.");
      })
      .finally(() => {
        setLoader2(false);
      });
  };

  const handleDeleteRoom = (id) => {
    console.log(id);
    setRoomChips((chips) => {
      deleteProtocol(protocolId, { room_type_id_delete: [id] })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          showToast("Failed to delete rooms");
          setRoomChips(chips);
        });
      return chips.filter((item) => item.id !== id);
    });
  };

  const handleDeleteSample = (id) => {
    const oldSamples = [...sampleChips];
    console.log(id);
    deleteProtocol(protocolId, { test_sample_id_delete: [id] })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        showToast("Failed to delete sample types");
        setSampleChips(oldSamples);
      });
    setSampleChips((chips) => {
      return chips.filter((item) => item.id !== id);
    });
  };

  return (
    <div className="mainbody">
      <div className="leftside" style={{ padding: "5px 5px 5px 20px" }}>
        <div className="leftside-header">
          <h5 className="mainheading">Protocols</h5>
          <button
            onClick={() => setModalShow(true)}
            className="btn btn-primary ml-auto"
          >
            Add Protocol <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <PerfectScrollbar style={{ height: "auto" }}>
          <ul
            className={loader ? "ul-style list-spinner" : "ul-style"}
            style={{ overflowY: "unset" }}
          >
            {loader ? (
              <Spinner animation="border" variant="success" />
            ) : (
              protocolList.map((item, i) => (
                <li
                  key={`protocol-${i}`}
                  className={`liststyle protocol-list${
                    i === active ? " listActive" : ""
                  }`}
                  onClick={() => setActive(i)}
                >
                  <div>{item.name}</div>

                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditTitleData(item.name);
                      setEditProtocolId(item.id);
                      setShowTitleEditModal(true);
                    }}
                  />
                </li>
              ))
            )}
            {noData && !loader ? (
              <li className="liststyle listActive">No protocols created!</li>
            ) : null}
          </ul>
        </PerfectScrollbar>
        {!loader && (
          <Paginator
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            onChange={(page) => setpage(page)}
          />
        )}
      </div>
      <PerfectScrollbar
        className="round-top"
        style={{
          height: "calc(100vh - 90px)",
          overflowY: "auto",
          width: "100%",
          background: "#e7ece8",
        }}
      >
        <div className="rightside" style={{ width: "100%", height: "100%" }}>
          <div
            className={
              loader2 ? "rightsideinner rightside-spinner" : "rightsideinner"
            }
          >
            {loader2 ? (
              <Spinner animation="border" variant="success" />
            ) : (
              !noData && (
                <React.Fragment>
                  <div className="protocol-details-section">
                    <span className="text-light20 protocol-top-text">
                      {selectedProtocol}
                    </span>
                    <hr className="bold-hr" />
                    <div className="float-label">
                      <div>
                        <EditProtocolEditor
                          text={protocolText}
                          disabled={disableEditProtocolText}
                          onSave={(val) => {
                            handleTextUpdate(val);
                          }}
                          maxLength={10000}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="protocol-details-section">
                    <span className="text-light20 protocol-top-text">
                      What rooms is the protocol associated with?
                    </span>

                    <PerfectScrollbar className="gray-bordered-container">
                      <div className="chips-div">
                        {roomChips.map((item, i) => (
                          <Chip
                            id={`room-chip-${i}`}
                            style={{ margin: "4px 8px" }}
                            onCross={() => {
                              console.log("cross");
                              handleDeleteRoom(item.id);
                            }}
                            key={`room-chip-${i}`}
                          >
                            {item.room_type}
                          </Chip>
                        ))}
                      </div>
                    </PerfectScrollbar>

                    <div className="rightsideactions">
                      <button
                        className="btn btn-primary"
                        onClick={() => setEditRoomsModalShow(true)}
                      >
                        Edit Rooms&nbsp;
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </div>
                  </div>

                  <div className="protocol-details-section">
                    <span className="text-light20 protocol-top-text">
                      What sample types is this associated with?
                    </span>

                    <PerfectScrollbar className="gray-bordered-container">
                      <div className="chips-div">
                        {sampleChips.map((item, i) => (
                          <Chip
                            id={`sample-chip-${item.id}`}
                            style={{ margin: "4px 8px", background: "#B44A9A" }}
                            onCross={() => handleDeleteSample(item.id)}
                            key={`sample-chip-${i}`}
                          >
                            {item.name}
                          </Chip>
                        ))}
                      </div>
                    </PerfectScrollbar>

                    <div className="rightsideactions">
                      <button
                        className="btn btn-primary"
                        onClick={() => setEditSampleModalShow(true)}
                      >
                        Edit Sample Types&nbsp;
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )
            )}
          </div>
        </div>
      </PerfectScrollbar>
      <Suspense fallback={null}>
        <AddProtocolModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          updateList={getProtocolList}
        />
      </Suspense>
      <Suspense fallback={null}>
        <EditRoomModal
          show={editRoomsModalShow}
          onHide={() => setEditRoomsModalShow(false)}
          defaults={defaultRooms}
          protocolId={protocolId}
          getDetails={getDetails}
        />
      </Suspense>
      <Suspense fallback={null}>
        <EditSampleModal
          show={editSampleModalShow}
          onHide={() => setEditSampleModalShow(false)}
          defaults={defaultSamples}
          protocolId={protocolId}
          getDetails={getDetails}
        />
      </Suspense>

      <EditProtocolTitle
        protocolId={editProtocolId}
        title={editTitleData}
        setTitle={setEditTitleData}
        show={showTitleEditModal}
        onHide={() => setShowTitleEditModal(false)}
        refresh={() => setRefreshList((s) => s + 1)}
      />
    </div>
  );
}

export default Protocols;
