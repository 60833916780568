import axios from "../axios-defaults";

const createCustomer = (data) => {
  return axios.post("/api/v1/account", data);
};

const getCustomers = (keyword = "", page = 1) => {
  return axios.get(`/api/v1/customers?search=${keyword}&page=${page}`);
};

const getCustomerById = (customerId) => {
  return axios.get(`/api/v1/bu/customers/${customerId}`);
};

const updateCustomer = (customerId, data) => {
  return axios.post(`/api/v1/bu/customers/${customerId}`, {
    _method: "PATCH",
    ...data,
  });
};

const getCustomerProperties = (customerId, page = 1) => {
  return axios.get(
    `api/v1/bu/properties?customer_id=${customerId}&page=${page}`
  );
};

export {
  getCustomers,
  createCustomer,
  getCustomerById,
  updateCustomer,
  getCustomerProperties,
};
