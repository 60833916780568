import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useState } from "react";
import { useDispatch } from "react-redux";
import { ADD_REPORT_OBSERVATION_COMMENTS } from "store/actions";
import DocumentView from "../../DocumentView";
import ObservationInfo from "../../ObservationInfo";
import ObservationPageTitle from "../../ObservationPageTitle";
import TopRightTitle from "../../TopRightTitle";
import { ObservationsEditable as OET } from "../ReportObservations";

export default function ObservationComments({
  index,
  roomIndex,
  comments,
  style,
  insp_id,
  sethandleSaveState,
}) {
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const [refreshCommentList, setRefreshCommentList] = useState(true);
  const [selectedObservationId, setSelectedObservationId] = useState(null);
  const [addCommentLogo, setAddCommentLogo] = useState(null);

  const AddCommentsModal = React.lazy(() =>
    import(
      "../../../../Inspection/InspectionDetails/Observation/AddCommentReport.js"
    )
  );

  const countReportComment = () => {
    return comments.filter((cm) => !cm.id);
  };

  return (
    <DocumentView style={style}>
      <div className="document-head" style={{ border: "none" }}>
        <header>
          <div className="grid-pg1">
            <div>
              <ObservationPageTitle />
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>

      <ObservationInfo index={index} roomIndex={roomIndex} />

      <div className="doc-body">
        <div>
          <div className="comments-heading">
            <p>
              <strong>Comments</strong>
            </p>
            <span></span>
          </div>
          <ol className="report-protocol-list">
            {comments.map((comment, i) => (
              // <div>
              <li key={`comment-${i}`}>
                <h4>
                  <strong>
                    <OET
                      reduxKey={
                        comment.name !== null ? "name" : "default_comment.name"
                      }
                      ml="100"
                      oi={index}
                      ci={i}
                      ri={roomIndex}
                      removeComment="true"
                    >
                      {comment.name ?? comment.default_comment.name}
                    </OET>
                  </strong>
                </h4>
                <p className="comment-remarks">
                  <OET
                    reduxKey="remarks"
                    ml="5000"
                    oi={index}
                    ci={i}
                    ri={roomIndex}
                    textArea
                    removeComment="true"
                  >
                    {comment.remarks}
                  </OET>
                </p>
                {/* </div> */}
              </li>
            ))}
          </ol>
          {countReportComment().length <= 4 ? (
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon
                onClick={() => {
                  setShowAddCommentsModal(true);
                }}
                style={{
                  margin: "2rem auto 3rem auto",
                  fontSize: "60px",
                  cursor: "pointer",
                }}
                icon={faPlusCircle}
                color="#dee2df"
              />
              <Suspense fallback={null}>
                <AddCommentsModal
                  show={showAddCommentsModal}
                  onHide={() => setShowAddCommentsModal(false)}
                  setRefreshList={setRefreshCommentList}
                  comments={comments}
                  index={index}
                  roomIndex={roomIndex}
                  d_coments=""
                  insp_id={insp_id}
                  sethandleSaveState={sethandleSaveState}
                />
              </Suspense>
            </div>
          ) : null}
        </div>
        {/* {countReportComment().length <= 4 && (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <FontAwesomeIcon
              onClick={addProtocol}
              style={{
                margin: "2rem auto 3rem auto",
                fontSize: "60px",
                cursor: "pointer",
              }}
              icon={faPlusCircle}
              color="#dee2df"
            />
          </div>
        )} */}
      </div >
    </DocumentView >
  );
}
