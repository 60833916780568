/* eslint-disable react-hooks/exhaustive-deps */
import {
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { updateAWS } from "services/s3";
import ScoutLogo from "../../../../assets/logo/scout_logo.png";
import axios from "../../../../axios-defaults";
import {
  loginRequest,
  profileRequest,
} from "../../../../services/auth-service";
import { LOG_IN, SET_PASS_REST } from "../../../../store/actions";
import { regexes } from "../../../shared/regexes";
import "../style.css";

const Login = () => {
  const showResetSuccess = useSelector((state) => state.app.showResetSuccess);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disableLogin, setDisableLogin] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [justLoaded, setJustLoaded] = useState(true);
  const [viewPass, setViewPass] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (email && password) {
      console.log("setting to true", email, password);
      setJustLoaded(true);
    } else {
      console.log("setting to false", email, password);
      // setJustLoaded(false);
    }
  }, [email, password]);

  const goToForgetPassword = () => {
    history.push("/forget-password");
  };

  function handleSubmit(event) {
    setErrorText("");
    if (showResetSuccess) {
      dispatch({
        type: SET_PASS_REST,
        payload: {
          showResetSuccess: false,
        },
      });
    }
    if (!email && !password) {
      setErrorText("Enter an email or password.");
    } else if (!email) {
      setErrorText("Please enter an email address");
    } else if (!password) {
      setErrorText("Please enter a password");
    } else if (!regexes.email.test(email)) {
      setErrorText("Please enter a valid email address.");
    } else {
      setErrorText("");

      setDisableLogin(true);
      loginRequest({
        username: email,
        password,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: process.env.REACT_APP_GRANT_TYPE,
      })
        .then((resp) => {
          localStorage.setItem("access_token", resp.data.access_token);
          localStorage.setItem("refresh_token", resp.data.refresh_token);

          if (resp.data.access_token) {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp.data.access_token}`;
          }

          return profileRequest();
        })
        .then(async (profile) => {
          await updateAWS();
          dispatch({
            type: LOG_IN,
            payload: profile.data,
          });

          history.replace("/");
        })
        .catch((error) => {
          if (error?.response?.data?.error === "inactive_user") {
            setErrorText(error?.response?.data?.error_description);
          } else {
            setErrorText(error?.noInternet ?? "Email or password incorrect.");
          }
          setDisableLogin(false);
        });
    }
    event.preventDefault();
  }

  return (
    <div className="login-page">
      <div>
        <img alt="Scout-Logo" className="scout-logo" src={ScoutLogo} />
      </div>

      <div
        style={{
          visibility: errorText !== "" ? "visible" : "hidden",
        }}
      >
        <div className="email-pass-error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span className="ml-4 font-bold">{errorText}</span>
        </div>
      </div>

      {showResetSuccess ? (
        <div>
          <div className="email-pass-success">
            <span className="ml-4 font-bold">
              Your password has been Successfully changed. Try logging in the
              web application again.
            </span>
          </div>
        </div>
      ) : null}

      <form onSubmit={handleSubmit}>
        <div id="float-label-auth">
          <div>
            <span className="placeholder-icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>

            <input
              autoFocus
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="auth-input-field"
              id="login_email"
              maxLength="340"
              type="email"
            />
          </div>

          <label
            className={
              email.length > 0 || justLoaded
                ? "Active auth-input-field"
                : "auth-input-field"
            }
            htmlFor="email"
          >
            Email Address
          </label>
        </div>

        <div className="mt-3">
          <div id="float-label-auth">
            <div>
              <span className="placeholder-icon mr-n-50">
                <FontAwesomeIcon icon={faLock} />
              </span>

              <input
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="login_password"
                className="auth-input-field"
                type={viewPass ? "text" : "password"}
                maxLength="15"
              />
              <FontAwesomeIcon
                className="pass-eye"
                icon={viewPass ? faEyeSlash : faEye}
                onClick={() => setViewPass((s) => !s)}
              />
            </div>
            <label
              className={
                password.length > 0 || justLoaded
                  ? "Active auth-input-field"
                  : "auth-input-field"
              }
              htmlFor="password"
            >
              Password
            </label>
          </div>
        </div>

        <div className="forgot-password">
          <span
            style={{ cursor: "pointer" }}
            onClick={goToForgetPassword}
            id="forgot_password"
          >
            Forgot Your Password?
          </span>
        </div>

        <div className="mt-3 text-align-end">
          <button
            id="signin_button"
            disabled={disableLogin}
            variant="primary"
            className={
              disableLogin
                ? "login-btn disabled-bg-color"
                : "login-btn bg-light20"
            }
            type="submit"
          >
            Sign In
          </button>
        </div>

        <div className="extra-text text-center">
          <div>Please contact your administrator for access to Scout Pro</div>
          <div className="mt-3">
            By Logging in you agree to the{" "}
            <Link
              to="/terms-of-use"
              target="_blank"
              className="new-tab-link"
              id="terms"
            >
              terms of use
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy-policy"
              target="_blank"
              className="new-tab-link"
              id="policy"
            >
              privacy policy
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
