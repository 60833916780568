import { Logout } from "main/shared/helpers";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

export default function LogoutPage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      <Modal
        show={true}
        onHide={() => {}}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="no-border">
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="dialogheader">
              <div className="d-flex">
                <h5 style={{ fontWeight: "bolder" }}>Access Denied</h5>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dialogbody">
            <p className="text-light20">Your subscribtion has expired.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="no-border">
          <Button
            className="dis-loading"
            onClick={() => {
              setIsLoading(true);
              Logout(dispatch, setIsLoading);
            }}
            style={{ marginRight: 25 }}
            id="logout-btn"
            disabled={isLoading}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
