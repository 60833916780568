/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import currency from "currency.js";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { authRoles } from "navigation/route-config";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { logoutRequest, profileRequest } from "../../services/auth-service";
import { uploadToS3 } from "../../services/s3";
import {
  LOGOUT_USER,
  LOG_IN,
  LOG_OUT,
  SET_HEADER,
  SET_SEARCH_LABEL,
  SHOW_SEARCH,
} from "../../store/actions";
import { ROTATE } from "../pages/InvestigationReport/Components/ImageEditor";
import { regexes } from "./regexes";
import { fileSizeLimits, folders } from "./validations";
dayjs.extend(customParseFormat);

export const isAuthenticated = () => {
  return localStorage.getItem("access_token") ? true : false;
};

export const getLocalUser = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(atob(user)) : null;
};

export const color = {
  primary300: "#57932F",
  light20: "#2F481E",
  error: "#B44A4A",
  primary500: "#84857A",
  light50: "#84857A",
  alert: "#EAE34C",
  primary900: "#F1F9D",
  light80: "#DEE2DF",
  accent500: "#B44A9A",
  secondary500: "#4AA2B4",
  light90: "#E7ECE8",
  secondary800: "CFE7EC",
  light100: "#FFFFFF",
  secondary900: "#EDF6F8",
};

export const fonts = {
  opensans: "Open Sans",
  exo2: "Exo 2",
};

export const setHeader = (dispatch, payload) => {
  dispatch({
    type: SET_HEADER,
    payload,
  });
};

export const Logout = (dispatch, disableSideNavLogoutBtn) => {
  logoutRequest()
    .then(() => {
      logoutLocal(dispatch);
    })
    .catch(() => {
      disableSideNavLogoutBtn && disableSideNavLogoutBtn(false);
      showToast("Something went wrong");
    });
};

export const logoutLocal = (dispatch) => {
  localStorage.clear();
  dispatch({
    type: LOG_OUT,
    payload: {},
  });
  dispatch({
    type: LOGOUT_USER,
    payload: {
      logout: true,
    },
  });
};

export const getErrorMsg = (err) => {
  let errMsg = err.noInternet ?? "";
  if (err?.response?.data?.message) {
    return err?.response?.data?.message;
  }
  if (err?.response?.status === 500) {
    return "Temporarily Down for Maintenance.";
  }
  if (err?.response?.status === 400) {
    let msg_val = err?.response?.data?.message;
    let msg_err =
      msg_val.charAt(0).toUpperCase() + msg_val.slice(1).toLowerCase();
    return msg_err;
  }

  const data = err?.response?.data;

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      for (let i = 0; i < data[key].length; i++) {
        const element = data[key][i];
        errMsg = errMsg + element + "\n";
      }
    }
  }
  errMsg = errMsg || "Something went wrong.";
  return errMsg;
};

export const truncate = (str) => {
  return str.length > 15 ? str.substring(0, 15) + "..." : str;
};

export const showToast = (msg, success = false) => {
  if (success === true) {
    toast.success(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.error(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const dismissAllToasts = () => toast.dismiss();

export const formatDate = (date) => {
  let temp = date.split("-");
  return temp[1] + "/" + temp[2] + "/" + temp[0][2] + temp[0][3];
};

export const utcTimeforInput = (datenTime) => {
  let dateInUtc = new Date(Date.parse(datenTime + " UTC")); // in 24hr format with current timezone
  return dateInUtc.toLocaleTimeString("en-GB");
};

export const utcDateforInput = (datenTime) => {
  let dateInUtc = new Date(Date.parse(datenTime)); // in yyyy-dd-mm format with current timezone
  return dateInUtc.toLocaleDateString("en-CA");
};
export const utcDate = (datenTime) => {
  const d1 = dayjs(
    datenTime,
    { format: "YYYY-MM-DD HH:mm:ss" /* utc: true */ },
    true
  );
  const d2 = dayjs(
    dayjs(datenTime, "MM-DD-YYYY HH:mm:ss", true).format("YYYY-MM-DD HH:mm:ss"),
    {
      utc: true,
    }
  );

  if (d1.isValid()) {
    return d1.format("MM/DD/YYYY");
  } else if (d2.isValid()) {
    return d2.format("MM/DD/YYYY");
  } else {
    return "Invalid Date";
  }
};
export const timeConversionUTC = (dateWithTime) => {
  const d1 = dayjs(dateWithTime, { format: "YYYY-MM-DD HH:mm:ss" }, true);
  const d2 = dayjs(
    dayjs(dateWithTime, "MM-DD-YYYY HH:mm:ss", true).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    {
      utc: true,
    }
  );

  if (d1.isValid()) {
    return d1.format("h:mm A");
  } else if (d2.isValid()) {
    return d2.format("h:mm A");
  } else {
    return "Invalid Date";
  }
};

export const timeConversionUTC2 = (dateWithTime) => {
  const d1 = dayjs(dateWithTime);
  return d1.format("h:mm A");
};

export const timeConversion = (dateWithTime) => {
  let dateSplit = dateWithTime?.split(" ");
  let time = dateSplit[1].split(":");
  let hour = parseInt(time[0]);
  let minutes = time[1];
  let hourSet = hour >= 12 ? "PM" : "AM";
  return (hour % 12 || 12) + ":" + minutes + " " + hourSet;
};

export const captalizeFirst = (word) => {
  let newWord = word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
  return newWord;
};

export const getAddress = (address) => {
  return (
    (address?.address1 ? address.address1 + ", " : "") +
    (address?.address2 ? address.address2 + ", " : "") +
    (address?.city ? address.city + ", " : "") +
    (address?.state ? address.state : "") +
    (address?.zipcode ? ", " + address.zipcode : "")
  );
};

export const checkStatus = (statusName) => {
  const statuses = {
    scheduled: "Not started",
    in_progress: "In progress",
    coc_sent: "COC Sent",
    finished: "Finished",
  };

  return statuses[statusName] ?? "N/A";
};

export const getStringFromObject = (object, key, separator) => {
  const dotSeparator = (object, key) => {
    const nest = key.split(".");
    let tempObj = object[nest[0]];
    for (let i = 1; i < nest.length; i++) {
      tempObj = tempObj[nest[i]];
    }
    return tempObj;
  };

  if (key.includes(".")) {
    return dotSeparator(object, key);
  } else if (Array.isArray(key)) {
    let temp = key.map((element) => dotSeparator(object, element));
    return temp.join(separator);
  } else {
    return object[key];
  }
};

export const setPropFromString = (obj, path, value) => {
  let schema = obj;
  let pList = path.split(".");
  let len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    let elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
};

export const utcToLocalDateTime = (value) => {
  //"2021-09-28 12:11:02"
  const date = dayjs(value, {
    format: "YYYY-MM-DD HH:mm:ss",
    utc: true,
  }).format("MM/DD/YYYY - hh:mm a");
  return date;
};

export const deleteArrayIndex = (index, stateArray) => {
  let array = [...stateArray];
  array.splice(index, 1);
  return array;
};

export const updateArrayIndex = (index, stateArray, replacement) => {
  let array = [...stateArray];
  array[index] = replacement;
  return array;
};

export const USD = (value) =>
  currency(value, { separator: "", symbol: "$", precision: 2 });

export const priceInputHandle = (e) => {
  let start = e.target.selectionStart;
  let val = e.target.value;
  e.target.value = USD(e.target.value).format();
  if (val.length > 0) {
    e.target.value = USD(e.target.value).format();
    e.target.setSelectionRange(start, start);
  }
};

export const refreshProfile = (dispatch, role) => {
  if (role === authRoles.ultraAdmin) {
    profileRequest()
      .then((res) => {
        dispatch({
          type: LOG_IN,
          payload: { ...res.data, role: authRoles.ultraAdmin },
        });
        //setSelectedBUName(res.data.business_unit?.company_name || "");
      })
      .catch((err) => {
        console.log(err.response);
        showToast(getErrorMsg(err) || "Unable to switch role");
      });
  } else {
    profileRequest().then((profile) => {
      dispatch({
        type: LOG_IN,
        payload: profile.data,
      });
      setTimeout(() => {
        showToast("Company Details updated successfully.", true);
      }, 500);
    });
  }
};

export const roleNames = {
  super_admin: "Super Admin",
  bu_admin: "Business Owner",
  bu_sub_admin: "Sub-Admin",
  bu_inspector: "Inspector",
  bu_customer: "Customer",
};

export const getCreatedAtDate = (dateWithT) => {
  const arr = dateWithT.split("T")[0]?.split("-");
  return `${arr[1]}/${arr[2]}/${arr[0]}`;
};

export const defaultAvatar = require("../../assets/icons/person.png").default;

export const permissions = [
  {
    name: "Manage Tools",
    description: "Add, Edit, or Delete Inspections",
    value: "tools",
    routePath: "/tools",
  },
  {
    name: "Manage Customers",
    description: "Add or Edit Customers",
    value: "customers",
    routePath: "/customers",
  },
  {
    name: "Manage Properties",
    description: "Add, Edit, or Delete Properties",
    value: "properties",
    routePath: "/properties",
  },
  {
    name: "Manage Inspections",
    description: "Add or Edit Inspections",
    value: "inspections",
    routePath: "/inspections",
  },
  {
    name: "Manage Settings",
    description: "Add or Delete Users",
    value: "settings",
    routePath: "/settings",
  },
];

export const stripeCards = {
  amex: { name: "American Express", icon: faCcAmex },
  diners: { name: "Diners Club", icon: faCcDinersClub },
  discover: { name: "Discover", icon: faCcDiscover },
  jcb: { name: "JCB", icon: faCcJcb },
  mastercard: { name: "Mastercard", icon: faCcMastercard },
  visa: { name: "Visa", icon: faCcVisa },
  unionpay: { name: "UnionPay", icon: faCreditCard },
};

export const updateHeaderSearch = (dispatch, show, label) => {
  dispatch({ type: SHOW_SEARCH, payload: show });
  dispatch({ type: SET_SEARCH_LABEL, payload: label });
};

export const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
};

export const getReduxPermissions = (roles) => {
  const permissions = {
    tools: false,
    customers: false,
    properties: false,
    inspections: false,
    settings: false,
  };

  roles.forEach((i) => {
    permissions[i.alias] = true;
  });

  return permissions;
};

export const getFallbackUrl = (roles) => {
  console.log(roles);
  if (roles) {
    const fallback = permissions.find((p) =>
      roles.find((r) => p.value === r.alias)
    );
    return fallback.routePath === "/tools"
      ? "/tools/tests"
      : fallback.routePath;
  } else {
    return "/tools/tests";
  }
};

export const fileChangedHandler = (
  event,
  setError,
  setPreview,
  setRotation
) => {
  const file = event.target.files[0];
  if (file) {
    if (file.size > fileSizeLimits["2mb"]) {
      setError("Max size is 2MB");
    } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
      setError("Unsupported format");
    } else {
      setError("");
      uploadToS3(file, folders.documentImages)
        .then((res) => setPreview(res.url))
        .catch(() => showToast("Failed to upload image"));
      setPreview(URL.createObjectURL(file));
      setRotation(0);
      return file;
    }
  }
};

export const mergeArray = (firstArray, secondArray) => {
  secondArray.forEach((v) => firstArray.push(v));
};

export function rotateImage(url, rotation) {
  return new Promise((resolve, reject) => {
    try {
      const Base64toBlobWorker = new Worker(
        `${process.env.PUBLIC_URL}/worker.js`
      );
      let img = new Image();
      img.crossOrigin = "*";
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const swapWidthHeight = [90, 270].indexOf(rotation) > -1;
        const halfImgHeight = img.height / 2;
        const halfImgWidth = img.width / 2;

        if (swapWidthHeight) {
          canvas.width = img.naturalHeight;
          canvas.height = img.naturalWidth;
        } else {
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
        }
        const ctx = canvas.getContext("2d");
        if (swapWidthHeight) {
          ctx.translate(halfImgHeight, halfImgWidth);
        } else {
          ctx.translate(halfImgWidth, halfImgHeight);
        }
        ctx.rotate(rotation * (Math.PI / 180));
        ctx.drawImage(img, -halfImgWidth, -halfImgHeight);
        const base64 = canvas.toDataURL("image/png");

        Base64toBlobWorker.postMessage(base64);
        Base64toBlobWorker.onmessage = (e) => {
          if (e.data.error) {
            reject(e.data.error);
          } else {
            resolve(e.data);
          }
          Base64toBlobWorker.terminate();
        };
      };
    } catch (e) {
      reject(e);
    }
  });
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function getReportDataForApi(report) {
  const deepReport = deepCopyObject(report);

  deepReport.cover_page.property.image =
    deepReport.cover_page.property.image.split(ROTATE)[0] +
    ROTATE +
    (deepReport.cover_page.property.rotation ?? 0);

  deepReport.cover_page.company.logo =
    deepReport.cover_page.company.logo.split(ROTATE)[0] +
    ROTATE +
    (deepReport.cover_page.company.rotation ?? 0);

  deepReport?.observations?.data.forEach((room) => {
    room.observations?.forEach((obs) => {
      obs.images.forEach((photo) => {
        if (photo.rotation) {
          photo.url =
            photo.url.split(ROTATE)[0] + ROTATE + (photo.rotation ?? 0);
        }
      });
      obs.tests.forEach((test) => {
        test.images.forEach((testImage) => {
          if (testImage.rotation) {
            testImage.url =
              testImage.url.split(ROTATE)[0] +
              ROTATE +
              (testImage.rotation ?? 0);
          }
        });
      });
    });
  });
  return deepReport;
}

export function formatReportData(report) {
  let page = 0;
  console.log("content", report?.toc?.content);
  report?.toc?.content?.forEach((content) => {
    if (content?.report_key === "observations") {
      const allRooms = report?.observations?.data;
      for (let i = 0; i < allRooms?.length; i++) {
        // page += allRooms[i].observations.length * 3 + 1;

        page +=
          allRooms[i]?.observations?.reduce((a, c) => {
            const pages =
              (c.comments.length > 0 ? 1 : 0) +
              (c.tests.length > 0 ? 1 : 0) +
              (c.images.length > 0 ? 1 : 0);
            return a + pages;
          }, 0) + 1;
      }
    } else {
      if (Array.isArray(content?.sub_content)) {
        content?.sub_content?.forEach((subContent) => {
          console.log(subContent.page, "-------------oooooooooooooooooooo");
          subContent.page = page;
          page++;
        });
      } else {
        content.sub_content = [];
      }
    }
  });

  if (!report?.extra_pages) {
    report.extra_pages = [];
  }
}

export function deepCopyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function isEmptyHtml(html) {
  if (html) {
    const text = html.replaceAll(regexes.htmlTags, "");
    return text.trim() === "" ? true : false;
  } else {
    return true;
  }
}

export const Images = {
  crossIcon: require("../../assets/icons/x_light50@3x.png").default,
};

export function getReportKeys(report) {
  let keys = [];
  report?.toc?.content?.forEach((content) =>
    content?.report_key === "observations"
      ? keys.push("observations")
      : content?.sub_content?.forEach((sub) => keys.push(sub?.report_key))
  );
  return keys;
}

export const htmlToText = (html) => {
  html = html.replace(/<style([\s\S]*?)<\/style>/gi, "");
  html = html.replace(/<script([\s\S]*?)<\/script>/gi, "");
  html = html.replace(/<\/div>/gi, " ");
  html = html.replace(/<\/li>/gi, " ");
  html = html.replace(/<li>/gi, " ");
  html = html.replace(/<\/ul>/gi, " ");
  html = html.replace(/<\/p>/gi, "<br>");
  html = html.replace(/&nbsp;/gi, " ");
  html = html.replace(/<(?!br\s*\/?)[^>]+>/g, "");

  return html;
};


export const handleReduxError = (error) => {
  let err = "";
  if (error?.message) {
    err = error.message;
  }
  if (!err) {
    err = "Network Error";
  }
  return err;
};

export const convertLabelIntoValueAndOption = (
  list = [],
  valueField = "id",
  optionField
) => {
  const cloneList = JSON.parse(JSON.stringify(list));
  const options = [];
  cloneList.forEach((data) => {
    const singleOption = {
      value: data[valueField],
      label: data[optionField],
    };
    options.push(singleOption);
  });
  return options;
};
