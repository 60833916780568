import DayjsUtils from "@date-io/dayjs";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createTheme } from "@material-ui/core/styles";
import {
  TimePicker as MuiTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";

const green = {
  50: "#74b44a",

  100: "#74b44a",

  200: "#74b44a",

  300: "#74b44a",

  400: "#74b44a",

  500: "#74b44a",

  600: "#74b44a",

  700: "#74b44a",

  800: "#74b44a",

  900: "#74b44a",

  A100: "#74b44a",

  A200: "#74b44a",

  A400: "#74b44a",

  A700: "#74b44a",
};

const defaultMaterialTheme = createTheme({
  palette: {
    primary: green,
  },
});

export default function TimePicker({
  value,
  setValue,
  label,
  disabled = false,
}) {
  const ref = useRef(null);

  useEffect(() => {
    console.log(ref);
  }, [ref]);

  return (
    <>
      <div
        className={`float-label ${
          disabled ? "disable-time" : "cursor-pointer-all"
        }  pointer-wrap`}
        onClick={() => ref.current.click()}
      >
        <label
          style={{ paddingLeft: value ? "20px" : "40px" }}
          className={value ? "Active input-field" : "input-field"}
        >
          {label}
        </label>
        <FontAwesomeIcon
          style={{
            position: "absolute",
            top: `calc(50% - ${value ? "0px" : "7px"})`,
            left: "15px",
            cursor: "pointer",
            color: "#2F481E",
          }}
          icon={faCalendar}
        />
        <input
          style={{ paddingLeft: "40px", pointerEvents: "none" }}
          onChange={(e) => setValue(e.target.value)}
          className="form-control"
          onClick={(e) => e.preventDefault()}
          value={value ? dayjs(value).format("hh:mm A") : ""}
          type="text"
          disabled={disabled}
        />
      </div>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MuiTimePicker
            inputRef={ref}
            style={{ visibility: "hidden" }}
            value={value}
            onChange={setValue}
            disabled={disabled}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
}
