/* eslint-disable react-hooks/exhaustive-deps */
import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  getInspectorDetail,
  getSubAdminDetail,
  updateInspector,
  updateSubAdmin,
} from "../../../../services/bu-admin-service";
import { uploadToS3 } from "../../../../services/s3";
import CustomInput from "../../../components/Custom/CustomInput";
import Toggle from "../../../components/Toggle/Toggle";
import {
  getErrorMsg,
  permissions,
  roleNames,
  setHeader,
  showToast,
  updateArrayIndex,
} from "../../../shared/helpers";
import { regexes } from "../../../shared/regexes";
import { fileSizeLimits, folders } from "../../../shared/validations";

const ResetPasswordModal = React.lazy(() => import("./ResetPasswordModal"));
const ConfirmationModal = React.lazy(() => import("./ConfirmationModal"));

export default function EditSubAdmin() {
  const [isLoading, setIsLoading] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [file_e, setFile_e] = useState("");
  const [file, setFile] = useState("");
  const [status, setStatus] = useState("");

  const [perm, setPerm] = useState([]);
  const [permissions_e, setPermissions_e] = useState("");

  const { userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    loadUser(userId);
  }, [userId]);

  const loadUser = (userId) => {
    setIsLoading(true);
    getSubAdminDetail(userId)
      .then((res) => {
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setRole(res.data.role);
        setEmail(res.data.email);
        setPhone(res.data.phone_number);
        setPreview(res.data.image?.url || null);
        setImage(res.data.image?.url || null);
        setStatus(res.data.status);
        setHeader(dispatch, {
          title: res.data.first_name + " " + res.data.last_name,
        });
        const a = permissions.map((permission) => {
          return res.data.roles.some((r) => r.alias === permission.value)
            ? permission.value
            : null;
        });
        setPerm(a);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSave = () => {
    if (perm.length > 0 && !perm.find((i) => i !== null && i !== undefined)) {
      setPermissions_e("At least one permission is required");
      return;
    } else {
      setPermissions_e("");
    }
    setDisableSave(true);
    if (image !== preview) {
      console.log("upload photo");
      if (image) {
        console.log("delete photo");
        //deleteFromS3(data?.business_unit?.logo_url);
      }
      uploadPhoto()
        .then((url) => {
          editUser(url);
        })
        .catch(() => showToast("Unable to upload photo."));
    } else {
      editUser(null);
    }
  };

  const editUser = (image) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      ...(image ? { avatar_url: image } : null),
      permissions: perm,
    };
    updateSubAdmin(userId, data)
      .then(() => {
        showToast("User has been updated successfully.", true);
        loadUser(userId);
        setEditEnabled(false);
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Unable to update user.");
        setIsLoading(false);
      })
      .finally(() => setDisableSave(false));
  };

  const uploadPhoto = () => {
    console.log("file", file);
    return new Promise((resolve, reject) => {
      if (file) {
        uploadToS3(file, folders.profile)
          .then((res) => {
            console.log(res);
            resolve(res.url);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve("");
      }
    });
  };

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("File size cannot be larger than 2MB");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG or PNG");
      } else {
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  const handleActivate = (setDisableBtns) => {
    setDisableBtns(true);
    updateSubAdmin(userId, { status: "active" })
      .then(() => {
        showToast("User has been activated successfully.", true);
        setShowActivateModal(false);
        loadUser(userId);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to activate user.");
      })
      .finally(() => setDisableBtns(false));
  };

  const handleDeActivate = (setDisableBtns) => {
    setDisableBtns(true);
    updateSubAdmin(userId, { status: "blocked" })
      .then(() => {
        showToast("User has been de-activated successfully.", true);
        setShowDeactivateModal(false);
        loadUser(userId);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to de-activate user.");
      })
      .finally(() => setDisableBtns(false));
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="main-content-area">
            <div className="d-flex align-items-center">
              {editEnabled ? (
                <div className="d-flex ml-auto">
                  <button
                    className="btn btn-primary ml-auto"
                    id="save_user"
                    onClick={handleSave}
                    disabled={disableSave}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-light ml-2 low-priority"
                    onClick={() => {
                      setEditEnabled(false);
                      loadUser(userId);
                    }}
                    disabled={disableSave}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary ml-auto"
                  id="edit_customer"
                  onClick={() => setEditEnabled(true)}
                >
                  Edit Sub-Admin <FontAwesomeIcon icon={faPen} color="#fff" />
                </button>
              )}
            </div>
            <div>
              <h4 className="font-weight-bold">Personal Details</h4>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          inputProps={{
                            maxLength: "30",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          inputProps={{
                            maxLength: "30",
                            disabled: !editEnabled,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Role"
                          value={roleNames[role]}
                          onChange={() => {}}
                          inputProps={{ maxLength: "50", disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Email"
                          value={email}
                          onChange={() => {}}
                          inputProps={{ maxLength: "50", disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Phone Number"
                          value={new AsYouType("US").input(phone)}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(regexes.phoneInput, "");
                            setPhone(value);
                          }}
                          inputProps={{
                            maxLength: "16",
                            disabled: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="upload-avatar">
                    <div
                      className={
                        editEnabled
                          ? "image-area"
                          : "cursor-disabled image-area"
                      }
                    >
                      {!preview ? (
                        <span
                          className="upload-icon"
                          style={
                            preview
                              ? { backgroundColor: "transparent" }
                              : { backgroundColor: "#fff" }
                          }
                        >
                          <FontAwesomeIcon icon={faCamera} />
                        </span>
                      ) : null}
                      <img
                        width="120"
                        className="avatar-lg"
                        src={preview}
                        alt=""
                      />
                      <input
                        type="file"
                        className="upload-image"
                        accept="image/jpeg,image/png"
                        onChange={(e) => {
                          fileChangedHandler(e);
                        }}
                        disabled={!editEnabled}
                      />
                    </div>
                    <p>Upload Photo (Optional)</p>
                    <small className="text-danger ml-1">{file_e}</small>
                  </div>
                </div>
              </div>

              <hr className="mt-0 mb-4" />

              <div>
                <h4>
                  <b>Permissions</b>
                </h4>
                <div className="ml-3">
                  {permissions.map((item, i) => (
                    <div
                      className="d-flex flex-row align-items-center my-3"
                      key={`perm-${i}`}
                    >
                      <Toggle
                        on={perm[i] && true}
                        disabled={!editEnabled}
                        onChange={(val) => {
                          setPerm(
                            updateArrayIndex(i, perm, val ? item.value : null)
                          );
                        }}
                      />
                      <div>
                        <h4 className="mb-0">
                          <b>{item.name}</b>
                        </h4>
                        <span>{item.description}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <small className="text-danger ml-1">{permissions_e}</small>
              </div>

              <hr />
              <h4 className="font-weight-bold">Password</h4>
              <button
                className="btn btn-primary mt-3 mb-4"
                id="reset_password"
                onClick={() => setShowResetModal(true)}
                disabled={disableSave}
              >
                Reset Password
              </button>

              <hr />
              <h4 className="font-weight-bold">Status</h4>
              {status === "active" ? (
                <button
                  className="btn btn-primary mt-3 mb-4 bg-error br-error"
                  id="de_activate"
                  onClick={() => setShowDeactivateModal(true)}
                  disabled={disableSave}
                >
                  De-Activate User
                </button>
              ) : (
                <button
                  className="btn btn-primary mt-3 mb-4 bg-primary500 br-primary500"
                  id="activate"
                  onClick={() => setShowActivateModal(true)}
                  disabled={disableSave}
                >
                  Activate User
                </button>
              )}
            </div>
          </div>
        )}
      </ScrollBar>
      <Suspense fallback={null}>
        <ResetPasswordModal
          userId={userId}
          onHide={() => setShowResetModal(false)}
          show={showResetModal}
          userType={role}
        />
      </Suspense>
      <Suspense fallback={null}>
        <ConfirmationModal
          show={showDeactivateModal}
          onHide={() => setShowDeactivateModal(false)}
          msg={"Are you sure you want to de-activate this user?"}
          onDelete={(setDisableBtns) => handleDeActivate(setDisableBtns)}
          yesBtnText="De-activate"
          noBtnText="Don't De-activate"
        />
      </Suspense>
      <Suspense fallback={null}>
        <ConfirmationModal
          show={showActivateModal}
          onHide={() => setShowActivateModal(false)}
          msg={"Are you sure you want to activate this user?"}
          onDelete={(setDisableBtns) => handleActivate(setDisableBtns)}
          yesBtnText="Activate"
          noBtnText="Don't Activate"
        />
      </Suspense>
    </div>
  );
}
