import React from "react";

const BUStats = ({ profileData }) => {
  return (
    <div className="row">
      <div className="col-md-3 d-flex justify-content-center">
        <div className="statsBlock" style={{ width: "84%", height: "155px" }}>
          <div className="d-flex">
            <img
              width="25"
              height="25"
              src={
                require(`../../../../../assets/icons/list_light20@3x.png`)
                  .default
              }
              alt=""
            />
            <p
              style={{ margin: "0px 0px 0px 15px" }}
              className="TabsLabel d-flex"
            >
              Inspections{" "}
            </p>
          </div>
          <div
            className="d-block"
            style={{
              margin: "13px 0px 8px 0px",
              paddingBottom: "5px",
              borderBottom: "1px solid #9a9a9a",
            }}
          >
            <span className="BU_stats_num">
              {profileData.incomplete_inspections_count}
            </span>
            <span
              style={{
                float: "right",
                marginTop: "8px",
              }}
            >
              Scheduled{" "}
            </span>
          </div>
          <div className="d-block">
            <span className="BU_stats_num">
              {" "}
              {profileData.completed_inspections_count}
            </span>
            <span
              style={{
                float: "right",
                marginTop: "8px",
                // display: "inline-flex",
              }}
            >
              Completed{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="statsBlock" style={{ height: "155px", width: "80%" }}>
          <div>
            <div className="d-flex">
              <img
                width="25"
                height="25"
                src={
                  require(`../../../../../assets/icons/home_light20@3x.png`)
                    .default
                }
                alt=""
              />
              <p
                style={{ margin: "0px 0px 0px 15px" }}
                className="TabsLabel d-flex"
              >
                Properties
              </p>
            </div>
            <span className="BU_stats_num" style={{ marginLeft: "8px" }}>
              {profileData.properties_count}
            </span>
          </div>
          <div
            style={{
              borderTop: "1px solid rgb(154, 154, 154)",
              paddingTop: "10px",
            }}
          >
            <div className="d-flex">
              <img
                width="25"
                height="25"
                src={
                  require(`../../../../../assets/icons/customer_light20@3x.png`)
                    .default
                }
                alt=""
              />
              <p
                style={{ margin: "0px 0px 0px 15px" }}
                className="TabsLabel d-flex"
              >
                Customers
              </p>
            </div>
            <span className="BU_stats_num" style={{ marginLeft: "8px" }}>
              {profileData.customers_count}
            </span>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default BUStats;
