/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { authRoles } from "../../../../navigation/route-config";
import { getUserList } from "../../../../services/bu-admin-service";
import Paginator from "../../../components/Paginator/Paginator";
import {
  roleNames,
  updateHeaderSearch,
  useDidUpdateEffect,
} from "../../../shared/helpers";
import "../style.css";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);

  useDidUpdateEffect(() => {
    setSkip(true);
    setIsLoading(true);
    getUserList(1, searchField)
      .then((res) => {
        setUsers(res.data.data);
        setActivePage(res.data.current_page);
        setItemsCountPerPage(res.data.per_page);
        setTotalItemsCount(res.data.total);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, [searchField]);

  useEffect(() => {
    if (!skip) {
      setIsLoading(true);
      getUserList(page)
        .then((res) => {
          console.log(res);
          setUsers(res.data.data);
          setActivePage(res.data.current_page);
          setItemsCountPerPage(res.data.per_page);
          setTotalItemsCount(res.data.total);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      setSkip(false);
    }
  }, [page]);

  useEffect(() => {
    updateHeaderSearch(dispatch, true, "Users");

    return () => updateHeaderSearch(dispatch, false, "");
  }, []);

  return (
    <div className="tab-child d-flex flex-column p-4">
      <div className="d-flex align-items-center">
        <Link
          className="ml-auto"
          style={{ textDecoration: "none" }}
          to="/settings/user/new"
          id="add_user"
        >
          <button id="add-user-button" className="btn btn-primary">
            Add User <FontAwesomeIcon icon={faPlus} />
          </button>
        </Link>
      </div>
      <ScrollBar className="mt-2">
        <div
          className="fixHeight"
          style={{ height: "calc(100vh - 240px - 50px)" }}
        >
          {isLoading ? (
            <div className="spinner-div">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  {/* <th width="10"></th> */}
                  <th>Name</th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th width="10px"></th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, i) => (
                  <tr key={`user-${i}`}>
                    <td>
                      <span className="green-bold-text">
                        {user.first_name + " " + user.last_name}
                      </span>
                    </td>
                    <td>
                      <span className="green-bold-text">
                        {roleNames[user.role]}
                      </span>
                    </td>
                    <td>
                      <span className="one-line-ellipsis text-email">
                        {user.email}
                      </span>
                    </td>
                    <td>
                      <span className="one-line-ellipsis text-email">
                        {user.phone_number
                          ? new AsYouType("US").input(user.phone_number)
                          : "N/A"}
                      </span>
                    </td>
                    <td>
                      <div>
                        <Link
                          style={{
                            textDecoration: "none",
                            width: "32px",
                            cursor: "pointer",
                          }}
                          to={`/settings/${
                            user.role === authRoles.buInspector
                              ? "inspector"
                              : "subadmin"
                          }/${user.id}`}
                        >
                          <button className="next-btn ripple">
                            <FontAwesomeIcon icon={faCaretRight} />
                          </button>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
                {users.length === 0 && (
                  <tr>
                    <td colSpan="4">No record found</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </ScrollBar>
      <Paginator
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        onChange={(page) => {
          setSkip(false);
          setPage(page);
        }}
      />
    </div>
  );
};

export default ManageUsers;
