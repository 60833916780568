import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useHistory, useParams } from "react-router-dom";
import { getCustomers } from "../../../services/CustomerService";
import { createProperty } from "../../../services/PropertyService";
import { uploadToS3 } from "../../../services/s3";
import CustomInput from "../../components/Custom/CustomInput";
import SearchDropdown from "../../components/SearchDropdown";
import { getErrorMsg, showToast } from "../../shared/helpers";
import { fileSizeLimits, folders } from "../../shared/validations";
import "./style.css";

const AddProperty = () => {
  const history = useHistory();

  const { customerId, customerName } = useParams();
  const [dis, setdis] = useState(false);

  useEffect(() => {
    if (customerId) {
      setpay((pay) => ({ ...pay, customer: customerId }));
    }
  }, [customerId, customerName]);

  const [isSubmit, setIsSubmit] = useState(false);

  const [pay, setpay] = useState({
    customer: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const [errors, seterrors] = useState({});

  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [, setFile_e] = useState("");

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("Photo cannot be larger than 2MB");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG or PNG");
      } else {
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  const handleSave = () => {
    console.log("file", file);
    return new Promise((resolve, reject) => {
      if (file) {
        uploadToS3(file, folders.property)
          .then((res) => {
            console.log(res);
            resolve(res.url);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve("");
      }
    });
  };

  const submit = () => {
    setIsSubmit(true);
    if (pay.address1 && pay.customer && pay.city && pay.state && pay.zipcode) {
      setdis(true);
      handleSave()
        .then((url) => {
          console.log(url);
          createProperty({ ...pay, image_url: url })
            .then((res) => {
              setdis(false);
              if (res?.data?.id) {
                history.push("/properties/" + res.data.id);
              } else {
                history.push("/properties");
              }
            })
            .catch((err) => {
              console.log(err?.response);
              setdis(false);
              if (err?.response?.status === 422) {
                seterrors(err?.response?.data);
              } else {
                showToast(getErrorMsg(err));
              }
            });
        })
        .catch((err) => {
          setdis(false);
          console.log(err);
          showToast("Error while uploading image");
        })
    }
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        <div className="main-content-area">
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary ml-auto"
              disabled={dis}
              onClick={submit}
              id="save_property"
            >
              Save
            </button>
          </div>
          <div>
            <h4>
              <b>Property Details</b>
            </h4>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            type="text"
                            maxLength="100"
                            onChange={(e) =>
                              setpay({ ...pay, address1: e.target.value })
                            }
                            id="first_street"
                          />
                        </div>
                        <label
                          className={`${
                            pay.address1 !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="stat"
                        >
                          Street 1
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {isSubmit && pay.address1 === ""
                          ? "Street 1 cannot be empty"
                          : errors?.address1
                          ? errors?.address1[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            id="second_street"
                            type="text"
                            maxLength="100"
                            onChange={(e) =>
                              setpay({ ...pay, address2: e.target.value })
                            }
                          />
                        </div>
                        <label
                          className={`${
                            pay.address2 !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="st2"
                        >
                          Street 2 (Optional)
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {errors?.address2 ? errors?.address2[0] : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            type="text"
                            maxLength="50"
                            onChange={(e) =>
                              setpay({ ...pay, city: e.target.value })
                            }
                            id="city"
                          />
                        </div>
                        <label
                          className={`${
                            pay.city !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="cty"
                        >
                          City
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {isSubmit && pay.city === ""
                          ? "City cannot be empty"
                          : errors?.city
                          ? errors?.city[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            id="state"
                            type="text"
                            maxLength="50"
                            onChange={(e) =>
                              setpay({ ...pay, state: e.target.value })
                            }
                          />
                        </div>
                        <label
                          className={`${
                            pay.state !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="state"
                        >
                          State
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {isSubmit && pay.state === ""
                          ? "State cannot be empty"
                          : errors?.state
                          ? errors?.state[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="form-control input-field"
                            id="zip_code"
                            type="text"
                            maxLength="50"
                            onChange={(e) =>
                              setpay({ ...pay, zipcode: e.target.value })
                            }
                          />
                        </div>
                        <label
                          className={`${
                            pay.zipcode !== "" ? "Active " : ""
                          }auth-input-field`}
                          htmlFor="zip"
                        >
                          Zip Code
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {isSubmit && pay.zipcode === ""
                          ? "Zip code cannot be empty"
                          : errors?.zipcode
                          ? errors?.zipcode[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="upload-avatar">
                  <div className="image-area" style={{ borderRadius: 24 }}>
                    {!preview ? (
                      <span
                        className="upload-icon"
                        style={
                          preview
                            ? {
                                backgroundColor: "transparent",
                                borderRadius: 24,
                              }
                            : { backgroundColor: "#fff", borderRadius: 24 }
                        }
                      >
                        <FontAwesomeIcon icon={faCamera} />
                      </span>
                    ) : null}
                    <img
                      width="120"
                      className="avatar-lg"
                      style={{ borderRadius: 24 }}
                      src={preview}
                      alt=""
                    />

                    <input
                      id="upload_image"
                      type="file"
                      className="upload-image"
                      accept="image/jpeg,image/png"
                      onChange={(e) => {
                        fileChangedHandler(e);
                      }}
                    />
                  </div>
                  <p>Upload property photo (Optional)</p>
                  <small className="text-danger ml-1">
                    {errors?.image_url ? errors?.image_url[0] : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4>
              <b>Link Customer</b>
            </h4>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    {!customerId ? (
                      <SearchDropdown
                        apiCall={getCustomers}
                        saveProp={"id"}
                        showProp={["first_name", "last_name"]}
                        placeHolder="Choose Linked Customer"
                        onSelect={(val) => {
                          setpay({ ...pay, customer: val?.value || "" });
                        }}
                      />
                    ) : (
                      <CustomInput
                        label="Linked Customer"
                        value={customerName}
                        inputProps={{ disabled: true }}
                      />
                    )}
                    <small className="text-danger ml-1">
                      {isSubmit && pay.customer === ""
                        ? "Customer cannot be empty"
                        : errors?.customer
                        ? errors?.customer[0]
                        : ""}
                    </small>
                    {/* <div className="form-group">
                      <select
                        value={pay.customer}
                        id="select_customer"
                        onChange={(e) =>
                          setpay({ ...pay, customer: e.target.value })
                        }
                        className="form-control"
                        style={{
                          fontStyle: "italic",
                          fontWeight: "bold",
                          color: "rgb(108 117 125)",
                        }}
                      >
                        <option value="" defaultValue disabled="disabled">
                          Choose Linked Customer
                        </option>
                        {customers.map((item, i) => (
                          <option key={`ap-customer-${i}`} value={item.id}>
                            {item.first_name + " " + item.last_name}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger ml-1">
                        {isSubmit && pay.customer === ""
                          ? "Customer cannot be empty"
                          : errors?.customer
                          ? errors?.customer[0]
                          : ""}
                      </small>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  );
};

export default AddProperty;
