import { folders } from "main/shared/validations";
import axios from "../axios-defaults";

const getReportData = (id) => {
  return axios.get(`/api/v1/report/${id}`);
};

const getReportDataV2 = (id, q, signal) => {
  return axios.get(`/api/v2/report/${id}?q=${q}`, { signal: signal });
};

const getReportPdf = (data, inspection_id) => {
  return axios.post(process.env.REACT_APP_PDF_ENDPOINT, {
    report: data,
    bucket: process.env.REACT_APP_S3_BUCKET,
    folder: folders.IRpdf,
    inspection_id,
  });
};

const publishReport = (data) => {
  return axios.post("/api/v1/documents", {
    ...data,
    type: "main_report",
  });
};

const saveReport = (id, data) => {
  return axios.post(`/api/v1/report/${id}/save`, data);
};

const refreshReport = (id) => {
  return axios.get(`/api/v1/report/${id}/reset`);
};

export {
  getReportData,
  getReportPdf,
  publishReport,
  saveReport,
  refreshReport,
  getReportDataV2,
};
