/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteButton from "main/components/DeleteButton/DeleteButton";
import { getErrorMsg, showToast } from "main/shared/helpers";
import React, { Suspense, useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { CSSTransition } from "react-transition-group";
import {
  deleteObservationTest,
  markTestStatus,
} from "services/ObservationService";
import ImageBox from "../../../../components/ImageBox/ImageBox";
import TestModalReadOnly from "../../../../components/TestModalReadOnly/TestModalReadOnly";
const TestImagesModal = React.lazy(() => import("./TestImagesModal"));

function ImagesUI({ images, setShow }) {
  const [images2, setImages2] = useState([]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < images.length; i++) {
      temp.push(images[i]);
      if (i === 1) {
        break;
      }
    }
    setImages2(temp);
  }, [images]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <TransitionGroup className="d-flex">
        {images2.map((image, i) => (
          <CSSTransition
            key={`test_image_${i}`}
            timeout={500}
            classNames="item"
          >
            <ImageBox
              src={image.url}
              style={{
                border: "none",
                height: "45px",
                width: "45px",
                boxShadow: "rgb(132 133 122 / 50%) 0px 0px 8px 0px",
              }}
              imgStyle={{
                height: "40px",
                width: "45px",
              }}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      {images.length > 2 && (
        <div
          style={{
            color: "rgb(74, 162, 180)",
            fontSize: "14px",
            fontWeight: "bold",
            width: "45px",
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#EDF6F8",
            borderRadius: "8px",
            margin: "5px 10px 10px 0px",
            cursor: "pointer",
          }}
          onClick={() => setShow(true)}
        >
          {`+${images.length - 2}`}
        </div>
      )}
    </div>
  );
}

export default function TestRow({ test, i, refresh }) {
  const [show, setShow] = useState(false);
  const [showTestDetail, setShowTestDetail] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleDeleteTest = (id) => {
    return (setLoading) => {
      setLoading(true);
      deleteObservationTest(id)
        .then(() => {
          showToast("Test deleted successfully", true);
          setLoading(false);
          refresh();
        })
        .catch((err) => {
          console.log(err);
          showToast(getErrorMsg(err) || "Error deleting test");
          setLoading(false);
        });
    };
  };

  const handleChange = (e) => {
    setDisable(true);
    markTestStatus(
      test.id,
      e.target.checked ? "finished" : "pending",
      test.test_type_id,
      test.sample_type_id
    )
      .then(() => {
        refresh();
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to change test status.");
        refresh();
      })
      .finally(() => setDisable(false));
  };

  return (
    <>
      <tr
        key={`test-${i}`}
        id={`test-${i}`}
        style={{ borderRadius: "10px", height: "72px" }}
        className="shadow"
      >
        <td id={`test_${i}_name`}>
          <div className="d-flex flex-column">
            <span
              className="text-light20"
              style={{ fontSize: "18px", fontWeight: "600" }}
            >
              {test.test.name}
            </span>
            <span className="text-light20 row-sub-text">
              {test.test_sample.name}
            </span>
          </div>
        </td>
        <td id={`test_${i}_completed`}>
          <label
            htmlFor={`test_${i}`}
            className={`text-light20 font-weight-bold checkbox-center${
              disable ? " cursor-disabled" : ""
            }`}
          >
            <input
              className="mr-2"
              id={`test_${i}`}
              type="checkbox"
              defaultChecked={test.status === "finished"}
              onChange={handleChange}
              disabled={disable}
            />
            {test.status === "finished"
              ? "Test completed"
              : "Mark test as completed"}
          </label>
        </td>
        <td id={`test_${i}_email`}>
          <ImagesUI images={test.images} setShow={setShow} />
        </td>
        <td
          width="25"
          style={{ padding: "5px 0px" }}
          id={`test_${i}_test_delete`}
        >
          <DeleteButton
            deleteFunction={handleDeleteTest(test.id)}
            message={"Are you sure you want to delete this test?"}
          />
        </td>
        <td
          style={{ padding: "5px 0px", width: "40px" }}
          id={`test_${i}_detail_button`}
        >
          <button
            className="next-btn ripple"
            onClick={() => setShowTestDetail(true)}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
        </td>
      </tr>
      <Suspense fallback={null}>
        <TestImagesModal
          show={show}
          onHide={() => setShow(false)}
          photos={test.images}
        />
        <TestModalReadOnly
          show={showTestDetail}
          onHide={() => setShowTestDetail(false)}
          test={test}
        />
      </Suspense>
    </>
  );
}
