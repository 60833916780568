import React from "react";
import "./circleProgress.css";

const CircleProgress = ({ styleBig, progressName }) => {
  // console.log(progressVal, "assssss");
  let progressValue;
  if (progressName === "scheduled") {
    progressValue = 1;
  } else if (progressName === "in_progress") {
    progressValue = 2;
  } else if (progressName === "coc_sent") {
    progressValue = 3;
  } else if (progressName === "finished") {
    progressValue = 4;
  } else {
    progressValue = null;
  }
  return (
    <div className="d-flex mt-2">
      <span
        className={`circleProgress ${
          progressValue >= 1 ? "newColor" : "prevColor"
        } ${styleBig ? "bigSize" : "normalSize"}`}
      ></span>{" "}
      <span
        className={` ${progressValue >= 2 ? "newColor" : "prevColor"} ${
          styleBig ? "lineSizeBig" : "circleLine"
        }`}
      ></span>
      <span
        className={`circleProgress ${
          progressValue >= 2 ? "newColor" : "prevColor"
        } ${styleBig ? "bigSize" : "normalSize"}`}
      ></span>{" "}
      <span
        className={`${progressValue >= 3 ? "newColor" : "prevColor"}  ${
          styleBig ? "lineSizeBig" : "circleLine"
        }`}
      ></span>
      <span
        className={`circleProgress ${
          progressValue >= 3 ? "newColor" : "prevColor"
        } ${styleBig ? "bigSize" : "normalSize"}`}
      ></span>
      <span
        className={` ${styleBig ? "lineSizeBig" : "circleLine"} ${
          progressValue >= 4 ? "newColor" : "prevColor"
        }`}
      ></span>
      <span
        className={`circleProgress ${
          progressValue >= 4 ? "newColor" : "prevColor"
        } ${styleBig ? "bigSize" : "normalSize"}`}
      ></span>
    </div>
  );
};

export default CircleProgress;
