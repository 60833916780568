import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { getKeysRequest } from "./auth-service";

export const updateAWS = async (params) => {
  const keys = await getKeysRequest();
  AWS.config.update({
    accessKeyId: keys.data.key,
    secretAccessKey: keys.data.value,
  });
};

export const uploadToS3 = (file, directory = "") => {
  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_S3_BUCKET },
    region: "us-east-1",
  });

  const temp = file.name.split(".");
  const fileExt = temp[temp.length - 1];
  const fileName = directory + uuidv4() + "." + fileExt;
  console.log(fileName);

  const params = {
    ACL: "public-read",
    Key: fileName,
    ContentType: file.type,
    Body: file,
    Bucket: process.env.REACT_APP_S3_BUCKET,
  };

  return new Promise((resolve, reject) => {
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err, data) => {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve({
            ...data,
            url: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`,
          });
        }
      });
  });
};

export const deleteFromS3 = (url) => {
  let Key = url.split("/");
  Key = Key[Key.length - 1];

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_S3_BUCKET, Key },
    region: "us-east-1",
  });

  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key,
  };

  return new Promise((resolve, reject) => {
    myBucket.deleteObject(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        reject();
      } else {
        console.log(data);
        resolve();
      }
    });
  });
};
