/* eslint-disable no-useless-escape */
export const regexes = {
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^<>{}"\/|;:.,~!?@#$%^=&*\\\-\_()+`])[A-Za-z\d\^<>{}"\/|;:.,~!?@#$%^=&*\\\-\_()+`]{8,15}$/,
  email:
    /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/,
  url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
  phone: /^([0-9\-+]){10,20}$/,
  phoneInput: /[^0-9\-+]/gi,
  required: /\S/,
  checkNumber: /([^0-9.]+)/,
  decimalDigit: /^(0|\.)/,
  numberAndDigitLength: /(\d{0,3})[^.]*((?:\.\d{0,2})?)/g,
  styleAttribute: /(style=".+?")/gm,
  htmlTags: /<(.|\n)*?>/g
};
