import React from "react";
import { useSelector } from "react-redux";
import ET from "./EditableText";

export default function TopRightTitle() {
  const title = useSelector((state) => state.report.cover_page?.title);

  return (
    <p>
      <ET reduxKey="cover_page.title" ml="32">
        {title}
      </ET>
    </p>
  );
}
