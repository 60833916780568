/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCamera,
  faCaretDown,
  faCaretRight,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getCustomerById } from "../../../services/CustomerService";
import {
  getInspectionsByProperty,
  getPropertyDetails,
  updateProperty,
} from "../../../services/PropertyService";
import { uploadToS3 } from "../../../services/s3";
import CircleProgress from "../../components/CircleProgress";
import CustomInput from "../../components/Custom/CustomInput";
import Paginator from "../../components/Paginator/Paginator";
import {
  captalizeFirst,
  checkStatus,
  getAddress,
  getErrorMsg,
  setHeader,
  showToast,
  timeConversionUTC,
  utcDate,
} from "../../shared/helpers";
import { fileSizeLimits, folders } from "../../shared/validations";

export default function EditProperty() {
  const [editEnabled, setEditEnabled] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [, setFile_e] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [propertyImage, setPropertyImage] = useState("");

  const [inspections, setinspections] = useState({ data: [] });
  const [page, setpage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [linkAddress, setLinkAddress] = useState("");
  const [customerId, setCustomerId] = useState("");

  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const inspectionsPermission = useSelector(
    (state) => state.user.permissions.inspections
  );
  const defaultAvatar = require("../../../assets/icons/person.png").default;

  useEffect(() => {
    loadProperty(propertyId);
  }, [propertyId]);

  useEffect(() => {
    loadInspections(propertyId, page);
  }, [page]);

  const handleSave = () => {
    setIsSubmit(true);
    if (street1 && city && state && zipcode) {
      setDisableSave(true);
      if (propertyImage !== preview) {
        console.log("upload photo");
        if (propertyImage) {
          console.log("delete photo");
          //deleteFromS3(data?.business_unit?.logo_url);
        }
        uploadPhoto()
          .then((url) => {
            editProperty(url);
          })
          .catch(() => showToast("Unable to upload photo."));
      } else {
        editProperty(null);
      }
    }
  };

  const editProperty = (image) => {
    const data = {
      street1,
      street2,
      city,
      state,
      zipcode,
      image_url: image ? image : null,
    };
    updateProperty(propertyId, data)
      .then((res) => {
        console.log(res);
        loadProperty(propertyId);
        loadInspections(propertyId);
        showToast("Property updated successfully.", true);
        setEditEnabled(false);
      })
      .catch((err) => {
        console.log(err);
        showToast(getErrorMsg(err) || "Unable to update property.");
      })
      .finally(() => setDisableSave(false));
  };

  const loadProperty = (propertyId) => {
    setIsLoading(true);
    getPropertyDetails(propertyId)
      .then((res) => {
        loadCustomer(res.data.customer_id);
        setHeader(dispatch, { title: getAddress(res.data.address) });
        setStreet1(res.data.address.address1);
        setStreet2(res.data.address.address2);
        setCity(res.data.address.city);
        setState(res.data.address.state);
        setZipcode(res.data.address.zipcode);
        setPreview(res.data.image?.url ?? null);
        setPropertyImage(res.data.image?.url ?? null);
        setLinkAddress(getAddress(res.data.address));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const loadInspections = (propertyId, page = 1) => {
    setIsLoading(true);
    getInspectionsByProperty(propertyId, page)
      .then((x) => {
        console.log(x.data);
        setinspections(x.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const loadCustomer = (customerId) => {
    getCustomerById(customerId)
      .then(({ data }) => {
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setPhone(data.phone_number);
        setEmail(data.email);
        setCustomerId(data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadPhoto = () => {
    console.log("file", file);
    return new Promise((resolve, reject) => {
      if (file) {
        uploadToS3(file, folders.property)
          .then((res) => {
            console.log(res);
            resolve(res.url);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve("");
      }
    });
  };

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("Photo cannot be larger than 2MB");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG or PNG");
      } else {
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="main-content-area">
            <div className="d-flex align-items-center">
              {editEnabled ? (
                <div className="d-flex ml-auto">
                  <button
                    className="btn btn-primary ml-auto"
                    id="save_customer"
                    onClick={handleSave}
                    disabled={disableSave}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-light ml-2 low-priority"
                    onClick={() => {
                      setEditEnabled(false);
                      loadProperty(propertyId);
                    }}
                    disabled={disableSave}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary ml-auto"
                  id="edit_property"
                  onClick={() => setEditEnabled(true)}
                >
                  Edit Property <FontAwesomeIcon icon={faPen} color="#fff" />
                </button>
              )}
            </div>
            <div>
              <h4>
                <b>Property Details</b>
              </h4>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Street 1"
                          value={street1}
                          onChange={(e) => setStreet1(e.target.value)}
                          inputProps={{
                            maxLength: "100",
                            disabled: !editEnabled,
                          }}
                        />

                        <small className="text-danger ml-1">
                          {isSubmit && street1 === ""
                            ? "Street 1 cannot be empty"
                            : errors?.address1
                            ? errors?.address1[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Street 2 (Optional)"
                          value={street2 || ""}
                          onChange={(e) => setStreet2(e.target.value)}
                          inputProps={{
                            maxLength: "100",
                            disabled: !editEnabled,
                          }}
                        />

                        <small className="text-danger ml-1">
                          {errors?.address2 ? errors?.address2[0] : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          inputProps={{
                            maxLength: "50",
                            disabled: !editEnabled,
                          }}
                        />

                        <small className="text-danger ml-1">
                          {isSubmit && city === ""
                            ? "City cannot be empty"
                            : errors?.city
                            ? errors?.city[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <CustomInput
                          label="State"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          inputProps={{
                            maxLength: "50",
                            disabled: !editEnabled,
                          }}
                        />

                        <small className="text-danger ml-1">
                          {isSubmit && state === ""
                            ? "State cannot be empty"
                            : errors?.state
                            ? errors?.state[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <CustomInput
                          label="Zip Code"
                          value={zipcode}
                          onChange={(e) => setZipcode(e.target.value)}
                          inputProps={{
                            maxLength: "50",
                            disabled: !editEnabled,
                          }}
                        />

                        <small className="text-danger ml-1">
                          {isSubmit && zipcode === ""
                            ? "Zip code cannot be empty"
                            : errors?.zipcode
                            ? errors?.zipcode[0]
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex">
                    <div className="upload-avatar">
                      <div className="image-area" style={{ borderRadius: 24 }}>
                        {!preview ? (
                          <span
                            className="upload-icon"
                            style={
                              preview
                                ? {
                                    backgroundColor: "transparent",
                                    borderRadius: 24,
                                  }
                                : {
                                    backgroundColor: "#fff",
                                    borderRadius: 24,
                                  }
                            }
                          >
                            <FontAwesomeIcon icon={faCamera} />
                          </span>
                        ) : null}
                        <img
                          width="120"
                          className="avatar-lg"
                          style={{ borderRadius: 24 }}
                          src={preview}
                          alt=""
                        />

                        <input
                          id="upload_image"
                          type="file"
                          className="upload-image"
                          accept="image/jpeg,image/png"
                          onChange={(e) => {
                            fileChangedHandler(e);
                          }}
                          disabled={!editEnabled}
                        />
                      </div>
                      <p>Upload property photo (Optional)</p>
                      <small className="text-danger ml-1">
                        {errors?.image_url ? errors?.image_url[0] : ""}
                      </small>
                    </div>

                    <div className="propertyInfoBlock ml-3">
                      <div className="text-light20 font-weight-bold f-18px">
                        {firstName} {lastName}
                      </div>
                      <div className="text-email">
                        {email}
                      </div>
                      <div className="text-phone">
                        {new AsYouType("US").input(phone)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center pt-5">
                <img
                  src={
                    require(`../../../assets/icons/list_light20@3x.png`).default
                  }
                  alt="home"
                  width="20px"
                  className="mr-2"
                />
                <span
                  className="text-light20 font-weight-bold"
                  style={{ fontSize: "20px" }}
                >
                  Inspections
                </span>
              </div>
              <div className={!inspectionsPermission && "cursor-disabled"}>
                <Link
                  style={{
                    textDecoration: "none",
                    ...(!inspectionsPermission && {
                      pointerEvents: "none",
                    }),
                  }}
                  to={
                    `/inspections/new/${customerId}/${firstName} ${lastName}` +
                    `/${propertyId}/${linkAddress}`
                  }
                  id="add_inspection"
                >
                  <button id="add-property-button" className="btn btn-primary">
                    Add Inspection <FontAwesomeIcon icon={faPlus} />
                  </button>
                </Link>
              </div>
            </div>

            <table className="table table-responsive-md inspections-display">
              <thead>
                <tr>
                  <th>
                    Inspection ID
                    <FontAwesomeIcon icon={faCaretDown} size="lg" />
                  </th>
                  <th>
                    Time/Date <FontAwesomeIcon icon={faCaretDown} size="lg" />
                  </th>
                  <th>
                    Inspector <FontAwesomeIcon icon={faCaretDown} size="lg" />
                  </th>
                  <th>
                    Address <FontAwesomeIcon icon={faCaretDown} size="lg" />
                  </th>
                  <th>
                    Name <FontAwesomeIcon icon={faCaretDown} size="lg" />
                  </th>
                  <th>
                    Status <FontAwesomeIcon icon={faCaretDown} size="lg" />
                  </th>
                  <th width="10"></th>
                </tr>
              </thead>
              <tbody>
                {inspections?.data && inspections.data.length !== 0 ? (
                  inspections.data.map((insp) => (
                    <tr id={`inspection-${insp.id}`} key={insp.id}>
                      <td id={`inspection_${insp?.id}_id`}>
                        <strong className="text-light20">#{insp?.code}</strong>
                      </td>
                      <td id={`inspection_${insp?.id}_scheduled_time`}>
                        <>
                          <strong className="text-light20">
                            {utcDate(insp?.scheduled_time)}
                          </strong>
                          <br />
                          <strong className="text-light20">
                            {timeConversionUTC(insp?.scheduled_time)}
                          </strong>
                        </>
                      </td>
                      <td
                        id={`inspection_${insp.id}_insp_name`}
                        style={{ paddingRight: "8px" }}
                      >
                        <div className="d-flex">
                          <img
                            width="120"
                            className={`${"avatar"} mr-2 ml-0 `}
                            src={
                              insp.inspectors[0]?.inspector_details &&
                              insp.inspectors[0]?.inspector_details
                                ?.avatar_url !== null
                                ? insp?.inspectors[0]?.inspector_details
                                    ?.avatar_url
                                : defaultAvatar
                            }
                            onError={(ev) => (ev.target.src = defaultAvatar)}
                            alt="Avatar"
                          />
                          <strong className="trunk-text m-auto-custom trunk-name text-light20">
                            {captalizeFirst(insp?.inspectors[0]?.first_name) +
                              " " +
                              insp?.inspectors[0]?.last_name}
                          </strong>
                        </div>
                      </td>
                      <td id={`inspection_${insp.id}_address`}>
                        <p>{getAddress(insp?.property?.address)}</p>
                      </td>
                      <td id={`inspection_${insp.id}_cus_name`}>
                        <strong className="trunk-text m-auto-custom trunk-cname text-light20">
                          {captalizeFirst(insp?.customer?.first_name) +
                            " " +
                            insp?.customer?.last_name}
                        </strong>
                      </td>
                      <td id={`inspection_${insp.id}_status`}>
                        <CircleProgress progressName={insp.status} />
                        <p className="text-progress pt-1">
                          {checkStatus(insp?.status)}
                        </p>
                      </td>
                      <td id={`inspection_${insp?.id}_detail_button`}>
                        <div
                          className={
                            !inspectionsPermission && "cursor-disabled"
                          }
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              width: "32px",
                              cursor: "pointer",
                              ...(!inspectionsPermission && {
                                pointerEvents: "none",
                              }),
                            }}
                            to={"/inspections/" + insp?.id}
                          >
                            <button className="next-btn ripple">
                              <FontAwesomeIcon icon={faCaretRight} />
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="9">No record found</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <Paginator
              activePage={inspections?.current_page}
              itemsCountPerPage={inspections?.per_page}
              totalItemsCount={inspections?.total}
              onChange={(page) => setpage(page)}
            />
          </div>
        )}
      </ScrollBar>
    </div>
  );
}
