import { Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ExpediteModal.module.css";
import { UPDATE_REPORT_DATA } from "store/types/sample_strategy/sampleStrategyTypes";

const ExpediteModal = (props) => {
  const { showExpediteModal, toggleExpediteModal } = props;

  const { sampleStrategyReport } = useSelector((state) => ({
    sampleStrategyReport: state.sampleStrategyReport,
  }));

  const dispatch = useDispatch();

  const { selectedRows, testList, reportData } = sampleStrategyReport;
  const { sample_count, expedites, summary } = reportData;

  const getTotalCount = () =>
    sample_count?.reduce((acc, curr) => acc + curr.count, 0);

  useEffect(() => {
    if (testList && selectedRows && reportData) {
      if (reportData?.sample_count?.length === 0) {
        const updatedSampleCounts = testList.map((testItem) => {
          const normalizedTestName = testItem.testName
            .toLowerCase()
            .replace(/\s+/g, "");
          const count = selectedRows.filter(
            (row) =>
              row.test.name.toLowerCase().replace(/\s+/g, "") ===
              normalizedTestName
          ).length;

          return {
            name: testItem.testName,
            count: count,
            is_active: false,
          };
        });
        dispatch({
          type: UPDATE_REPORT_DATA,
          payload: { ...reportData, sample_count: updatedSampleCounts },
        });
      } else {
        updateSampleCount();
      }
    }
  }, [testList, selectedRows]);

  useEffect(() => {
    getTotalCount();
  }, [sample_count]);

  const updateSampleCount = () => {
    let updatedData = [];
    const updatedSampleCounts = sample_count?.map((testItem) => {
      const normalizedTestName = testItem.name
        .toLowerCase()
        .replace(/\s+/g, "");
      const count = selectedRows.filter(
        (row) =>
          row.test.name.toLowerCase().replace(/\s+/g, "") === normalizedTestName
      ).length;

      if (testItem.count !== count) {
        if (testItem?.is_active) {
          updatedData.push({
            ...testItem,
          });
        }

        return {
          ...testItem,
          count: count,
          is_active: false,
        };
      }
      return testItem;
    });

    if (updatedData.length > 0) {
      updatedData?.map((item) => {
        if (item?.is_active) {
          const response = updateCostsOnRowChange(item?.name, item?.count);
          dispatch({
            type: UPDATE_REPORT_DATA,
            payload: {
              ...reportData,
              sample_count: updatedSampleCounts,
              expedites: response?.updatedExpedites,
              summary: response?.updatedSummary,
            },
          });
        }
      });
    } else {
      const nonZeroCostExpeditesCount = expedites?.filter(
        (expedite) => parseFloat(expedite.cost) > 0
      ).length;

      const updatedShipping = parseFloat(nonZeroCostExpeditesCount) * 85;

      const total = (
        parseFloat(summary?.inspection_fee) +
        parseFloat(summary?.service_fee) +
        parseFloat(summary?.total_sample_cost) +
        parseFloat(updatedShipping) +
        parseFloat(summary?.expediate_fees) -
        parseFloat(summary?.total_discount)
      ).toFixed(2);

      dispatch({
        type: UPDATE_REPORT_DATA,
        payload: {
          ...reportData,
          sample_count: updatedSampleCounts,
          summary: {
            ...summary,
            shipping: updatedShipping,
            total_cost: total,
          },
        },
      });
    }
  };

  const updateCostsOnRowChange = (name, count) => {
    const updatedExpedites = expedites.map((expedite) => {
      if (expedite.tests.includes(name)) {
        const existingCost = parseFloat(expedite.cost || 0);
        const newCost = existingCost - parseFloat(expedite.charge * count);

        return {
          ...expedite,
          cost: Math.max(0, newCost).toFixed(2),
        };
      }
      return expedite;
    });

    const totalExpediateFees = updatedExpedites
      .reduce((total, expedite) => {
        return total + parseFloat(expedite.cost);
      }, 0)
      .toFixed(2);

    const nonZeroCostExpeditesCount = updatedExpedites?.filter(
      (expedite) => parseFloat(expedite.cost) > 0
    ).length;

    const updatedShipping = parseFloat(nonZeroCostExpeditesCount) * 85;

    const total = (
      parseFloat(summary?.inspection_fee) +
      parseFloat(summary?.service_fee) +
      parseFloat(summary?.total_sample_cost) +
      parseFloat(updatedShipping) +
      parseFloat(totalExpediateFees) -
      parseFloat(summary?.total_discount)
    ).toFixed(2);

    const samplingBalance = (
      Number(summary?.service_fee) +
      Number(summary?.total_sample_cost) +
      Number(updatedShipping) +
      Number(totalExpediateFees) -
      Number(summary?.total_discount)
    ).toFixed(2);

    const updatedSummary = {
      ...summary,
      expediate_fees: totalExpediateFees,
      shipping: updatedShipping,
      sampling_balance: samplingBalance,
      total_cost: total,
    };

    return { updatedSummary, updatedExpedites };
  };

  const handleChangeCheck = (e) => {
    const { name, value, checked } = e.target || e;

    const updateSampleCount = sample_count.map((val) =>
      val.name === name ? { ...val, is_active: checked } : val
    );
    const response = updateCosts(name, value, checked);

    dispatch({
      type: UPDATE_REPORT_DATA,
      payload: {
        ...reportData,
        sample_count: updateSampleCount,
        expedites: response?.updatedExpedites,
        summary: response?.updatedSummary,
      },
    });
  };

  const updateCosts = (name, count, isChecked) => {
    const normalizedTestName = name.toLowerCase().replace(/\s+/g, "");
    const updatedExpedites = expedites.map((expedite) => {
      if (
        expedite.tests.some(
          (test) =>
            test.toLowerCase().replace(/\s+/g, "") === normalizedTestName
        )
      ) {
        const existingCost = parseFloat(expedite.cost || 0);
        const newCost = isChecked
          ? existingCost + parseFloat(expedite.charge * count)
          : existingCost - parseFloat(expedite.charge * count);

        return {
          ...expedite,
          cost: Math.max(0, newCost).toFixed(2),
        };
      }
      return expedite;
    });

    const totalExpediateFees = updatedExpedites
      .reduce((total, expedite) => {
        return total + parseFloat(expedite.cost);
      }, 0)
      .toFixed(2);

    const nonZeroCostExpeditesCount = updatedExpedites.filter(
      (expedite) => parseFloat(expedite.cost) > 0
    ).length;

    const updatedShipping = parseFloat(nonZeroCostExpeditesCount) * 85;

    const total = (
      parseFloat(summary?.inspection_fee) +
      parseFloat(summary?.service_fee) +
      parseFloat(summary?.total_sample_cost) +
      parseFloat(updatedShipping) +
      parseFloat(totalExpediateFees) -
      parseFloat(summary?.total_discount)
    ).toFixed(2);

    const samplingBalance = (
      Number(summary?.service_fee) +
      Number(summary?.total_sample_cost) +
      Number(updatedShipping) +
      Number(totalExpediateFees) -
      Number(summary?.total_discount)
    ).toFixed(2);

    const updatedSummary = {
      ...summary,
      expediate_fees: totalExpediateFees,
      sampling_balance: samplingBalance,
      shipping: updatedShipping,
      total_cost: total,
    };

    return { updatedSummary, updatedExpedites };
  };

  return (
    <Modal
      show={showExpediteModal}
      onHide={toggleExpediteModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          <h2 className={styles.heading}>Expedites</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ whiteSpace: "pre-wrap" }}>
        <div className="row">
          <div className="col-md-7 sample-table">
            <table className={`${styles.table} shadow`}>
              <thead>
                <tr>
                  <th
                    className={styles.header}
                    colSpan="2"
                    style={{ width: "80%" }}
                  >
                    Sample Count
                  </th>
                  <th className={styles.header} style={{ width: "20%" }}>
                    Expedite
                  </th>
                </tr>
              </thead>
              <tbody>
                {sample_count?.length ? (
                  sample_count?.map((item, index) => {
                    return (
                      <tr
                        className={styles.tableSampleRow}
                        key={`${item.name}-${index}`}
                      >
                        <td style={{ width: "60%", padding: "0 20px" }}>
                          {item.name}
                        </td>
                        <td className="text-center">{item?.count}</td>
                        <td
                          className={`${styles.selectCnt} text-center`}
                          onClick={() => {
                            if (item?.count > 0) {
                              handleChangeCheck({
                                name: item?.name,
                                value: item?.count,
                                checked: !item?.is_active,
                              });
                            }
                          }}
                        >
                          <input
                            disabled={item?.count === 0}
                            className={styles.select_checkbox}
                            type="checkbox"
                            name={item?.name}
                            onChange={handleChangeCheck}
                            value={item?.count}
                            checked={item?.is_active}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No Sample Count
                    </td>
                  </tr>
                )}
                {sample_count?.length ? (
                  <tr className={styles.totalRow}>
                    <td style={{ width: "60%" }}>Total</td>
                    <td className={styles.totalCount}>
                      {getTotalCount() || 0}
                    </td>
                    <td></td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="col-md-5">
            <table className={`${styles.table} shadow`}>
              <thead>
                <tr>
                  <th className={styles.headerExpedites} colSpan={2}>
                    Expedites
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {expedites?.map((item, index) => {
                  return (
                    <tr key={`${item.key}-${index}`}>
                      <td style={{ width: "70%", padding: "0 20px" }}>
                        {item.label}
                      </td>
                      <td className="text-center font-weight-bold">{`$${item.cost}`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div class="w-100 text-right mt-2">
              <button
                className={styles.doneButton}
                onClick={toggleExpediteModal}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ExpediteModal;
