import React from "react";
import { useSelector } from "react-redux";
import DocumentView from "../DocumentView";
import ET from "../EditableText";
import TopRightTitle from "../TopRightTitle";

export default function TemplatePage({ style, templateKey }) {
  const report = useSelector((state) => state.report);

  return (
    <DocumentView style={style}>
      <div className="document-head">
        <header>
          <div className="grid-pg1">
            <div>
              <h4>
                <strong>
                  <ET
                    isPageTitle
                    titleKey={templateKey}
                    reduxKey={templateKey + ".title"}
                    ml="50"
                  >
                    {report[templateKey]?.title}
                  </ET>
                </strong>
              </h4>
            </div>
            <div className="grid-pg1-item2">
              <TopRightTitle />
            </div>
          </div>
        </header>
      </div>

      <div className="doc-body template-body">
        <ET
          reduxKey={templateKey + ".text"}
          ml={templateLimits[templateKey]}
          innerHtml={true}
        >
          {report[templateKey]?.text}
        </ET>
      </div>
    </DocumentView>
  );
}

export const templatePagesArray = [
  "specific_decontamination",
  "generel_decontamination",
  "sampling",
  "laboratory",
  "interpreting",
  "health_effects",
  "reference",
  "limitation",
];

export const templateLimits = {
  specific_decontamination: 26000,
  generel_decontamination: 26000,
  sampling: 26000,
  laboratory: 26000,
  interpreting: 26000,
  health_effects: 26000,
  reference: 26000,
  limitation: 26000,
};
