/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../../axios-defaults";
import { authRoles } from "../../../navigation/route-config";
import { LOG_IN } from "../../../store/actions";
import { useQuery } from "../Auth/ResetPassword/ResetPassword";
import AdminContent from "./AdminTabs/AdminContent/AdminContent";
import BusinessUnit from "./AdminTabs/BusinessUnit/BusinessUnit";
import DefaultTemplates from "./AdminTabs/DefaultTemplates/DefaultTemplates";
import "./style.css";

const SuperAdmin = () => {
  const [key, setKey] = useState("manageBusinessUnit");
  let history = useHistory();
  let query = useQuery();
  let location = useLocation();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.data.role);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (role === authRoles.ultraAdmin) {
      const oldToken = localStorage.getItem("x-auth-token");
      delete axios.defaults.headers.common["X-Custom-Information"];
      axios.defaults.headers.common["Authorization"] = "Bearer " + oldToken;

      localStorage.removeItem("x-auth-token");
      localStorage.setItem("access_token", oldToken);

      const userString = localStorage.getItem("oldUser");
      localStorage.removeItem("oldUser");
      const user = JSON.parse(atob(userString));
      console.log(user);
      dispatch({
        type: LOG_IN,
        payload: user,
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setKey(query.get("tab") || "manageBusinessUnit");
  }, [query]);

  return (
    <div className="main">
      <Tabs
        className="setting-tab-btns"
        defaultActiveKey="manageBusinessUnit"
        activeKey={key}
        onSelect={(k) => {
          history.push(`${location.pathname}?tab=${k}`);
        }}
      >
        <Tab eventKey="manageBusinessUnit" title="Manage Business Unit">
          <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
            {key === "manageBusinessUnit" && (
              <BusinessUnit parentLoading={isLoading} />
            )}
          </ScrollBar>
        </Tab>
        <Tab eventKey="content" title="Content">
          <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
            {key === "content" && <AdminContent />}
          </ScrollBar>
        </Tab>
        <Tab eventKey="deafultTemplates" title="Report Templates">
          {key === "deafultTemplates" && <DefaultTemplates />}
        </Tab>
      </Tabs>
    </div>
  );
};

export default SuperAdmin;
