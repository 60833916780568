/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ImageBox from "../ImageBox/ImageBox";

export default function TestModalReadOnly(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <h5 style={{ fontWeight: "bolder" }}>Test</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={props.onHide}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>
        <div className="dialogbody">
          <div className="mt-3">
            <span className="text-light20 font-weight-bold">Test Type</span>
            <div className="mt-2">
              <span className="gray-bold-text font-italic">
                {props.test.test.name}
              </span>
            </div>
          </div>

          <div className="mt-3">
            <span className="text-light20 font-weight-bold">Sample Type</span>
            <div className="mt-2">
              <span className="gray-bold-text font-italic">
                {props.test.test_sample.name}
              </span>
            </div>
          </div>

          <div className="mt-3">
            <span className="text-light20 font-weight-bold">Sample Info</span>
            <div key={`sample-info-`} className="mt-2">
              <span className="gray-bold-text font-italic">
                {props.test.sample_info
                  .filter((j) => j.pivot.value)
                  .map((i) => i.pivot.value)
                  .join(", ")}
                {props.test.sample_info.filter((j) => j.pivot.value).length ===
                  0 && "No sample info added."}
              </span>
            </div>
          </div>

          <div className="mt-3">
            <span className="text-light20 font-weight-bold">Test Photos</span>
            <div className="mt-2">
              <ScrollBar className="d-flex flex-row pb-1">
                <TransitionGroup className="d-flex">
                  {props.test.images.map((item, i) => (
                    <CSSTransition
                      timeout={500}
                      key={`test_image_${i}`}
                      classNames="item"
                    >
                      <ImageBox src={item.url} />
                    </CSSTransition>
                  ))}
                  {!props.test.images.length ? (
                    <span className="gray-bold-text font-italic">
                      No photos available
                    </span>
                  ) : null}
                </TransitionGroup>
              </ScrollBar>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border"></Modal.Footer>
    </Modal>
  );
}
