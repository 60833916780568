import axios from "../axios-defaults";

class Labs {
    getLabs(page, q=""){
        return axios.get(`/api/v1/labs?page=${page}&q=${q}`);
    }

    postLab(data){
        return axios.post('/api/v1/labs',data);
    }

    getLabById(id){
        return axios.get(`/api/v1/labs/${id}`)
    }

    getTestTypeById(id,labid){
        return axios.get(`/api/v1/test-samples?test_type_id=${id}&lab_id=${labid}`)
    }

    updateLab(id,data){
        Object.assign(data,{_method:'PATCH'})
        return axios.post(`/api/v1/labs/${id}`,data)
    }

    unLinkSample(delid,labid){
        let data = {
            _method:'PATCH',
            unlink_test_sample_id : delid
        }
        return axios.post(`/api/v1/labs/${labid}`,data)
    }
}

const obj = new Labs();

export default obj;