import axios from "../axios-defaults";

const getProtocols = (page = 1, medium, q = "") => {
  return axios.get(
    `/api/v1/protocol?page=${page}${q ? `&medium=${medium}&q=${q}` : ""}`
  );
};

const getProtocolDetails = (id) => {
  return axios.get(`/api/v1/protocol/${id}`);
};

const updateProtocol = (id, data) => {
  return axios.post(`/api/v1/protocol/${id}`, { _method: "patch", ...data });
};

const deleteProtocol = (id, data) => {
  return axios.post(`/api/v1/protocol/${id}`, { _method: "patch", ...data });
};

const createProtocol = (data) => {
  return axios.post(`/api/v1/protocol`, data);
};

const getSampleTypes = (keyword, page = 1, room_id) => {
  return axios.get(
    `/api/v1/protocol/test-samples/${room_id}?${
      keyword ? `search=${keyword}&` : ""
    }page=${page}`
  );
};

const getAvailableSampleTypes = (keyword, page = 1, protocol_id) => {
  return axios.get(
    `/api/v1/protocol/${protocol_id}/test-sample?q=addTestSample&${
      keyword ? `search=${keyword}&` : ""
    }page=${page}`
  );
};

const getAvailableRoomTypes = (keyword, page = 1, protocol_id) => {
  return axios.get(
    `/api/v1/protocol/${protocol_id}/room?q=addRoom&${
      keyword ? `search=${keyword}&` : ""
    }page=${page}`
  );
};

const updateProtocolTitle = (id, data) => {
  return axios.post(`/api/v1/protocol/${id}`, { _method: "patch", ...data });
};

export {
  getProtocols,
  updateProtocol,
  deleteProtocol,
  createProtocol,
  getSampleTypes,
  getProtocolDetails,
  getAvailableSampleTypes,
  getAvailableRoomTypes,
  updateProtocolTitle,
};
