/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { handleKeyDown, handleSelectionChange } from "./ReportTemplateEditor";

const bindings = {
  custom: {
    key: 13,
    handler: () => {},
  },
};

export default function EmailTemplateEditor({
  text,
  disabled,
  maxLength,
  setTemplateBody,
  smallHieght,
  id,
  disableEnter,
}) {
  const editor = useRef(null);
  const [quill, setQuill] = useState(null);
  const [disabledInput, setDisabledInput] = useState(disabled);
  const [body, setBody] = useState("");
  const lengthRef = useRef(null);

  const updateLength = (quill) => {
    if (quill) {
      const html = quill.root.innerHTML;
      const strip_tags = html.replace(/<(?!br\s*\/?)[^>]+>/g, "");
      const strip_enter = strip_tags.replace(/<br\s*\/?>/gi, " ");
      const strip_space = strip_enter.replace(/&nbsp;/gi, " ");
      //const strip_and = strip_space.replace(/&amp;/gi, "&");
      const html_to_text = strip_space;
      const total = html_to_text.length;
      lengthRef.current.innerText = total + "/" + maxLength;
      return total;
    }
  };

  useEffect(() => {
    setQuill(editor.current.getEditor());
    // console.log("editor");
  }, [editor]);

  useEffect(() => {
    if (quill) {
      updateLength(quill);
    }
  }, [quill]);

  useEffect(() => {
    if (quill) {
      updateLength(quill);
    }
  }, [body, quill]);

  useEffect(() => {
    setBody(text);
  }, [text]);

  useEffect(() => {
    setDisabledInput(disabled);
  }, [disabled]);

  useLayoutEffect(() => {
    id && document.getElementById(id).addEventListener("paste", copyListner);
    return () => {
      id &&
        document.getElementById(id).removeEventListener("paste", copyListner);
    };
  }, []);

  useLayoutEffect(() => {
    try {
      console.log("resize");
      const width = document.getElementById("report_header")?.clientWidth;
      if (width > 0) {
        document.getElementsByClassName("ql-editor")[0].style.width =
          width + "px";
      }
    } catch {}
  }, []);

  function copyListner(event) {
    var pastedText = undefined;
    if (window.clipboardData && window.clipboardData.getData) {
      pastedText = window.clipboardData.getData("Text");
    } else if (event.clipboardData && event.clipboardData.getData) {
      pastedText = event.clipboardData.getData("text/plain");
    }
    if (
      pastedText.indexOf("[") !== -1 ||
      pastedText.indexOf("[") !== -1 ||
      pastedText.length + editor?.current?.getEditor()?.getText()?.length >
        maxLength
    ) {
      console.log(pastedText);
      event.preventDefault();
    } else {
      console.log("not pasting", pastedText);
    }
  }

  return (
    <>
      <ReactQuill
        className={`${
          disabledInput ? "disabled" : ""
        } ${"no-toolbar extra-padding"} ${
          smallHieght ? "smallEditor" : "email-template-body"
        }`}
        ref={editor}
        value={body || ""}
        id={id}
        modules={{
          toolbar:
            id === "email_body"
              ? [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ]
              : false,
          history: {
            userOnly: true,
          },
          keyboard: disableEnter ? { bindings } : {},
        }}
        // formats={[]}
        onChange={(val, _, source) => {
          console.log(updateLength(quill), maxLength);
          if (updateLength(quill) > maxLength && source === "user") {
            const selection = quill.getSelection();
            const delta = quill.clipboard.convert(body);
            quill.setContents(delta, "api");
            console.log(selection);
            setTimeout(() => {
              quill.setSelection(selection);
            }, 0);
          } else if (source === "user") {
            setBody(val);
            setTemplateBody(val);
          }
        }}
        readOnly={disabledInput}
        onKeyDown={(event) => {
          if (
            quill &&
            handleKeyDown(
              editor?.current?.getEditor(),
              event,
              maxLength - 1,
              updateLength
            )
          ) {
            event.preventDefault();
          }
        }}
        onKeyPress={() => {
          quill && updateLength(quill);
        }}
        onKeyUp={() => {
          quill && updateLength(quill);
        }}
        onMouseDown={() => {
          console.log("mouse");
          quill && updateLength(quill);
        }}
        onChangeSelection={(range) => {
          quill && handleSelectionChange(quill, range);
        }}
      />
      <small ref={lengthRef} style={{ float: "right", marginRight: "5px" }}>
        0/{maxLength}
      </small>
    </>
  );
}
