import * as Actions from "../actions";

const initialState = {
  showResetSuccess: false,
  logout: false,
  showGoback: false,
  confirmationModal: {
    show: false,
    okText: "",
    cancelText: "",
    title: "",
    message: "",
    onOk: () => {},
  },
  selectedBU: "",
  selectingBU: false,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_PASS_REST:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.LOGOUT_USER:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.SHOW_GOBACK:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.SET_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: action.payload,
      };
    case Actions.HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: { ...state.confirmationModal, show: false },
      };
    case Actions.SET_SELECTED_BU:
      return {
        ...state,
        selectedBU: action.payload,
      };
    case Actions.SET_SLECTING_BU:
      return {
        ...state,
        selectingBU: action.payload,
      };
    default:
      return state;
  }
}
