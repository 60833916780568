import React from "react";

export default function EmptyListIndicator({ text }) {
  return (
    <div
      className="bg-light80 d-flex align-items-center justify-content-center rounded font-weight-bold text-light20"
      style={{ height: "150px" }}
    >
      {text}
    </div>
  );
}
