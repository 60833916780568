export const emails = [
  { email: "bu@example.com" }, // Dev
  { email: "lopez_co@mailinator.com" },
  { email: "charles_co@mailinator.com" }, // QA
  { email: "mark_taylor@mailinator.com" },
  { email: "m_jansen@mailinator.com" },
  { email: "reports@yesweinspect.com" }, // Prod
  { email: "info@yesweinspect.com" }, // Prod
  { email: "jsantoadmin@yesweinspect.com" }, // Prod
  { email: "ejefferys@yesweinspect.com" }, // Prod
  { email: "zchaidezadmin@yesweinspect.com" }, // Prod
  { email: "bkarr@yesweinspect.com" }, // Prod
  { email: "clevy@yesweinspect.com" }, // Prod
  { email: "bbowersock@yesweinspect.com" }, // Prod
  { email: "clevy@yesweinspect.com" }, // Prod
  { email: "dgarway@yesweinspect.com" }, // Prod
  { email: "jallen@yesweinspect.com" }, // Prod
  { email: "hhudson@yesweinspect.com" }, // Prod
  { email: "gabriela@yesweinspect.com" }, // Prod
  { email: "harry_kane@mailinator.com" }, // Prod
  { email: "a_markram@mailinator.com" }, // Prod
];

export const isSampleStrategyReportShown = (user) => {
  const companyEmail = user?.data?.email || "";
  return emails.find((email) => email.email.trim() === companyEmail.trim());
};

//https://backend.scoutproportal.com/dev/api/v1/account

// show_sample_strategy = true
