import * as Actions from "../actions";

const initialState = {
  open: false,
};

export default function sidenavReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SIDENAV:
      return action.payload;
    case Actions.TOGGLE_SIDENAV:
      return { open: !state.open };
    default:
      return state;
  }
}
