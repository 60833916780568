/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DollarInput from "main/components/DollarInput/DollarInput";
import React, { useEffect, useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useHistory } from "react-router";
import { createTest } from "../../../services/TestService";
import CustomInput from "../../components/Custom/CustomInput";
import {
  deleteArrayIndex,
  getErrorMsg,
  showToast,
  updateArrayIndex,
} from "../../shared/helpers";
import "./style.css";

export default function AddTest() {
  const [name, setName] = useState("");
  const [tests, setTests] = useState([{ sampleType: "", cost: "" }]);
  const [sampleInfos, setSampleInfos] = useState([""]);
  const [isSaving, setIsSaving] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const history = useHistory();

  const handleSave = () => {
    setIsSaving(true);
    const test_samples = [];
    const test_costs = [];

    tests.forEach((item) => {
      test_samples.push(item.sampleType);
      test_costs.push(item.cost.replace("$", "").replaceAll(",", ""));
    });

    const data = {
      name: name,
      sample_info: sampleInfos,
      test_samples,
      test_costs,
    };
    createTest(data)
      .then((res) => {
        history.replace("/tools/tests");
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to create test.");
      })
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    if (name && sampleInfos[0] && tests[0].sampleType && tests[0].cost) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [name, sampleInfos, tests]);

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        <div className="main-content-area">
          <div className="d-flex align-items-center">
            <button
              className={`btn btn-primary ml-auto`}
              id="add_test"
              onClick={handleSave}
              disabled={isSaving || disableSave}
            >
              Save
            </button>
          </div>

          <div className="row p-3">
            <div className="col-md-6 col-sm-12">
              <h4>What Kind Of Test Is This?</h4>
              <div className="form-group">
                <CustomInput
                  label="Test Type"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{ maxLength: "50" }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between pr-3 pl-3">
            <h4>What Info Needs To Be Sampled?</h4>
            <button
              className="btn btn-primary mr-1"
              onClick={() => {
                setSampleInfos([...sampleInfos, ""]);
              }}
              disabled={sampleInfos.length === 5}
            >
              Add Sample Info <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          <div className="pr-3 pl-3">
            <div className="row">
              {sampleInfos.map((_, i) => (
                <div className="col-md-5 col-sm-12" key={`sample-info-${i}`}>
                  <div className="form-group d-flex flex-row align-items-center">
                    <CustomInput
                      label="Sample Info"
                      value={sampleInfos[i]}
                      onChange={(e) => {
                        setSampleInfos((state) =>
                          updateArrayIndex(i, state, e.target.value)
                        );
                      }}
                      inputProps={{ maxLength: "50" }}
                      parentClassName="webkit-fill-available"
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-light50 ml-3 cursor-pointer"
                      onClick={() =>
                        sampleInfos.length !== 1 &&
                        setSampleInfos((state) => deleteArrayIndex(i, state))
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between pr-3 pl-3 pt-3">
            <h4>What Are You Testing For?</h4>
            <button
              className="btn btn-primary mr-1"
              onClick={() => {
                setTests([...tests, { sampleType: "", cost: "" }]);
              }}
              disabled={tests.length === 15}
            >
              Add Sample Info <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          {tests.map((_, i) => (
            <div className="row pr-3 pl-3" key={`test-${i}`}>
              <div className="form-group col-md-4">
                <CustomInput
                  label="Sample Type"
                  value={tests[i].sampleType}
                  onChange={(e) =>
                    setTests((state) =>
                      updateArrayIndex(i, state, {
                        sampleType: e.target.value,
                        cost: tests[i].cost,
                      })
                    )
                  }
                  inputProps={{ maxLength: "50" }}
                />
              </div>
              <div className="form-group col-md-4">
                <div className="form-group d-flex flex-row align-items-center">
                  <DollarInput
                    label="Customer Cost"
                    type="text"
                    className="form-control"
                    value={tests[i].cost}
                    onChange={(e) => {
                      setTests((state) =>
                        updateArrayIndex(i, state, {
                          sampleType: tests[i].sampleType,
                          cost: e.target.value,
                        })
                      );
                    }}
                  />

                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-light50 ml-3 cursor-pointer"
                    onClick={() =>
                      tests.length !== 1 &&
                      setTests((state) => deleteArrayIndex(i, state))
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </ScrollBar>
    </div>
  );
}
