/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTestsList } from "../../../services/TestService";
import Paginator from "../../components/Paginator/Paginator";
import { truncate, useDidUpdateEffect } from "../../shared/helpers";
import { IS_SUBSCRIBED } from "store/actions";

import "./style.css";

const TestsListComponent = (props) => {
  const testsList = props.testsList;
  return testsList?.data?.length > 0 ? (
    testsList.data.map((item, i) => (
      <tr key={`test-${i}`}>
        <td className="text-light20">
          <strong>{truncate(item.name)}</strong>
        </td>
        <td className="text-light20">
          <strong>
            {truncate(item.test_samples.map((item) => item.name).join(", "))}
          </strong>
        </td>
        <td>
          <div>
            <Link
              style={{
                textDecoration: "none",
                width: "32px",
                cursor: "pointer",
              }}
              to={"/tools/tests/" + item.id}
            >
              <button className="next-btn ripple">
                <FontAwesomeIcon icon={faCaretRight} />
              </button>
            </Link>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="3">No record found</td>
    </tr>
  );
};

const Tests = () => {
  const [loader, setloader] = useState(true);

  const [testsList, setTestsList] = useState({});
  const [activePage, setActivePage] = useState("");
  const [itemsCountPerPage, setItemsCountPerPage] = useState("");
  const [totalItemsCount, setTotalItemsCount] = useState("");
  const [pageRangeDisplayed] = useState(5);
  const [page, setPage] = useState(1);

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);
  const dispatch = useDispatch();

  useDidUpdateEffect(() => {
    getData(1, searchField, () => {
      setSkip(true);
      setPage(1);
    });
  }, [searchField]);

  useEffect(() => {
    if (!skip) {
      getData(page, searchField);
    } else {
      setSkip(false);
    }
  }, [page]);

  const getData = (page, search, func) => {
    setloader(true);
    getTestsList(page, search)
      .then((res) => {
        console.log("------------------------------------");
        setTestsList(res.data);
        setItemsCountPerPage(parseInt(res.data.per_page));
        setTotalItemsCount(parseInt(res.data.total));
        setActivePage(parseInt(res.data.current_page));
        func && func();
      })
      .catch((err) => {
        console.log(
          err.response.data.subscription,
          "888888888888888888888888888888888888"
        );
        if (err.response.data.subscription === null) {
          // setisSubscribed(false);
          console.log("================");
          dispatch({
            type: IS_SUBSCRIBED,
            payload: {
              isSubscribed: false,
            },
          });
        } else {
          // setisSubscribed(true);
          console.log("11111111111111");
          dispatch({
            type: IS_SUBSCRIBED,
            payload: {
              isSubscribed: true,
            },
          });
        }
        setloader(false);
      })
      .finally(() => {
        setloader(false);
      });
  };

  return (
    <>
      {loader ? (
        <Spinner
          animation="border"
          variant="success"
          className="app-spinner"
          style={{ left: "10%" }}
        />
      ) : (
        <div className="main">
          <div className="main-content-area">
            <div
              className="d-flex align-items-center"
              style={{ justifyContent: "flex-end" }}
            >
              <Link
                style={{ textDecoration: "none" }}
                to="/tools/tests/new"
                id="add_test"
              >
                <button
                  id="add-test-button"
                  className="btn btn-primary ml-auto"
                >
                  Add Test <FontAwesomeIcon icon={faPlus} />
                </button>
              </Link>
            </div>
            <ScrollBar className="mt-2">
              <div className="fixHeight">
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th>Test Type </th>
                      <th>Sample Type </th>

                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <TestsListComponent testsList={testsList} />
                  </tbody>
                </table>
              </div>
            </ScrollBar>
            <nav className="mt-4 mb-3">
              <ul className="pagination justify-content-center mb-0 paginator">
                <Paginator
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={pageRangeDisplayed}
                  onChange={(page) => {
                    setSkip(false);
                    setPage(page);
                  }}
                />
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Tests;
