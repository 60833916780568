import { authRoles } from "navigation/route-config";
import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteBU } from "../../../services/bu-admin-service";
import { LOGOUT_USER, LOG_OUT } from "../../../store/actions";
import { getErrorMsg, showToast } from "../../shared/helpers";
import CustomInput from "../Custom/CustomInput";

const DeleteBUModal = (props) => {
  const [BUCompanyName, setBUCompanyName] = useState("");
  const [disableBtns, setDisableBtns] = useState(false);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.data.role);
  const history = useHistory();

  const DeleteFunctionCall = () => {
    if (BUCompanyName === props.company_name) {
      if (props.delfunc) {
        props.delfunc(setDisableBtns);
      } else {
        deleteBU()
          .then(() => {
            if (role === authRoles.ultraAdmin) {
              history.push("/");
            } else {
              localStorage.clear();
              dispatch({
                type: LOG_OUT,
                payload: {},
              });
              dispatch({
                type: LOGOUT_USER,
                payload: {
                  logout: true,
                },
              });
            }
          })
          .catch((err) => {
            showToast(getErrorMsg(err) || "Unable to delete Business Unit.");
            close();
          });
      }
    } else {
      showToast("Company name doesn't match");
    }
  };

  const close = () => {
    setBUCompanyName("");
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={close}
      size="md"
      aria-labelledby="contained-modal-title"
      centered
      backdropClassName="foo-modal-backdrop"
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title">
          <div className="dialogheader">
            <div className="d-flex">
              <img
                className="iconstyle"
                src={
                  require("../../../assets/icons/attention_light20@3x.png")
                    .default
                }
                alt="icon"
              />
              <h5 style={{ fontWeight: "bolder" }}>Warning</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={close}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <div>
            <span>
              Are you sure you want to delete this account? All associated
              inspections, users, reports and data will also be deleted / non
              recoverable.
            </span>
          </div>
          <div className="mt-3">
            <span>
              Please type{" "}
              <b className="user-select-none">{props.company_name}</b> to
              confirm.
            </span>
          </div>

          <div className="align-text-coupon pt-4">
            <div className="row" style={{ width: "80%" }}>
              <div className="col-md-12">
                <div className="form-group">
                  <CustomInput
                    label="Company Name"
                    value={BUCompanyName}
                    onChange={(e) => setBUCompanyName(e.target.value)}
                    inputProps={{ autoComplete: "off" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          variant="light"
          onClick={DeleteFunctionCall}
          style={{
            color: "#962424",
            background: "transparent",
            border: "none",
          }}
          id="delete"
          disabled={disableBtns}
        >
          Delete
        </Button>
        <Button
          style={{ marginRight: 25 }}
          onClick={close}
          id="dont_delete"
          disabled={disableBtns}
        >
          Don't Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteBUModal;
