import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "main/components/Custom/CustomInput";
import { getErrorMsg, showToast } from "main/shared/helpers";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { addCaption } from "services/ObservationService";

export default function AddCaption({ caption, edit }) {
  return (
    <div className="mx-auto caption-text">
      {caption || <b>Add Caption</b>}{" "}
      <FontAwesomeIcon
        icon={faPen}
        color="#49A2B4"
        style={{ cursor: "pointer" }}
        onClick={edit}
      />
    </div>
  );
}

export function AddCaptionModal(props) {
  const [text, setText] = useState("");
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setText(props.data.caption || "");
    setId(props.data.id);
  }, [props.data]);

  const handleUpdateCaption = () => {
    setIsLoading(true);
    addCaption(id, { caption: text })
      .then(() => {
        showToast("Caption updated successfully.", true);
        setIsLoading(false);
        props.refresh();
        props.onHide();
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to updated caption.");
        setIsLoading(false);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={() => !isLoading && props.onHide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <h5 style={{ fontWeight: "bolder" }}>Update Caption</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={
                require("../../../../../assets/icons/x_light50@3x.png").default
              }
              alt="cross"
              onClick={() => !isLoading && props.onHide()}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <CustomInput
            label="Caption"
            value={text}
            onChange={(e) => setText(e.target.value)}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          id="cancel"
          className="text-secondary500 bg-transparent border-0"
          onClick={props.onHide}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          style={{ marginRight: 25 }}
          id="save"
          onClick={handleUpdateCaption}
          disabled={isLoading}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
