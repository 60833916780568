import axios from "../axios-defaults";

class ExtraFees {
  getExtraFees() {
    return axios.get(`/api/v1/extra-fees`);
  }

  updateExtraFees(data) {
    Object.assign(data, { _method: "patch" });
    return axios.post(`/api/v1/extra-fees`, data);
  }
}

const obj = new ExtraFees();

export default obj;
