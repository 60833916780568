/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { contentRequest } from "../../../services/auth-service";

export default function TermsAndPolicy() {
  const [html, setHtml] = useState("<div />");
  const location = useLocation();

  useEffect(() => {
    contentRequest(location.pathname.substring(1))
      .then((res) => {
        setHtml(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div
      style={{ overflowY: "auto", height: "100vh", wordBreak: "break-word" }}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  );
}
